// const auth = {
//   isAuthenticated: true,
//   role: 'admin',
//   activeRole: 'doctor',
//   userDetails: {
//     name: 'Hemanth',
//     email: 'h@wipadika.com',
//     phoneNumber: '9545366277',
//   },
//   idToken: '',
//   accessToken: '',
//   refreshToken: '',
//   username: '',
//   userPoolId: ''
// };

export default function setAuthentication(auth) {
  return new Promise(resolve => {
    const {
      isAuthenticated,
      role,
      activeRole,
      userDetails,
      username,
      userPoolId,
    } = auth;
    const result = {
      isAuthenticated,
      role,
      activeRole,
      userDetails,
      username,
      userPoolId,
    };
    resolve(result);
  });
}
