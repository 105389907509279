import setAuthentication from '../services/authentication';

export const START_AUTHENTICATION = 'START_AUTHENTICATION';
export const STOP_AUTHENTICATION = 'STOP_AUTHENTICATION';
export const LOAD_AUTHENTICATION = 'LOAD_AUTHENTICATION';

const initialState = {
  auth: {
    isAuthenticated: false,
    role: '',
    activeRole: '',
    userDetails: {
      name: '',
      email: '',
      phone_number: '',
    },
    userId: '',
    username: '',
    userPoolId: '',
  },
};

function loadAuthentication(state, action) {
  const newState = {
    ...state,
    auth: action.auth,
  };
  return newState;
}

function startAuthentication(state) {
  return {
    ...state,
    isLoading: true,
  };
}

function stopAuhentication(state) {
  return {
    ...state,
    isLoading: false,
  };
}

export default function authenticate(state = initialState, action) {
  switch (action.type) {
    case LOAD_AUTHENTICATION:
      return loadAuthentication(state, action);
    case START_AUTHENTICATION:
      return startAuthentication(state);
    case STOP_AUTHENTICATION:
      return stopAuhentication(state);
    default:
      return state;
  }
}

export function addAuthtoReduxStore(user) {
  return (dispatch, getState) => {
    const promises = [setAuthentication(user)];
    dispatch({ type: START_AUTHENTICATION });
    return Promise.all(promises)
      .then(results => {
        const [auth] = results;
        dispatch({
          type: LOAD_AUTHENTICATION,
          auth,
        });
        dispatch({ type: STOP_AUTHENTICATION });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch({ type: STOP_AUTHENTICATION });
      });
  };
}
