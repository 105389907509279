/* eslint-disable no-nested-ternary */
import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button, makeStyles } from '@material-ui/core';
import apiCaller from '../../../utils/apiCallerESP';

const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid lightgray 2px',
  // borderRadius: '7px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',

  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};

const formStyles = { display: 'flex', alignItems: 'baseline' };
const labelStyles = { margin: '10px' };
const profileImage = { width: '600px', height: '400px', objectFit: 'cover' };
const callToAction = {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '15px',
};
const profileImageContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: { marginTop: '40px' },
  dialogActions: { display: 'flex', alignContent: 'baseline' },
  viewButton: { backgroundColor: '#3366FF', color: 'white' },
  textField: { width: '600px' },
  activeButton: {
    backgroundColor: '#FF0000',
    color: 'white',
    // marginTop: '10px',
  },
}));
const CompanyDetails = ({
  user,
  agentId,
  active,
  agentDetails,
  rejectAgentHandle,
}) => {
  const classes = useStyles();
  const [flag, setFlag] = React.useState(false);
  const [remark, setRemark] = React.useState('');
  //   const handleClose = () => {
  //     setOpen(false);
  //   };
  const handleActive = () => {
    active(2);
  };
  // const handleReject = event => {
  //   setFlag(!flag);
  // };
  const handleReject = async event => {
    const endpoint = 'activate-agent';
    const data = {
      agent_id: agentId.toString(),
      super_admin_email_id: user.email,
      approval_status: false,
      remark,
    };
    await apiCaller(endpoint, data);
    setFlag(!flag);
    rejectAgentHandle(false);
  };

  return (
    <div>
      <DialogContent className={classes.DialogContent}>
        <fieldset style={shifter}>
          <legend style={{ maxWidth: '300px' }}>
            <div style={avatarContainer}>
              <div style={avatar}>
                <AccountBalanceIcon style={{ color: 'white' }} />
              </div>
              <Typography variant="h6" gutterBottom>
                Company Details
              </Typography>
            </div>
          </legend>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <div>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                >
                  <Grid item xs={12}>
                    <div style={profileImageContainer}>
                      <img
                        src={agentDetails.business_card_url}
                        style={profileImage}
                        alt="images"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div>
                      <div style={formStyles}>
                        <Typography variant="subtitle2">
                          Company Name :
                        </Typography>
                        <span style={labelStyles}>
                          <Typography variant="body1">
                            {agentDetails.company_name}
                          </Typography>
                        </span>
                      </div>
                      <div style={callToAction}>
                        <Button
                          variant="contained"
                          className={flag ? classes.activeButton : ''}
                          onClick={handleReject}
                          size="small"
                        >
                          Reject
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <textarea
                    style={{
                      padding: '10px',
                      width: '100%',
                      marginBottom: '10px',
                      borderRadius: '5px',
                      border: '1px solid lightgray',
                    }}
                    placeholder="remarks"
                    rows={4}
                    onChange={event => setRemark(event.target.value)}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </fieldset>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '20px',
          }}
        >
          <Button
            // onClick={handleClose}
            onClick={handleActive}
            className={classes.viewButton}
            variant="contained"
            endIcon={<ArrowForwardIcon />}
          >
            Next Step
          </Button>
        </div>
      </DialogContent>
    </div>
  );
};

export default CompanyDetails;
