/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import { Button, makeStyles, MenuItem, Select } from '@material-ui/core';
import apiCaller from '../../../../utils/apiCallerESP';

const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid lightgray 2px',
  // borderRadius: '7px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',

  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};

const profileImage = { width: '229px', height: '283px', objectFit: 'cover' };
const profileImageContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: { marginTop: '40px' },
  dialogActions: { display: 'flex', alignContent: 'center' },
  filter: { minWidth: '150px', height: '20px' },
}));
const Personaldetails = ({
  submit,
  tripDetails,
  userId,
  selectedAccountId,
}) => {
  const classes = useStyles();
  const [account, setAccount] = React.useState('');
  const [espaccounts, setESPAccounts] = React.useState();
  const handleChange = event => {
    setAccount(event.target.value);
    selectedAccountId(event.target.value);
  };
  const getESPAccounts = async () => {
    // eslint-disable-next-line prefer-const
    let espAccounts = [];
    const data = { transporter_id: userId };
    const endPoint = 'view-esp-accounts';
    const response = await apiCaller(endPoint, data);
    const { body } = response.data;
    for (let keys in body) {
      espAccounts.push(body[keys].account_id);
    }
    setESPAccounts(espAccounts);
  };
  useEffect(() => {
    getESPAccounts();
  }, []);

  return (
    <div>
      <DialogContent className={classes.DialogContent}>
        <fieldset style={shifter}>
          <legend style={{ maxWidth: '300px' }}>
            <div style={avatarContainer}>
              <div style={avatar}>
                <AccountBalanceIcon style={{ color: 'white' }} />
              </div>
              <Typography variant="h6" gutterBottom>
                Account Details
              </Typography>
            </div>
          </legend>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">
                      Account Name : :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {tripDetails.account_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">
                      Account Number :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {tripDetails.account_number}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Bank Name :</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {tripDetails.bank_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">ISFC Code :</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {tripDetails.ifsc_code}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">
                      Balance Amount :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">
                      {' '}
                      {tripDetails.balance}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">ESP Account :</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={account}
                      onChange={handleChange}
                      className={classes.filter}
                    >
                      {espaccounts?.map(Account => (
                        <MenuItem value={Account}>{Account}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={5}
                      className={classes.profileAction}
                    >
                      <Grid item xs={3}>
                        <Button variant="contained">Reject</Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={profileImageContainer}>
                <img
                  src="https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg"
                  style={profileImage}
                />
              </div>
            </Grid>
          </Grid>
        </fieldset>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '20px',
          }}
        >
          <Button
            onClick={submit}
            // onClick={}
            color="primary"
            variant="contained"
            endIcon={<CheckIcon />}
          >
            Approve
          </Button>
        </div>
      </DialogContent>
    </div>
  );
};

export default Personaldetails;
