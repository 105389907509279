/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
import React from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// @material-ui/icons
import PermIdentity from '@material-ui/icons/Lock';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import AddAlert from '@material-ui/icons/AddAlert';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth } from 'aws-amplify';
import purple from '@material-ui/core/colors/purple';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';

import userProfileStyles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx';

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      name: '',
      nameState: '',
      email: '',
      emailState: '',
      companyName: '',
      phoneNumber: '',
      uid: '',
      isLoading: false,
      timeDiff: 0,
      alert: null,
      isCompanyAdmin: false,
      smsAlerts: true,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    };
    this.change = this.change.bind(this);
    this.getCompanyDetails = this.getCompanyDetails.bind(this);
    this.submitUser = this.submitUser.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.submitNewPassword = this.submitNewPassword.bind(this);

    // this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    // Check weather the user is Authenticated
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  submitUser() {}

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  verifyConfirmPassword(value) {
    const { newPassword } = this.state;
    if (value === newPassword) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;

      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'confirmPassword':
        if (this.verifyConfirmPassword(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  submitNewPassword(event) {
    const { oldPassword, newPassword } = this.state;

    this.validateAllFields('changePassword')
      .then(success => {
        if (success) {
          this.setState({
            isLoading: true,
          });
          Auth.currentAuthenticatedUser()
            .then(user => Auth.changePassword(user, oldPassword, newPassword))
            .then(data => {
              this.setState(
                {
                  infoMessage: 'Password Reset successfully',
                  isLoading: false,
                },
                this.showNotification('infoSnack')
              );
              this.clearFields();
            })
            .catch(err => {
              this.setState(
                {
                  errMessage: err.message,
                  isLoading: false,
                },
                this.showNotification('errSnack')
              );
              this.clearFields();
            });
        }
      })
      .catch(err => {
        console.log('error', err);
      });
    event.preventDefault();
  }

  getCompanyDetails(companyId) {}

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked }, this.updateSettingsInDB);
  };

  clearFields() {
    this.setState({
      newPassword: '',
      oldPassword: '',
      confirmPassword: '',
      newPasswordState: '',
      oldPasswordState: '',
      confirmPasswordState: '',
      isLoading: false,
    });
  }

  showNotification(place, message) {
    this.setState({
      message,
    });
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 6000);
    }
  }

  validateAllFields(screen) {
    const { newPassword, confirmPassword } = this.state;
    let error = false;
    return new Promise((resolve, reject) => {
      if (screen === 'changePassword') {
        if (this.verifyLength(newPassword, 1)) {
          this.setState({
            newPasswordState: 'success',
          });
        } else {
          this.setState({ newPasswordState: 'error' });
          error = true;
        }

        if (this.verifyLength(confirmPassword, 1)) {
          this.setState({
            confirmPasswordState: 'success',
          });
        } else {
          this.setState({ confirmPasswordState: 'error' });
          error = true;
        }

        if (this.verifyConfirmPassword(confirmPassword)) {
          this.setState({
            confirmPasswordState: 'success',
          });
        } else {
          this.setState({ confirmPasswordState: 'error' });
          error = true;
        }
      }

      if (!error) {
        resolve(true);
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('validation Failed');
      }
    });
  }

  updateSettingsInDB() {}

  render() {
    const { classes } = this.props;
    const {
      newPassword,
      newPasswordState,
      confirmPassword,
      confirmPasswordState,
      oldPassword,
      oldPasswordState,
    } = this.state;

    return (
      <div>
        <form
          className={classes.form}
          onSubmit={event => this.submitNewPassword(event)}
        >
          <GridContainer>
            <GridItem xs={12} sm={8} md={6}>
              {this.state.alert}
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <PermIdentity />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    <small>Change Password</small>
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={12}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          onChange: event =>
                            this.change(event, 'oldPassword', 'length', 2),
                          type: 'password',
                          value: oldPassword,
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircle
                                className={classes.inputAdornmentIcon}
                              />
                            </InputAdornment>
                          ),
                          placeholder: 'Old Password',
                        }}
                        success={oldPasswordState === 'success'}
                        error={oldPasswordState === 'error'}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={12}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          onChange: event =>
                            this.change(event, 'newPassword', 'length', 2),
                          type: 'password',
                          value: newPassword,
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircle
                                className={classes.inputAdornmentIcon}
                              />
                            </InputAdornment>
                          ),
                          placeholder: 'New Password',
                        }}
                        success={newPasswordState === 'success'}
                        error={newPasswordState === 'error'}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={12}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          onChange: event =>
                            this.change(
                              event,
                              'confirmPassword',
                              'confirmPassword'
                            ),
                          type: 'password',
                          value: confirmPassword,
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircle
                                className={classes.inputAdornmentIcon}
                              />
                            </InputAdornment>
                          ),
                          placeholder: 'Confirm Password',
                        }}
                        success={confirmPasswordState === 'success'}
                        error={confirmPasswordState === 'error'}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer />
                  {this.state.isLoading ? (
                    <GridContainer justify="flex-end">
                      <GridItem>
                        <CircularProgress
                          className={classes.progress}
                          style={{ color: purple[500], textAlign: 'right' }}
                          thickness={7}
                        />
                      </GridItem>
                    </GridContainer>
                  ) : (
                    <Button
                      color="rose"
                      className={classes.updateProfileButton}
                      type="Submit"
                    >
                      Submit
                    </Button>
                  )}
                  <Clearfix />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <Snackbar
            place="tc"
            color="info"
            icon={AddAlert}
            message={this.state.infoMessage || ''}
            open={this.state.infoSnack}
            closeNotification={() => this.setState({ infoSnack: false })}
            close
          />
          <Snackbar
            place="tc"
            color="danger"
            icon={AddAlert}
            message={this.state.errMessage || ''}
            open={this.state.errSnack}
            closeNotification={() => this.setState({ errSnack: false })}
            close
          />
        </form>
      </div>
    );
  }
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
});

export default connect(mapStateToProps)(
  withStyles(userProfileStyles)(UserProfile)
);
