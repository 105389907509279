export const CHECK_TRANSPORTER_EDIT_FORM =
  'transporters/CHECK_TRANSPORTER_EDIT_FORM';

const initialState = {
  isTransporterEditForm: false,
  selectedTransporterData: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHECK_TRANSPORTER_EDIT_FORM:
      return {
        ...state,
        isTransporterEditForm: action.isTransporterEditForm,
        selectedTransporterData: action.selectedTransporterData,
      };
    default:
      return state;
  }
};

export const switchTransporterActivePage = (
  activePage,
  transporterData
) => dispatch => {
  dispatch({
    type: CHECK_TRANSPORTER_EDIT_FORM,
    isTransporterEditForm: activePage,
    selectedTransporterData: transporterData,
  });
};
