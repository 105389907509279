/* eslint-disable no-var */
/* eslint-disable guard-for-in */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Button from '@material-ui/core/Button';
import EnableESPModal from './EnableESPModal';
import SingleTransporterDetailsDialog from './SingleTransporterDetailsDialog';
import ESPStatement from './ESPStatement';

// var offset = 0;

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: { backgroundColor: 'black' },
  tableHeadCell: { color: 'white' },
  tableBodyRow: { height: '50px' },
});

function createData(transporterName, userId, wallet, espStatus, account) {
  return { transporterName, userId, wallet, espStatus, account };
}

export default function TransporterListView({ transporterData }) {
  const [toggle, setToggle] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState('  ');
  const classes = useStyles();

  const toIndianCurrency = num => {
    if (num) {
      const curr = num.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
      });
      return curr;
    }
    return 0;
  };
  const rows = transporterData?.map(data =>
    createData(
      data?.company_name,
      data?.user_id,
      toIndianCurrency(data?.wallet),
      data,
      data
      // data?.data[2]
    )
  );

  const handleToggle = async id => {
    // await getAccountStatement(id);
    setSelectedAccountId(id);
    setToggle(true);
    //
  };
  // const handlePrevious = async () => {
  //   if (offset !== 0) {
  //     offset = offset;
  //   } else offset -= 10;
  // };
  // const handleNext = async () => {
  //   offset += 10;
  // };
  return (
    <div style={{ marginTop: '40px' }}>
      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '10px',
        }}
      >
        <Button onClick={handlePrevious}>Previous</Button>
        <Button onClick={handleNext}>Next</Button>
      </div> */}
      {!toggle ? (
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableHeadCell}>
                  Transporter Name
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="left">
                  User ID
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="right">
                  ESP Status
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map(row => (
                <TableRow className={classes.tableBodyRow} key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.transporterName}
                  </TableCell>
                  <TableCell align="left">{row.userId}</TableCell>
                  {/* <TableCell align="left" />
                  <TableCell align="right" /> */}
                  <TableCell align="right">
                    <EnableESPModal data={row.espStatus} />
                  </TableCell>
                  <TableCell align="right">
                    <SingleTransporterDetailsDialog
                      handleToggle={value => handleToggle(value)}
                      transporterData={row.account}
                    />
                    {/* <CheckMap handleToggle={value => setToggle(value)} /> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <ESPStatement
          selectedAccountId={selectedAccountId}
          // transactionData={statementDetails}
          handleToggle={value => handleToggle(value)}
        />
      )}
    </div>
  );
}
