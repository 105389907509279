import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button, makeStyles } from '@material-ui/core';

const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid lightgray 2px',
  // borderRadius: '7px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',

  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};

const profileImage = { width: '229px', height: '283px', objectFit: 'cover' };
const profileImageContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: { marginTop: '40px' },
  dialogActions: { display: 'flex', alignContent: 'center' },
}));
const Personaldetails = ({ active, tripDetails, remarks }) => {
  const classes = useStyles();
  //   const handleClose = () => {
  //     setOpen(false);
  //   };
  const handleActive = () => {
    active(1);
  };

  return (
    <div>
      <DialogContent className={classes.DialogContent}>
        <fieldset style={shifter}>
          <legend style={{ maxWidth: '300px' }}>
            <div style={avatarContainer}>
              <div style={avatar}>
                <AccountCircleIcon
                  style={{ color: 'white' }}
                  fontSize="large"
                />
              </div>
              <Typography variant="h6" gutterBottom>
                Personal Details
              </Typography>
            </div>
          </legend>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Name :</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {tripDetails.agent_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Phone Number :</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {tripDetails.phone_number}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">E-Mail ID :</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {tripDetails.email_id}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Gender :</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {' '}
                      {tripDetails.gender}
                    </Typography>
                  </Grid>

                  <Grid container spacing={5} className={classes.profileAction}>
                    <Grid item xs={6}>
                      <Button variant="contained">Reject</Button>
                    </Grid>
                    <Grid item xs={12}>
                      <textarea
                        style={{
                          padding: '10px',
                          width: '100%',
                          marginBottom: '10px',
                          borderRadius: '5px',
                          border: '1px solid lightgray',
                        }}
                        rows={4}
                        onChange={event => remarks(event.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={profileImageContainer}>
                <img
                  src={tripDetails.selfie_url}
                  style={profileImage}
                  alt="imageicon"
                />
              </div>
            </Grid>
          </Grid>
        </fieldset>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '20px',
          }}
        >
          <Button
            // onClick={handleClose}
            onClick={handleActive}
            color="primary"
            variant="contained"
            endIcon={<ArrowForwardIcon />}
          >
            Next Step
          </Button>
        </div>
      </DialogContent>
    </div>
  );
};

export default Personaldetails;
