/* eslint-disable no-prototype-builtins */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable react/require-default-props */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import { setWalletBalance } from '../../reducers/main';
import { setVendors } from '../../reducers/transactions';
import apiCaller from '../../utils/apiCaller';

const defaultButton = props => (
  <Button {...props} color="info">
    {props.children}
  </Button>
);
const style = {
  customCardContentClass: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  cardIconTitle: {
    marginTop: '15px',
    marginBottom: '0px',
  },
};

class Pagination extends React.Component {
  constructor(props) {
    super();
    this.changePage = this.changePage.bind(this);
  }

  static propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    PageButtonComponent: PropTypes.any,
    onPageChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string,
  };

  componentDidMount() {
    this.getTransactionList();
  }

  getTransactionList() {
    const vendorData = [];
    const body = {
      total_items: 10,
    };
    let nextCursorId = null;
    apiCaller('premium-api/read-vendor-list', 'post', body, true, false)
      .then(response => {
        if (response.items.length !== 0) {
          response.items.forEach(dataList => {
            if (
              response.hasOwnProperty('LastEvaluatedKey') &&
              response.LastEvaluatedKey !== null
            ) {
              nextCursorId = response.LastEvaluatedKey;
            }
            vendorData.push({
              color: 'success',
              data: [
                dataList.company_name,
                dataList.trucker_name || '___',
                dataList.phone_number || '___',
                dataList.email_address || '___',
              ],
              dataList,
            });
          });
          this.props.setVendors(vendorData, nextCursorId);
          if (
            response.hasOwnProperty('LastEvaluatedKey') &&
            response.LastEvaluatedKey !== null
          ) {
            this.getNextVendors();
          }
        } else {
          this.props.setVendors([], null);
        }
      })
      .catch(err => {});
  }

  componentWillReceiveProps(nextProps) {
    this.changePage(nextProps.page + 1);
  }

  changePage(page) {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    this.props.onPageChange(page - 1);
  }

  onNext(activePage) {
    // if (activePage === this.props.pages) return;
    if (this.props.vendorLastEvaluatedKey !== null) {
      this.getNextVendors();
    }
    this.changePage(activePage + 1);
  }

  getNextVendors() {
    const { vendorLastEvaluatedKey, vendors } = this.props;
    const vendorData = JSON.parse(JSON.stringify(vendors));
    const body = {
      total_items: 10,
    };
    if (vendorLastEvaluatedKey !== '' || vendorLastEvaluatedKey !== null) {
      body.LastEvaluatedKey = vendorLastEvaluatedKey;
    }
    let nextCursorId = null;
    apiCaller('premium-api/read-vendor-list', 'post', body, true, false)
      .then(response => {
        if (response.items.length !== 0) {
          response.items.forEach(dataList => {
            if (
              response.hasOwnProperty('LastEvaluatedKey') &&
              response.LastEvaluatedKey !== null
            ) {
              nextCursorId = response.LastEvaluatedKey;
            }
            vendorData.push({
              color: 'success',
              data: [
                dataList.company_name,
                dataList.trucker_name || '___',
                dataList.phone_number || '___',
                dataList.email_address || '___',
              ],
              dataList,
            });
          });
          this.props.setVendors(vendorData, nextCursorId);
        }
      })
      .catch(err => {});
  }

  render() {
    const { PageButtonComponent = defaultButton } = this.props;
    const activePage = this.props.page + 1;
    return (
      <GridContainer justify="center">
        <GridItem xs={6} sm={6} md={6}>
          <PageButtonComponent
            className="Table__pageButton"
            onClick={() => {
              if (activePage === 1) return;
              this.changePage(activePage - 1);
            }}
            disabled={activePage === 1}
          >
            {this.props.previousText}
          </PageButtonComponent>
        </GridItem>

        <GridItem xs={6} sm={6} md={6}>
          <PageButtonComponent
            className="Table__pageButton"
            onClick={() => {
              //   if (activePage === this.props.pages) return;
              this.onNext(activePage);
            }}
            disabled={
              (this.props.vendorLastEvaluatedKey === '' ||
                this.props.vendorLastEvaluatedKey === null) &&
              activePage === this.props.pages
            }
            style={{ float: 'right' }}
          >
            {this.props.nextText}
          </PageButtonComponent>
        </GridItem>
      </GridContainer>
    );
  }
}

Pagination.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setWalletBalance,
      setVendors,
    },
    dispatch
  );

const mapStateToProps = state => ({
  walletBalance: state.main.walletBalance,
  vendors: state.transactions.vendors,
  vendorLastEvaluatedKey: state.transactions.vendorLastEvaluatedKey,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Pagination));
