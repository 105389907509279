import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import counter from './counter';
import transactions from './transactions';
import vendorTransactions from './vendorHistory';
import truckers from './truckers';
import transporters from './transporters';
import wallet from './walletList';
import auth from './auth';

import main from './main';

export default combineReducers({
  routing: routerReducer,
  counter,
  transactions,
  vendorTransactions,
  wallet,
  truckers,
  transporters,
  main,
  auth,
});
