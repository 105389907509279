/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-console */
/* eslint-disable guard-for-in */
/* eslint-disable vars-on-top */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-var */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from 'components/Card/Card.jsx';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from '@material-ui/core/Typography';
import Cookie from 'universal-cookie';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from 'axios';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import TableDataView from './TableDataView';
import apiCaller from '../../utils/apiCaller';
import { refreshToken } from '../../services';

const { REACT_APP_END_POINT } = process.env;
const cookies = new Cookie();
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  box: {
    margin: '10px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paper: {
    background: 'lightgray',
    padding: 2,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '2px dashed lightgray',
    borderRadius: '5px',
    // width: '80%',
    height: '95px',
    // margin: '14px 20px',
  },
  sampleButton: {
    background: '#3DAC16',
    color: 'white',
    height: '36px',
    padding: '14px 10px',
  },
  progressBarContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  progressBar: {
    padding: '4px',
    borderRadius: '2px',
  },
}));

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};
const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const headers = [
  { Header: 'Transaction No.', accessor: 'id' },
  { Header: 'Loading Date', accessor: 'created_on' },
  { Header: 'Truck No', accessor: 'geolocation_degrees' },
  { Header: 'Transporter', accessor: 'name' },
  { Header: 'Freight Amount Received', accessor: 'lat' },
  { Header: 'Total', accessor: 'lon' },
  { Header: 'Age', accessor: 'expiry' },
];
var activePageNumber = 0;
var SignedURL = '';
var transporterID = '';
var lastkeyData = { key: '', timestamp: '' };
var DataContainer = [];
var PageRecords = [];
var lastPage = false;

const BulkTransaction = () => {
  const [open, setOpen] = React.useState(false);
  const [url, setUrl] = React.useState('');
  const [historyData, setHistoryData] = React.useState();
  const [progress, setProgress] = React.useState(0);
  const [fetching, setFetching] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const [previousDisabled, setPreviousDisabled] = React.useState(false);
  const classes = useStyles();
  const username = cookies.get('username');
  const token = cookies.get('idToken');
  const role = cookies.get('role');
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setStatus(false);
  };
  async function getSignedUrl(ID) {
    refreshToken();
    const timeStamp = new Date().getTime();
    const endPoint = 'get-signed-url';
    const res = await axios.get(`${REACT_APP_END_POINT}${endPoint}`, {
      params: {
        key: `${ID}&&${username}&&${role}&&${timeStamp}`,
      },
      headers: {
        Authorization: token,
      },
    });
    const signedUrl = res.data.signed_url;
    setUrl(signedUrl);
    SignedURL = signedUrl;
  }

  const onDrop = useCallback(
    async acceptedFiles => {
      setOpen(true);
      await getSignedUrl(transporterID);
      setProgress(10);
      try {
        setProgress(50);
        await axios.put(SignedURL, acceptedFiles[0]);
        setProgress(100);
      } catch (error) {
        console.log(error);
      }
    },
    [url]
  );
  async function getBulkTransactionHistory(
    ID,
    key,
    timestamp,
    next,
    previous,
    activePage
  ) {
    const endPoint = 'bulk-transactions-report';
    if (!previous) {
      const pageSize = PageRecords.length;
      const LastElement = PageRecords[pageSize - 1];
      setPreviousDisabled(false);
      console.log('LAST KEY USED ON NEXT', LastElement);

      setFetching(true);
      try {
        const res = await axios.get(`${REACT_APP_END_POINT}${endPoint}`, {
          params: {
            transporter_id: ID,
            limit: 10,
            lastkey_id: LastElement?.lastkey_id || '',
            lastkey_timestamp: LastElement?.lastkey_timestamp || '',
          },
          headers: {
            Authorization: token,
          },
        });
        const lastEvalKeyData = res.data?.lastEvaluatedKey;
        lastkeyData = {
          key: lastEvalKeyData?.lastkey_id,
          timestamp: lastEvalKeyData?.lastkey_timestamp,
        };
        const chunk = res.data.data;
        const lastkeys = res.data.lastEvaluatedKey;
        if (!lastEvalKeyData) {
          lastPage = true;
        } else {
          // UNLESS THE LAST EVAL KEY RECIEVED IS UNDEFINED WE KEEP ON PPUSHING THE LAST EVAL KEYS IN THE ARRAY
          PageRecords.push(lastkeys);
        }
        DataContainer.push(chunk);
        setHistoryData(chunk);
        setFetching(false);
        if (!lastEvalKeyData && next) {
          setStatus(true);
        }
      } catch (error) {
        console.log('Error', error);
        setFetching(false);
      }
    } else if (previous) {
      PageRecords.pop();
      // AT THIS POINT WE REMOVE THE LAST EVAL KEY FROM THE ARRAY ON PREVIOUS BUTTON CLICK
      const pageSize = PageRecords.length;
      const LastElement = PageRecords[pageSize - 1];
      setFetching(true);
      lastPage = false;
      try {
        const res = await axios.get(`${REACT_APP_END_POINT}${endPoint}`, {
          params: {
            transporter_id: ID,
            limit: 10,
            lastkey_id: LastElement?.lastkey_id || '',
            lastkey_timestamp: LastElement?.lastkey_timestamp || '',
          },

          headers: {
            Authorization: token,
          },
        });
        const lastEvalKeyData = res.data?.lastEvaluatedKey;
        const chunk = res.data.data;
        DataContainer.push(chunk);
        setHistoryData(chunk);
        setFetching(false);
        if (activePage == 0) {
          setPreviousDisabled(true);
          // AT THIS POINT WE UPDATE THE INITAILIZED ARRAY WITH LAST EVAL KEYS RECIEVED FOR THE INITAIL VALUES
          PageRecords.push(lastEvalKeyData);
        }
      } catch (error) {
        console.log('Error', error);
        setFetching(false);
      }
    }
  }
  async function getRefreshedBulkTransactionHistory() {
    // WE USE THIS FUNCTION FOR INITIAL RENDERING AND FOR THE REFRESH VALUES
    setPreviousDisabled(true);
    activePageNumber = 0;
    lastPage = false;
    const endPoint = 'bulk-transactions-report';
    setFetching(true);
    try {
      const res = await axios.get(`${REACT_APP_END_POINT}${endPoint}`, {
        params: {
          transporter_id: transporterID,
          limit: 10,
          lastkey_id: '',
          lastkey_timestamp: '',
        },
        headers: {
          Authorization: token,
        },
      });
      const lastEvalKeyData = res.data?.lastEvaluatedKey;
      lastkeyData = {
        key: lastEvalKeyData?.lastkey_id,
        timestamp: lastEvalKeyData?.lastkey_timestamp,
      };
      const chunk = res.data.data;
      const lastkeys = res.data.lastEvaluatedKey;
      // AT THIS POINT WE WE UPDATE THE ARRAY WITH RECIEVED LAST KEY VALUES FOR NEXT BUTTON API CALL
      PageRecords.push(lastkeys);
      DataContainer.push(chunk);
      setHistoryData(chunk);
      setFetching(false);
    } catch (error) {
      console.log('Error', error);
      setFetching(false);
    }
  }

  const getTransporterId = async () => {
    const body = {};
    apiCaller('premium-api/get-transporter', 'post', body, true, false).then(
      response => {
        const ID = response.transporter_id;
        transporterID = ID;
        getRefreshedBulkTransactionHistory();
      }
    );
  };

  const handleRefreshCall = (key, timestamp, activePage, next, previous) => {
    if (!next && !previous) {
      getRefreshedBulkTransactionHistory();
    } else {
      getBulkTransactionHistory(
        transporterID,
        key,
        timestamp,
        next,
        previous,
        activePage
      );
    }
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  useEffect(() => {
    // AT THIS POINT WE INITIALIAZE PAGERECORDS ARRAY WITH EMPTY VALUES
    const lastKeys = { lastkey_id: '', lastkey_timestamp: '' };
    PageRecords.push(lastKeys);
    getTransporterId();
  }, []);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardIconTitle}>
              <small>Bulk Transaction</small>
            </h4>
          </CardHeader>
          <CardBody>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="baseline"
            >
              <Grid item xs={12} md={5} lg={4}>
                <Box className={classes.box}>
                  <h4 className={classes.cardIconTitle}>
                    <large style={{ color: '#AFB2B7' }}>
                      Upload Excel File
                    </large>
                  </h4>
                  <Button
                    startIcon={<GetAppIcon />}
                    className={classes.sampleButton}
                    type="submit"
                  >
                    <a
                      href="https://s3.ap-south-1.amazonaws.com/com.lobb.assets/LobbPay/BulkUploadPayoutsSample.xlsx"
                      download
                      style={{ color: 'white', textTransform: 'initial' }}
                    >
                      Download Sample
                    </a>
                  </Button>
                </Box>
                {open ? (
                  <div className={classes.progressBarContainer}>
                    <div
                      style={{
                        width: '100%',
                        marginRight: '10px',
                        display: ' flex',
                        flexDirection: 'column',
                      }}
                    >
                      <LinearProgress
                        variant="determinate"
                        value={progress}
                        className={classes.progressBar}
                      />
                      {progress === 100 ? (
                        <Typography
                          variant="body2"
                          style={{ color: 'green', marginTop: '5px' }}
                          gutterBottom
                        >
                          Successfully Uploaded and Queued for Processing.
                        </Typography>
                      ) : (
                        ''
                      )}
                    </div>

                    {progress !== 100 ? (
                      <CancelIcon color="error" />
                    ) : progress === 100 ? (
                      <CheckCircleIcon style={{ color: 'green' }} />
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  // <Box className={classes.container}>
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginTop: 5,
                      }}
                    >
                      <CloudUploadIcon />
                      <p style={{ fontSize: '10px', color: 'gray' }}>
                        Drag and Drop the File or Click here to Upload a File.
                      </p>
                    </div>
                  </div>
                  // </Box>
                )}
              </Grid>
            </Grid>
            <TableDataView
              headers={headers}
              data={historyData}
              fetching={fetching}
              refreshCall={(key, timestamp, activePage, next, previous) =>
                handleRefreshCall(key, timestamp, activePage, next, previous)
              }
              lastDataKey={lastkeyData.key}
              lastDataTimestamp={lastkeyData.timestamp}
              PageRecords={PageRecords}
              lastPage={lastPage}
              previousDisabled={previousDisabled}
              // previousData={() => }
            />
            {/* : ''} */}
          </CardBody>
        </Card>
      </Grid>
      {/* <ReactJsAlert
        status={status} // true or false
        type={type} // success, warning, error, info
        title={title}
        Close={() => setStatus(false)}
      /> */}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={status}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Reached Last Page"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </Grid>
  );
};

export default BulkTransaction;
