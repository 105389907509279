import React, { useState, useEffect } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import WalletCard from './WalletCard';
import ESPOverview from './Dashboard/ESPOverview';
import apiCaller from '../../utils/apiCallerESP';

const Dashboard = () => {
  const [accounts, setAccounts] = useState();
  const [fetching, setFetching] = useState(false);
  const getESPAcounts = async id => {
    setFetching(true);
    const endPoint = 'view-esp-accounts';
    const body = { transporter_id: id };
    const response = await apiCaller(endPoint, body);
    const espAccounts = response.data.body;
    setAccounts(espAccounts);
    setFetching(false);
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser().then(result => {
      getESPAcounts(result.username);
    });
  }, []);
  return (
    <div style={{ padding: '20px 10px' }}>
      {fetching ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid
              container
              spacing={5}
              direction="row"
              // justifyContent="center"
              style={{ justifyContent: 'center', flexWrap: 'wrap' }}
              // alignItems="center"
            >
              <WalletCard espAccounts={accounts} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ESPOverview espAccounts={accounts} />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Dashboard;
