/* eslint-disable no-nested-ternary */
import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import { Button, makeStyles } from '@material-ui/core';
import BankIllustration from '../../../assets/img/Bank Illustration.png';
import apiCaller from '../../../utils/apiCallerESP';

const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid lightgray 2px',
  // borderRadius: '7px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',

  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};
const profileImageContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: { marginTop: '10px' },
  dialogActions: { display: 'flex', alignContent: 'center' },
  filter: { minWidth: '250px' },
  viewButton: { marginTop: '10px' },
  activeButton: {
    backgroundColor: '#FF0000',
    color: 'white',
    marginTop: '10px',
  },
  Button: { margin: '10px' },
  // textField: { width: '600px' },
}));
const Personaldetails = ({
  user,
  agentId,
  active,
  agentDetails,
  rejectAgentHandle,
  status,
}) => {
  const classes = useStyles();
  const [remark, setRemark] = React.useState('');
  const [flag, setFlag] = React.useState(false);
  const handleReject = async () => {
    const endpoint = 'activate-agent';
    const data = {
      agent_id: agentId.toString(),
      super_admin_email_id: user.email,
      approval_status: false,
      remark,
    };
    await apiCaller(endpoint, data);

    setFlag(!flag);
    rejectAgentHandle(false);
  };
  const handleApprove = async () => {
    const endpoint = 'activate-agent';
    const data = {
      agent_id: agentId.toString(),
      super_admin_email_id: user.email,
      approval_status: true,
      remark,
    };
    await apiCaller(endpoint, data);
    setFlag(!flag);
    rejectAgentHandle(false);
  };
  return (
    <div>
      <DialogContent className={classes.DialogContent}>
        <fieldset style={shifter}>
          <legend style={{ maxWidth: '300px' }}>
            <div style={avatarContainer}>
              <div style={avatar}>
                <AccountBalanceIcon style={{ color: 'white' }} />
              </div>
              <Typography variant="h6" gutterBottom>
                Account Details
              </Typography>
            </div>
          </legend>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <div>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Account Name :</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {agentDetails.account_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">
                      Account Number :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {agentDetails.account_number}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Bank Name :</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {agentDetails.bank_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">ISFC Code :</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {agentDetails.ifsc_code}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Branch :</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">
                      {agentDetails.bank_branch_name}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      className={
                        flag ? classes.activeButton : classes.viewButton
                      }
                      onClick={handleReject}
                      variant="contained"
                      size="small"
                    >
                      Reject
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <textarea
                      style={{
                        padding: '10px',
                        width: '100%',
                        marginBottom: '10px',
                        borderRadius: '5px',
                        border: '1px solid lightgray',
                      }}
                      placeholder="remarks"
                      rows={4}
                      onChange={event => setRemark(event.target.value)}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={profileImageContainer}>
                <img src={BankIllustration} alt="imageicon" />
              </div>
            </Grid>
          </Grid>
        </fieldset>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '20px',
          }}
        >
          <Button
            // onClick={() => submit(false)}
            // onClick={}
            onClick={handleApprove}
            className={classes.viewButton}
            variant="contained"
            endIcon={<CheckIcon />}
            disabled={status === 'true'}
          >
            Approve
          </Button>
        </div>
      </DialogContent>
    </div>
  );
};

export default Personaldetails;
