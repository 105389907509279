/* eslint-disable camelcase */
import { Grid, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import RequestsListView from './ESPRequests/RequestsListView';
import apiCaller from '../../utils/apiCallerESP';

// const useStyles = makeStyles(theme => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
//   filter: { minWidth: '250px' },
// }));
const ESPRequest = () => {
  // const classes = useStyles();
  const [user, setUser] = React.useState();
  const [tripDetails, setTripDetails] = React.useState();
  const getTripList = async userID => {
    const endPoint = 'esp-request-list';
    const data = {
      transporter_id: userID,
      limit: '10',
      offset: 0,
    };
    try {
      const response = await apiCaller(endPoint, data);
      const { trip_list } = response.data.body;
      setTripDetails(trip_list);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser().then(result => {
      setUser(result.username);
      getTripList(result.username);
    });
  }, []);
  return (
    <div>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            {/* <Grid item>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                className={classes.filter}
                variant="outlined"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </Grid> */}
            <Grid item>
              <TextField
                id="outlined-basic"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search Broker"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <RequestsListView tripDetails={tripDetails} userId={user} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ESPRequest;
