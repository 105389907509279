/* eslint-disable no-console */
/* eslint-disable camelcase */
// const auth = {
//   isAuthenticated: true,
//   role: 'receptionist',
//   activeRole: 'doctor',
//   userDetails: {
//     name: 'Hemanth',
//     email: 'h@wipadika.com',
//     phoneNumber: '9545366277',
//   },
//   idToken: '',
//   accessToken: '',
//   refreshToken: '',
//   userPoolId: '',
//   username: '',
// };

import { Auth } from 'aws-amplify';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function checkAuth() {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then(user => {
        const { attributes, username, signInUserSession, pool } = user;
        const { userPoolId } = pool;
        const { email, name, phone_number } = attributes;
        const { idToken } = signInUserSession;
        const { jwtToken } = idToken;

        const userGroup = idToken.payload['cognito:groups'][0]; // groups
        let role = 'user';
        switch (userGroup) {
          case 'super_admin':
            role = 'super_admin';
            cookies.set('role', 'super_admin');
            break;
          case 'admin':
            role = 'admin';
            cookies.set('role', 'admin');
            break;
          case 'checker':
            role = 'checker';
            cookies.set('role', 'checker');
            break;
          case 'maker':
            role = 'maker';
            cookies.set('role', 'maker');
            break;
          default:
            cookies.set('role', 'user');
        }
        const userId = username;
        const auth = {
          isAuthenticated: true,
          role,
          activeRole: role,
          userDetails: {
            name,
            email,
            phone_number,
          },
          userId,
          idToken: jwtToken,
          accessToken: signInUserSession.accessToken.jwtToken,
          refreshToken: signInUserSession.refreshToken.token,
          username,
          userPoolId,
        };
        resolve(auth);
      })
      .catch(err => {
        console.log('Error', err);
        const emptyObj = removedAuth();
        reject(emptyObj);
        cookies.remove('email');
        cookies.remove('name');
        cookies.remove('userId');
        cookies.remove('userGroup');
        cookies.remove('role');
        cookies.remove('accessToken');
        cookies.remove('refreshToken');
        cookies.remove('idToken');
      });
  });
}

const removedAuth = () => {
  const emptyAuth = {
    isAuthenticated: false,
    role: '',
    activeRole: '',
    userDetails: {
      name: '',
      email: '',
      phoneNumber: '',
    },
    idToken: '',
    accessToken: '',
    refreshToken: '',
  };
  return emptyAuth;
};
