/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, CircularProgress } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DocumentDialog from './DocumentDialog';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: { backgroundColor: 'black' },
  tableCell: { color: 'white' },
  tableBodyCell: { height: '60px' },
  viewButton: { backgroundColor: '#3366FF' },
});

function createData(
  profileImage,
  agentName,

  Details,
  status
) {
  return {
    profileImage,
    agentName,

    Details,
    status,
  };
}

export default function DenseTable({ user, data, fetching }) {
  const classes = useStyles();

  const rows = data?.map((agent, index) =>
    createData(
      agent?.profile_image,
      agent?.agent_name,
      agent,
      agent?.approval_status
    )
  );

  return (
    <div>
      {fetching ? (
        <CircularProgress />
      ) : (
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableCell} align="left" />
                <TableCell className={classes.tableCell} align="center">
                  Agent Name
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Documents
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, index) => (
                <TableRow key={row} className={classes.tableBodyCell}>
                  <TableCell component="th" scope="row" align="left">
                    <Avatar alt="Remy Sharp" src={row.profileImage} />
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.agentName}
                  </TableCell>

                  <TableCell component="th" scope="row" align="center">
                    {/*  */}
                    <DocumentDialog user={user} agentDetails={row.Details} />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color:
                        row.status == 'requested'
                          ? 'gray'
                          : row.status == 'approved'
                          ? 'green'
                          : row.status == 'rejected'
                          ? 'red'
                          : 'black',
                    }}
                  >
                    {row.status}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
