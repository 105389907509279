/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 400,
    borderRadius: '20px',
    background: 'linear-gradient(180deg, #670EAB 0%, #163BAC 100%);',
    color: 'white',
    height: '150px',
  },
  cardHeader: { padding: '0px 16px' },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  divider: { backgroundColor: 'white', margin: '5px 30px' },
  cardAction: {
    color: 'white',
    textTransform: 'capitalize',
    margin: '0px 0px 15px 0px',
  },
}));

export default function RecipeReviewCard({ espAccounts }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });
  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Grid item>
      {espAccounts?.map(account => (
        <Card className={classes.root}>
          <CardHeader
            action={
              <IconButton aria-label="settings">
                <Switch
                  checked={state.checkedA}
                  onChange={handleChange}
                  name="checkedA"
                  //   color="secondary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </IconButton>
            }
            title={
              <Typography variant="subtitle1" gutterBottom>
                ESP Acc : {account.account_id}
              </Typography>
            }
            // subheader="September 14, 2016"
            className={classes.cardHeader}
          />

          <CardContent style={{ textAlign: 'center', padding: 0 }}>
            <Typography variant="h4" color="white">
              {account.balance}
            </Typography>
            <Divider className={classes.divider} />
            {/* <Button className={classes.cardAction}>View Statement</Button> */}
          </CardContent>
        </Card>
      ))}
    </Grid>
  );
}
