import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button, makeStyles } from '@material-ui/core';

const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid lightgray 2px',
  // borderRadius: '7px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',

  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};

const profileImage = { width: '275px', height: '370px', objectFit: 'cover' };
const profileImageContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: {
    marginTop: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogActions: { display: 'flex', alignContent: 'center' },
  button: { margin: '10px' },
}));
const PODdetails = ({ active, tripDetails, remarks }) => {
  const classes = useStyles();
  //   const handleClose = () => {
  //     setOpen(false);
  //   };
  const handleActive = () => {
    active(4);
  };

  return (
    <div>
      <DialogContent className={classes.DialogContent}>
        <fieldset style={shifter}>
          <legend style={{ maxWidth: '300px' }}>
            <div style={avatarContainer}>
              <div style={avatar}>
                <AccountBalanceIcon style={{ color: 'white' }} />
              </div>
              <Typography variant="h6" gutterBottom>
                E-Way Bill
              </Typography>
            </div>
          </legend>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <div style={profileImageContainer}>
                <img
                  src={tripDetails.eway_bill}
                  alt="icon"
                  style={profileImage}
                />
              </div>
              <div className={classes.profileAction}>
                <Button className={classes.button} variant="contained">
                  Reject
                </Button>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <textarea
                  style={{
                    padding: '10px',
                    width: '100%',
                    marginBottom: '10px',
                    borderRadius: '5px',
                    border: '1px solid lightgray',
                  }}
                  rows={4}
                  onChange={event => remarks(event.target.value)}
                />
              </div>
            </Grid>
          </Grid>
        </fieldset>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '20px',
          }}
        >
          <Button
            // onClick={handleClose}
            onClick={handleActive}
            color="primary"
            variant="contained"
            endIcon={<ArrowForwardIcon />}
          >
            Next Step
          </Button>
        </div>
      </DialogContent>
    </div>
  );
};

export default PODdetails;
