import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: { backgroundColor: 'black' },
  tableHeadCell: { color: 'white' },
  tableBodyRow: { height: '50px' },
});

function createData(companyName, userID, balance, account) {
  return { companyName, userID, balance, account };
}

export default function DenseTable({ transactionData }) {
  const classes = useStyles();
  const toIndianCurrency = num => {
    if (num) {
      const curr = num.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
      });
      return curr;
    }
    return 0;
  };
  const rows = transactionData.map(data =>
    createData(
      data.dataList.company_name,
      data.dataList.user_id,
      toIndianCurrency(data.dataList.wallet),
      data.data[2]
    )
  );
  return (
    <div style={{ marginTop: '40px' }}>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>Company</TableCell>
              <TableCell className={classes.tableHeadCell} align="left">
                User-Id
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="right">
                Balance
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="right">
                Account
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow className={classes.tableBodyRow} key={row.name}>
                <TableCell component="th" scope="row">
                  {row.companyName}
                </TableCell>
                <TableCell align="left">{row.userID}</TableCell>
                <TableCell align="right">{row.balance}</TableCell>
                <TableCell align="right">{row.account}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
