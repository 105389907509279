/* eslint-disable no-lone-blocks */
/* eslint-disable no-prototype-builtins */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable react/require-default-props */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookie from 'universal-cookie';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import { setWalletBalance } from '../../reducers/main';
import { setWalletList } from '../../reducers/walletList';
import apiCaller from '../../utils/apiCaller';

const cookies = new Cookie();

const defaultButton = props => (
  <Button {...props} color="info">
    {props.children}
  </Button>
);
const style = {
  customCardContentClass: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  cardIconTitle: {
    marginTop: '15px',
    marginBottom: '0px',
  },
};

class Pagination extends React.Component {
  constructor(props) {
    super();
    this.changePage = this.changePage.bind(this);
  }

  static propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    PageButtonComponent: PropTypes.any,
    onPageChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string,
  };

  componentDidMount() {
    this.getTransactionList();
  }

  getTransactionList() {
    const role = cookies.get('role');
    let fromDate = '';
    let toDate = '';
    // let transporterId = '';
    if (role === 'super_admin') {
      fromDate = cookies.get('epocFromDate');
      toDate = cookies.get('epocToDate');
      // transporterId = cookies.get('transporterId');
    }
    const transactionData = [];
    const body = {
      total_items: 30,
      filter_status: 'payout.processed',
    };
    let nextCursorId = null;
    let path = '';
    if (role !== 'super_admin') {
      path = 'premium-api/read-payout-list';
    } else {
      path = 'premium-api/read-all-premium-payout-list';
      body.from_date = isNaN(fromDate) ? null : fromDate;
      body.to_date = isNaN(toDate) ? null : toDate;
    }
    apiCaller(path, 'post', body, true, false)
      .then(response => {
        if (response.items.length !== 0) {
          response.items.forEach(dataList => {
            if (
              response.hasOwnProperty('LastEvaluatedKey') &&
              response.LastEvaluatedKey !== null
            ) {
              nextCursorId = response.LastEvaluatedKey;
            }
            if (
              dataList.payin === true ||
              dataList.status === 'payout.processed'
            ) {
              transactionData.push({
                color: 'success',
                data: [
                  dataList.serial_index === '' ? '....' : dataList.serial_index,
                  dataList.amount,
                  moment
                    .unix(dataList.created_at)
                    .format('DD-MM-YYYY hh:mm:ss'),
                  dataList.status === undefined
                    ? 'Account Recharged'
                    : dataList.status,
                  dataList.payin === undefined ? '' : dataList.payin,
                  dataList.premium_transporter_id,
                ],
                dataList,
              });
            }
          });
          this.props.setWalletList(transactionData, nextCursorId);
          setTimeout(() => {
            if (
              response.hasOwnProperty('LastEvaluatedKey') &&
              response.LastEvaluatedKey !== null
            ) {
              this.getNextTransactions();
            }
          }, 1000);
        } else {
          this.props.setTransactions([], nextCursorId);
        }
      })
      .catch(err => {});
  }

  componentWillReceiveProps(nextProps) {
    this.changePage(nextProps.page + 1);
  }

  changePage(page) {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    this.props.onPageChange(page - 1);
  }

  onNext(activePage) {
    // if (activePage === this.props.pages) return;
    if (this.props.lastEvaluatedKey !== null) {
      this.getNextTransactions();
    }
    this.changePage(activePage + 1);
  }

  getNextTransactions() {
    const role = cookies.get('role');
    const { lastEvaluatedKey, listWallet } = this.props;
    const transactionData = JSON.parse(JSON.stringify(listWallet));
    let fromDate = '';
    let toDate = '';
    let transporterId = '';
    if (role === 'super_admin') {
      fromDate = cookies.get('epocFromDate');
      toDate = cookies.get('epocToDate');
      transporterId = cookies.get('transporterId');
    }
    const body = {
      total_items: 30,
      filter_status: 'payout.processed',
    };
    if (lastEvaluatedKey !== '' && lastEvaluatedKey !== null) {
      body.LastEvaluatedKey = lastEvaluatedKey;
    }
    let nextCursorId = null;
    let path = '';
    if (role !== 'super_admin') {
      path = 'premium-api/read-payout-list';
    } else {
      path = 'premium-api/read-all-premium-payout-list';
      body.from_date = isNaN(fromDate) ? null : fromDate;
      body.to_date = isNaN(toDate) ? null : toDate;
      if (transporterId !== '') {
        body.transporter_id = transporterId === undefined ? '' : transporterId;
      }
    }
    apiCaller(path, 'post', body, true, false)
      .then(response => {
        if (response.items.length !== 0) {
          response.items.forEach(dataList => {
            if (
              response.hasOwnProperty('LastEvaluatedKey') &&
              response.LastEvaluatedKey !== null
            ) {
              nextCursorId = response.LastEvaluatedKey;
            }
            if (
              dataList.payin === true ||
              dataList.status === 'payout.processed'
            ) {
              transactionData.push({
                color: 'success',
                data: [
                  dataList.serial_index === '' ? '....' : dataList.serial_index,
                  dataList.amount,
                  moment
                    .unix(dataList.created_at)
                    .format('DD-MM-YYYY hh:mm:ss'),
                  dataList.status === undefined
                    ? 'Account Recharged'
                    : dataList.status,
                  dataList.payin === undefined ? '' : dataList.payin,
                  dataList.premium_transporter_id,
                ],
                dataList,
              });
            }
          });
          this.props.setWalletList(transactionData, nextCursorId);
        } else {
          this.props.setWalletList(transactionData, nextCursorId);
        }
      })
      .catch(err => {});
  }

  render() {
    const { PageButtonComponent = defaultButton } = this.props;
    const activePage = this.props.page + 1;
    return (
      <GridContainer justify="center">
        <GridItem xs={6} sm={6} md={6}>
          <PageButtonComponent
            className="Table__pageButton"
            onClick={() => {
              if (activePage === 1) return;
              this.changePage(activePage - 1);
            }}
            disabled={activePage === 1}
          >
            {this.props.previousText}
          </PageButtonComponent>
        </GridItem>

        <GridItem xs={6} sm={6} md={6}>
          <PageButtonComponent
            className="Table__pageButton"
            onClick={() => {
              //   if (activePage === this.props.pages) return;
              this.onNext(activePage);
            }}
            disabled={
              (this.props.lastEvaluatedKey === '' ||
                this.props.lastEvaluatedKey === null) &&
              activePage === this.props.pages
            }
            style={{ float: 'right' }}
          >
            {this.props.nextText}
          </PageButtonComponent>
        </GridItem>
      </GridContainer>
    );
  }
}

Pagination.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setWalletBalance,
      setWalletList,
    },
    dispatch
  );

const mapStateToProps = state => ({
  walletBalance: state.main.walletBalance,
  listWallet: state.wallet.listWallet,
  lastEvaluatedKey: state.wallet.lastEvaluatedKey,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Pagination));
