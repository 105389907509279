/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
import React from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import PropTypes from 'prop-types';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';

import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AddAlert from '@material-ui/icons/AddAlert';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Danger from 'components/Typography/Danger.jsx';
import { connect } from 'react-redux';
import Snackbar from 'components/Snackbar/Snackbar.jsx';

import userProfileStyles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx';

import apiCaller from '../../utils/apiCaller';

class AddUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameState: '',

      email: '',
      emailState: '',

      phoneNumber: '',
      phoneNumberState: '',
      isLoading: false,

      alert: null,

      lastName: '',
      handleUser: [],
      handleUserState: [],
      errMessage: '',
      errSnack: false,
      infoMessage: '',
      infoSnack: false,
    };
    this.change = this.change.bind(this);
    this.submitUser = this.submitUser.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.handleUser = this.handleUser.bind(this);
  }

  componentDidMount() {
    const {
      first_name,
      last_name,
      phone_number,
      email_address,
      role,
    } = this.props.selectedUserData;
    this.setState({
      name: first_name,
      lastName: last_name,
      phoneNumber: phone_number,
      email: email_address,
      handleUser: role,
    });
  }

  handleUser = event => {
    this.setState({ handleUser: event.target.value });
  };

  handleChange(event) {
    this.setState({ selectedValue: event.target.value });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
    this.props.goBack();
  }

  // function that returns true if value is there, false otherwise
  verifyRequired(value) {
    if (value) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    const emailRex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPhone(value) {
    const regPhone = /^[6789]\d{9}$/;
    if (regPhone.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  /**
   * Shows the notification when user click submit for success message.
   */
  showNotification = place => {
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 4000);
    }
  };

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'required':
        if (this.verifyRequired(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'phone':
        if (this.verifyPhone(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Name
      if (this.verifyLength(this.state.name, 1)) {
        this.setState({
          nameState: 'success',
        });
      } else {
        this.setState({
          nameState: 'error',
        });
        error = true;
      }
      // Verify Email
      if (this.verifyEmail(this.state.email)) {
        this.setState({
          emailState: 'success',
        });
      } else {
        this.setState({
          emailState: 'error',
        });
        error = true;
      }

      if (this.state.handleUser.length > 0) {
        this.setState({ handleUserState: '' });
      } else {
        this.setState({
          handleUserState: 'Please Select atleast 1 cities',
        });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('validation Failed');
      }
    });
  }

  /**
   * Submits the user details to the Database
   * @function
   */
  submitUser() {
    // eslint-disable-next-line no-underscore-dangle
    const _me = this;
    this.setState({
      isLoading: true,
    });
    this.validateAllFields()
      .then(
        success => {
          //   let _me = this;
          if (success) {
            const {
              name,
              phoneNumber,
              email,
              handleUser,
              lastName,
            } = this.state;
            const body = {
              user_details: [
                {
                  first_name: name,
                  last_name: lastName,
                  email_address: email,
                  role: handleUser,
                  phone_number: phoneNumber,
                  user_id: this.props.selectedUserData.user_id,
                },
              ],
            };
            apiCaller('premium-api/add-users', 'post', body, true, false)
              .then(response => {
                this.setState(
                  {
                    infoMessage: 'Edited User Successfully',
                    isLoading: false,
                  },
                  this.showNotification('infoSnack')
                );
                // this.clearForm();
                this.props.goBack();
              })
              .catch(err => {
                this.setState(
                  {
                    errMessage: err.message || 'Oops Something went wrong',
                    isLoading: false,
                  },
                  this.showNotification('errSnack')
                );
              });
          }
        },
        err => {
          _me.setState({
            isLoading: false,
          });
        }
      )
      .catch(err => {
        _me.setState({
          isLoading: false,
        });
      });
  }

  /**
   * Will Clears ALl the State values after form submision
   * @function
   */
  clearForm() {
    // eslint-disable-next-line no-underscore-dangle
    const _me = this;
    _me.setState({
      name: '',
      nameState: '',
      lastName: '',
      email: '',
      emailState: '',
      phoneNumber: '',
      phoneNumberState: '',
      handleUser: [],
      handleUserState: [],
    });
  }

  render() {
    const { classes } = this.props;
    const {
      lastName,
      handleUser,
      handleUserState,
      errSnack,
      errMessage,
    } = this.state;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {this.state.alert}
            <Snackbar
              place="tc"
              color="info"
              icon={AddAlert}
              message={this.state.infoMessag || ''}
              open={this.state.infoSnack}
              closeNotification={() => this.setState({ infoSnack: false })}
              close
            />
            <Snackbar
              place="tc"
              color="danger"
              icon={AddAlert}
              message={errMessage || ''}
              open={errSnack}
              closeNotification={() => this.setState({ errSnack: false })}
              close
            />
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="warning">
                <CardIcon
                  color="primary"
                  className={`${classes.cardHeader}`}
                  onClick={this.props.goBack}
                >
                  <ArrowBack />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Update User <small />
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Name *"
                      id="username"
                      formControlProps={{ fullWidth: true }}
                      success={this.state.nameState === 'success'}
                      error={this.state.nameState === 'error'}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'name', 'length', 1),
                        type: 'length',
                        value: this.state.name,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'lastName', 'length', 2),
                        type: 'length',
                        value: lastName,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          />
                        ),
                        placeholder: 'Last Name',
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      success={this.state.emailState === 'success'}
                      error={this.state.emailState === 'error'}
                      labelText="Email address "
                      id="email-address"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        type: 'email',
                        value: this.state.email,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="multiple-select"
                        className={classes.selectLabel}
                      >
                        Select User type *
                      </InputLabel>
                      <Select
                        value={handleUser}
                        onChange={this.handleUser}
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                        inputProps={{
                          name: 'multipleSelect',
                          id: 'multiple-select',
                        }}
                      >
                        <MenuItem
                          classes={{ root: classes.selectMenuItem }}
                        >
                          Choose User
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="checker"
                        >
                          Checker
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="maker"
                        >
                          Maker
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <Danger>{handleUserState}</Danger>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Phone Number *"
                      id="phone-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'phoneNumber', 'phone'),
                        type: 'phone',
                        value: this.state.phoneNumber,
                        disabled: true,
                      }}
                      success={this.state.phoneNumberState === 'success'}
                      error={this.state.phoneNumberState === 'error'}
                    />
                  </GridItem>
                </GridContainer>
                {this.state.isLoading ? (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={4}>
                      <CircularProgress
                        className={classes.progress}
                        style={{ color: purple[500] }}
                        thickness={7}
                      />
                    </GridItem>
                  </GridContainer>
                ) : (
                  <Button
                    color="warning"
                    onClick={this.submitUser}
                    className={classes.updateProfileButton}
                  >
                    Submit
                  </Button>
                )}
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
AddUsers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
  systemDetails: state.main.systemDetails,
});

export default connect(mapStateToProps)(
  withStyles(userProfileStyles)(AddUsers)
);
