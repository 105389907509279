/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import apiCaller from '../../../utils/apiCallerESP';

const options = ['Initiated', 'Processing', 'Processed', 'Reversed', 'Success'];
export default function AlertDialog({ status, data }) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [utr, setUTR] = React.useState('');
  const [remarks, setRemarks] = React.useState('');
  // const [open, setOpen] = React.useState(false);
  const { transaction_id, trip_id } = data;
  // const [selectedIndex, setSelectedIndex] = React.useState(
  //   status == 'initiated'
  //     ? 0
  //     : status == 'processed'
  //     ? 1
  //     : status == 'processing'
  //     ? 2
  //     : status == 'reversed'
  //     ? 3
  //     : ''
  // );
  const selectStyles =
    status == 'processing'
      ? { backgroundColor: '#FF7E03', color: 'white' }
      : status == 'processed'
      ? { backgroundColor: '#00811C', color: 'white' }
      : status == 'reversed'
      ? { backgroundColor: '#FF0000', color: 'white' }
      : status == 'success'
      ? { backgroundColor: '#3366FF', color: 'white' }
      : '';
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    // console.log();
  };

  const handleSuccess = async () => {
    const endPoint = 'balancerequest-esp';
    const body = {
      transaction_id,
      status: 'success',
      trip_id,
    };
    try {
      await apiCaller(endPoint, body);
    } catch (e) {
      console.log(e);
    }
  };
  const handleRejected = async () => {
    const endPoint = 'balancerequest-esp';
    const body = {
      transaction_id,
      status: 'reversed',
      trip_id,
    };
    try {
      await apiCaller(endPoint, body);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSelect = (event, index) => {
    setAnchorEl(event.currentTarget);
    if (options[index] == 'Processed') {
      setOpen(true);
    }
    if (options[index] == 'Success') {
      handleSuccess();
    }
    if (options[index] == 'Reversed') {
      handleRejected();
    }
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleEnableESP = async () => {
    const endPoint = 'balancerequest-esp';
    const body = {
      transaction_id,
      utr,
      remark: remarks,
      status: 'processed',
      trip_id,
    };
    try {
      await apiCaller(endPoint, body);
    } catch (e) {
      console.log(e);
    }
    // console.log(response);
  };

  return (
    <div>
      <div>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={selectStyles}
          size="small"
          // disabled={status === 'processed'}
        >
          {status}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          // keepMounted
          open={Boolean(anchorEl)}
          // onClick={event => console.log(event.target.value)}
          onClose={handleMenuClose}
        >
          {options.map((item, index) => (
            <MenuItem onClick={event => handleSelect(event, index)}>
              {item}
            </MenuItem>
          ))}
        </Menu>
      </div>

      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Please Provide UTR</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="UTR"
                onChange={event => setUTR(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Remarks"
                onChange={event => setRemarks(event.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ marginTop: '10px' }}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEnableESP} color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
