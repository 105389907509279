import Cookies from 'universal-cookie';

const cookies = new Cookies();
// const auth = {
//   isAuthenticated: true,
//   role: 'admin',
//   activeRole: 'doctor',
//   userDetails: {
//     name: 'Hemanth',
//     email: 'h@wipadika.com',
//     phoneNumber: '9545366277',
//   },
//   idToken: '',
//   accessToken: '',
//   refreshToken: '',
// };

export default function setAuthCookies(auth) {
  return new Promise(resolve => {
    const {
      isAuthenticated,
      role,
      activeRole,
      userDetails,
      idToken,
      accessToken,
      refreshToken,
      username,
      userPoolId,
    } = auth;
    cookies.set('isAuthenticated', isAuthenticated);
    cookies.set('role', role);
    cookies.set('activeRole', activeRole);
    cookies.set('userDetails', userDetails);
    cookies.set('idToken', idToken);
    cookies.set('accessToken', accessToken);
    cookies.set('refreshToken', refreshToken);
    cookies.set('username', username);
    cookies.set('userPoolId', userPoolId);
    resolve('success');
  });
}
