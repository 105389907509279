/* eslint-disable eqeqeq */
/* eslint-disable no-undef */
import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import { makeStyles } from '@material-ui/core/styles';
import ESPViewTable from './ESPTableView';

const useStyles = makeStyles(theme => ({
  tabs: {
    borderBottom: '1px solid lightgray',
    borderTop: '1px solid lightgray',
  },
  tabsPanel: { padding: '20px 0px ' },
}));

const ESPOverview = ({ espAccounts }) => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const tabsHeaders = espAccounts?.map((account, index) => ({
    id: index,
    label: account.account_id,
  }));

  const panelView = espAccounts?.map((account, index) => ({
    id: index,
    view: <ESPViewTable id={account.account_id} />,
  }));
  return (
    <div>
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          // variant="fullWidth"
          aria-label="full width tabs example"
          className={classes.tabs}
        >
          {tabsHeaders?.map(NavItem => (
            <Tab label={NavItem?.label} {...a11yProps(NavItem?.id)} />
          ))}
        </Tabs>
        {panelView?.map(view =>
          view?.id == value ? (
            <TabPanel value={value} id={view?.id} className={classes.tabsPanel}>
              {view?.view}
            </TabPanel>
          ) : (
            ''
          )
        )}
      </TabContext>
    </div>
  );
};

export default ESPOverview;
