/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unused-state */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import logo from 'assets/img/Lobb-Logo.png';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import pagesRoutes from 'routes/pages.jsx';

import pagesHeaderStyle from 'assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle.jsx';
import './ledger-header.css';

class PagesHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      uid: '',
      isAuthenticated: false,
      isLoggedIn: false,
      userRole: '',
    };
  }

  componentDidMount() {}

  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1;
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({ open: false });
    }
  }

  render() {
    const { classes, color } = this.props;
    const appBarClasses = cx({
      [` ${classes[color]}`]: color,
    });

    const list = (
      <List className={classes.list}>
        <React.Fragment>
          <ListItem className={classes.listItem}>
            <NavLink to="/pages/landing-page" className={classes.navLink}>
              <ListItemText
                primary="SIGN UP FOR LOBB PAY"
                disableTypography
                className={classes.listItemText}
              />
            </NavLink>
          </ListItem>
        </React.Fragment>
        {pagesRoutes.map((prop, key) => {
          if (prop.redirect) {
            return null;
          }
          const navLink =
            classes.navLink +
            cx({
              [` ${classes.navLinkActive}`]: this.activeRoute(prop.path),
            });
          return (
            <ListItem key={key} className={classes.listItem}>
              <NavLink to={prop.path} className={navLink}>
                <ListItemIcon className={classes.listItemIcon}>
                  <prop.icon />
                </ListItemIcon>
                <ListItemText
                  primary={prop.short}
                  disableTypography
                  className={classes.listItemText}
                />
              </NavLink>
            </ListItem>
          );
        })}
      </List>
    );
    return (
      <AppBar position="static" className={classes.appBar + appBarClasses}>
        <Toolbar
          className={classes.container}
          style={{
            marginLeft: '0px',
            marginRight: '0px',
            paddingLeft: '40px',
            paddingRight: '2rem',
            width: 'auto',
          }}
        >
          <Hidden smDown implementation="css">
            <div className={classes.flex}>
              <div className={classes.title}>
                <NavLink to="/pages/landing-page" className="logo-mini">
                  <img src={logo} alt="logo" className={classes.avatarImg} />
                </NavLink>
              </div>
            </div>
          </Hidden>
          <Hidden mdUp>
            <div className={classes.flex}>
              <div className={classes.title}>
                <NavLink to="/pages/landing-page" className="logo-mini">
                  <img src={logo} alt="logo" className={classes.avatarImg} />
                </NavLink>
              </div>
            </div>
          </Hidden>
          <GridContainer justify="flex-end">
            <GridItem>
              <Hidden smDown>{list}</Hidden>
            </GridItem>
          </GridContainer>
          <Hidden mdUp implementation="css">
            <Hidden mdUp>
              <GridContainer justify="flex-end" style={{ marginTop: '-15px' }}>
                <GridItem>{list}</GridItem>
              </GridContainer>
            </Hidden>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

PagesHeader.defaultProps = {
  // bgColor: "",
};
PagesHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
  bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
};

export default withStyles(pagesHeaderStyle)(PagesHeader);
