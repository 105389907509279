/* eslint-disable no-self-assign */
/* eslint-disable no-var */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, TextField, Typography } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment';
import AccountsStatement from './AccountsStatment';
import PayinModal from './PayinModal';

var offset = 0;
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: { backgroundColor: 'black' },
  tableHeadCell: { color: 'white' },
  tableBodyRow: { height: '50px' },
});

function createData(
  transporterName,
  accountId,
  accountName,
  balance,
  transporterID
) {
  return { transporterName, accountId, accountName, balance, transporterID };
}

export default function DenseTable({ transporterData, triggerRefresh }) {
  const [toggle, setToggle] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState('');
  const classes = useStyles();

  const toIndianCurrency = num => {
    if (num) {
      const curr = num.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
      });
      return curr;
    }
    return 0;
  };
  const rows = transporterData?.map(data =>
    createData(
      data.transporter_name,
      data.account_id,
      data.account_name,
      toIndianCurrency(data.balance),
      data.user_id
    )
  );
  const handlePrevious = async () => {
    if (offset !== 0) {
      offset = offset;
      triggerRefresh(offset);
    } else {
      offset -= 10;
      triggerRefresh(offset);
    }
  };
  const handleNext = async () => {
    offset += 10;
    triggerRefresh(offset);
  };
  const handleClickOpen = value => {
    setToggle(true);
    setSelectedAccountId(value);
  };
  return (
    <div style={{ marginTop: '40px' }}>
      {!toggle ? (
        <TableContainer>
          <div style={{ margin: '20px 0px' }}>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    startIcon={<CachedIcon />}
                    onClick={() => triggerRefresh()}
                  >
                    Refresh
                  </Button>
                  <Typography variant="body1" style={{ color: 'lightgray' }}>
                    Last refresh at {moment().format('MMMM Do YYYY, h:mm:ss a')}
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Broker"
                />
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '10px',
            }}
          >
            <Button onClick={handlePrevious}>Previous</Button>
            <Button onClick={handleNext}>Next</Button>
          </div>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                {/* <TableCell className={classes.tableHeadCell}>
                  Serial No.
                </TableCell> */}
                <TableCell className={classes.tableHeadCell} align="left">
                  Transporter Name
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="center">
                  Acount ID
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="center">
                  Account Name
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="right">
                  Current Balance
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="right" />
                <TableCell className={classes.tableHeadCell} align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, index) => (
                <TableRow className={classes.tableBodyRow} key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.transporterName}
                  </TableCell>
                  <TableCell align="center">{row.accountId}</TableCell>
                  <TableCell align="center">{row.accountName}</TableCell>
                  <TableCell align="right">{row.balance}</TableCell>
                  <TableCell align="right">
                    <PayinModal transporterESPDetails={row} />
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.viewButton}
                      startIcon={<VisibilityIcon />}
                      size="small"
                      onClick={() => handleClickOpen(row.accountId)}
                    >
                      Statement
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        // <ESPStatement transactionData={transporterData} />
        <AccountsStatement selectedAccountId={selectedAccountId} />
      )}
    </div>
  );
}
