import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AccountBalance from '@material-ui/icons/AccountBalance';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import pricingPageStyle from 'assets/jss/material-dashboard-pro-react/views/pricingPageStyle.jsx';

class PricingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFreePlan: false,
      isMonthlyPlan: true,
      isYearlyPlan: false,
      isOrgPlan: false,
    };
    this.onChangPlan = this.onChangePlan.bind(this);
  }

  // On Change Each Plan Changes Css Updates
  onChangePlan(plan) {
    switch (plan) {
      case 'free':
        this.setState({
          isFreePlan: true,
          isMonthlyPlan: false,
          isYearlyPlan: false,
          isOrgPlan: false,
        });
        break;
      case 'monthly':
        this.setState({
          isFreePlan: false,
          isMonthlyPlan: true,
          isYearlyPlan: false,
          isOrgPlan: false,
        });
        break;
      case 'yearly':
        this.setState({
          isFreePlan: false,
          isMonthlyPlan: false,
          isYearlyPlan: true,
          isOrgPlan: false,
        });
        break;
      default:
        this.setState({
          isFreePlan: true,
          isMonthlyPlan: false,
          isYearlyPlan: false,
          isOrgPlan: false,
        });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.title}>Pick the best plan for you</h2>
              <h5 className={classes.description}>
                You have Free Unlimited Updates and Premium Support on each
                package.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            {/* <GridItem xs={12} sm={12} md={3}>
              <Card pricing plain={!this.state.isFreePlan} raised={this.state.isFreePlan} onClick={()=>this.onChangePlan("free")}>
                <CardBody pricing plain={!this.state.isFreePlan} raised={this.state.isFreePlan}>
                  <h6 className={classes.cardCategory}>Freelancer</h6>
                  <div className={classes.icon}>
                    <Weekend className={classes.iconRose} />
                  </div>
                  <h3
                    className={`${!this.state.isFreePlan ? classes.cardTitleWhite : classes.cardTitle } ${
                      classes.marginTop30
                    }`}
                  >
                    Free
                  </h3>
                  <p className={classes.cardCategory}>
                    Get 60 Days Free Trial.
                  </p>
                  <Button round color="white">
                    Choose plan
                  </Button>
                </CardBody>
              </Card>
            </GridItem> */}
            <GridItem xs={12} sm={12} md={3}>
              <Card
                pricing
                plain={!this.state.isMonthlyPlan}
                raised={this.state.isMonthlyPlan}
              >
                <CardBody
                  pricing
                  plain={!this.state.isMonthlyPlan}
                  raised={this.state.isMonthlyPlan}
                >
                  <h6 className={classes.cardCategory}>Monthly Plan</h6>
                  <div className={classes.icon}>
                    <AccountBalance className={classes.iconRose} />
                  </div>
                  <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                    <i className="fa fa-inr" aria-hidden="true" /> 2,500
                  </h3>
                  <p className={classes.cardDescription}>
                    Get 60 Days Free trial
                  </p>
                  <Button round color="rose">
                    Choose plan
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

PricingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(pricingPageStyle)(PricingPage);
