import {
  primaryColor,
  dangerColor,
  successColor,
  defaultFont,
} from 'assets/jss/material-dashboard-pro-react.jsx';

const customInputStyle = {
  disabled: {
    '&:before': {
      borderColor: 'transparent !important',
    },
  },
  underline: {
    '&:hover:not($disabled):before,&:before': {
      borderColor: '#D2D2D2 !important',
      borderWidth: '1px !important',
    },
    '&:after': {
      borderColor: primaryColor,
    },
  },
  underlineError: {
    '&:after': {
      borderColor: dangerColor,
    },
  },
  underlineSuccess: {
    '&:after': {
      borderColor: successColor,
    },
  },
  rightAlign: {
    color: 'red',
    textAlign: 'right',
  },
  labelRoot: {
    ...defaultFont,
    color: '#AAAAAA !important',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    top: '10px',
    '& + $underline': {
      marginTop: '0px',
    },
  },
  labelRootError: {
    color: dangerColor + ' !important',
  },
  labelRootSuccess: {
    color: successColor + ' !important',
  },
  inputCenter: {
    textAlign: 'right',
  },
  feedback: {
    position: 'absolute',
    bottom: '3px',
    right: '0',
    zIndex: '2',
    display: 'block',
    width: '1em',
    height: '1em',
    textAlign: 'center',
    pointerEvents: 'none',
  },
  feedbackLeft: {
    position: 'absolute',
    bottom: '3px',
    left: '0',
    zIndex: '2',
    display: 'block',
    width: '1em',
    height: '1em',
    textAlign: 'left',
    pointerEvents: 'none',
    float: 'left'
  },
  feedbackRight: {
    marginRight: '22px',
  },
  formControl: {
    margin: '0 0 17px 0',
    paddingTop: '27px',
    position: 'relative',
    '& svg,& .fab,& .far,& .fal,& .fas': {
      color: '#495057',
    },
  },
  whiteUnderline: {
    '&:hover:not($disabled):before,&:before': {
      backgroundColor: '#FFFFFF',
    },
    '&:after': {
      backgroundColor: '#FFFFFF',
    },
  },
  input: {
    color: '#495057',
    '&,&::placeholder': {
      fontSize: '14px',
      fontFamily: '"Rajdhani","Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.42857',
      opacity: '1',
    },
    '&::placeholder': {
      color: '#AAAAAA',
    },
  },
  whiteInput: {
    '&,&::placeholder': {
      color: '#FFFFFF',
      opacity: '1',
    },
  },
};

export default customInputStyle;
