/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable camelcase */
import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button, makeStyles } from '@material-ui/core';
import panicon from '../../../../assets/img/espicons/panicon.png';
import apiCaller from '../../../../utils/apiCallerESP';

const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid lightgray 2px',
  // borderRadius: '7px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',

  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};

const profileImage = { width: '320px', height: '160px', objectFit: 'cover' };
const profileImageContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: {
    margin: '20px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogActions: { display: 'flex', alignContent: 'center' },
  button: { margin: '10px' },
  activeButton: {
    backgroundColor: '#FF0000',
    color: 'white',
    // marginTop: '10px',
  },
}));
const PANdetails = ({
  active,
  agentDetails,
  agentId,
  user,
  rejectAgentHandle,
}) => {
  const classes = useStyles();
  const [flag, setFlag] = React.useState(false);
  const [remark, setRemark] = React.useState('');
  const { pan_front, pan_back } = agentDetails;
  const handleReject = async () => {
    const endpoint = 'broker-approve';
    const data = {
      agent_id: agentId.toString(),
      transporter_id: user.username,
      approval_status: false,
      remark,
    };
    await apiCaller(endpoint, data);
    setFlag(!flag);
    rejectAgentHandle(false);
  };
  const handleActive = () => {
    active(2);
  };

  return (
    <div>
      <DialogContent className={classes.DialogContent}>
        <fieldset style={shifter}>
          <legend style={{ maxWidth: '300px' }}>
            <div style={avatarContainer}>
              <div style={avatar}>
                {/* <AccountBalanceIcon style={{ color: 'white' }} /> */}
                <img src={panicon} />
              </div>
              <Typography variant="h6" gutterBottom>
                PAN Card Details
              </Typography>
            </div>
          </legend>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <div style={profileImageContainer}>
                <img src={pan_front} style={profileImage} />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={profileImageContainer}>
                <img src={pan_back} style={profileImage} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <textarea
                style={{
                  padding: '10px',
                  width: '100%',
                  marginBottom: '10px',
                  borderRadius: '5px',
                  border: '1px solid lightgray',
                }}
                rows={4}
                onChange={event => setRemark(event.target.value)}
              />
            </Grid>
          </Grid>
        </fieldset>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            margin: '20px',
          }}
        >
          <Button
            className={flag ? classes.activeButton : ''}
            onClick={handleReject}
            variant="contained"
          >
            Reject
          </Button>
          <Button
            // onClick={handleClose}
            onClick={handleActive}
            color="primary"
            variant="contained"
            endIcon={<ArrowForwardIcon />}
          >
            Confirm
          </Button>
        </div>
      </DialogContent>
    </div>
  );
};

export default PANdetails;
