/* eslint-disable no-self-assign */
/* eslint-disable eqeqeq */
/* eslint-disable no-var */
import React, { useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Snackbar from '@material-ui/core/Snackbar';
import {
  Grid,
  TextField,
  // MenuItem,
  // Select,
  // InputLabel,
  // FormControl,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import CloseIcon from '@material-ui/icons/Close';
import { Auth } from 'aws-amplify';
import moment from 'moment';
import AgentRequestsListView from './AgentActivation/AgentRequestsListView';
import apiCallerESP from '../../utils/apiCallerESP';

var offset = 0;
// const useStyles = makeStyles(theme => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 200,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));
const AgentActivation = () => {
  const [data, setData] = React.useState([]);
  const [user, setUser] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [fetching, setFetching] = React.useState(false);
  // const classes = useStyles();
  // const [status, setStatus] = React.useState('');

  // const handleChange = event => {
  //   setStatus(event.target.value);
  // };
  const getAgentList = async value => {
    setFetching(true);
    const body = { limit: '10', offset: value || 0 };
    const endPoint = 'agent-activation-list';
    try {
      const response = await apiCallerESP(endPoint, body);
      const { agentsList } = response.data.body;
      setData(agentsList);
      setFetching(false);
    } catch (error) {
      setFetching(false);
      setMessage('No Records Found');
      setOpen(true);
      if (offset != 0) {
        offset -= 10;
      }
    }
  };
  const handlePrevious = async () => {
    if (offset !== 0) {
      offset = offset;
    } else offset -= 10;
    await getAgentList(offset);
  };
  const handleNext = async () => {
    offset += 10;
    await getAgentList(offset);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    offset = 0;
    setFetching(true);
    Auth.currentAuthenticatedUser().then(result => setUser(result.attributes));

    getAgentList();
  }, []);
  return (
    <div>
      <div style={{ margin: '20px 0px' }}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Button startIcon={<CachedIcon />} onClick={() => getAgentList()}>
              Refresh
            </Button>
            <Typography variant="body1" style={{ color: 'lightgray' }}>
              Last refresh at {moment().format('MMMM Do YYYY, h:mm:ss a')}
            </Typography>
          </Grid>
          {/* <Grid item>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Filter By Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={status}
                onChange={handleChange}
                label="Filter By Status"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item>
            <TextField
              id="outlined-basic"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Broker"
            />
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '10px',
        }}
      >
        <Button onClick={handlePrevious}>Previous</Button>
        <Button onClick={handleNext}>Next</Button>
      </div>

      <AgentRequestsListView
        user={user}
        data={data}
        fetching={fetching}
        triggerRefresh={value => getAgentList(value)}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default AgentActivation;
