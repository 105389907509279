/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/order */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import history from '../../history.js';
import './main.css';
import './color.css';
// import ''

import landingPageStyle from 'assets/jss/material-dashboard-pro-react/views/landingPageStyle';

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.goRegister = this.goRegister.bind(this);
  }

  componentDidMount() {
    // Check weather the user is Authenticated
  }

  goRegister(e) {
    history.push('/pages/register-page');
    e.preventDefault();
  }

  render() {
    const { classes } = this.props;
    return (
      <div
        className={`yellow-gradient font-modern ${classes.container}`}
        style={{ width: '100%', paddingLeft: '0px', paddingRight: '0px' }}
      >
        <section className="section">
          <div className="container mb40">
            <div className="col-12 text-center" style={{ marginTop: '30px' }}>
              {/* <h3 className="heading-lrg primary-color">
                We are down for maintenance, please reach out if you need any
                assistance on support@lobb.in
              </h3> */}
              <h1 className="heading-lrg primary-color">
                Payments made simple
              </h1>
              <div className="mt40">
                <a
                  href=""
                  target="_blank"
                  className="button mobile-text-center mt10 mobile-text-center accent-bg primary-color"
                  onClick={e => this.goRegister(e)}
                >
                  {' '}
                  <span> Get Started Now </span>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section
          id="feature-1"
          className="section yellow-accent"
          sitemeta="[object Object]"
          activepage="Landing"
        >
          <div className="container-lrg flex">
            <div className="col-6 flex mobile-flex-wrap mobile-text-center">
              <i className="icon secondary-bg mr20 mobile-center-icon">
                <svg
                  className="svg-fill"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 25 25"
                >
                  <path d="M20 22.494v1a.5.5 0 0 1-1 0v-1a.5.5 0 1 1 1 0zm-14.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 1 0v-1a.5.5 0 0 0-.5-.5zm7 1a10.924 10.924 0 0 1-2.494-.391l-.27 1.76a.528.528 0 0 0 .097.454.498.498 0 0 0 .387.183h4.56a.5.5 0 0 0 .49-.598l-.275-1.8a10.928 10.928 0 0 1-2.495.392zm9.06-7.44L18 11.995v9h2.5a1.5 1.5 0 0 0 1.5-1.5v-2.879a1.5 1.5 0 0 0-.44-1.06zM17 5.995v15a18.472 18.472 0 0 1-4 .974v-7.474a.5.5 0 0 0-1 0v7.474a18.47 18.47 0 0 1-4-.974v-15C8 3.67 11.553.244 12.5 0c.947.244 4.5 3.67 4.5 5.994zm-2.168 1.127A4.374 4.374 0 0 0 12.5 5.994a4.113 4.113 0 0 0-2.343 1.136.5.5 0 0 0 .686.729 3.213 3.213 0 0 1 1.657-.865 3.417 3.417 0 0 1 1.668.874.5.5 0 0 0 .664-.748zM3 16.614v2.88a1.5 1.5 0 0 0 1.5 1.5H7v-9l-3.56 3.56a1.5 1.5 0 0 0-.44 1.06z" />
                </svg>
              </i>
              <div>
                <h3 className="bold primary-color">
                  Payment Solutions for Logistics
                </h3>
                <p className="paragraph secondary-color">
                  Manage vendors and make Realtime Automated payments based on
                  internal workflow.
                </p>
              </div>
            </div>
            <div className="col-6 flex mobile-flex-wrap mobile-text-center">
              <i
                className="icon secondary-bg mr20 mobile-center-icon"
                style={{ float: 'right' }}
              >
                <svg
                  className="svg-fill"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 25 25"
                >
                  <path d="M8.354 6.146c-.951-.95-.903-3.794-.706-5.169a12.502 12.502 0 0 1 11.919 1.216 3.221 3.221 0 0 0-.981 2.017 4.127 4.127 0 0 0 1.06 3.143c.365.366.362 2.283-.19 4.116a2.2 2.2 0 0 1-4.249-.224c-.132-.676-.85-4.095-1.853-5.098-.678-.678-1.69-.364-2.67-.063-1.106.34-1.865.53-2.33.063zm12.033-3.34a2.233 2.233 0 0 0-.806 1.506 3.148 3.148 0 0 0 .772 2.335c.902.902.53 3.55.06 5.11a3.198 3.198 0 0 1-6.186-.318 12.355 12.355 0 0 0-1.58-4.585c-.251-.25-.97-.03-1.669.186-1.071.33-2.405.74-3.331-.186-1.174-1.175-1.199-3.791-1.061-5.367a12.482 12.482 0 0 0-2.52 20.22c.18-2.598.849-7.13 3.323-7.694a3.717 3.717 0 0 1 2.96.306 4.396 4.396 0 0 1 1.642 3.088c.383 2.03 1.552 3.093 2.509 3.093.647 0 1.415-1.083 2.092-2.04.857-1.209 1.743-2.46 2.908-2.46 1.662 0 2.79 1.43 3.13 3.803a12.47 12.47 0 0 0-2.243-16.997zM19.5 17c-.647 0-1.415 1.083-2.092 2.04-.857 1.209-1.743 2.46-2.908 2.46-1.622 0-3.058-1.607-3.491-3.907A3.578 3.578 0 0 0 9.8 15.155a2.796 2.796 0 0 0-2.19-.168c-1.735.397-2.467 4.412-2.59 7.512a12.446 12.446 0 0 0 16.712-1.594C21.65 18.94 21.037 17 19.5 17z" />
                </svg>
              </i>
              <div>
                <h3 className="bold primary-color">
                  Realtime Instant Payments
                </h3>
                <p
                  className="paragraph secondary-color"
                  style={{ letterSpacing: '1px' }}
                >
                  Anytime 365 days, &amp; 24 hours Days a week. All Indian Banks
                  Supported.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* </div> */}
      </div>
    );
  }
}

LandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(landingPageStyle)(LandingPage);
