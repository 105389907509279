/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Personaldetails from './Personaldetails';
import CompanyDetails from './CompanyDetails';
import AccountBalance from './AccountDetails';
import apiCaller from '../../../utils/apiCallerESP';

const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: { marginTop: '40px' },
  dialogActions: { display: 'flex', alignContent: 'center' },
  viewButton: { backgroundColor: '#3366FF' },
}));

export default function MaxWidthDialog({ user, agentDetails, view }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [details, setDetails] = React.useState();
  const [active, setActive] = React.useState(0);
  const getAgentDetails = async () => {
    const endPoint = 'agent-activation-detail';

    const response = await apiCaller(endPoint, {
      agent_id: agentDetails.agent_id,
    });
    const data = response.data.body.agent_detail;
    setDetails(data);
    setOpen(true);
    setActive(0);
  };
  const handleClickOpen = () => {
    getAgentDetails();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        className={classes.viewButton}
        startIcon={<VisibilityIcon />}
        size="small"
        onClick={handleClickOpen}
      >
        View Documents
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.Dialog}
      >
        {active == 0 ? (
          <Personaldetails
            active={value => setActive(value)}
            agentDetails={details}
            agentId={agentDetails.agent_id}
            user={user}
            rejectAgentHandle={value => setOpen(value)}
          />
        ) : active == 1 ? (
          <CompanyDetails
            active={value => setActive(value)}
            agentDetails={details}
            agentId={agentDetails.agent_id}
            user={user}
            rejectAgentHandle={value => setOpen(value)}
          />
        ) : active == 2 ? (
          <AccountBalance
            active={value => setActive(value)}
            submit={value => setOpen(value)}
            agentDetails={details}
            agentId={agentDetails.agent_id}
            user={user}
            rejectAgentHandle={value => setOpen(value)}
            status={agentDetails.approved}
          />
        ) : (
          ''
        )}
      </Dialog>
    </React.Fragment>
  );
}
