// @material-ui/icons

const pagesRoutes = [
  {
    redirect: true,
    path: '/',
    pathTo: '/pages/landing-page',
    name: 'Landing Page',
  },
];

export default pagesRoutes;
