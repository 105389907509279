/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable class-methods-use-this */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// import { Manager, Reference, Popper } from 'react-popper';
import Popper from '@material-ui/core/Popper';
import { Auth } from 'aws-amplify';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
// import { createBrowserHistory } from "history";
// @material-ui/icons
import Person from '@material-ui/icons/Person';
// import Notifications from "@material-ui/icons/Notifications";

// core components
import Button from 'components/CustomButtons/Button.jsx';

import headerLinksStyle from 'assets/jss/material-dashboard-pro-react/components/headerLinksStyle';
import history from '../../history.js';
import '../../views/Pages/main.css';
import '../../views/Pages/color.css';

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openNotification: false,
      openProfile: false,
    };
    this.logOut = this.logOut.bind(this);
    this.handleClickNotification = this.handleClickNotification.bind(this);
    this.handleCloseNotification = this.handleCloseNotification.bind(this);
    this.handleClickProfile = this.handleClickProfile.bind(this);
    this.handleCloseProfile = this.handleCloseProfile.bind(this);
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  /**
   * phone auth logout
   * @function
   */
  logOut() {
    Auth.signOut({ global: true })
      .then(data => {
        history.push('/pages/login-page');
      })
      .catch(err => console.log(err));
  }

  handleClickNotification = () => {
    this.setState({ openNotification: !this.state.openNotification });
  };

  handleCloseNotification = () => {
    this.setState({ openNotification: false });
  };

  handleClickProfile = () => {
    this.setState({ openProfile: !this.state.openProfile });
  };

  handleCloseProfile = () => {
    this.setState({ openProfile: false });
  };

  render() {
    const { classes, rtlActive } = this.props;
    const { openNotification } = this.state;
    const dropdownItem = `${classes.dropdownItem} ${classNames({
      [classes.dropdownItemRTL]: rtlActive,
    })}`;
    // const dropdownItem = classNames(
    //   classes.dropdownItem,
    //   classes.primaryHover,
    //   { [classes.dropdownItemRTL]: rtlActive }
    // );
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive,
    });
    return (
      <div className={wrapper}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={openNotification ? 'notification-menu-list' : null}
          aria-haspopup="true"
          onClick={this.handleClickNotification}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : '',
          }}
          buttonRef={node => {
            this.anchorNotification = node;
          }}
        >
          <Person />
        </Button>
        <Popper
          open={openNotification}
          anchorEl={this.anchorNotification}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: '0 0 0' }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={this.handleCloseNotification}>
                  <MenuList role="menu">
                    <MenuItem onClick={this.logOut} className={dropdownItem}>
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
};

export default withStyles(headerLinksStyle)(HeaderLinks);
