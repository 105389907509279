/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Auth } from 'aws-amplify';
import Personaldetails from './MultiStep/Personaldetails';
import TripDetails from './MultiStep/TripDetails';
import PODdetails from './MultiStep/PODDetails';
import EwayBill from './MultiStep/EwayBill';
import AccountBalance from './MultiStep/AccountDetails';
import apiCaller from '../../../utils/apiCallerESP';

const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: { marginTop: '40px' },
  dialogActions: { display: 'flex', alignContent: 'center' },
}));

export default function MaxWidthDialog({ tripID, userId, balance }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [active, setActive] = React.useState(0);
  // const [user, setUser] = React.useState();
  const [approvedAmount, setApprovedAmount] = React.useState('');
  const [personalRemarks, setPersonalRemarks] = React.useState('');
  const [podRemarks, setPODRemarks] = React.useState('');
  const [tripRemarks, setTripRemarks] = React.useState('');
  const [ewayRemarks, setEwayRemarks] = React.useState('');
  const [espAccount, setESPAccount] = React.useState();

  const [details, setDetails] = React.useState();
  const getTripDetails = async () => {
    const endPoint = 'esp-trip-detail';
    const data = {
      trip_id: tripID,
    };
    const response = await apiCaller(endPoint, data);
    const extractedData = response.data.body.trip_detail;
    setDetails(extractedData);
    setOpen(true);
  };
  const handleClickOpen = () => {
    setActive(0);
    Auth.currentAuthenticatedUser().then(result => {
      // setUser(result);
    });
    getTripDetails();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const ESPRequestSubmit = async () => {
    const body = {
      trip_id: tripID,
      transporter_id: userId,
      approval_status: true,
      approved_balance: approvedAmount,
      account_number: espAccount,
      trip_balance: balance,
      trip_detail_remark: tripRemarks,
      eway_bill_remark: ewayRemarks,
      pod_remark: podRemarks,
      remark: personalRemarks,
    };
    const endpoint = 'esp-balance-request';
    await apiCaller(endpoint, body);
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        className={classes.viewButton}
        startIcon={<VisibilityIcon />}
        size="small"
        onClick={handleClickOpen}
      >
        View Details
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.Dialog}
      >
        {active == 0 ? (
          <Personaldetails
            active={value => setActive(value)}
            tripDetails={details}
            remarks={value => setPersonalRemarks(value)}
          />
        ) : active == 1 ? (
          <TripDetails
            active={value => setActive(value)}
            tripDetails={details}
            remarks={value => setTripRemarks(value)}
            approvedAmount={value => setApprovedAmount(value)}
          />
        ) : active == 2 ? (
          <PODdetails
            active={value => setActive(value)}
            tripDetails={details}
            remarks={value => setPODRemarks(value)}
          />
        ) : active == 3 ? (
          <EwayBill
            active={value => setActive(value)}
            tripDetails={details}
            remarks={value => setEwayRemarks(value)}
          />
        ) : active == 4 ? (
          <AccountBalance
            active={value => setActive(value)}
            tripDetails={details}
            userId={userId}
            submit={() => ESPRequestSubmit()}
            selectedAccountId={value => setESPAccount(value)}
          />
        ) : (
          ''
        )}
      </Dialog>
    </React.Fragment>
  );
}
