/* eslint-disable import/no-mutable-exports */
import Dashboard from 'views/Dashboard/Dashboard.jsx';
// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupAdd from '@material-ui/icons/GroupAdd';
import LocalShipping from '@material-ui/icons/LocalShipping';
import List from '@material-ui/icons/List';

import AddTransaction from 'views/AddTransaction/AddTransaction.jsx';

import Vendor from '../views/Vendor/Vendor';
import VendorList from '../views/Vendor/VendorList';
import VendorHistory from '../views/Vendor/VendorHistory';
import Users from '../views/Users/Users.jsx';
import BulkTransaction from '../views/BulkTransaction/BulkTransaction';

const checkerRoutes = {
  routes: [
    {
      path: '/checker/dashboard',
      name: 'Dashboard',
      icon: DashboardIcon,
      component: Dashboard,
    },
    {
      collapse: true,
      // path: '/admin/vendor',
      name: 'Transactions',
      icon: List,
      state: 'openApprove',
      views: [
        {
          path: '/checker/create_transaction',
          name: 'Add Transaction',
          mini: 'A',
          component: AddTransaction,
        },
        {
          path: '/checker/bulk_transaction',
          name: 'Bulk Transaction',
          mini: 'B',
          component: BulkTransaction,
        },
      ],
    },
    {
      collapse: true,
      path: '/checker/vendor',
      name: 'Vendor',
      icon: LocalShipping,
      state: 'openVendor',
      views: [
        {
          path: '/checker/vendor/add',
          name: 'Add Vendor',
          mini: 'A',
          component: Vendor,
        },
        {
          path: '/checker/vendor/list',
          name: 'Vendor List',
          mini: 'L',
          component: VendorList,
        },
        {
          path: '/checker/vendor/history',
          name: 'Vendor History',
          mini: 'V',
          component: VendorHistory,
        },
      ],
    },
    {
      collapse: true,
      path: '/checker/users',
      name: 'Users',
      icon: GroupAdd,
      state: 'openUsers',
      views: [
        {
          path: '/checker/users/view',
          name: 'View',
          mini: 'V',
          component: Users,
        },
        // {
        //   path: '/checker/users/add',
        //   name: 'Add',
        //   mini: 'A',
        //   component: AddUsers,
        // },
      ],
    },
    {
      redirect: true,
      path: '/checker',
      pathTo: '/checker/dashboard',
      name: 'Dashboard',
    },
  ],
};

export default checkerRoutes;
