export const CHECK_TRUCKER_EDIT_FORM = 'truckers/CHECK_TRUCKER_EDIT_FORM';

const initialState = {
  isTruckerEditForm: false,
  selectedTruckerData: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHECK_TRUCKER_EDIT_FORM:
      return {
        ...state,
        isTruckerEditForm: action.isTruckerEditForm,
        selectedTruckerData: action.selectedTruckerData,
      };
    default:
      return state;
  }
};

export const switchTruckerActivePage = (
  activePage,
  truckerData
) => dispatch => {
  dispatch({
    type: CHECK_TRUCKER_EDIT_FORM,
    isTruckerEditForm: activePage,
    selectedTruckerData: truckerData,
  });
};
