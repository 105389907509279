/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable guard-for-in */
/* eslint-disable vars-on-top */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-var */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import 'reactjs-popup/dist/index.css';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    margin: 20,
  },
  tableHead: {
    backgroundColor: 'black',
  },
  tableCell: {
    color: 'white',
    padding: '20px',
  },
  sampleButton: {
    background: '#3DAC16',
    color: 'white',
    // width: '124px',
    height: '36px',
    // padding: '1px 10px',
  },
});
var temp = [];
var rows = [];

function createData(truck_number, vendor_name, status, amount, reason) {
  return { truck_number, vendor_name, status, amount, reason };
}
const headers = [
  { title: 'Truck Number' },
  { title: 'Vendor Name' },
  { title: 'Success/Failure' },
  { title: 'Amount' },
  { title: 'Reason' },
];

export default function BulkTransactionHistoryModal({ logID, data }) {
  const classes = useStyles();
  const [filtering, setFiltering] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  const [filterAmount, setFilterAmount] = React.useState('');
  const size = rows.length;
  const handleChangePage = () => {
    setPage(page + 1);
  };
  const handlePreviousChangePage = () => {
    setPage(page - 1);
  };

  const handleFilterQuery = event => {
    const filterQuery = event.target.value;
    var reg = new RegExp('^[0-9]*$');
    if (reg.test(filterQuery)) {
      setFilterAmount(filterQuery);
    } else alert('Not  number');
  };
  const filterData = async () => {
    rows = [];
    temp = [];
    setFiltering(true);
    for (const i in data) {
      if (data[i].log_id == logID) {
        temp.push(JSON.parse(data[i].history));
      }
    }
    rows = await temp[0]?.map(item =>
      createData(
        item.truck_number,
        item.vendor_company_name,
        item.status,
        item.amount,
        item.reason
      )
    );
    setFiltering(false);
  };
  useEffect(() => {
    filterData();
  }, []);
  return (
    <Box className={classes.tableContainer}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '20px',
        }}
      >
        <Button
          onClick={handlePreviousChangePage}
          disabled={page == 0}
          variant="contained"
        >
          Previous
        </Button>
        <Button
          onClick={handleChangePage}
          disabled={page == Math.ceil(size / rowsPerPage)}
          variant="contained"
        >
          next
        </Button>
      </div>
      <TableContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '10px',
          }}
        >
          <TextField
            id="standard-secondary"
            label="Filter By Amount"
            color="primary"
            onChange={handleFilterQuery}
          />
        </div>

        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
          // stickyHeader
        >
          <TableHead className={classes.tableHead}>
            <TableRow>
              {headers.map(item => (
                <TableCell align="center" className={classes.tableCell}>
                  {item.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filtering ? (
              <CircularProgress style={{ margin: 40 }} />
            ) : rows ? (
              rows
                .filter(transactions =>
                  filterAmount
                    ? transactions.amount.toString().includes(filterAmount)
                    : transactions
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map(row => (
                  <TableRow key={row}>
                    <TableCell
                      align="center"
                      style={{ padding: '20px', fontWeight: 'bold' }}
                    >
                      {row.truck_number}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ padding: '20px', fontWeight: 'bold' }}
                    >
                      {row.vendor_name}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        padding: '20px',
                        color:
                          row.status == 'Success'
                            ? 'green'
                            : row.status == 'Failure'
                            ? '#FF0000'
                            : '',
                      }}
                    >
                      {row.status}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontWeight: 'bold', padding: '20px' }}
                    >
                      {`₹${row.amount}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color:
                          row.status == 'Success'
                            ? 'green'
                            : row.status == 'Failure'
                            ? '#FF0000'
                            : '',
                        padding: '20px',
                      }}
                    >
                      {row.reason}
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <p>No data Found</p>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
