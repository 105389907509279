import { Grid, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import apiCaller from '../../utils/apiCallerESP';
import RequestsListView from './BrokerRequests/RequestsListView';

const BrokerRequest = () => {
  const [fetching, setFetching] = React.useState(false);
  const [user, setUser] = React.useState();
  const [brokerDetails, setBrokerDetails] = React.useState();

  const getBrokerList = async userID => {
    const endPoint = 'broker-list';
    const data = {
      transporter_id: userID,
      limit: '10',
      offset: 0,
    };
    try {
      const response = await apiCaller(endPoint, data);
      const { agentsList } = response.data.body;
      setBrokerDetails(agentsList);
      setFetching(false);
    } catch (e) {
      console.error(e);
      setFetching(false);
    }
  };
  useEffect(() => {
    setFetching(true);
    Auth.currentAuthenticatedUser().then(result => {
      setUser(result.username);
      getBrokerList(result.username);
    });
  }, []);
  return (
    <div>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item>
              <TextField
                id="outlined-basic"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search Broker"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <RequestsListView
            fetching={fetching}
            brokerDetails={brokerDetails}
            triggerRefresh={() => {
              getBrokerList(user);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default BrokerRequest;
