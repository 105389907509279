/* eslint-disable import/no-mutable-exports */
import Pages from 'layouts/Pages.jsx';
import AdminDashboard from 'layouts/AdminDashboard.jsx';
import CheckerDashboard from 'layouts/CheckerDashboard.jsx';
import MakerDashboard from 'layouts/MakerDashboard.jsx';
import SuperAdminDashboard from '../layouts/SuperAdminDashboard';
import Ledger from '../layouts/Ledger';

const indexRoutes = [
  { path: '/super_admin', name: 'SuperAdmin', component: SuperAdminDashboard },
  { path: '/admin', name: 'Admin', component: AdminDashboard },
  { path: '/checker', name: 'Checker', component: CheckerDashboard },
  { path: '/maker', name: 'Maker', component: MakerDashboard },
  { path: '/ledger', name: 'Ledger', component: Ledger },
  { path: '/', name: 'Pages', component: Pages },
];

export default indexRoutes;
