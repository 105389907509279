import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import norecord from '../../../assets/img/norecord.png';
import DocumentDialog from './DocumentDialog';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: { backgroundColor: 'black' },
  tableCell: { color: 'white' },
  tableBodyCell: { height: '60px' },
  viewButton: { backgroundColor: '#3366FF' },
});

function createData(agentName, From, To, truckNo, balance, tripId, status) {
  return {
    agentName,
    From,
    To,
    truckNo,
    balance,
    tripId,
    status,
  };
}

export default function DenseTable({ tripDetails, userId }) {
  const classes = useStyles();
  const rows = tripDetails?.map(trip =>
    createData(
      trip.agent_name,
      trip.from_city,
      trip.to_city,
      trip.truck_number,
      trip.balance,
      trip.trip_id,
      trip.trip_status
    )
  );
  if (tripDetails) {
    return (
      <div>
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableCell} align="left">
                  Agent Name
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  From City
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  To City
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Truck Number
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Balance Amount
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Details
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map(row => (
                <TableRow
                  key={row.transactionSerial}
                  className={classes.tableBodyCell}
                >
                  <TableCell component="th" scope="row" align="left">
                    {row.agentName}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.From}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.To}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.truckNo}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.balance}
                  </TableCell>

                  <TableCell component="th" scope="row" align="center">
                    <DocumentDialog
                      tripID={row.tripId}
                      userId={userId}
                      balance={row.balance}
                    />
                  </TableCell>
                  <TableCell align="center">{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
  return (
    <Grid container justifyContent="center" align="center">
      <Grid item xs={12}>
        <img src={norecord} alt="emptystate" />
      </Grid>
      <Grid item xs={12}>
        <p style={{ textAlign: 'center', margin: '10px' }}>No Requests Found</p>
      </Grid>
    </Grid>
  );
}
