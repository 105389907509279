import React from 'react';
import LensIcon from '@material-ui/icons/Lens';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CachedIcon from '@material-ui/icons/Cached';
import moment from 'moment';

const CustomSelect = ({ triggerRefresh }) => {
  const handleFilterList = value => {
    triggerRefresh(value);
  };
  return (
    <div
      style={{
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <ButtonGroup
          disableElevation
          variant="contained"
          color="primary"
          onClick={event => console.log(event)}
        >
          <Button
            variant="outlined"
            startIcon={<LensIcon style={{ fontSize: 10, color: 'black' }} />}
            onClick={() => handleFilterList('all')}
          >
            All
          </Button>

          <Button
            variant="outlined"
            startIcon={<LensIcon style={{ fontSize: 10, color: '#FF7E03' }} />}
            onClick={() => handleFilterList('processing')}
          >
            Processing
          </Button>
          <Button
            variant="outlined"
            startIcon={<LensIcon style={{ fontSize: 10, color: '#00811C' }} />}
            onClick={() => handleFilterList('processed')}
          >
            Processed
          </Button>
          <Button
            variant="outlined"
            startIcon={<LensIcon style={{ fontSize: 10, color: '#3366FF' }} />}
            onClick={() => handleFilterList('success')}
          >
            Success
          </Button>
          <Button
            variant="outlined"
            startIcon={<LensIcon style={{ fontSize: 10, color: '#D14343' }} />}
            onClick={() => handleFilterList('reversed')}
          >
            Reversed
          </Button>
        </ButtonGroup>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          startIcon={<CachedIcon />}
          onClick={() => handleFilterList('all')}
        >
          Refresh
        </Button>
        <Typography variant="body1" style={{ color: 'lightgray' }}>
          Last refresh at {moment().format('MMMM Do YYYY, h:mm:ss a')}
        </Typography>
      </div>
    </div>
  );
};

export default CustomSelect;
