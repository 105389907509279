/* eslint-disable no-console */
/* eslint-disable camelcase */
// const auth = {
//   isAuthenticated: true,
//   role: 'receptionist',
//   activeRole: 'doctor',
//   userDetails: {
//     name: 'Hemanth',
//     email: 'h@wipadika.com',
//     phoneNumber: '9545366277',
//   },
//   idToken: '',
//   accessToken: '',
//   refreshToken: '',
//   userPoolId: '',
//   username: '',
// };

import { Auth } from 'aws-amplify';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function refreshToken() {
  return new Promise((resolve, reject) => {
    Auth.currentSession({ bypassCache: true })
      .then(data => {
        const { accessToken, idToken } = data;
        const refToken = data.refreshToken;
        cookies.set('idToken', idToken.jwtToken);
        cookies.set('accessToken', accessToken.jwtToken);
        cookies.set('refreshToken', refToken.jwtToken);
      })
      .catch(err => {
        console.log('Error', err);
        const emptyObj = removedAuth();
        reject(emptyObj);
        cookies.remove('email');
        cookies.remove('name');
        cookies.remove('userId');
        cookies.remove('userGroup');
        cookies.remove('role');
        cookies.remove('accessToken');
        cookies.remove('refreshToken');
        cookies.remove('idToken');
      });
  });
}

const removedAuth = () => {
  const emptyAuth = {
    isAuthenticated: false,
    role: '',
    activeRole: '',
    userDetails: {
      name: '',
      email: '',
      phoneNumber: '',
    },
    idToken: '',
    accessToken: '',
    refreshToken: '',
  };
  return emptyAuth;
};
