/* eslint-disable camelcase */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-prototype-builtins */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable react/require-default-props */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import moment from 'moment';

import { setWalletBalance } from '../../reducers/main';
import { setLedgers } from '../../reducers/transactions';
import apiCaller from '../../utils/apiCaller';
import './react-table.css';


const defaultButton = props => (
  <Button {...props} color="rose">
    {props.children}
  </Button>
);
const style = {
  customCardContentClass: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  cardIconTitle: {
    marginTop: '15px',
    marginBottom: '0px',
  },
};

class Pagination extends React.Component {
  constructor(props) {
    super();
    this.state = {
      next_cursor: null,
      current_cursor: null,
    };
    this.changePage = this.changePage.bind(this);
  }

  static propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    PageButtonComponent: PropTypes.any,
    onPageChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string,
  };

  componentDidMount() {
    // this.getTransactionList();
  }

  componentWillReceiveProps(nextProps) {
    this.changePage(nextProps.page + 1);
  }

  changePage(page) {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    this.props.onPageChange(page - 1);
  }

  onNext(activePage) {
    const { ledgerLastEvaluatedKey, ledgerId } = this.props;
    if (activePage === this.props.pages) {
      if (ledgerLastEvaluatedKey === null) {
        // this.getNextVendors();
        this.changePage(activePage + 1);
      } else {
        if (ledgerId.includes('LO-')) {
          this.setState(
            {
              ledgerId,
            },
            () => this.getNextLobbOfficeLedgers()
          );
        } else {
          this.getNextVendors();
        }
        this.changePage(activePage + 1);
      }
    } else if (this.props.ledgerLastEvaluatedKey !== null) {
      if (ledgerId.includes('LO-')) {
        this.setState(
          {
            ledgerId,
          },
          () => this.getNextLobbOfficeLedgers()
        );
      } else {
        this.getNextVendors();
      }
      this.changePage(activePage + 1);
    } else {
      this.changePage(activePage + 1);
    }
  }

  getNextLobbOfficeLedgers() {
    const { ledgerLastEvaluatedKey, ledgers, ledgerId } = this.props;
    const vendorData = JSON.parse(JSON.stringify(ledgers));
    const body = {
      ledgerId: ledgerId,
      limit: 10,
      last_key: ledgerLastEvaluatedKey,
    };
    this.setState({
      isLoading: true,
    });
    apiCaller('utilities-getLedgerLink', 'post', body, false, false, true)
      .then(response => {
        const { ledgerList } = response;
        ledgerList.forEach(dataList => {
          const { total_fee } = dataList;
          const amount_received = Number(dataList.amount) - Number(total_fee);
          dataList.amount_recieved = amount_received;
          dataList.fee = total_fee;
          dataList.service_provider_utr = dataList.utr;
          dataList.notes = dataList.remarks;
          dataList.truck_number = dataList.vehicleNumber;
          vendorData.push({
            color: 'success',
            data: [
              dataList.createdAt || '___',
              Intl.NumberFormat('en-IN').format(dataList.amount) || '___',
              Intl.NumberFormat('en-IN').format(total_fee) || '___',
              Intl.NumberFormat('en-IN').format(amount_received) ||
                '___',
              dataList.utr || '___',
              dataList.vehicleNumber || '___',
              dataList.remarks || '___',
            ],
            dataList,
          });
        });
        if(ledgerList.length > 0) {
          let last_key = Number(ledgerLastEvaluatedKey);
          last_key += 10;
          this.props.setLedgers(vendorData, last_key);
        } else {
          let last_key = null;
          this.props.setLedgers(vendorData, last_key);
        }
      })
      .catch(err => {
        this.setState({
          showImage: false,
          isLoading: false,
        });
        console.log('Err', err);
      });
  }

  getNextVendors() {
    const { ledgerLastEvaluatedKey, ledgers, ledgerId } = this.props;
    const vendorData = JSON.parse(JSON.stringify(ledgers));
    const body = {
      ledger_id: ledgerId,
      total_items: 10,
    };
    let nextCursorId = null;

    if (ledgerLastEvaluatedKey !== '' || ledgerLastEvaluatedKey !== null) {
      body.last_key = ledgerLastEvaluatedKey;
    }
    apiCaller('premium-api/get-ledger', 'post', body, true, false)
      .then(response => {
        if (response.payouts.length !== 0) {
          response.payouts.forEach(dataList => {
            if (
              response.hasOwnProperty('last_key') &&
              response.last_key !== null
            ) {
              nextCursorId = response.last_key;
            } else {
              nextCursorId = null;
            }
            vendorData.push({
              color: 'success',
              data: [
                moment.unix(dataList.modified_at).format('DD-MM-YYYY') || '___',
                Intl.NumberFormat('en-IN').format(dataList.amount) || '___',
                Intl.NumberFormat('en-IN').format(dataList.fee),
                Intl.NumberFormat('en-IN').format(dataList.amount_recieved) ||
                  '___',
                dataList.service_provider_utr || '___',
                dataList.truck_number || '___',
                dataList.notes || '___',
              ],
              dataList,
            });
          });
          this.props.setLedgers(vendorData, nextCursorId);
        } else {
          this.props.setLedgers(vendorData, null);
        }
      })
      .catch(err => {});
  }

  render() {
    const { PageButtonComponent = defaultButton } = this.props;
    const activePage = this.props.page + 1;
    return (
      <GridContainer justify="start">
        <GridItem xs={4} sm={3} md={6} className="no-padding">
          <PageButtonComponent
            className="prev-button"
            onClick={() => {
              if (activePage === 1) return;
              this.changePage(activePage - 1);
            }}
            disabled={activePage === 1}
          >
            {this.props.previousText}
          </PageButtonComponent>
        </GridItem>

        <GridItem xs={4} sm={3} md={6} className="no-padding">
          <PageButtonComponent
            className="next-button"
            onClick={() => {
              //   if (activePage === this.props.pages) return;
              this.onNext(activePage);
            }}
            disabled={
              (this.props.ledgerLastEvaluatedKey === '' ||
                this.props.ledgerLastEvaluatedKey === null) &&
              activePage === this.props.pages
            }
            // style={{ float: 'right' }}
          >
            {this.props.nextText}
          </PageButtonComponent>
        </GridItem>
      </GridContainer>
    );
  }
}

Pagination.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setWalletBalance,
      setLedgers,
    },
    dispatch
  );

const mapStateToProps = state => ({
  walletBalance: state.main.walletBalance,
  ledgers: state.transactions.ledgers,
  ledgerId: state.transactions.ledgerId,
  ledgerLastEvaluatedKey: state.transactions.ledgerLastEvaluatedKey,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Pagination));
