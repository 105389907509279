/* eslint-disable import/no-mutable-exports */
import Dashboard from 'views/Dashboard/Dashboard.jsx';

import AddTransaction from 'views/AddTransaction/AddTransaction.jsx';

// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupAdd from '@material-ui/icons/GroupAdd';
import LocalShipping from '@material-ui/icons/LocalShipping';
import List from '@material-ui/icons/List';
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ESPDashboard from '../views/ESP/Dashboard';
import Vendor from '../views/Vendor/Vendor';
import VendorList from '../views/Vendor/VendorList';
import WalletList from '../views/Wallet/WalletList';
import VendorHistory from '../views/Vendor/VendorHistory';
import Users from '../views/Users/Users.jsx';
import AddUsers from '../views/Users/AddUsers.jsx';
import BulkTransaction from '../views/BulkTransaction/BulkTransaction';
import BrokerRequest from '../views/ESP/BrokerRequest';
import ESPRequests from '../views/ESP/ESPRequests';

const dashRoutes = {
  agentRoutes: [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
      icon: DashboardIcon,
      component: Dashboard,
    },
    {
      redirect: true,
      path: '/admin',
      pathTo: '/admin/dashboard',
      name: 'Dashboard',
    },
  ],
  adminRoutes: [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
      icon: DashboardIcon,
      component: Dashboard,
    },
    {
      path: '/admin/account_view',
      name: 'View Account',
      icon: AccountBalanceWallet,
      component: WalletList,
    },
    {
      collapse: true,
      // path: '/admin/vendor',
      name: 'ESP',
      icon: AccountBalanceIcon,
      state: 'openESP',
      views: [
        {
          path: '/admin/ESPDashboard',
          name: 'Dashboard',
          mini: 'D',
          component: ESPDashboard,
        },
        {
          path: '/admin/BrokerRequest',
          name: 'Broker Requests',
          mini: 'B',
          component: BrokerRequest,
        },
        {
          path: '/admin/ESPRequest',
          name: 'ESP Requests',
          mini: 'E',
          component: ESPRequests,
        },
      ],
    },
    {
      collapse: true,
      // path: '/admin/vendor',
      name: 'Transactions',
      icon: List,
      state: 'openApprove',
      views: [
        {
          path: '/admin/create_transaction',
          name: 'Add Transaction',
          mini: 'A',
          component: AddTransaction,
        },
        {
          path: '/admin/bulk_transaction',
          name: 'Bulk Transaction',
          mini: 'B',
          component: BulkTransaction,
        },
      ],
    },
    {
      collapse: true,
      path: '/admin/vendor',
      name: 'Vendor',
      icon: LocalShipping,
      state: 'openVendor',
      views: [
        {
          path: '/admin/vendor/add',
          name: 'Add Vendor',
          mini: 'A',
          component: Vendor,
        },
        {
          path: '/admin/vendor/list',
          name: 'Vendor List',
          mini: 'V',
          component: VendorList,
        },
        {
          path: '/admin/vendor/history',
          name: 'Vendor History',
          mini: 'V',
          component: VendorHistory,
        },
      ],
    },
    {
      collapse: true,
      path: '/admin/users',
      name: 'Users',
      icon: GroupAdd,
      state: 'openUsers',
      views: [
        {
          path: '/admin/users/view',
          name: 'View',
          mini: 'V',
          component: Users,
        },
        {
          path: '/admin/users/add',
          name: 'Add',
          mini: 'A',
          component: AddUsers,
        },
      ],
    },
    {
      redirect: true,
      path: '/admin',
      pathTo: '/admin/dashboard',
      name: 'Dashboard',
    },
  ],
};

export default dashRoutes;
