/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import { Button, DialogContent } from '@material-ui/core';
import apiCaller from '../../../utils/apiCallerESP';

const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid lightgray 2px',
  // borderRadius: '7px',
  margin: '20px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',

  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};
const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: { marginTop: '40px' },
  dialogActions: { display: 'flex', alignContent: 'center' },
  info: { display: 'flex', alignContent: 'baseline', margin: '10px' },
  TextField: {
    border: '1px solid lightgray',
    borderRadius: '4px',
    padding: ' 2px',
    marginLeft: '10px',
  },
  footerAction: { display: 'flex', justifyContent: 'center', margin: '30px' },
  cta: { backgroundColor: '#3366FF', color: '#FFFFFF' },
  label: { fontWeight: 'bold' },
  enabledButton: { backgroundColor: '#039400', color: 'white' },
}));

export default function MaxWidthDialog({ data }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [accountname, setAccountName] = React.useState('');
  const [espLimit, setESPLimit] = React.useState('');
  const [valid, setValid] = React.useState({ name: true, limit: false });
  // const [alert, setAlert] = React.useState(false);

  const { user_id } = data;

  const handleClickOpen = event => {
    if (!data.esp_enabled) {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleESPLimit = event => {
    const regex = /^[0-9][A-Za-z0-9 -]*$/;
    const test = regex.test(event.target.value);
    if (test) {
      setValid({ limit: true });
      setESPLimit(event.target.value);
    } else {
      setValid({ limit: false });
    }
  };
  const handleAccountName = event => {
    setAccountName(event.target.value);
  };
  const handleEnableESP = async () => {
    const body = {
      account_name: accountname,
      // company_name,
      // company_user_id,
      transporter_id: user_id,
      balance: espLimit,
      source: 'lobb_pay',
    };
    const endPoint = 'admin-enable-esp';
    const response = await apiCaller(endPoint, body);
    const { status } = response;
    if (status) {
      // setAlert(true);
    }
  };
  // const handleCloseAlert = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }

  //   setOpen(false);
  // };

  // useEffect(() => {}, []);

  return (
    <React.Fragment>
      <Button
        className={data.esp_enabled ? classes.enabledButton : ''}
        size="small"
        onClick={handleClickOpen}
      >
        {data.esp_enabled ? 'Enabled' : 'Disabled'}
      </Button>

      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.Dialog}
      >
        <DialogContent>
          <fieldset style={shifter}>
            <legend style={{ maxWidth: '300px' }}>
              <div style={avatarContainer}>
                <div style={avatar}>
                  <AccountBalanceIcon style={{ color: 'white' }} />
                </div>
                <Typography variant="h6" gutterBottom>
                  Personal Details
                </Typography>
              </div>
            </legend>
            <div>
              <div className={classes.info}>
                <Typography variant="body2" className={classes.label}>
                  Transporter Name :
                </Typography>
                &nbsp; &nbsp; &nbsp;
                <Typography variant="body2">{data.company_name}</Typography>
              </div>
              <div className={classes.info}>
                <Typography variant="body2" className={classes.label}>
                  Account Name :
                </Typography>
                <input
                  type="text"
                  className={classes.TextField}
                  onChange={handleAccountName}
                />
              </div>
              <div className={classes.info}>
                <Typography variant="body2" className={classes.label}>
                  ESP Account ID :{' '}
                </Typography>
                &nbsp; &nbsp; &nbsp;
                <Typography variant="body2">124325436344214</Typography>
              </div>
              <div className={classes.info}>
                <Typography variant="body2" className={classes.label}>
                  ESP Limit :
                </Typography>
                <input
                  type="text"
                  className={classes.TextField}
                  onChange={handleESPLimit}
                />
              </div>
              <div className={classes.footerAction}>
                <Button
                  className={classes.cta}
                  size="small"
                  onClick={handleEnableESP}
                  disabled={!valid.limit}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </fieldset>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
