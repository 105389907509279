/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Visibility from '@material-ui/icons/Visibility';
import Refresh from '@material-ui/icons/Refresh';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';

import AddAlert from '@material-ui/icons/AddAlert';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import AdminEditUser from './AdminEditUser';
import apiCaller from '../../utils/apiCaller';

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      userDataList: [],
      isLoading: false,
      view: true,
      errMessage: '',
      errSnack: false,
    };
    this.getUsers = this.getUsers.bind(this);
    this.AddUser = this.AddUser.bind(this);
    this.goToView = this.goToView.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.refreshUserList = this.refreshUserList.bind(this);
    this.changeFont = this.changeFont.bind(this);
    this.deleteuser = this.deleteuser.bind(this);
  }

  componentDidMount() {
    this.getUsers();
  }

  openEditForm(user) {
    this.setState({ selectedUserData: user, view: false });
  }

  deleteuser(user) {
    const body = {};
    apiCaller('', 'post', body, true, false)
      .then(response => {
        if (response.message === 'success') {
          this.setState(
            {
              infoMessage: 'Delete User Successfully',
            },
            this.showNotification('infoSnack')
          );
          this.getUsers();
        } else {
          this.setState(
            {
              errMessage: response.message || 'Something Went wrong',
              isLoading: false,
            },
            this.showNotification('errSnack')
          );
        }
      })
      .catch(err => {
        this.setState(
          {
            errMessage: err.message || 'Something Went wrong',
            isLoading: false,
          },
          this.showNotification('errSnack')
        );
      });
  }

  changeFont(value) {
    return <span style={{ fontSize: '16px' }}>{value}</span>;
  }

  refreshUserList() {
    this.setState({
      users: [],
      userDataList: [],
    });
    this.getUsers();
  }

  goToView() {
    this.setState({ view: true });
  }

  /**
   * Shows the notification when user click submit for success message.
   */
  showNotification = place => {
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 4000);
    }
  };

  /**
   * Will Get ALl the Truckers For The User
   * @function
   */
  getUsers(user) {
    const userDataList = [];
    this.setState({ isLoading: false });
    let flag = 0;
    const body = {};
    apiCaller('premium-api/read-user-list', 'post', body, true, false)
      .then(response => {
        response.items.forEach(userItem => {
          userDataList.push({
            color: 'success',
            data: [
              userItem.first_name,
              userItem.phone_number,
              userItem.email_address,
            ],
            userItem,
          });
        });
        this.setState({ userDataList });
        if (flag === 0) {
          flag = 1;
        } else {
          flag = 0;
        }
      })
      .catch(err => {
        this.setState(
          {
            errMessage: err.message || 'No Vendors found',
            isLoading: false,
          },
          this.showNotification('errSnack')
        );
      });
  }

  /**
   * will redirect to Add Truckers Page
   * @function
   */
  AddUser() {
    this.props.history.push('/admin/users/add');
  }

  render() {
    const { classes } = this.props;
    const { userDataList, errMessage, errSnack } = this.state;
    return this.state.view ? (
      <div>
        <GridContainer justify="center">
          <Snackbar
            place="tc"
            color="danger"
            icon={AddAlert}
            message={errMessage || ''}
            open={errSnack}
            closeNotification={() => this.setState({ errSnack: false })}
            close
          />
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="warning">
                <CardIcon color="primary"> {userDataList.length} </CardIcon>
                <h5
                  className={classes.cardTitle}
                  style={{ color: '#FFF', fontWeight: '400', fontSize: '28px' }}
                >
                  Users List
                </h5>
                <Refresh
                  onClick={() => this.refreshUserList()}
                  style={{
                    float: 'right',
                    top: '0px',
                    cursor: 'pointer',
                    fontSize: 30,
                  }}
                />
              </CardHeader>
              <CardBody>
                {this.state.isLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    thickness={7}
                  />
                ) : (
                  // <React.Fragment>
                  <>
                    {userDataList.length <= 0 ? (
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <p className={classes.textCenter}>No Users Available</p>
                        {/* <div className={classes.textCenter}>
                          <Button round color="warning" onClick={this.AddUser}>
                            Add User
                          </Button>
                        </div> */}
                      </GridItem>
                    ) : (
                      // <GridItem xs={12} sm={12} md={12} lg={12}>
                      //   <Card>
                      //     <CardBody>
                      <ReactTable
                        getTheadThProps={() => ({
                          style: {
                            outline: 0,
                            color: 'black',
                            fontSize: '18px',
                            fontWeight: '400',
                          },
                        })}
                        style={{
                          height: '900px',
                        }}
                        data={userDataList.map((prop, key) => ({
                          id: key,
                          name: prop.data[0],
                          phone: prop.data[1],
                          email: prop.data[2],
                          actions: (
                            <div className="actions-right">
                              <Tooltip title="View" arrow>
                                <Button
                                  justIcon
                                  round
                                  simple
                                  onClick={() =>
                                    this.openEditForm(prop.userItem)
                                  }
                                  color="success"
                                  className="view"
                                >
                                  <Visibility />
                                </Button>
                              </Tooltip>{' '}
                              {/* <Tooltip title="Delete" arrow>
                                      <Button
                                        justIcon
                                        round
                                        simple
                                        onClick={() =>
                                          this.deleteuser(prop.userItem)
                                        }
                                        color="danger"
                                        className="view"
                                      >
                                        <DeleteOutlined />
                                      </Button>
                                    </Tooltip>{' '} */}
                            </div>
                          ),
                        }))}
                        filterable
                        columns={[
                          {
                            Header: 'Name',
                            accessor: 'name',
                            Cell: row => this.changeFont(row.value),
                            Filter: ({ filter, onChange }) => (
                              <input
                                onChange={event => onChange(event.target.value)}
                                value={filter ? filter.value : ''}
                                placeholder="Name"
                                style={{
                                  width: '100%',
                                  backgroundColor: 'white',
                                  color: 'black',
                                }}
                              />
                            ),
                          },
                          {
                            Header: 'Phone',
                            accessor: 'phone',
                            Cell: row => this.changeFont(row.value),
                            Filter: ({ filter, onChange }) => (
                              <input
                                onChange={event => onChange(event.target.value)}
                                value={filter ? filter.value : ''}
                                placeholder="Phone"
                                style={{
                                  width: '100%',
                                  backgroundColor: 'white',
                                  color: 'black',
                                }}
                              />
                            ),
                          },
                          {
                            Header: 'Email',
                            accessor: 'email',
                            Cell: row => this.changeFont(row.value),
                            Filter: ({ filter, onChange }) => (
                              <input
                                onChange={event => onChange(event.target.value)}
                                value={filter ? filter.value : ''}
                                placeholder="Email"
                                style={{
                                  width: '100%',
                                  backgroundColor: 'white',
                                  color: 'black',
                                }}
                              />
                            ),
                          },
                          {
                            Header: 'Actions',
                            accessor: 'actions',
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        minRows={0}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom={false}
                        className="-striped -highlight"
                      />
                      //     </CardBody>
                      //   </Card>
                      // </GridItem>
                    )}
                  </>
                )}
              </CardBody>
              <CardFooter stats>
                <div className={classes.stats} />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    ) : (
      <AdminEditUser
        goBack={this.goToView}
        selectedUserData={this.state.selectedUserData}
      />
    );
  }
}

Users.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(TransactionStyle)(Users);
