/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable consistent-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/no-unused-state */
/* eslint-disable import/order */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
// react plugin for creating charts

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import DateRange from '@material-ui/icons/DateRange';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import Button from 'components/CustomButtons/Button.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import Transactions from '../Transactions/Transactions.jsx';
import ReactTable from 'react-table';
import Cookies from 'universal-cookie';

import '../../assets/chart.css';
import { setUserDetails, setWalletBalance } from '../../reducers/main';
import { addAuthtoReduxStore } from '../../reducers/auth';
import { checkAuth, setAuthCookies } from '../../services';
import Refresh from '@material-ui/icons/Refresh';

import AddAlert from '@material-ui/icons/AddAlert';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import moment from 'moment';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import apiCaller from '../../utils/apiCaller';
import LoadingOverlay from 'react-loading-overlay';

import history from '../../history';

const cookies = new Cookies();

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      walletLoader: false,
      infoMessage: '',
      infoSnack: false,
      updateDate: '',
      userRole: '',
      account_number: '',
      ifsc: '',
      actions: [
        {
          color: 'success',
          data: ['Transactions', 'dashboard', 'create_transaction'],
        },
        {
          color: 'info',
          data: ['Vendors', 'vendor/list', 'vendor/add'],
        },
      ],
    };
    this.showNotification = this.showNotification.bind(this);
    this.changeFont = this.changeFont.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  state = {
    value: 0,
  };

  componentDidMount() {
    const role = cookies.get('role');
    this.setState({ userRole: role });
    checkAuth()
      .then(result => {
        this.props.addAuthtoReduxStore(result);
        setAuthCookies(result);
        if (role !== 'super_admin') {
          this.props.setWalletBalance();
          this.setTime();
        }
      })
      .catch(err => {
        const result = {
          isAuthenticated: false,
          role: '',
          activeRole: '',
          userDetails: {
            name: '',
            email: '',
            phoneNumber: '',
          },
          idToken: '',
          accessToken: '',
          refreshToken: '',
          username: '',
          userPoolId: '',
        };
        history.push('/pages/initial-page');
        this.props.addAuthtoReduxStore(result);
        setAuthCookies(result);
      });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  changeFont(value) {
    let color;
    let values = value;
    switch (value) {
      case 'queued':
        color = '#d480f2';
        values = 'Queued';
        break;
      case 'otp_verified':
        color = '#87e0ed';
        values = 'Verified';
        break;
      case 'payout.processed':
        color = '#74f285';
        values = 'Processed';
        break;
      case 'user_created':
        color = '#7c78ff';
        values = 'Created';
        break;
      case 'payout.reversed':
        color = '#79f299';
        values = 'Reversed';
        break;
      case 'payout.failed':
        color = '#ba2d36';
        values = 'Failed';
        break;
      case 'admin_rejected':
        color = '#e6303c';
        values = 'Rejected';
        break;
      case 'wallet_failed':
        color = '#e6303c';
        values = 'LOW BALANCE';
        break;
      default:
        color = '#000000';
    }
    return (
      <span style={{ fontSize: '18px', color }}>
        {color !== '#000000' ? values.toUpperCase() : values}
      </span>
    );
  }

  /**
   * Shows the notification when user click submit for success message.
   */
  showNotification = place => {
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 4000);
    }
  };

  renderTransactionsTable() {
    return <Transactions />;
  }

  updateWallet(e) {
    const role = cookies.get('role');
    this.setState({ walletLoader: true });
    const body = {};
    apiCaller(
      'premium-api/client-check-wallet-balance',
      'post',
      body,
      true,
      false
    )
      .then(response => {
        this.setState({
          updateDate: moment
            .unix(response.current_time)
            .format('DD-MM-YYYY hh:mm:ss'),
        });
        if (response.data.message !== 'virtual account not found') {
          response.data.receivers.forEach(data => {
            this.setState({
              account_number: data.account_number || '---',
              name: data.name || '---',
              ifsc: data.ifsc || '---',
              bank_name: data.bank_name === null ? '...' : data.bank_name,
            });
          });
          this.setState({ walletLoader: false });
        } else {
          this.setState(
            {
              infoMessage: 'Virtual account not Found',
              isLoading: false,
              walletLoader: false,
            },
            this.showNotification('infoSnack')
          );
        }
      })
      .catch(err => {
        console.log('error', err);
      });
    this.setState(
      {
        infoMessage: 'Account updated successfully',
        isLoading: false,
        walletLoader: false,
      },
      this.showNotification('infoSnack')
    );
    if (role !== 'super_admin') {
      this.props.setWalletBalance();
    }
    e.preventDefault();
  }

  setTime() {
    const body = {};
    this.setState({ walletLoader: true });
    apiCaller(
      'premium-api/client-check-wallet-balance',
      'post',
      body,
      true,
      false
    )
      .then(response => {
        this.setState({
          updateDate: moment
            .unix(response.current_time)
            .format('DD-MM-YYYY hh:mm:ss'),
        });
        if (response.data.message !== 'virtual account not found') {
          response.data.receivers.forEach(data => {
            this.setState({
              account_number: data.account_number || '---',
              name: data.name || '---',
              ifsc: data.ifsc || '---',
              bank_name: data.bank_name === null ? '...' : data.bank_name,
            });
          });
          this.setState({ walletLoader: false });
        } else {
          this.setState(
            {
              infoMessage: 'Virtual account not Found',
              isLoading: false,
              walletLoader: false,
            },
            this.showNotification('infoSnack')
          );
        }
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          walletLoader: false,
        });
        console.log('error', err);
      });
  }

  goTo(page) {
    const role = cookies.get('role');
    if (role !== undefined) {
      history.push(`/${role}/${page}`);
    }
  }

  render() {
    const { classes } = this.props;
    const {
      infoMessage,
      infoSnack,
      updateDate,
      userRole,
      account_number,
      ifsc,
      walletLoader,
    } = this.state;
    return (
      <div>
        <GridContainer>
          <Snackbar
            place="tc"
            color="success"
            icon={AddAlert}
            message={infoMessage || ''}
            open={infoSnack}
            closeNotification={() => this.setState({ infoSnack: false })}
            close
          />
          {userRole === 'admin' ? (
            <>
              <GridItem xs={12} sm={6} md={6} lg={5}>
                <LoadingOverlay
                  active={walletLoader}
                  spinner
                  text="Loading Account..."
                >
                  <Card>
                    <CardHeader color="info" stats icon>
                      <CardIcon color="info" style={{ height: '74px' }}>
                        <i
                          className="fa fa-inr"
                          style={{ fontSize: '28px', marginTop: '-5px' }}
                          aria-hidden="true"
                        />
                      </CardIcon>
                      <p className={classes.cardCategory}>
                        <span style={{ fontWeight: '300', fontSize: '20px' }}>
                          Account Balance
                        </span>
                      </p>
                      <h5
                        className={classes.cardTitle}
                        style={{ fontSize: '34px' }}
                      >
                        <i
                          className="fa fa-inr"
                          style={{ fontSize: '28px', paddingLeft: '30px' }}
                          aria-hidden="true"
                        />{' '}
                        {Intl.NumberFormat('en-IN').format(
                          this.props.walletBalance
                        )}
                      </h5>
                      <h5
                        className={classes.cardTitle}
                        style={{
                          fontSize: '30px',
                          float: 'left',
                        }}
                      >
                        <b>
                          {`${account_number}`
                            .replace(/(\d{4})/g, '$1 ')
                            .replace(/(^\s+|\s+$)/, '')}
                        </b>
                        <br />
                        <p style={{ fontSize: '15px' }}>{ifsc}</p>
                      </h5>
                    </CardHeader>
                    <CardFooter>
                      <div className={classes.stats}>
                        <DateRange />
                        <p style={{ marginTop: '2px' }}>{updateDate}</p>
                      </div>
                      <div>
                        <Refresh
                          onClick={e => this.updateWallet(e)}
                          style={{
                            float: 'right',
                            top: '0px',
                            cursor: 'pointer',
                            fontSize: 30,
                          }}
                        />
                      </div>
                    </CardFooter>
                  </Card>
                </LoadingOverlay>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={6}>
                <Card>
                  <CardHeader color="info">
                    <h5
                      className={classes.cardTitle}
                      style={{ fontSize: '34px', color: '#FFF' }}
                    >
                      Action Center
                    </h5>
                  </CardHeader>
                  <CardBody>
                    <ReactTable
                      getTheadThProps={() => ({
                        style: {
                          outline: 0,
                          color: 'black',
                          fontSize: '18px',
                          fontWeight: '400',
                        },
                      })}
                      data={this.state.actions.map((prop, key) => ({
                        id: key,
                        name: this.changeFont(prop.data[0]),
                        actions: (
                          <GridContainer justify="flex-end">
                            <GridItem xs={12} sm={8} md={8}>
                              <Button
                                justIcon
                                color="info"
                                onClick={() => this.goTo(prop.data[1])}
                                style={{ marginRight: '5px' }}
                              >
                                <i className="fa fa-search" />
                              </Button>
                              <Button
                                justIcon
                                color="info"
                                onClick={() => this.goTo(prop.data[2])}
                              >
                                <i className="fa fa-plus" />
                              </Button>
                            </GridItem>
                          </GridContainer>
                        ),
                      }))}
                      columns={[
                        {
                          Header: '',
                          accessor: 'name',
                          sortable: false,
                          filterable: false,
                        },
                        {
                          Header: '',
                          accessor: 'actions',
                          sortable: false,
                          filterable: false,
                        },
                      ]}
                      defaultPageSize={2}
                      showPagination={false}
                      showPaginationBottom={false}
                      className="tableFont info"
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </>
          ) : (
            ''
          )}
        </GridContainer>

        {this.renderTransactionsTable()}
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setUserDetails,
      addAuthtoReduxStore,
      setWalletBalance,
    },
    dispatch
  );

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
  auth: state.auth.auth,
  walletBalance: state.main.walletBalance,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle)(Dashboard));
