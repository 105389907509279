import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import FullStory from 'react-fullstory';
import ReactPixel from 'react-facebook-pixel';
// import ReactGA from 'react-ga';

import App from './app/app.jsx';
import store from './store';
import history from './history';

import 'assets/scss/material-dashboard-pro-react.css?v=1.2.0';

const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

if (process.env.REACT_APP_API_PROD === 'true') {
  Sentry.init({
    dsn: 'https://3ce83e98d4294bd2a44fe24d0741a506@sentry.io/1852398',
    release: 'V1.0.0',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
  ReactPixel.init('531652887426779', advancedMatching, options);
}

const ORG_ID = 'EDBD8';

ReactDOM.render(
  <Provider store={store}>
    <div>
      <FullStory org={ORG_ID} />
      <Router history={history}>
        <Switch>
          <App />
          <FullStory org={ORG_ID} />
        </Switch>
      </Router>
    </div>
  </Provider>,
  document.getElementById('root')
);
