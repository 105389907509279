/* eslint-disable no-prototype-builtins */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddAlert from '@material-ui/icons/AddAlert';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';

// @material-ui/icons
import Email from '@material-ui/icons/Email';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
// import Cookies from 'universal-cookie';
import Danger from 'components/Typography/Danger.jsx';
import Snackbar from 'components/Snackbar/Snackbar.jsx';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Table from 'components/Table/Table.jsx';
import ReactGA from 'react-ga';

import Select from '@material-ui/core/Select';
// import Add from '@material-ui/icons/Add';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Close from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import registerPageStyle from 'assets/jss/material-dashboard-pro-react/views/registerPageStyle';
import history from '../../history';
import { checkAuth, setAuthCookies } from '../../services';
import { addAuthtoReduxStore } from '../../reducers/auth';
import apiCaller from '../../utils/apiCaller';

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyAddress: '',
      companyAddressState: '',
      userName: '',
      userNameState: '',
      name: '',
      nameState: '',
      lastName: '',
      email: '',
      emailState: '',
      yourAddress: '',
      userLastName: '',
      userEmail: '',
      userPhoneNumber: '',
      companyName: '',
      companyNameState: '',
      handleUser: [],
      handleUserState: [],
      phoneNumber: '',
      phoneNumberState: '',
      gstNumber: '',
      gstNumberState: '',
      panNumber: '',
      panNumberState: '',
      uid: '',
      isLoading: false,
      isButtonLoading: false,
      open: false,
      addUser: false,
      userArray: [],
      userArrayTable: [],
      password: '',
      passwordState: '',
      errMessage: '',
      errSnack: false,
      infoMessage: '',
      infoSnack: false,
    };
    this.clearForm = this.clearForm.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.addUser = this.addUser.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeCompany = this.onChangeCompany.bind(this);
    this.register = this.register.bind(this);
    this.getMultiForm = this.getMultiForm.bind(this);
    this.handleUser = this.handleUser.bind(this);
  }

  componentDidMount() {
    this.checkAuth();
  }

  /**
   * Check weather the user is Authenticated
   * @function
   */
  checkAuth() {
    checkAuth()
      .then(result => {
        this.setState({
          isLoading: false,
        });
        if (result.role === 'user') {
          this.setState({
            validateOtpForm: true,
          });
        } else if (result.role === 'super_admin') {
          history.push('/super_admin/dashboard');
        } else if (result.role === 'admin') {
          history.push('/admin/dashboard');
        } else if (result.role === 'checker') {
          history.push('/checker/dashboard');
        } else if (result.role === 'maker') {
          history.push('/maker/dashboard');
        }
        this.props.addAuthtoReduxStore(result);
        setAuthCookies(result);
      })
      .catch(err => {
        const result = {
          isAuthenticated: false,
          role: '',
          activeRole: '',
          userDetails: {
            name: '',
            email: '',
            phoneNumber: '',
          },
          idToken: '',
          accessToken: '',
          refreshToken: '',
          username: '',
          userPoolId: '',
        };
        this.props.addAuthtoReduxStore(result);
        setAuthCookies(result);
      });
  }

  clearForm() {
    const self = this;
    self.setState({
      companyAddress: '',
      companyAddressState: '',
      userName: '',
      userNameState: '',
      name: '',
      nameState: '',
      lastName: '',
      email: '',
      emailState: '',
      yourAddress: '',
      userLastName: '',
      userEmail: '',
      userPhoneNumber: '',
      companyName: '',
      companyNameState: '',
      contactNumber: '',
      handleUser: [],
      handleUserState: [],
      phoneNumber: '',
      phoneNumberState: '',
      gstNumber: '',
      gstNumberState: '',
      panNumber: '',
      panNumberState: '',
      uid: '',
      isLoading: false,
      isButtonLoading: false,
      open: false,
      addUser: false,
      userArray: [],
      userArrayTable: [],
      password: '',
    });
  }

  /**
   * Shows the notification when user click submit for success message.
   */
  showNotification = place => {
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 4000);
    }
  };

  handleUser = event => {
    this.setState({ handleUser: event.target.value });
  };

  onChangeName(e) {
    this.setState({
      name: e.target.value,
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangeCompany(e) {
    this.setState({
      companyName: e.target.value,
    });
  }

  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  /**
   * closes the modal button
   * @param {*} modal
   */
  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // Will Verify cities
  verifyCities(value) {
    if (value.length < 2) {
      return false;
    }
    return true;
  }

  // function that verifies if value contains PanCard
  verifyPhone(value) {
    const regPhone = /^[6789]\d{9}$/;
    if (regPhone.test(value)) {
      return true;
    }
    return false;
  }

  verifyNumberAndGreater(value, minValue) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value) && value >= minValue) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'greater':
        if (this.verifyNumberAndGreater(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'Invalid',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'pan':
        if (this.verifyPan(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'phone':
        if (this.verifyPhone(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'gst':
        if (this.verifyGst(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  // function that returns true if value is Valid GST, false otherwise
  verifyGst(value) {
    const gstRex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (gstRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPan(value) {
    const regpan = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
    if (regpan.test(value)) {
      return true;
    }
    return false;
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    const {
      name,
      email,
      phoneNumber,
      companyName,
      userName,
      password,
    } = this.state;
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify  Name
      if (this.verifyLength(name, 1)) {
        this.setState({
          nameState: 'success',
        });
      } else {
        this.setState({ nameState: 'error' });
        error = true;
      }

      // Verify  userName
      if (this.verifyLength(userName, 2)) {
        this.setState({
          userNameState: 'success',
        });
      } else {
        this.setState({ userNameState: 'error' });
        error = true;
      }

      // Verify  Password
      if (this.verifyLength(password, 8)) {
        this.setState({
          passwordState: 'success',
        });
      } else {
        this.setState({ passwordState: 'error' });
        error = true;
      }

      // Verify  Email
      if (this.verifyEmail(email)) {
        this.setState({
          emailState: 'success',
        });
      } else {
        this.setState({ emailState: 'error' });
        error = true;
      }

      // phone number
      if (this.verifyPhone(phoneNumber)) {
        this.setState({
          phoneNumberState: 'success',
        });
      } else {
        this.setState({ phoneNumberState: 'error' });
        error = true;
      }

      // Verify Company
      if (this.verifyLength(companyName, 2)) {
        this.setState({
          companyState: 'success',
        });
      } else {
        this.setState({ companyNameState: 'error' });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('validation Failed');
      }
    });
  }

  /**
   * Register User to Db
   * @function
   */
  register() {
    const {
      companyName,
      panNumber,
      gstNumber,
      companyAddress,
      name,
      lastName,
      email,
      yourAddress,
      phoneNumber,
      userName,
      password,
    } = this.state;
    this.setState({ isLoading: true });
    this.validateAllFields()
      .then(success => {
        if (success) {
          Auth.signUp({
            username: userName,
            password,
            attributes: {
              email,
              phone_number: `+91${phoneNumber}`,
            },
          })
            .then(result => {
              const body = {
                transporter_id: userName,
                company_name: companyName,
                pan_number: panNumber,
                gst_number: gstNumber,
                company_address: companyAddress,
                transporter_first_name: name,
                transporter_last_name: lastName,
                transporter_email: email,
                transporter_address: yourAddress,
                transporter_phone_number: phoneNumber,
              };
              apiCaller(
                'premium-api/add-premium-transporter',
                'post',
                body,
                false,
                false
              )
                .then(response => {
                  this.setState({ isLoading: false });
                  ReactGA.event({
                    category: 'Sign Up',
                    action: 'Register',
                    label: 'User Registered',
                  });
                  this.setState(
                    {
                      infoMessage: 'Successfully signed up',
                    },
                    this.showNotification('infoSnack')
                  );
                  this.clearForm();
                  history.push('/pages/login-page');
                })
                .catch(err => {
                  this.setState(
                    {
                      errMessage: err.message,
                      isLoading: false,
                    },
                    this.showNotification('errSnack')
                  );
                });
            })
            .catch(err => {
              console.log('Error', err);
              if (err.hasOwnProperty('code')) {
                this.setState(
                  {
                    errMessage: err.message,
                    isLoading: false,
                  },
                  this.showNotification('errSnack')
                );
              } else {
                this.setState(
                  {
                    errMessage: err.message,
                    isLoading: false,
                  },
                  this.showNotification('errSnack')
                );
              }
            });
          // Removed from here
        }
      })
      .catch(err => {
        this.setState(
          {
            errMessage:
              err.message ||
              'Please fill all the required fields. and try again.',
            isLoading: false,
          },
          this.showNotification('errSnack')
        );
      });
  }

  getTable() {
    const { classes } = this.props;
    const { userArrayTable } = this.state;
    return (
      <>
        {userArrayTable.length > 0 ? (
          <Card>
            <CardBody className={classes.customCardContentClass}>
              <Table
                hover
                tableHead={[
                  'First Name',
                  'Last Name',
                  'Email',
                  'Phone',
                  'Status',
                ]}
                tableData={userArrayTable}
              />
            </CardBody>
          </Card>
        ) : (
          ''
        )}
      </>
    );
  }

  getMultiForm() {
    const { classes } = this.props;
    const {
      addUser,
      userName,
      userNameState,
      userLastName,
      userEmail,
      userPhoneNumber,
      handleUser,
      handleUserState,
    } = this.state;
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        classes={{ root: classes.center, paper: classes.modal }}
        style={{ maxWidth: '400px !important', textAlign: 'center' }}
        open={addUser}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('addUser')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => this.handleClose('addUser')}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>Add User</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={6} md={4}>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'userName', 'length', 2),
                  type: 'length',
                  value: userName,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    />
                  ),
                  placeholder: 'Full Name...',
                }}
                success={userNameState === 'success'}
                error={userNameState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'userLastName', 'length', 2),
                  type: 'length',
                  value: userLastName,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    />
                  ),
                  placeholder: 'last Name',
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'userEmail', 'length', 2),
                  type: 'email',
                  value: userEmail,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <Email className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  placeholder: 'Email...',
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={4}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="multiple-select"
                  className={classes.selectLabel}
                >
                  Select User type
                </InputLabel>
                <Select
                  value={handleUser}
                  onChange={this.handleUser}
                  MenuProps={{ className: classes.selectMenu }}
                  classes={{ select: classes.select }}
                  inputProps={{ name: 'multipleSelect', id: 'multiple-select' }}
                >
                  <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                    Choose User
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="checker"
                  >
                    Checker
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="maker"
                  >
                    Maker
                  </MenuItem>
                </Select>
              </FormControl>
              <Danger>{handleUserState}</Danger>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'userPhoneNumber', 'number', 2),
                  type: 'length',
                  value: userPhoneNumber,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    />
                  ),
                  placeholder: 'Phone Number',
                }}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button color="primary" onClick={() => this.addUser()}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  addUser() {
    const {
      userName,
      userLastName,
      userEmail,
      userPhoneNumber,
      handleUser,
      userArray,
      userArrayTable,
    } = this.state;
    userArray.push({
      name: userName,
      lastName: userLastName,
      email: userEmail,
      phone: userPhoneNumber,
      role: handleUser,
    });
    userArrayTable.push({
      color: 'success',
      data: [userName, userLastName, userEmail, userPhoneNumber, handleUser],
    });
    this.setState({
      userArrayTable,
      userArray,
      userName: '',
      userLastName: '',
      userEmail: '',
      userPhoneNumber: '',
      handleUser: [],
    });
    this.handleClose('addUser');
  }

  render() {
    const { classes } = this.props;
    const {
      isLoading,
      companyName,
      companyNameState,
      panNumber,
      panNumberState,
      gstNumber,
      gstNumberState,
      name,
      nameState,
      lastName,
      email,
      emailState,
      phoneNumber,
      phoneNumberState,
      isButtonLoading,
      userName,
      userNameState,
      password,
      passwordState,
    } = this.state;
    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            {isLoading ? (
              <GridItem style={{ marginTop: '50px' }}>
                <CircularProgress
                  className={classes.progress}
                  style={{ color: purple[500] }}
                  thickness={7}
                />
              </GridItem>
            ) : (
              <GridItem xs={12} sm={12} md={12}>
                <Card className={classes.cardSignup}>
                  <h2 className={classes.cardTitle}>SignUp</h2>
                  <CardBody>
                    <GridContainer justify="center">
                      <Snackbar
                        place="tc"
                        color="danger"
                        icon={AddAlert}
                        message={this.state.errMessage || ''}
                        open={this.state.errSnack}
                        closeNotification={() =>
                          this.setState({ errSnack: false })
                        }
                        close
                      />
                      <Snackbar
                        place="tc"
                        color="info"
                        icon={AddAlert}
                        message={this.state.infoMessage || ''}
                        open={this.state.infoSnack}
                        closeNotification={() =>
                          this.setState({ infoSnack: false })
                        }
                        close
                      />
                      <GridItem xs={12} sm={12} md={5}>
                        <GridContainer
                          className={(classes.center, classes.cardTitle)}
                        >
                          <GridItem xs={12} sm={12} md={12}>
                            <h2>3 Simple Steps</h2>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={8}>
                            <h3 style={{ marginLeft: '32px' }}>1. Sign up</h3>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={8}>
                            <h3 style={{ marginLeft: '67px' }}>
                              2. Add vendor
                            </h3>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={8}>
                            <h3 style={{ marginLeft: '65px' }}>
                              3. Pay Vendor
                            </h3>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={12} sm={8} md={7}>
                        <form className={classes.form}>
                          <h4>
                            <b>Company Details</b>
                          </h4>
                          <GridContainer>
                            <GridItem xs={12} sm={6} md={7}>
                              <CustomInput
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses,
                                }}
                                inputProps={{
                                  onChange: event =>
                                    this.change(
                                      event,
                                      'companyName',
                                      'length',
                                      2
                                    ),
                                  type: 'length',
                                  value: companyName,
                                  placeholder: 'Company Name',
                                }}
                                success={companyNameState === 'success'}
                                error={companyNameState === 'error'}
                              />
                            </GridItem>
                            <br />
                            <GridItem xs={12} sm={6} md={7}>
                              <CustomInput
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses,
                                }}
                                inputProps={{
                                  onChange: event =>
                                    this.change(event, 'panNumber', 'pan'),
                                  type: 'length',
                                  value: panNumber,
                                  placeholder: 'PAN',
                                }}
                                success={panNumberState === 'success'}
                                error={panNumberState === 'error'}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={7}>
                              <CustomInput
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses,
                                }}
                                inputProps={{
                                  onChange: event =>
                                    this.change(event, 'gstNumber', 'gst'),
                                  type: 'length',
                                  value: gstNumber,
                                  placeholder: 'GST',
                                }}
                                success={gstNumberState === 'success'}
                                error={gstNumberState === 'error'}
                              />
                            </GridItem>
                          </GridContainer>
                          <h4 style={{ marginTop: '35px' }}>
                            <b>Owner Details</b>
                          </h4>
                          <GridContainer>
                            <GridItem xs={12} sm={6} md={7}>
                              <CustomInput
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses,
                                }}
                                inputProps={{
                                  onChange: event =>
                                    this.change(event, 'name', 'length', 2),
                                  type: 'length',
                                  value: name,
                                  placeholder: 'First Name',
                                }}
                                success={nameState === 'success'}
                                error={nameState === 'error'}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={7}>
                              <CustomInput
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses,
                                }}
                                inputProps={{
                                  onChange: event =>
                                    this.change(event, 'lastName', 'length', 2),
                                  type: 'length',
                                  value: lastName,
                                  placeholder: 'Last Name',
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={7}>
                              <CustomInput
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses,
                                }}
                                inputProps={{
                                  onChange: event =>
                                    this.change(event, 'email', 'email'),
                                  type: 'email',
                                  value: email,
                                  placeholder: 'Email',
                                }}
                                success={emailState === 'success'}
                                error={emailState === 'error'}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={7}>
                              <CustomInput
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses,
                                }}
                                inputProps={{
                                  onChange: event =>
                                    this.change(event, 'phoneNumber', 'phone'),
                                  type: 'length',
                                  value: phoneNumber,
                                  placeholder: 'Phone Number',
                                }}
                                success={phoneNumberState === 'success'}
                                error={phoneNumberState === 'error'}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={7}>
                              <CustomInput
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses,
                                }}
                                inputProps={{
                                  onChange: event =>
                                    this.change(event, 'userName', 'length', 2),
                                  type: 'length',
                                  value: userName,
                                  placeholder: 'Username',
                                }}
                                success={userNameState === 'success'}
                                error={userNameState === 'error'}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={7}>
                              <CustomInput
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses,
                                }}
                                inputProps={{
                                  onChange: event =>
                                    this.change(event, 'password', 'length', 8),
                                  type: 'password',
                                  value: password,
                                  placeholder: 'Password',
                                }}
                                success={passwordState === 'success'}
                                error={passwordState === 'error'}
                              />
                            </GridItem>
                          </GridContainer>
                          <div
                            className={classes.center}
                            style={{ marginTop: '10px' }}
                          >
                            {isButtonLoading ? (
                              <CircularProgress
                                className={classes.progress}
                                style={{ color: purple[500] }}
                                thickness={7}
                              />
                            ) : (
                              <Button
                                round
                                color="primary"
                                onClick={this.register}
                              >
                                Sign Up
                              </Button>
                            )}
                          </div>
                        </form>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            )}
          </GridContainer>
        </div>
      </div>
    );
  }
}
RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addAuthtoReduxStore,
    },
    dispatch
  );
const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
  auth: state.auth.auth,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(registerPageStyle)(RegisterPage));
