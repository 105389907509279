// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'ap-south-1',
  aws_cognito_identity_pool_id:
    'ap-south-1:2b61e92f-0fab-448a-9b66-a9b3c23f00cf',
  aws_cognito_region: 'ap-south-1',
  aws_user_pools_id: 'ap-south-1_BrUVuYhV3',
  aws_user_pools_web_client_id: '37r45oocshq2l0rblbqg10nd0i',
  oauth: {},
};

export default awsmobile;
