// @material-ui/icons

const ledgerRoutes = [
  {
    redirect: true,
    path: '/ledger',
    pathTo: '/ledger/ledgerId',
    name: 'Ledger Page',
  },
];

export default ledgerRoutes;
