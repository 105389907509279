import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  MenuItem,
  Select,
  CircularProgress,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import apiCaller from '../../utils/apiCallerESP';
import TransporterListView from './TransporterManagement/TransporterListView';

const useStyles = makeStyles(theme => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filter: { minWidth: '250px' },
}));
const TransporterManagement = () => {
  const [transporterData, setTransporterData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const classes = useStyles();
  const [age, setAge] = React.useState('');

  const handleChange = event => {
    setAge(event.target.value);
  };
  // let transporterData = [];
  const getTranporterList = async () => {
    const endPoint = 'esp-premium-transporter-list';
    const body = {
      total_items: 10,
      last_evaluated_key: null,
    };
    const response = await apiCaller(endPoint, body);

    const data = response.data.items;
    setTransporterData(data);
    setFetching(false);
  };
  useEffect(() => {
    setFetching(true);
    getTranporterList();
  }, []);
  return (
    <div>
      {fetching ? (
        <CircularProgress />
      ) : (
        <div>
          <div style={{ margin: '20px 0px' }}>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Grid item>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={age}
                  onChange={handleChange}
                  className={classes.filter}
                  variant="outlined"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </Grid>
              <Grid item>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Broker"
                />
              </Grid>
            </Grid>
          </div>
          <TransporterListView
            transporterData={transporterData}
            fetching={fetching}
          />
        </div>
      )}
    </div>
  );
};

export default TransporterManagement;
