/* eslint-disable no-self-assign */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import UpdateTransactionModal from './UpdateTransactionModal';
import CustomSelect from './CustomSelect';
import UTRModal from './UTRModal';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: { backgroundColor: 'black' },
  tableHeadCell: { color: 'white' },
  tableBodyRow: { height: '50px' },
  // buttonFIlter: { borderColor: 'lightgray' },
});

function createData(
  transporterName,
  agentName,
  data,
  amount,
  utr,
  timestamp,
  status
) {
  return {
    transporterName,
    agentName,
    data,
    amount,
    utr,
    timestamp,
    status,
  };
}
var offset = 0;

export default function DenseTable({ balanceRequest, triggerRefresh }) {
  const classes = useStyles();

  const toIndianCurrency = num => {
    if (num) {
      const curr = num.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
      });
      return curr;
    }
    return 0;
  };
  const convertTimestamp = value => {
    const dt = new Date(value);
    const date = dt.toISOString();
    return date;
  };
  const rows = balanceRequest?.map(data =>
    createData(
      data?.transporter_name,
      data?.agent_name,
      data,
      toIndianCurrency(data?.balance),
      data.utr,
      convertTimestamp(data.created_at),
      data.transaction_status
      // data?.data[2]
    )
  );
  const handlePrevious = () => {
    if (offset !== 0) {
      offset = offset;
    } else offset -= 10;
    triggerRefresh('all', offset);
  };
  const handleNext = () => {
    offset += 10;
    triggerRefresh('all', offset);
  };
  return (
    <div style={{ marginTop: '10px' }}>
      <CustomSelect triggerRefresh={value => triggerRefresh(value)} />
      <TableContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '10px',
          }}
        >
          <Button onClick={handlePrevious}>Previous</Button>
          <Button onClick={handleNext}>Next</Button>
        </div>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>
                Transporter Name
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="left">
                Agent
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="center">
                Trip Details
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="right">
                Balance Amount
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="center">
                UTR
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="center">
                Date Time Stamp
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="center">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map(row => (
              <TableRow className={classes.tableBodyRow} key={row.name}>
                <TableCell component="th" scope="row">
                  {row.transporterName}
                </TableCell>
                <TableCell align="left">{row.agentName}</TableCell>
                <TableCell align="center">
                  <UpdateTransactionModal data={row.data} />
                </TableCell>
                <TableCell align="right">{row.amount}</TableCell>
                <TableCell align="center">{row.utr}</TableCell>
                <TableCell align="center">{row.timestamp}</TableCell>
                <TableCell align="center">
                  <UTRModal
                    status={row.status}
                    data={row.data}
                    // updateStatus={value => updateStatus(value)}
                    triggerRefresh={value => triggerRefresh(value)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
