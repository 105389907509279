export const SET_WALLET_LIST = 'walletList/SET_WALLET_LIST';

const initialState = {
  listWallet: [],
  lastEvaluatedKey: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_WALLET_LIST:
      return {
        ...state,
        listWallet: action.listWallet,
        lastEvaluatedKey: action.lastEvaluatedKey,
      };
    default:
      return state;
  }
};

export const setWalletList = (listWallet, lastEvaluatedKey) => dispatch => {
  dispatch({
    type: SET_WALLET_LIST,
    listWallet,
    lastEvaluatedKey,
  });
};
