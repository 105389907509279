/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-var */
/* eslint-disable no-unused-vars */
/* eslint-disable guard-for-in */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import CallMadeSharpIcon from '@material-ui/icons/CallMadeSharp';
import CallReceivedSharpIcon from '@material-ui/icons/CallReceivedSharp';
import TableRow from '@material-ui/core/TableRow';
import CachedIcon from '@material-ui/icons/Cached';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {
  Button,
  CircularProgress,
  Select,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import apiCaller from '../../../utils/apiCallerESP';

var statementDetails = [];
const filterContainer = {
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-evenly',
};
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: { backgroundColor: 'black' },
  tableHeadCell: { color: 'white' },
  tableBodyRow: { height: '50px' },
  downloadButton: {
    backgroundColor: '#163BAC',
    color: 'white',
    marginLeft: '10px',
  },
});

function createData(
  transactionNumber,
  amount,
  accountId,
  agent,
  truckNumber,
  timeStamp,
  utr,
  status,
  nature
) {
  return {
    transactionNumber,
    amount,
    accountId,
    agent,
    truckNumber,
    timeStamp,
    utr,
    status,
    nature,
  };
}

export default function DenseTable({
  handleToggle,
  selectedAccountId,
  transactionData,
}) {
  const [balance, setBalance] = useState();
  const [fetching, setFetching] = useState(false);
  const [broker_name, setBrokerName] = useState('');
  const classes = useStyles();

  const toIndianCurrency = num => {
    if (num) {
      const curr = num.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
      });
      return curr;
    }
    return 0;
  };
  const convertTimestamp = value => {
    const dt = new Date(value);
    const date = dt.toISOString();
    return date;
  };
  const rows = statementDetails?.map(data =>
    createData(
      data?.transaction_id,
      toIndianCurrency(data?.amount),
      data?.account,
      data?.agent,
      data?.truck,
      convertTimestamp(data?.date),
      data?.utr,
      data?.status,
      data?.nature
      // data?.data[2]
    )
  );
  const handleExcelDownload = async () => {
    const body = {
      account_id: selectedAccountId.toString(),
      statement: true,
      type: 'accounts',
    };
    const endPoint = 'view-transporters-statement';
    try {
      const response = await apiCaller(endPoint, body);
      const { url } = response.data.body;

      window.open(url, '_blank');

      // setFetching(false);
    } catch (e) {
      // setFetching(false);
    }
  };
  const getAccountStatement = async id => {
    setFetching(true);
    const body = {
      account_id: id.toString(),
      limit: 10,
      offset: 0,
      broker_name,
      type: 'accounts',
    };
    const endPoint = 'view-transporters-statement';
    try {
      const response = await apiCaller(endPoint, body);
      const statement = response.data.body.transactions;
      const accountbalance = toIndianCurrency(
        response.data.body.account_balance
      );
      setBalance(accountbalance);
      for (const key in statement) {
        const duplicate = statementDetails.some(
          item => item.utr === statement[key].utr
        );
        if (!duplicate) {
          statementDetails.push(statement[key]);
        }
      }
      setFetching(false);
    } catch (e) {
      setFetching(false);
    }
  };

  useEffect(() => {
    getAccountStatement(selectedAccountId);
  }, []);
  return (
    <div style={{ marginTop: '40px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '10px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            startIcon={<CachedIcon />}
            onClick={() => getAccountStatement(selectedAccountId)}
          >
            Refresh
          </Button>
          <Typography variant="body1" style={{ color: 'lightgray' }}>
            Last refresh at {moment().format('MMMM Do YYYY, h:mm:ss a')}
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle2">Account Balance : </Typography>
          <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
            {balance}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button
            className={classes.downloadButton}
            startIcon={<ArrowDownwardIcon />}
            size="small"
            onClick={handleExcelDownload}
          >
            Download
          </Button>
        </div>
      </div>
      {fetching ? (
        <CircularProgress />
      ) : (
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableHeadCell}>Txn #</TableCell>
                <TableCell
                  align="left"
                  className={classes.tableHeadCell}
                  // align="left"
                >
                  Amount
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="center">
                  Account #
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="center">
                  Agent
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="center">
                  Truck #
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="center">
                  Date
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="center">
                  UTR
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="center">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map(row => (
                <TableRow className={classes.tableBodyRow} key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.transactionNumber}
                  </TableCell>
                  <TableCell align="left">
                    {row.amount}
                    {row.nature == 'debit' ? (
                      <CallMadeSharpIcon />
                    ) : (
                      <CallReceivedSharpIcon />
                    )}
                  </TableCell>
                  <TableCell align="center">{row.accountId}</TableCell>
                  <TableCell align="center">{row.agent}</TableCell>
                  <TableCell align="center">{row.truckNumber}</TableCell>
                  <TableCell align="center">{row.timeStamp}</TableCell>
                  <TableCell align="center">{row.utr}</TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color:
                        row.status == 'processing'
                          ? 'orange'
                          : row.status == 'processed'
                          ? 'green'
                          : row.status == 'reversed'
                          ? 'red'
                          : row.status == 'payment approved'
                          ? 'blue'
                          : 'black',
                    }}
                  >
                    {row.status}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
