/* eslint-disable react/jsx-no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-prototype-builtins */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactGA from 'react-ga';
import moment from 'moment';

// @material-ui/Icons
// import NotificationImportant from '@material-ui/icons/NotificationImportant';
import CardHeader from 'components/Card/CardHeader.jsx';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import logo from 'assets/img/lobb_404.jpg';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import Responsive from 'react-responsive';
import Button from 'components/CustomButtons/Button.jsx';

import ledgerPageStyle from 'assets/jss/material-dashboard-pro-react/views/ledgerPageStyle.jsx';
import Pagination from './Pagination.jsx';
import { setLedgers, setLedgerId } from '../../reducers/transactions';
import apiCaller from '../../utils/apiCaller';
import './react-table.css';

const isMobile = window.innerWidth <= 700;

const Desktop = props => <Responsive {...props} minWidth={701} />;
const Mobile = props => <Responsive {...props} maxWidth={700} />;

class LedgerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFreePlan: false,
      isMonthlyPlan: true,
      isYearlyPlan: false,
      isOrgPlan: false,
      ledgers: [],
      beneficiary_name: '',
      beneficiary_phone: '',
      total_amount_transferred: '',
      transporter_name: '',
      transporter_phone: '',
      ledgerId: '',
      isLoading: false,
      showImage: false,
      next_cursor: null,
      current_cursor: null,
      count: 0,
      isOffice: false,
    };
    this.changeFont = this.changeFont.bind(this);
  }

  componentDidMount() {
    const { ledgerId } = this.props.match.params;
    if (ledgerId.includes('XXX')) {
      this.setState(
        {
          ledgerId,
          isOffice: false,
        },
        () => this.getPayouts()
      );
    } else {
      this.getVendorList(ledgerId);
      this.setState({
        ledgerId,
      });
    }
    this.props.setLedgerId(ledgerId);
  }

  getPayouts() {
    const { ledgerId } = this.props.match.params;
    const body = {
      ledgerId,
      limit: 10,
    };
    this.setState({
      isLoading: true,
    });
    const vendorData = [];
    apiCaller('utilities-getLedgerLink', 'post', body, false, false, true)
      .then(response => {
        if (response.hasOwnProperty('total')) {
          this.setState({
            total_amount_transferred: response.total,
          });
        }
        const { ledgerList } = response;
        let beneficiary_name = '';
        let beneficiary_phone = '';
        ledgerList.forEach(dataList => {
          const { truckerPhoneNumber, truckerName, total_fee } = dataList;
          dataList.fee = total_fee;
          dataList.service_provider_utr = dataList.utr;
          dataList.notes = dataList.remarks;
          dataList.truck_number = dataList.vehicleNumber;
          if (truckerPhoneNumber !== undefined) {
            beneficiary_phone = truckerPhoneNumber;
          }
          if (truckerName !== undefined) {
            beneficiary_name = truckerName;
          }
          const amount_received = Number(dataList.amount) - Number(total_fee);
          dataList.amount_recieved = amount_received;

          vendorData.push({
            color: 'success',
            data: [
              dataList.createdAt || '___',
              Intl.NumberFormat('en-IN').format(dataList.amount) || '___',
              Intl.NumberFormat('en-IN').format(total_fee) || '___',
              Intl.NumberFormat('en-IN').format(amount_received) || '___',
              dataList.utr || '___',
              dataList.vehicleNumber || '___',
              dataList.remarks || '___',
            ],
            dataList,
          });
        });
        if (vendorData.length <= 0) {
          this.setState({
            showImage: true,
            isLoading: false,
          });
        }
        this.props.setLedgers(vendorData, 10);
        this.getNextVendorsOffice();
        this.setState({
          isLoading: false,
          beneficiary_name,
          beneficiary_phone,
          transporter_name: 'LOBB - TruckCentral Solutions',
          transporter_phone: '9025109730',
        });
      })
      .catch(err => {
        this.setState({
          showImage: false,
          isLoading: false,
        });
        console.log('Err', err);
      });
  }

  // eslint-disable-next-line lines-between-class-members
  changeFont(value) {
    return <span style={{ fontSize: '16px' }}>{value}</span>;
  }

  getVendorList(ledgerId) {
    const vendorData = [];
    const body = {
      ledger_id: ledgerId,
      total_items: 10,
    };
    let nextCursorId = null;
    this.setState({
      isLoading: true,
    });
    apiCaller('premium-api/get-ledger', 'post', body, true, false)
      .then(response => {
        const {
          beneficiary_name,
          beneficiary_phone,
          total_amount_transferred,
          transporter_name,
          transporter_phone,
        } = response;
        this.setState({
          beneficiary_name,
          beneficiary_phone,
          total_amount_transferred,
          transporter_name,
          transporter_phone,
        });
        if (response === 'ledger id not found') {
          this.setState({
            showImage: true,
          });
        }
        if (response.hasOwnProperty('payouts')) {
          if (response.payouts.length !== 0) {
            ReactGA.event({
              category: 'promotion',
              action: 'view_ledger',
              label: 'Ledger view',
            });
            this.setState({
              showImage: false,
            });
            response.payouts.forEach(dataList => {
              if (
                response.hasOwnProperty('last_key') &&
                response.last_key !== null
              ) {
                nextCursorId = response.last_key;
              } else {
                nextCursorId = null;
              }
              vendorData.push({
                color: 'success',
                data: [
                  moment.unix(dataList.modified_at).format('DD-MM-YYYY') ||
                    '___',
                  Intl.NumberFormat('en-IN').format(dataList.amount) || '___',
                  Intl.NumberFormat('en-IN').format(dataList.fee),
                  Intl.NumberFormat('en-IN').format(dataList.amount_recieved) ||
                    '___',
                  dataList.service_provider_utr || '___',
                  dataList.truck_number || '___',
                  dataList.notes || '___',
                ],
                dataList,
              });
            });
            this.props.setLedgers(vendorData, nextCursorId);
            this.setState({
              isLoading: false,
            });
            if (
              response.hasOwnProperty('last_key') &&
              response.last_key !== null
            ) {
              if (!isMobile) {
                this.getNextVendors();
              }
            }
          } else {
            this.setState({
              isLoading: false,
            });
            this.props.setLedgers([], null);
          }
        }
      })
      .catch(err => {
        this.setState({
          showImage: false,
          isLoading: false,
        });
        console.log('Err ', err);
      });
  }

  nextVendors() {
    const { ledgerId } = this.state;
    if (ledgerId.includes('XXX')) {
      this.getNextVendorsOffice();
    } else {
      this.getNextVendors();
    }
  }

  getNextVendors() {
    const { ledgerLastEvaluatedKey, ledgers, ledgerId } = this.props;
    const vendorData = JSON.parse(JSON.stringify(ledgers));
    const body = {
      ledger_id: ledgerId,
      total_items: 10,
    };
    let nextCursorId = null;

    if (ledgerLastEvaluatedKey !== '' || ledgerLastEvaluatedKey !== null) {
      body.last_key = ledgerLastEvaluatedKey;
    }
    apiCaller('premium-api/get-ledger', 'post', body, true, false)
      .then(response => {
        if (response.hasOwnProperty('payouts')) {
          if (response.payouts.length !== 0) {
            response.payouts.forEach(dataList => {
              if (
                response.hasOwnProperty('last_key') &&
                response.last_key !== null
              ) {
                nextCursorId = response.last_key;
              } else {
                nextCursorId = null;
              }
              vendorData.push({
                color: 'success',
                data: [
                  moment.unix(dataList.modified_at).format('DD-MM-YYYY') ||
                    '___',
                  Intl.NumberFormat('en-IN').format(dataList.amount) || '___',
                  Intl.NumberFormat('en-IN').format(dataList.fee),
                  Intl.NumberFormat('en-IN').format(dataList.amount_recieved) ||
                    '___',
                  dataList.service_provider_utr || '___',
                  dataList.truck_number || '___',
                  dataList.notes || '___',
                ],
                dataList,
              });
            });
            this.props.setLedgers(vendorData, nextCursorId);
          } else {
            this.props.setLedgers(vendorData, null);
          }
        }
      })
      .catch(err => {});
  }

  getNextVendorsOffice() {
    const { ledgers, ledgerId, ledgerLastEvaluatedKey } = this.props;
    const vendorData = JSON.parse(JSON.stringify(ledgers));
    const body = {
      ledgerId: ledgerId,
      limit: 10,
      last_key: ledgerLastEvaluatedKey,
    };
    apiCaller('utilities-getLedgerLink', 'post', body, false, false, true)
      .then(response => {
        const { ledgerList } = response;
        ledgerList.forEach(dataList => {
          const { total_fee } = dataList;
          const amount_received = Number(dataList.amount) - Number(total_fee);
          dataList.amount_recieved = amount_received;
          dataList.fee = total_fee;
          dataList.service_provider_utr = dataList.utr;
          dataList.notes = dataList.remarks;
          dataList.truck_number = dataList.vehicleNumber;
          vendorData.push({
            color: 'success',
            data: [
              dataList.createdAt || '___',
              Intl.NumberFormat('en-IN').format(dataList.amount) || '___',
              Intl.NumberFormat('en-IN').format(dataList.fee) || '___',
              Intl.NumberFormat('en-IN').format(amount_received) || '___',
              dataList.utr || '___',
              dataList.vehicleNumber || '___',
              dataList.remarks || '___',
            ],
            dataList,
          });
        });
        let next_key = Number(ledgerLastEvaluatedKey);
        next_key += 10;
        if (ledgerList.length === 0) {
          next_key = null;
        }
        this.props.setLedgers(vendorData, next_key);
      })
      .catch(err => {
        this.setState({
          showImage: false,
          isLoading: false,
        });
        console.log('Err', err);
      });
  }

  compare(a, b) {
    if (typeof a.data[0] !== 'number' && typeof b.data[0] !== 'number') {
      const bandA = a.data[0].toUpperCase();
      const bandB = b.data[0].toUpperCase();
      let comparison = 0;
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
      return comparison;
    }
    return 1;
  }

  getMobileCard() {
    const { classes } = this.props;
    const {
      beneficiary_name,
      beneficiary_phone,
      total_amount_transferred,
      transporter_name,
      transporter_phone,
    } = this.state;
    return (
      <Card>
        <CardHeader className={`${classes.cardHeader}`} color="primary">
          <GridContainer>
            <GridItem xs={12} md={3} lg={3}>
              <p
                className={`${classes.cardHeader} ${classes.rajdhaniFont}`}
                style={{
                  fontSize: '16px',
                  marginBottom: '10px',
                }}
              >
                Transporter Name : {transporter_name}
              </p>
              <p
                className={`${classes.cardHeader} ${classes.rajdhaniFont}`}
                style={{
                  fontSize: '16px',
                  marginBottom: '10px',
                }}
              >
                Transporter Phone Number : {transporter_phone}
              </p>
              <p
                className={`${classes.cardHeader} ${classes.rajdhaniFont}`}
                style={{
                  fontSize: '16px',
                  marginBottom: '10px',
                }}
              >
                Beneficiary Name :{' '}
                <span style={{ fontWeight: 'bold' }}> {beneficiary_name} </span>
              </p>
              <p
                className={`${classes.cardHeader} ${classes.rajdhaniFont}`}
                style={{
                  fontSize: '16px',
                  marginBottom: '10px',
                }}
              >
                Beneficiary Phone Number :{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {' '}
                  {beneficiary_phone}{' '}
                </span>
              </p>
              <p
                className={`${classes.cardHeader} ${classes.rajdhaniFont}`}
                style={{
                  fontSize: '16px',
                  marginBottom: '10px',
                }}
              >
                Total Amount Transferred :{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {' '}
                  <i
                    className="fa fa-inr"
                    style={{ fontSize: '12px' }}
                    aria-hidden="true"
                  />{' '}
                  {Intl.NumberFormat('en-IN').format(total_amount_transferred)}
                </span>
              </p>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody className={`${classes.cardHeader} ${classes.textCenter}`}>
          <GridContainer>
            {this.props.ledgers.map(data => (
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card
                  chart
                  className={classes.card}
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                >
                  <CardBody
                    style={{
                      paddingLeft: '2px',
                      paddingRight: '2px',
                      paddingTop: '2px',
                      paddingBottom: '2px',
                    }}
                  >
                    <GridContainer>
                      <GridItem
                        xs={4}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <p className="para-text-header">Amount Transferred</p>
                      </GridItem>
                      <GridItem
                        xs={4}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <p className="para-text-bold">
                          <i
                            className="fa fa-inr"
                            style={{ fontSize: '12px' }}
                            aria-hidden="true"
                          />{' '}
                          {Intl.NumberFormat('en-IN').format(
                            data.dataList.amount
                          ) || '___'}
                        </p>
                      </GridItem>
                      <GridItem
                        xs={4}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {this.state.isOffice ? (
                          <p className="para-text-bold">
                            {data.dataList.createdAt || '___'}
                          </p>
                        ) : (
                          <p className="para-text-bold">
                            {moment
                              .unix(data.dataList.modified_at)
                              .format('DD-MM-YYYY') || '___'}
                          </p>
                        )}
                      </GridItem>
                    </GridContainer>
                    <hr
                      style={{
                        display: 'block',
                        height: '1px',
                        border: 0,
                        borderTop: '1px solid #ccc',
                        margin: '2px 0',
                        padding: 0,
                      }}
                    />
                    <GridContainer>
                      <GridItem
                        xs={4}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p className="para-text-header">Platform Charges</p>
                      </GridItem>
                      <GridItem
                        xs={4}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <p className="para-text-bold">
                          <i
                            className="fa fa-inr"
                            style={{ fontSize: '12px' }}
                            aria-hidden="true"
                          />{' '}
                          {Intl.NumberFormat('en-IN').format(
                            data.dataList.fee
                          ) || '___'}
                        </p>
                      </GridItem>
                      <GridItem
                        xs={4}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    </GridContainer>
                    <hr
                      style={{
                        display: 'block',
                        height: '1px',
                        border: 0,
                        borderTop: '1px solid #ccc',
                        margin: '2px 0',
                        padding: 0,
                      }}
                    />
                    <GridContainer>
                      <GridItem
                        xs={4}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <p className="para-text-header">Received Amount</p>
                      </GridItem>
                      <GridItem
                        xs={4}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <p className="para-text-bold">
                          <i
                            className="fa fa-inr"
                            style={{ fontSize: '12px' }}
                            aria-hidden="true"
                          />{' '}
                          {Intl.NumberFormat('en-IN').format(
                            data.dataList.amount_recieved
                          ) || '___'}
                        </p>
                      </GridItem>
                      <GridItem
                        xs={4}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    </GridContainer>
                    <hr
                      style={{
                        display: 'block',
                        height: '1px',
                        border: 0,
                        borderTop: '1px solid #ccc',
                        margin: '2px 0',
                        padding: 0,
                      }}
                    />
                    <GridContainer>
                      <GridItem
                        xs={4}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p className="para-text-header">Truck Number</p>
                      </GridItem>
                      <GridItem
                        xs={4}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p className="para-text-bold">
                          {data.dataList.truck_number || '___'}
                        </p>
                      </GridItem>
                      <GridItem
                        xs={4}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    </GridContainer>
                    <hr
                      style={{
                        display: 'block',
                        height: '1px',
                        border: 0,
                        borderTop: '1px solid #ccc',
                        margin: '2px 0',
                        padding: 0,
                      }}
                    />
                    <GridContainer>
                      <GridItem
                        xs={4}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p
                          className="para-text-header"
                          style={{ marginBottom: '0px' }}
                        >
                          Remarks
                        </p>
                      </GridItem>
                      <GridItem
                        xs={8}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <p className="para-text-bold">
                          {data.dataList.notes || '___'}
                        </p>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter chart style={{ marginBottom: '2px' }}>
                    <div className={classes.stats}>UTR</div>
                    <div
                      className={classes.stats}
                      style={{ fontWeight: 'bold' }}
                    >
                      {data.dataList.service_provider_utr || '___'}
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            ))}
          </GridContainer>
          <GridContainer justify="center">
            <GridItem>
              {this.props.ledgerLastEvaluatedKey !== null && (
                <Button color="rose" onClick={() => this.nextVendors()}>
                  Load More
                </Button>
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }

  getDesktopTable() {
    return (
      <GridItem xs={12} sm={12} md={11}>
        <div style={{ marginLeft: '-15px', marginRight: '-15px' }}>
          <ReactTable
            getTheadThProps={() => ({
              style: {
                outline: 0,
                color: 'black',
                fontSize: '18px',
                fontWeight: '400',
                backgroundColor: '#FCD21F',
              },
            })}
            PaginationComponent={Pagination}
            // style={{
            //   height: '00px',
            // }}
            data={this.props.ledgers.map((prop, key) => ({
              id: key,
              date: prop.data[0],
              amount: prop.data[1],
              fee: prop.data[2],
              amount_recieved: prop.data[3],
              service_provider_utr: prop.data[4],
              truck_no: prop.data[5],
              remarks: prop.data[6],
            }))}
            columns={[
              {
                filterable: false,
                sortable: false,
                Header: 'Date',
                accessor: 'date',
                Cell: row => this.changeFont(row.value),
              },
              {
                filterable: false,
                Header: () => (
                  <div
                    style={{
                      textAlign: 'right',
                    }}
                  >
                    Amount Transferred
                  </div>
                ),
                sortable: false,
                accessor: 'amount',
                Cell: row => (
                  <div
                    style={{
                      textAlign: 'right',
                      fontWeight: 500,
                    }}
                  >
                    <i
                      className="fa fa-inr"
                      style={{ fontSize: '15px' }}
                      aria-hidden="true"
                    />{' '}
                    {row.value}
                  </div>
                ),
              },
              {
                filterable: false,
                sortable: false,
                Header: () => (
                  <div
                    style={{
                      textAlign: 'right',
                    }}
                  >
                    Platform Charges
                  </div>
                ),
                accessor: 'fee',
                Cell: row => (
                  <div
                    style={{
                      textAlign: 'right',
                      fontWeight: 500,
                    }}
                  >
                    <i
                      className="fa fa-inr"
                      style={{ fontSize: '15px' }}
                      aria-hidden="true"
                    />{' '}
                    {row.value}
                  </div>
                ),
              },
              {
                filterable: false,
                sortable: false,
                Header: () => (
                  <div
                    style={{
                      textAlign: 'right',
                    }}
                  >
                    Received Amount
                  </div>
                ),
                accessor: 'amount_recieved',
                Cell: row => (
                  <div
                    style={{
                      textAlign: 'right',
                      paddingRight: '10px',
                      fontWeight: 500,
                    }}
                  >
                    <i
                      className="fa fa-inr"
                      style={{ fontSize: '15px' }}
                      aria-hidden="true"
                    />{' '}
                    {row.value}
                  </div>
                ),
              },
              {
                filterable: false,
                sortable: false,
                Header: 'UTR',
                accessor: 'service_provider_utr',
                Cell: row => this.changeFont(row.value),
              },
              {
                filterable: false,
                sortable: false,
                Header: 'Truck Number',
                accessor: 'truck_no',
                Cell: row => this.changeFont(row.value),
              },
              {
                filterable: false,
                sortable: false,
                Header: 'Remarks',
                accessor: 'remarks',
                Cell: row => this.changeFont(row.value),
              },
            ]}
            minRows={0}
            defaultPageSize={10}
            showPaginationBottom
            className="-striped -highlight"
          />
        </div>
      </GridItem>
    );
  }

  render() {
    const { classes } = this.props;
    const {
      beneficiary_name,
      beneficiary_phone,
      total_amount_transferred,
      transporter_name,
      transporter_phone,
      showImage,
      isLoading,
    } = this.state;
    return (
      <div className={classes.content} style={{ backgroundColor: '#FFF' }}>
        <div className={classes.container}>
          {showImage ? (
            <img
              src={logo}
              alt="logo"
              style={{ maxWidth: '100%', height: 'auto', marginTop: '90px' }}
            />
          ) : (
            <div>
              {isLoading ? (
                <CircularProgress
                  className={classes.progress}
                  style={{ color: purple[500], textAlign: 'center' }}
                  thickness={7}
                />
              ) : (
                <div>
                  <Desktop>
                    <GridContainer justify="start">
                      <GridItem xs={12} sm={12} md={6}>
                        <p className="page-header-title">Lobb Pay Ledger</p>
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      justify="flex-end"
                      style={{ marginBottom: '10px' }}
                    >
                      <GridItem xs={12} sm={6} md={6}>
                        <div className="marginBottom">
                          <p className="tr-title">
                            Transporter Name:{' '}
                            <span style={{ fontWeight: 'normal' }}>
                              {transporter_name}
                            </span>
                          </p>
                        </div>
                        <div className="marginBottom">
                          <h3 className="tr-title">
                            Transporter Phone Number:{' '}
                            <span style={{ fontWeight: 'normal' }}>
                              {transporter_phone}
                            </span>
                          </h3>
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="flex-start">
                      <GridItem xs={12} sm={6} md={6}>
                        <div className="marginBottom">
                          <p className="trucker-title">
                            Beneficiary Name:{' '}
                            <span style={{ fontWeight: 'normal' }}>
                              {beneficiary_name}
                            </span>
                          </p>
                        </div>
                        <div className="marginBottom">
                          <p className="trucker-title">
                            Beneficiary Phone Number:{' '}
                            <span style={{ fontWeight: 'normal' }}>
                              {beneficiary_phone}
                            </span>
                          </p>
                        </div>
                        <div className="marginBottom">
                          <p className="trucker-title">
                            Total Amount Transferred:{' '}
                            <span style={{ fontWeight: 'normal' }}>
                              <i
                                className="fa fa-inr"
                                style={{ fontSize: '18px', marginRight: '2px' }}
                                aria-hidden="true"
                              />
                              {Intl.NumberFormat('en-IN').format(
                                total_amount_transferred
                              )}
                            </span>
                          </p>
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      justify="flex-start"
                      className="react-table-container"
                    >
                      {this.getDesktopTable()}
                    </GridContainer>
                  </Desktop>
                  <Mobile>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={6}>
                        <p className="page-header-title-mobile">
                          Lobb Pay Ledger
                        </p>
                      </GridItem>
                    </GridContainer>
                    {this.getMobileCard()}
                  </Mobile>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

LedgerPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setLedgers,
      setLedgerId,
    },
    dispatch
  );

const mapStateToProps = state => ({
  walletBalance: state.main.walletBalance,
  ledgers: state.transactions.ledgers,
  ledgerId: state.transactions.ledgerId,
  ledgerLastEvaluatedKey: state.transactions.ledgerLastEvaluatedKey,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(ledgerPageStyle)(LedgerPage));
