/* eslint-disable no-empty */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import Cookie from 'universal-cookie';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import UserProfile from 'views/Settings/settings.jsx';
import EditVendor from 'views/Vendor/VendorEdit.jsx';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import boy from 'assets/img/man.png';
import girl from 'assets/img/girl.png';
// core components
import Header from 'components/Header/Header.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';

import makerRoutes from 'routes/makerRoutes.jsx';
// import RequireAuth from "../../app/auth";

import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx';

import logo from 'assets/img/Lobb-Logo.png';

import { setUserDetails, setWalletBalance } from '../reducers/main';
import { addAuthtoReduxStore } from '../reducers/auth';
import { checkAuth, setAuthCookies } from '../services';

import history from '../history';

const cookies = new Cookie();

const switchRoutes = (
  <Switch>
    <Route path="/maker/settings" component={UserProfile} />
    <Route path="/checker/vendor_view" component={EditVendor} />

    {makerRoutes.routes.map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
      if (prop.collapse)
        return prop.views.map((prop, key) => (
          <Route path={prop.path} component={prop.component} key={key} />
        ));
      return <Route path={prop.path} component={prop.component} key={key} />;
    })}
  </Switch>
);

let ps;

class AdminDashboard extends React.Component {
  state = {
    mobileOpen: false,
    miniActive: false,
    name: '',
    isLoading: false,
    isAdmin: true,
    isMale: true,
    isFemale: false,
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  getRoute() {
    return this.props.location.pathname !== '/maps/full-screen-maps';
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    const name = cookies.get('username');
    this.setState({
      name,
    });
    const authRole = cookies.get('role');
    if (authRole === 'maker') {
    } else {
      this.props.history.push('/pages/login-page');
    }
    checkAuth()
      .then(result => {
        this.props.addAuthtoReduxStore(result);
        setAuthCookies(result);
      })
      .catch(err => {
        const result = {
          isAuthenticated: false,
          role: '',
          activeRole: '',
          userDetails: {
            name: '',
            email: '',
            phoneNumber: '',
          },
          idToken: '',
          accessToken: '',
          refreshToken: '',
          username: '',
          userPoolId: '',
        };
        history.push('/pages/initial-page');
        this.props.addAuthtoReduxStore(result);
        setAuthCookies(result);
      });
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }

  render() {
    let image = '';
    if (this.state.isMale) {
      image = boy;
    } else {
      image = girl;
    }
    const name = cookies.get('username');
    const { classes, ...rest } = this.props;
    const mainPanel = `${classes.mainPanel} ${cx({
      [classes.mainPanelSidebarMini]: this.state.miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1,
    })}`;
    return (
      <div className={classes.wrapper}>
        {this.state.isAdmin ? (
          <React.Fragment>
            <Sidebar
              routes={makerRoutes.routes}
              logoText="Lobb Pay"
              logo={logo}
              name={name}
              image={image}
              handleDrawerToggle={this.handleDrawerToggle}
              open={this.state.mobileOpen}
              color="blue"
              bgColor="black"
              miniActive={this.state.miniActive}
              walletBalance={this.props.walletBalance}
              {...rest}
            />
            <div className={mainPanel} ref="mainPanel">
              <Header
                sidebarMinimize={this.sidebarMinimize.bind(this)}
                miniActive={this.state.miniActive}
                routes={makerRoutes.routes}
                handleDrawerToggle={this.handleDrawerToggle}
                {...rest}
              />
              {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
              {this.getRoute() ? (
                <div className={classes.content}>
                  <div>{switchRoutes}</div>
                </div>
              ) : (
                <div className={classes.map}>{switchRoutes}</div>
              )}
              {this.getRoute() ? <Footer fluid /> : null}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Sidebar
              routes={[]}
              logoText="Lobb Pay"
              logo={logo}
              name={
                name // image={image}
              }
              handleDrawerToggle={this.handleDrawerToggle}
              open={this.state.mobileOpen}
              color="blue"
              bgColor="black"
              miniActive={this.state.miniActive}
              {...rest}
            />
            <div className={mainPanel} ref="mainPanel" />
          </React.Fragment>
        )}
      </div>
    );
  }
}

AdminDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setUserDetails,
      addAuthtoReduxStore,
      setWalletBalance,
    },
    dispatch
  );

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
  auth: state.auth.auth,
  walletBalance: state.main.walletBalance,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(appStyle)(AdminDashboard));
