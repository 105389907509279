export const SET_VENDOR_TRANSACTIONS = 'transactions/SET_VENDOR_TRANSACTIONS';

const initialState = {
  vendorTransactions: [],
  lastEvaluatedKey: null,
  vendorLastEvaluatedKey: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_VENDOR_TRANSACTIONS:
      return {
        ...state,
        vendorTransactions: action.vendorTransactions,
        lastEvaluatedKey: action.lastEvaluatedKey,
      };
    default:
      return state;
  }
};

export const setVendorTransactions = (
  vendorTransactions,
  lastEvaluatedKey
) => dispatch => {
  dispatch({
    type: SET_VENDOR_TRANSACTIONS,
    vendorTransactions,
    lastEvaluatedKey,
  });
};
