/* eslint-disable no-prototype-builtins */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable react/require-default-props */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import { setVendorTransactions } from '../../reducers/vendorHistory';
import apiCaller from '../../utils/apiCaller';

const defaultButton = props => (
  <Button {...props} color="info">
    {props.children}
  </Button>
);
const style = {
  customCardContentClass: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  cardIconTitle: {
    marginTop: '15px',
    marginBottom: '0px',
  },
};

class PaginationHistory extends React.Component {
  constructor(props) {
    super();
    this.changePage = this.changePage.bind(this);
  }

  static propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    PageButtonComponent: PropTypes.any,
    onPageChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string,
  };

  componentDidMount() {
    this.getTransactionList();
  }

  getTransactionList() {
    const transactionData = [];
    const body = {
      total_items: 25,
    };
    let nextCursorId = null;
    apiCaller('premium-api/read-payout-list', 'post', body, true, false)
      .then(response => {
        if (response.items.length !== 0) {
          response.items.forEach(dataList => {
            if (
              response.hasOwnProperty('LastEvaluatedKey') &&
              response.LastEvaluatedKey !== null
            ) {
              nextCursorId = response.LastEvaluatedKey;
            }
            if (dataList.payin !== true) {
              transactionData.push({
                color: 'success',
                data: [
                  moment
                    .unix(dataList.created_at)
                    .format('DD-MM-YYYY hh:mm:ss'),
                  dataList.serial_index || '___',
                  dataList.amount || '___',
                  dataList.account_number || '___',
                  dataList.status || '___',
                  dataList.truck_number || '___',
                  dataList.vendor_name || '___',
                  dataList.service_provider_utr || '___',
                ],
                dataList,
              });
            }
          });
          this.props.setVendorTransactions(transactionData, nextCursorId);
          if (
            response.hasOwnProperty('LastEvaluatedKey') &&
            response.LastEvaluatedKey !== null
          ) {
            this.getNextTransactions();
          }
        } else {
          this.props.setVendorTransactions([], null);
        }
      })
      .catch(err => {});
  }

  componentWillReceiveProps(nextProps) {
    this.changePage(nextProps.page + 1);
  }

  changePage(page) {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    this.props.onPageChange(page - 1);
  }

  onNext(activePage) {
    // if (activePage === this.props.pages) return;
    if (this.props.lastEvaluatedKey !== null) {
      this.getNextTransactions();
    }
    this.changePage(activePage + 1);
  }

  getNextTransactions() {
    const { lastEvaluatedKey, vendorTransactions } = this.props;
    const transactionData = JSON.parse(JSON.stringify(vendorTransactions));
    const body = {
      total_items: 25,
    };
    if (lastEvaluatedKey !== '' || lastEvaluatedKey !== null) {
      body.LastEvaluatedKey = lastEvaluatedKey;
      body.vendor_id = lastEvaluatedKey.vendor_id;
    }
    let nextCursorId = null;
    apiCaller('premium-api/read-payout-list', 'post', body, true, false)
      .then(response => {
        if (response.items.length !== 0) {
          response.items.forEach(dataList => {
            if (
              response.hasOwnProperty('LastEvaluatedKey') &&
              response.LastEvaluatedKey !== null
            ) {
              nextCursorId = response.LastEvaluatedKey;
            }
            if (dataList.payin !== true) {
              transactionData.push({
                color: 'success',
                data: [
                  moment
                    .unix(dataList.created_at)
                    .format('DD-MM-YYYY hh:mm:ss'),
                  dataList.serial_index || '___',
                  dataList.amount || '___',
                  dataList.account_number || '___',
                  dataList.status || '___',
                  dataList.truck_number || '___',
                  dataList.vendor_name || '___',
                  dataList.service_provider_utr || '___',
                ],
                dataList,
              });
            }
          });
          this.props.setVendorTransactions(transactionData, nextCursorId);
        }
      })
      .catch(err => {});
  }

  render() {
    const { PageButtonComponent = defaultButton } = this.props;
    const activePage = this.props.page + 1;
    return (
      <GridContainer justify="center">
        <GridItem xs={6} sm={6} md={6}>
          <PageButtonComponent
            className="Table__pageButton"
            onClick={() => {
              if (activePage === 1) return;
              this.changePage(activePage - 1);
            }}
            disabled={activePage === 1}
          >
            {this.props.previousText}
          </PageButtonComponent>
        </GridItem>

        <GridItem xs={6} sm={6} md={6}>
          <PageButtonComponent
            className="Table__pageButton"
            onClick={() => {
              //   if (activePage === this.props.pages) return;
              this.onNext(activePage);
            }}
            disabled={
              (this.props.lastEvaluatedKey === '' ||
                this.props.lastEvaluatedKey === null) &&
              activePage === this.props.pages
            }
            style={{ float: 'right' }}
          >
            {this.props.nextText}
          </PageButtonComponent>
        </GridItem>
      </GridContainer>
    );
  }
}

PaginationHistory.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setVendorTransactions,
    },
    dispatch
  );

const mapStateToProps = state => ({
  vendorTransactions: state.vendorTransactions.vendorTransactions,
  lastEvaluatedKey: state.vendorTransactions.lastEvaluatedKey,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(PaginationHistory));
