export const SET_TRANSACTIONS = 'transactions/SET_TRANSACTIONS';
export const SET_VENDORS = 'transactions/SET_VENDORS';
export const SET_LEDGERS = 'transactions/SET_LEDGERS';
export const SET_LEDGER_ID = 'transactions/SET_LEDGER_ID';

const initialState = {
  transactions: [],
  lastEvaluatedKey: null,
  vendors: [],
  vendorLastEvaluatedKey: null,
  ledgers: [],
  ledgerLastEvaluatedKey: null,
  ledgerId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.transactions,
        lastEvaluatedKey: action.lastEvaluatedKey,
      };
    case SET_VENDORS:
      return {
        ...state,
        vendors: action.vendors,
        vendorLastEvaluatedKey: action.vendorLastEvaluatedKey,
      };
    case SET_LEDGERS:
      return {
        ...state,
        ledgers: action.ledgers,
        ledgerLastEvaluatedKey: action.ledgerLastEvaluatedKey,
      };
    case SET_LEDGER_ID:
      return {
        ...state,
        ledgerId: action.ledgerId,
      };
    default:
      return state;
  }
};

export const setTransactions = (transactions, lastEvaluatedKey) => dispatch => {
  dispatch({
    type: SET_TRANSACTIONS,
    transactions,
    lastEvaluatedKey,
  });
};

export const setVendors = (vendors, vendorLastEvaluatedKey) => dispatch => {
  dispatch({
    type: SET_VENDORS,
    vendors,
    vendorLastEvaluatedKey,
  });
};

export const setLedgers = (ledgers, ledgerLastEvaluatedKey) => dispatch => {
  dispatch({
    type: SET_LEDGERS,
    ledgers,
    ledgerLastEvaluatedKey,
  });
};

export const setLedgerId = ledgerId => dispatch => {
  dispatch({
    type: SET_LEDGER_ID,
    ledgerId,
  });
};
