/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */
/* eslint-disable camelcase */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons
import Refresh from '@material-ui/icons/Refresh';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import AddAlert from '@material-ui/icons/AddAlert';
import Assignment from '@material-ui/icons/Assignment';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingOverlay from 'react-loading-overlay';
import CardIcon from 'components/Card/CardIcon.jsx';

import Snackbar from 'components/Snackbar/Snackbar.jsx';
import Cookie from 'universal-cookie';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import TransporterTableList from './TransporterTableList';
import { addAuthtoReduxStore } from '../../reducers/auth';
import { setUserDetails } from '../../reducers/main';
import apiCaller from '../../utils/apiCaller';

const cookies = new Cookie();

class SuperAdminWalletList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction: [],
      transactionArray: [],
      transactionData: [],
      errSnack: false,
      errMessage: '',
      account_number: '',
      userRole: '',
      verifyLoader: false,
    };
    this.getTransactionList = this.getTransactionList.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.refreshWallet = this.refreshWallet.bind(this);
    this.changeFont = this.changeFont.bind(this);
    this.retryClientWallet = this.retryClientWallet.bind(this);
    this.refreshClientWallet = this.refreshClientWallet.bind(this);
  }

  componentDidMount() {
    const role = cookies.get('role');
    this.setState({ userRole: role });
    this.getTransactionList();
  }

  changeFont(value) {
    let color;
    let values = value;
    switch (value) {
      case 'queued':
        color = '#d480f2';
        values = 'Queued';
        break;
      case 'otp_verified':
        color = '#87e0ed';
        values = 'Verified';
        break;
      case 'payout.processed':
        color = '#74f285';
        values = 'Processed';
        break;
      case 'payout.processing':
        color = '#0000FF';
        values = 'Processing';
        break;
      case 'user_created':
        color = '#7c78ff';
        values = 'Created';
        break;
      case 'payout.reversed':
        color = '#79f299';
        values = 'Reversed';
        break;
      case 'payout.failed':
        color = '#ba2d36';
        values = 'Failed';
        break;
      case 'admin_rejected':
        color = '#e6303c';
        values = 'Rejected';
        break;
      case 'wallet_failed':
        color = '#e6303c';
        values = 'LOW BALANCE';
        break;
      case 'Paas_failed':
        color = '#e6303c';
        values = 'Paas Failed';
        break;
      default:
        color = '#000000';
    }
    return (
      <span style={{ fontSize: '16px', color }}>
        {color !== '#000000' ? values.toUpperCase() : values}
      </span>
    );
  }

  refreshWallet() {
    this.setState({
      transactionData: [],
    });
    this.getTransactionList();
  }

  refreshClientWallet() {
    this.retryClientWallet();
  }

  retryClientWallet() {
    const body = {
      type: 'virtual_account',
    };
    apiCaller(
      'premium-api/retry-id-transaction-account',
      'post',
      body,
      true,
      false
    )
      .then(response => {
        if (response.message !== 'virtual account not found') {
          this.setState(
            {
              infoMessage: 'Account retrieve Successfully',
              isLoading: false,
            },
            this.showNotification('infoSnack')
          );
        }
      })
      .catch(err => {
        console.log('error', err);
        this.setState(
          {
            errMessage: err.message || 'Oops Something went wrong',
            isLoading: false,
          },
          this.showNotification('errSnack')
        );
      });
  }

  getTransactionList() {
    this.setState({ verifyLoader: true });
    const body = {
      total_items: 200,
    };
    const transactionData = [];
    apiCaller(
      'premium-api/read-premium-transporter-list',
      'post',
      body,
      true,
      false
    )
      .then(response => {
        if (response.data.length !== 0) {
          response.data.items.forEach(dataList => {
            {
              transactionData.push({
                color: 'success',
                data: [
                  dataList.company_name,
                  dataList.wallet,
                  dataList.receivers[0].account_number,
                ],
                dataList,
              });
            }
          });
          if (response.data.hasOwnProperty('LastEvaluatedKey')) {
            if (response.data.LastEvaluatedKey !== null) {
              this.setState({ transactionData }, () =>
                this.getTransactionListWithLastKey(
                  response.data.LastEvaluatedKey
                )
              );
            } else {
              this.setState({ transactionData, verifyLoader: false });
            }
          } else {
            this.setState({ transactionData, verifyLoader: false });
          }
        } else {
          this.setState(
            {
              errMessage: 'No Transactions found',
              isLoading: false,
              verifyLoader: false,
            },
            this.showNotification('errSnack')
          );
        }
      })
      .catch(err => {
        this.setState(
          {
            errMessage: err.message || 'Oops Something went wrong',
            isLoading: false,
            verifyLoader: false,
          },
          this.showNotification('errSnack')
        );
      });
  }

  getTransactionListWithLastKey(LastEvaluatedKey) {
    const body = {
      total_items: 200,
      LastEvaluatedKey,
    };
    const { transactionData } = this.state;

    apiCaller(
      'premium-api/read-premium-transporter-list',
      'post',
      body,
      true,
      false
    )
      .then(response => {
        if (response.data.length !== 0) {
          response.data.items.forEach(dataList => {
            {
              transactionData.push({
                color: 'success',
                data: [
                  dataList.company_name,
                  dataList.wallet,
                  dataList.receivers[0].account_number,
                ],
                dataList,
              });
            }
          });
          if (response.data.hasOwnProperty('LastEvaluatedKey')) {
            if (response.data.LastEvaluatedKey !== null) {
              this.setState({ transactionData }, () =>
                this.getTransactionListWithLastKey(
                  response.data.LastEvaluatedKey
                )
              );
            } else {
              this.setState({ transactionData, verifyLoader: false });
            }
          } else {
            this.setState({ transactionData, verifyLoader: false });
          }
        } else {
          this.setState(
            {
              errMessage: 'No Transactions found',
              isLoading: false,
              verifyLoader: false,
            },
            this.showNotification('errSnack')
          );
        }
      })
      .catch(err => {
        this.setState(
          {
            errMessage: err.message || 'Oops Something went wrong',
            isLoading: false,
            verifyLoader: false,
          },
          this.showNotification('errSnack')
        );
      });
  }

  /**
   * will return the notification snackbar
   * @param {string} place
   */
  showNotification(place) {
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 5000);
    }
  }

  updateWallet(e) {
    const { classes } = this.props;
    const { isLoading } = this.state;
    this.setState({ isLoading: true });
    if (isLoading) {
      return (
        <CircularProgress
          className={classes.progress}
          style={{ color: 'danger' }}
          thickness={7}
        />
      );
    }
    this.setState(
      {
        infoMessage: 'Account updated successfully',
        isLoading: false,
      },
      this.showNotification('infoSnack')
    );
    e.preventDefault();
  }

  render() {
    const {
      transactionData,
      errMessage,
      errSnack,
      infoMessage,
      infoSnack,
      verifyLoader,
    } = this.state;
    // const replaced = account_number.replace(/.(?=.{4,}$)/g, 'X');
    return (
      <GridContainer>
        <Snackbar
          place="tc"
          color="success"
          icon={AddAlert}
          message={infoMessage}
          open={infoSnack}
          closeNotification={() => this.setState({ infoSnack: false })}
          close
        />
        <Snackbar
          place="tc"
          color="danger"
          icon={AddAlert}
          message={errMessage}
          open={errSnack}
          closeNotification={() => this.setState({ errSnack: false })}
          close
        />
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader color="warning">
              <CardIcon color="info">
                <Assignment />
              </CardIcon>
              <h4
                style={{ color: '#FFF', fontWeight: '400', fontSize: '28px' }}
              >
                {' '}
                Account Balance <small />{' '}
              </h4>
              <Refresh
                fontSize="large"
                onClick={() => this.refreshWallet()}
                style={{
                  float: 'right',
                  top: '0px',
                  cursor: 'pointer',
                  fontSize: 30,
                }}
              />
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <LoadingOverlay
                        active={verifyLoader}
                        spinner
                        text="Loading Account List..."
                      >
                        <TransporterTableList
                          transactionData={transactionData}
                        />
                        {/* <ReactTable
                          getTheadThProps={() => ({
                            style: {
                              outline: 0,
                              color: 'black',
                              fontSize: '18px',
                              fontWeight: '400',
                              textAlign: 'left',
                            },
                          })}
                          data={transactionData.map((prop, key) => ({
                            id: key,
                            company_name: prop.data[0],
                            amount: prop.data[1],
                            virtual_account: prop.data[2],
                          }))}
                          filterable
                          columns={[
                            {
                              filterable: false,
                              Header: 'Company Name',
                              accessor: 'company_name',
                              Cell: row => this.changeFont(row.value),
                              Filter: ({ filter, onChange }) => (
                                <input
                                  onChange={event =>
                                    onChange(event.target.value)
                                  }
                                  value={filter ? filter.value : ''}
                                  placeholder="Company Name"
                                  style={{
                                    width: '100%',
                                    backgroundColor: 'white',
                                    color: 'black',
                                  }}
                                />
                              ),
                            },
                            {
                              Header: () => (
                                <div
                                  style={{
                                    textAlign: 'right',
                                  }}
                                >
                                  Amount
                                </div>
                              ),
                              accessor: 'amount',
                              filterable: false,
                              Cell: row => (
                                <div
                                  style={{
                                    textAlign: 'right',
                                    fontWeight: 500,
                                  }}
                                >
                                  <i
                                    className="fa fa-inr"
                                    style={{ fontSize: '15px' }}
                                    aria-hidden="true"
                                  />{' '}
                                  {row.value}
                                </div>
                              ),
                              Filter: ({ filter, onChange }) => (
                                <input
                                  onChange={event =>
                                    onChange(event.target.value)
                                  }
                                  value={filter ? filter.value : ''}
                                  placeholder="Amount"
                                  style={{
                                    width: '100%',
                                    backgroundColor: 'white',
                                    color: 'black',
                                    textAlign: 'right',
                                  }}
                                />
                              ),
                              sortable: false,
                            },
                            {
                              Header: 'Virtual Account',
                              accessor: 'virtual_account',
                              Cell: row => this.changeFont(row.value),
                              sortable: false,
                              filterable: false,
                            },
                          ]}
                          minRows={0}
                          defaultPageSize={10}
                          showPaginationTop
                          showPaginationBottom={false}
                          className="-striped -highlight"
                        /> */}
                      </LoadingOverlay>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

SuperAdminWalletList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setUserDetails,
      addAuthtoReduxStore,
    },
    dispatch
  );

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
  auth: state.auth.auth,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle)(SuperAdminWalletList));
