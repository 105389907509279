import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import AccountsListView from './AccountsManagement/AccountsListView';
import apiCaller from '../../utils/apiCallerESP';

const Accounts = () => {
  const [transporterData, setTransporterData] = useState([]);
  const [fetching, setFetching] = useState(false);

  const getTranporterList = async offset => {
    setFetching(true);
    const endPoint = 'view-all-esp-accounts';
    const body = {
      limit: 10,
      offset: offset || 0,
    };
    try {
      const response = await apiCaller(endPoint, body);
      const data = response.data.body;
      setTransporterData(data);
      setFetching(false);
    } catch (e) {
      setFetching(false);
    }
  };
  useEffect(() => {
    getTranporterList();
  }, []);
  return (
    <div>
      {fetching ? (
        <CircularProgress />
      ) : (
        <div>
          <AccountsListView
            transporterData={transporterData}
            fetching={fetching}
            triggerRefresh={offset => getTranporterList(offset)}
          />
        </div>
      )}
    </div>
  );
};

export default Accounts;
