/* eslint-disable import/no-mutable-exports */
import Dashboard from 'views/Dashboard/Dashboard.jsx';

// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import LocalShipping from '@material-ui/icons/LocalShipping';
import List from '@material-ui/icons/List';

import AddTransaction from 'views/AddTransaction/AddTransaction.jsx';

import Vendor from '../views/Vendor/Vendor';
import VendorList from '../views/Vendor/VendorList';
import BulkTransaction from '../views/BulkTransaction/BulkTransaction';

const makerRoutes = {
  routes: [
    {
      path: '/maker/dashboard',
      name: 'Dashboard',
      icon: DashboardIcon,
      component: Dashboard,
    },
    {
      collapse: true,
      // path: '/admin/vendor',
      name: 'Transactions',
      icon: List,
      state: 'openApprove',
      views: [
        {
          path: '/maker/create_transaction',
          name: 'Add Transaction',
          mini: 'A',
          component: AddTransaction,
        },
        {
          path: '/maker/bulk_transaction',
          name: 'Bulk Transaction',
          mini: 'B',
          component: BulkTransaction,
        },
      ],
    },
    {
      collapse: true,
      path: '/maker/vendor',
      name: 'Vendor',
      icon: LocalShipping,
      state: 'openVendor',
      views: [
        {
          path: '/maker/vendor/add',
          name: 'Add Vendor',
          mini: 'A',
          component: Vendor,
        },
        {
          path: '/maker/vendor/list',
          name: 'Vendor List',
          mini: 'L',
          component: VendorList,
        },
      ],
    },
    {
      redirect: true,
      path: '/maker',
      pathTo: '/maker/dashboard',
      name: 'Dashboard',
    },
  ],
};

export default makerRoutes;
