/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Auth } from 'aws-amplify';
import Personaldetails from './MultiStep/Personaldetails';
import PANDetails from './MultiStep/PANDetails';
import AdhaarDetails from './MultiStep/AdhaarDetails';
import AccountDetails from './MultiStep/AccountDetails';
import apiCaller from '../../../utils/apiCallerESP';

const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: { marginTop: '40px' },
  dialogActions: { display: 'flex', alignContent: 'center' },
}));

export default function MaxWidthDialog({ brokerDetails, handleRefreshList }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [active, setActive] = React.useState(0);
  const [user, setUser] = React.useState();
  const [details, setDetails] = React.useState();
  const getBrokerDetails = async () => {
    const endPoint = 'get-broker-detail';
    const data = {
      agent_id: brokerDetails.agent_id,
    };
    const response = await apiCaller(endPoint, data);
    const extractedData = response.data.body.agent_detail;
    setDetails(extractedData);
    setOpen(true);
  };
  const handleClickOpen = () => {
    setActive(0);
    Auth.currentAuthenticatedUser().then(result => {
      setUser(result);
    });

    getBrokerDetails();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleStatusUpdate = value => {
    setOpen(value);
    handleRefreshList();
  };
  const handleApprove = () => {
    setOpen(false);
    handleRefreshList();
  };
  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        className={classes.viewButton}
        startIcon={<VisibilityIcon />}
        size="small"
        onClick={handleClickOpen}
      >
        View Documents
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.Dialog}
      >
        {active == 0 ? (
          <Personaldetails
            active={value => setActive(value)}
            agentDetails={details}
            agentId={brokerDetails.agent_id}
            user={user}
            rejectAgentHandle={value => handleStatusUpdate(value)}
          />
        ) : active == 1 ? (
          <PANDetails
            active={value => setActive(value)}
            agentDetails={details}
            agentId={brokerDetails.agent_id}
            user={user}
            rejectAgentHandle={value => handleStatusUpdate(value)}
          />
        ) : active == 2 ? (
          <AdhaarDetails
            active={value => setActive(value)}
            agentDetails={details}
            agentId={brokerDetails.agent_id}
            user={user}
            rejectAgentHandle={value => handleStatusUpdate(value)}
          />
        ) : active == 3 ? (
          <AccountDetails
            submit={() => handleApprove()}
            agentDetails={details}
            agentId={brokerDetails.agent_id}
            user={user}
            rejectAgentHandle={value => handleStatusUpdate(value)}
          />
        ) : (
          ''
        )}
      </Dialog>
    </React.Fragment>
  );
}
