import React, { useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button, makeStyles, TextField } from '@material-ui/core';
import TRipImage from '../../../../assets/img/delivery guy.png';

const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid lightgray 2px',
  // borderRadius: '7px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',

  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};

const profileImageContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: { marginTop: '40px' },
  dialogActions: { display: 'flex', alignContent: 'center' },
}));
const Personaldetails = ({ active, tripDetails, remarks, approvedAmount }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);

  const [message, setMessage] = useState('');
  //   const handleClose = () => {
  //     setOpen(false);
  //   };
  const handleActive = () => {
    active(2);
  };
  const handleApprovedAmount = value => {
    const regex = /^[1-9]\d*(\.\d+)?$/;
    const valid = regex.test(value);
    if (valid) {
      if (value <= 10000) {
        setError(false);
        approvedAmount(value);
      } else {
        setError(true);
        setMessage('Amount should not exceed 10000');
      }
    } else {
      setError(true);
      setMessage('Please check amount');
    }
  };
  return (
    <div>
      <DialogContent className={classes.DialogContent}>
        <fieldset style={shifter}>
          <legend style={{ maxWidth: '300px' }}>
            <div style={avatarContainer}>
              <div style={avatar}>
                <AccountBalanceIcon style={{ color: 'white' }} />
              </div>
              <Typography variant="h6" gutterBottom>
                Trip Details
              </Typography>
            </div>
          </legend>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">From City :</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {tripDetails.from_city}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">To City :</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {tripDetails.to_city}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Truck Number :</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {tripDetails.truck_number}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Truck Type :</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {tripDetails.truck_type}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Freight Value :</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {tripDetails.freight_value}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Advance :</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {tripDetails.advance}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">
                      Requested Balance :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {tripDetails.requested_balance}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">
                      Approved Amount :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      onChange={event =>
                        handleApprovedAmount(event.target.value)
                      }
                      error={error}
                      helperText={error ? message : ''}
                    />
                  </Grid>
                  <Grid container spacing={5} className={classes.profileAction}>
                    <Grid item xs={6}>
                      <Button variant="contained">Reject</Button>
                    </Grid>
                    <Grid item xs={12}>
                      <textarea
                        style={{
                          padding: '10px',
                          width: '100%',
                          marginBottom: '10px',
                          borderRadius: '5px',
                          border: '1px solid lightgray',
                        }}
                        rows={4}
                        onChange={event => remarks(event.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={profileImageContainer}>
                <img src={TRipImage} alt="imagecions" />
              </div>
            </Grid>
          </Grid>
        </fieldset>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '20px',
          }}
        >
          <Button
            // onClick={handleClose}
            onClick={handleActive}
            color="primary"
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            disabled={error}
          >
            Next Step
          </Button>
        </div>
      </DialogContent>
    </div>
  );
};

export default Personaldetails;
