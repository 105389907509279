/* eslint-disable no-prototype-builtins */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-undef */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint no-underscore-dangle: 0 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import indexRoutes from 'routes/index.jsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import AddAlert from '@material-ui/icons/AddAlert';
import Amplify, { Hub } from 'aws-amplify';

import { Online } from 'react-detect-offline';
import * as Sentry from '@sentry/browser';
import { FullStoryAPI } from 'react-fullstory';

import ReactGA from 'react-ga';
// import Drift from 'react-driftjs';

import Cookies from 'universal-cookie';
import {
  setUserDetails,
  setWalletBalance,
  clearWallet,
} from '../reducers/main';
import { addAuthtoReduxStore } from '../reducers/auth';
import { setTransactions, setVendors } from '../reducers/transactions';
import { setVendorTransactions } from '../reducers/vendorHistory';

import { checkAuth, setAuthCookies } from '../services';
import apiCaller from '../utils/apiCaller';
import history from '../history';

import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

const cookie = new Cookies();

/**
 * Represents a Root Componet.
 * @class
 */
class App extends React.Component {
  /*
   * Main app Starts
   */

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      isRefreshNotification: true,
      isRefresh: false,
      connectionNotifText: '',
    };
    this.onChangeConnection = this.onChangeConnection.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
    this.onSignout = this.onSignout.bind(this);
  }

  checkNested(obj) {
    if (obj === undefined) return false
    if (obj.hasOwnProperty('drift')) {
      return true
    } else {
      return false
    }
  }

  componentDidMount() {
    const role = cookie.get('role');
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          history.push('/pages/initial-page');
          break;
        case 'signOut':
          this.onSignout();
          break;
        default:
          console.log('Default');
      }
    });
    checkAuth()
      .then(result => {
        this.setState({ loaded: true });
        this.props.addAuthtoReduxStore(result);
        setAuthCookies(result);
        Sentry.configureScope(scope => {
          scope.setUser({ id: result.userId });
        });
        FullStoryAPI('identify', result.userId, {
          userId: result.userId,
        });
        this.getTransporterId();
        if (result.isAuthenticated) {
          if(this.checkNested(window.drift)) {
            window.drift.identify(result.userId, {
              email: result.userDetails.email,
              phone_number: result.userDetails.phone_number,
              username: result.username,
            });
          }
        }
        if (role !== 'super_admin') {
          setTimeout(() => {
            this.props.setWalletBalance();
          }, 2000);
        }
      })
      .catch(err => {
        console.log('Error', err);
        this.setState({ loaded: true });
        const result = {
          isAuthenticated: false,
          role: '',
          activeRole: '',
          userDetails: {
            name: '',
            email: '',
            phoneNumber: '',
          },
          idToken: '',
          accessToken: '',
          refreshToken: '',
          username: '',
          userPoolId: '',
        };
        this.props.addAuthtoReduxStore(result);
        setAuthCookies(result);
      });
  }

  getTransporterId() {
    const body = {};
    apiCaller('premium-api/get-transporter', 'post', body, true, false)
      .then(response => {
        if (response.hasOwnProperty('transporter_id')) {
          ReactGA.set({ userId: response.transporter_id }, ['userTrack']);
        }
      })
      .catch(err => {});
  }

  componentDidUpdate() {
    /** all pages will start from the top */
    window.scrollTo(0, 0);
  }

  componentWillMount() {}

  onSignout() {
    this.props.setTransactions([], null);
    this.props.clearWallet();
    this.props.setVendors([], null);
    this.props.setVendorTransactions([], null);
    history.push('/pages/initial-page');
  }

  componentWillUnmount() {}

  showNotification(place) {
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      // use this to make the notification autoclose
      setTimeout(() => {
        // console.log('TIMEOUT');
        x[place] = false;
        this.setState(x);
      }, 6000);
    }
  }

  /**
   * Will Refresh The Page
   * @function
   */
  refreshPage() {
    const body = {};
    apiCaller('', 'post', body, true, false)
      .then(response => {
        if (response.isRefresh === true) {
          window.location.reload();
          this.setState({ isRefresh: true });
        }
      })
      .catch(err => {
        console.log('err', err);
      });
  }

  onChangeConnection(online) {
    // console.log('HELLO')
    if (!online) {
      this.setState(
        {
          // connectionNotif: true,
          connectionNotifStatus: 'danger',
          connectionNotifText: 'You are Offline.',
        },
        this.showNotification('connectionNotif')
      );
    } else {
      this.setState(
        {
          // connectionNotif: true,
          connectionNotifStatus: 'success',
          connectionNotifText: 'You are back to Online.',
        },
        this.showNotification('connectionNotif')
      );
    }
  }

  /**
   * Logs out user
   * @function
   */

  render() {
    const {
      connectionNotif,
      connectionNotifText,
      connectionNotifStatus,
      isRefresh,
    } = this.state;
    return (
      <div>
        <Switch>
          {indexRoutes.map((prop, key) => (
            <Route path={prop.path} component={prop.component} key={key} />
          ))}
        </Switch>
        <Online onChange={this.onChangeConnection} />
        <Snackbar
          place="tc"
          color={connectionNotifStatus}
          icon={AddAlert}
          message={connectionNotifText}
          open={connectionNotif}
          closeNotification={() => this.setState({ connectionNotif: false })}
          close
        />
        {isRefresh && (
          <Snackbar
            place="tc"
            color="success"
            icon={AddAlert}
            message="We’ve fine-tuned Lobb Pay under the hood. Refresh this tab to experience the latest Lobb Pay."
            open={isRefresh}
            actionButton
            actionButtonName="Refresh"
            // onSubmit={this.refreshPage}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setUserDetails,
      addAuthtoReduxStore,
      setWalletBalance,
      setTransactions,
      clearWallet,
      setVendorTransactions,
      setVendors,
    },
    dispatch
  );

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
  auth: state.auth.auth,
  walletBalance: state.main.walletBalance,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
