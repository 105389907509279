/* eslint-disable no-prototype-builtins */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
// import Cookies from "universal-cookie";
import InfoArea from 'components/InfoArea/InfoArea.jsx';
import Info from '@material-ui/icons/Info';
import registerPageStyle from 'assets/jss/material-dashboard-pro-react/views/registerPageStyle';
// import Cookies from 'universal-cookie';
import ReactGA from 'react-ga';

import { setUserDetails, setWalletBalance } from '../../reducers/main';
import { addAuthtoReduxStore } from '../../reducers/auth';
import history from '../../history';
import { checkAuth, setAuthCookies } from '../../services';
import apiCaller from '../../utils/apiCaller';

// const cookies = new Cookies();
class InitialPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      name: '',
      email: '',
      companyName: '',
      contactNumber: '',
      uid: '',
      isLoading: true,
      validateOtpForm: '',
    };
    this.checkAuth = this.checkAuth.bind(this);
  }

  componentDidMount() {
    // Check weather the user is Authenticated
    this.checkAuth();
  }

  /**
   * Check weather the user is Authenticated
   * @function
   */
  checkAuth() {
    checkAuth()
      .then(result => {
        this.setState({
          isLoading: false,
        });
        if (result.isAuthenticated) {
          this.props.addAuthtoReduxStore(result);
          setAuthCookies(result);
          this.getTransporterId();
          if (result.role === 'user') {
            this.setState({
              validateOtpForm: true,
            });
          } else if (result.role === 'super_admin') {
            history.push('/super_admin/dashboard');
          } else if (result.role === 'admin') {
            history.push('/admin/dashboard');
          } else if (result.role === 'checker') {
            history.push('/checker/dashboard');
          } else if (result.role === 'maker') {
            history.push('/maker/dashboard');
          }
          if (result.role !== 'super_admin') {
            setTimeout(() => {
              this.props.setWalletBalance();
            }, 2000);
          }
        } else {
          history.push('/pages/login-page');
        }
      })
      .catch(err => {
        const result = {
          isAuthenticated: false,
          role: '',
          activeRole: '',
          userDetails: {
            name: '',
            email: '',
            phoneNumber: '',
          },
          idToken: '',
          accessToken: '',
          refreshToken: '',
          username: '',
          userPoolId: '',
        };
        this.props.addAuthtoReduxStore(result);
        setAuthCookies(result);
        history.push('/pages/login-page');
      });
  }

  getTransporterId() {
    const body = {};
    apiCaller('premium-api/get-transporter', 'post', body, true, false)
      .then(response => {
        if (response.hasOwnProperty('transporter_id')) {
          ReactGA.set({ userId: response.transporter_id }, ['userTrack']);
        }
      })
      .catch(err => {});
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          {this.state.isLoading && (
            <GridItem style={{ marginTop: '150px' }}>
              <CircularProgress
                className={classes.progress}
                style={{ color: purple[500] }}
                thickness={7}
              />
            </GridItem>
          )}
          {this.state.isPending && (
            <GridItem style={{ marginTop: '150px' }}>
              <InfoArea
                title="Pending"
                description="Please Wait for the Approval"
                icon={Info}
                iconColor="rose"
              />
            </GridItem>
          )}
        </GridContainer>
      </div>
    );
  }
}

InitialPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setUserDetails,
      addAuthtoReduxStore,
      setWalletBalance,
    },
    dispatch
  );

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
  auth: state.auth.auth,
  walletBalance: state.main.walletBalance,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(registerPageStyle)(InitialPage));
