/* eslint-disable no-prototype-builtins */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-undef */
import apiCaller from '../utils/apiCaller';

export const SET_USER_DETAILS = 'main/SET_USER_DETAILS';
export const SET_ACTIVE_ROLE = 'main/SET_ACTIVE_ROLE';
export const SET_WALLET_BALANCE = 'main/SET_WALLET_BALANCE';
export const CLEAR_WALLET_BALANCE = 'main/CLEAR_WALLET_BALANCE';

const initialState = {
  uid: '',
  userDetails: {},
  activeRole: '', // sales, finance, admin, or ops
  walletBalance: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.userDetails,
        uid: action.uid,
      };
    case SET_ACTIVE_ROLE:
      return {
        ...state,
        activeRole: action.role,
      };
    case SET_WALLET_BALANCE:
      return {
        ...state,
        walletBalance: action.walletBalance,
      };
    case CLEAR_WALLET_BALANCE:
      return {
        ...state,
        walletBalance: action.walletBalance,
      };
    default:
      return state;
  }
};

export const setUserDetails = (userDetails, uid) => dispatch => {
  dispatch({
    type: SET_USER_DETAILS,
    userDetails,
    uid,
  });
};

export const setActiveRole = role => dispatch => {
  dispatch({
    type: SET_ACTIVE_ROLE,
    role,
  });
};

export const setWalletBalance = () => dispatch => {
  const body = {
    // transporter_id: username,
  };
  let walletBalance = 0;
  apiCaller(
    'premium-api/client-check-wallet-balance',
    'post',
    body,
    true,
    false
  )
    .then(response => {
      if (response.data.hasOwnProperty('wallet_amount')) {
        walletBalance = response.data.wallet_amount;
        dispatch({
          type: SET_WALLET_BALANCE,
          walletBalance,
        });
      } else {
        dispatch({
          type: SET_WALLET_BALANCE,
          walletBalance,
        });
      }
    })
    .catch(err => {
      dispatch({
        type: SET_WALLET_BALANCE,
        walletBalance,
      });
    });
};

export const clearWallet = () => dispatch => {
  dispatch({
    type: CLEAR_WALLET_BALANCE,
    walletBalance: 0,
  });
};
