/* eslint-disable no-var */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import TransactionListView from './BalanceRequests/TransactionListView';
import apiCaller from '../../utils/apiCallerESP';

const BalanceRequests = () => {
  const [balanceRequest, setBalanceRequest] = useState();
  const [fetching, setFetching] = useState(false);
  const getTransactionList = async (value, offset) => {
    setFetching(true);
    const endPoint = 'balancerequestlist-esp';
    const body = {
      limit: '10',
      offset: offset || 0,
      transaction_status: value !== 'all' ? value : null,
    };
    try {
      const response = await apiCaller(endPoint, body);

      const requests = response.data.body;
      setBalanceRequest(requests);
      setFetching(false);
    } catch (error) {
      setFetching(false);
    }
  };
  useEffect(() => {
    getTransactionList();
  }, []);
  return (
    <div>
      {fetching ? (
        <CircularProgress />
      ) : (
        <TransactionListView
          balanceRequest={balanceRequest}
          triggerRefresh={(value, offset) => getTransactionList(value, offset)}
        />
      )}
    </div>
  );
};

export default BalanceRequests;
