/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import CallMadeSharpIcon from '@material-ui/icons/CallMadeSharp';
import CallReceivedSharpIcon from '@material-ui/icons/CallReceivedSharp';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import apiCaller from '../../../utils/apiCallerESP';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: { backgroundColor: 'black' },
  tableCell: { color: 'white' },
  tableBodyCell: { height: '60px' },
});

function createData(
  transactionSerial,
  amount,
  accountNo,
  agent,
  truckNo,
  date,
  utr,
  status,
  nature
) {
  return {
    transactionSerial,
    amount,
    accountNo,
    agent,
    truckNo,
    date,
    utr,
    status,
    nature,
  };
}

export default function DenseTable({ id }) {
  const classes = useStyles();
  const [transactions, setTransactions] = useState();
  const convertTimestamp = value => {
    const dt = new Date(value);
    const date = dt.toISOString();
    return date;
  };
  const rows = transactions?.map(transaction =>
    createData(
      transaction.transaction_id,
      transaction.amount,
      transaction.account,
      transaction.agent,
      transaction.truck,
      convertTimestamp(transaction.date),
      transaction.utr,
      transaction.status,
      transaction.nature
    )
  );

  const accountStatement = async () => {
    const endPoint = 'view-transporters-statement';
    const body = { account_id: id, limit: '10', offset: '0' };
    const response = await apiCaller(endPoint, body);
    const data = response.data.body.transactions;
    setTransactions(data);
  };
  useEffect(() => {
    accountStatement();
  }, []);
  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={classes.tableCell} align="left">
              Txn #
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Amount
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Account #
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Agent
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Truck #
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Date
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              UTR
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map(row => (
            <TableRow
              key={row.transactionSerial}
              className={classes.tableBodyCell}
            >
              <TableCell component="th" scope="row">
                {row.transactionSerial}
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                {row.amount}
                {row.nature == 'debit' ? (
                  <CallMadeSharpIcon />
                ) : (
                  <CallReceivedSharpIcon />
                )}
              </TableCell>
              <TableCell align="center">{row.accountNo}</TableCell>
              <TableCell align="center">{row.agent}</TableCell>
              <TableCell align="center">{row.truckNo}</TableCell>
              <TableCell align="center">{row.date}</TableCell>
              <TableCell align="center">{row.utr}</TableCell>
              <TableCell
                align="center"
                style={{
                  color:
                    row.status == 'processing'
                      ? 'orange'
                      : row.status == 'processed'
                      ? 'green'
                      : row.status == 'reversed'
                      ? 'red'
                      : row.status == 'payment approved'
                      ? 'blue'
                      : 'black',
                }}
              >
                {row.status}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
