/* eslint-disable no-var */
/* eslint-disable guard-for-in */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  Grid,
  Typography,
  DialogContent,
  // CircularProgress,
  // Divider,
} from '@material-ui/core';
import apiCaller from '../../../utils/apiCallerESP';

var ESPDetails = [];
const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid lightgray 2px',
  // borderRadius: '7px',
  margin: '20px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',

  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};
const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  viewButton: {
    backgroundColor: '#3366FF',
    color: 'white',
    marginLeft: '20px',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: { marginTop: '40px' },
  dialogActions: { display: 'flex', alignContent: 'center' },
  label: { fontWeight: 'bold', marginBottom: '10px' },
  value: { marginBottom: '10px' },
}));

export default function MaxWidthDialog({ handleToggle, transporterData }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  // const [fetching, setFetching] = React.useState(false);
  // const [espDetails, setESPDetails] = React.useState([]);
  const { user_id } = transporterData;

  const getUserEspDetail = async () => {
    // setFetching(true);
    const body = {
      transporter_id: user_id,
    };
    const endPoint = 'view-esp-accounts';
    const response = await apiCaller(endPoint, body);
    const details = response.data.body;
    // setESPDetails(details);
    for (const key in details) {
      const duplicate = ESPDetails.some(
        item => item.account_id === details[key].account_id
      );
      if (!duplicate) {
        ESPDetails.push(details[key]);
      }
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
    getUserEspDetail();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        className={classes.viewButton}
        startIcon={<VisibilityIcon />}
        size="small"
        onClick={handleClickOpen}
      >
        View Details
      </Button>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.Dialog}
      >
        <DialogContent>
          <fieldset style={shifter}>
            <legend style={{ maxWidth: '300px' }}>
              <div style={avatarContainer}>
                <div style={avatar}>
                  <AccountBalanceIcon style={{ color: 'white' }} />
                </div>
                <Typography variant="h6" gutterBottom>
                  Personal Details
                </Typography>
              </div>
            </legend>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1" className={classes.label}>
                  Transporter Name
                </Typography>
                <Typography variant="body2">
                  {transporterData.company_name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" className={classes.label}>
                  User Name
                </Typography>
                <Typography variant="body2">
                  {transporterData.user_id}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" className={classes.label}>
                  Number of Txn
                </Typography>
                <Typography variant="body2">Delhi</Typography>
              </Grid>
              {/* <Grid item xs={6}>
                <Typography variant="body1" className={classes.label}>
                  Virtual Account Number
                </Typography>
                <Typography variant="body2">
                  {transporterData.receivers[0].account_number}
                </Typography>
              </Grid> */}
              <Grid item xs={12}>
                {ESPDetails.map(detail => (
                  <Grid container style={{ marginBottom: '20px' }}>
                    <Grid item xs={6}>
                      <Typography variant="body1" className={classes.label}>
                        ESP Account ID
                      </Typography>
                      <Typography variant="body1" className={classes.label}>
                        Number Of Txn
                      </Typography>
                      <Typography variant="body1" className={classes.label}>
                        {detail.balance}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" className={classes.value}>
                        {detail.account_id}
                      </Typography>
                      <Typography variant="body1" className={classes.value}>
                        {detail.number_of_transactions
                          ? detail.number_of_transactions
                          : 0}
                      </Typography>
                      <Button
                        size="small"
                        onClick={() => handleToggle(detail.account_id)}
                        variant="contained"
                      >
                        View Statement
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </fieldset>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
