/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable func-names */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
import React from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// material-ui icons
import Assignment from '@material-ui/icons/Assignment';
import PropTypes from 'prop-types';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Slide from '@material-ui/core/Slide';
import ReactGA from 'react-ga';

import Link from '@material-ui/core/Link';

import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import AddAlert from '@material-ui/icons/AddAlert';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import ReactTable from 'react-table';
import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import ThumbUp from '@material-ui/icons/ThumbUp';
import ThumbDown from '@material-ui/icons/ThumbDown';
import SweetAlert from 'react-bootstrap-sweetalert';
import Refresh from '@material-ui/icons/Refresh';
import Tooltip from '@material-ui/core/Tooltip';
import LoadingOverlay from 'react-loading-overlay';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';
import Cookie from 'universal-cookie';
import moment from 'moment';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import { setWalletBalance } from '../../reducers/main';
import Pagination from './Pagination';
import { setTransactions } from '../../reducers/transactions';
import apiCaller from '../../utils/apiCaller';

const cookies = new Cookie();

const style = {
  ...customCheckboxRadioSwitch,
  customCardContentClass: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  tableFont: {
    fontSize: '25px',
    marginTop: '15px',
    color: 'green',
  },
};

function Transition(props) {
  return <Slide direction="down" {...props} />;
}
class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      transactionData: [],
      isAcceptNotification: false,
      isRejectNotification: false,
      infoMessage: '',
      infoSnack: false,
      errSnack: false,
      errMessage: '',
      isLoading: false,
      updateDate: '',
      verifyLoader: false,
      checked: [],
      showDetailed: false,
      currentTransactionData: {},
      alert: '',
      checkedData: [],
      clickedTransaction: '',
      transporter_id: '',
    };
    this.getTransactionList = this.getTransactionList.bind(this);
    this.verifyEntry = this.verifyEntry.bind(this);
    this.rejectTransaction = this.rejectTransaction.bind(this);
    this.submitOtp = this.submitOtp.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.refreshTransactions = this.refreshTransactions.bind(this);
    this.changeFont = this.changeFont.bind(this);
    this.resendOtpPayable = this.resendOtpPayable.bind(this);
    this.retryTransaction = this.retryTransaction.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.closeDetailedDialog = this.closeDetailedDialog.bind(this);
    this.onConfirmAlert = this.onConfirmAlert.bind(this);
    this.onCancelAlert = this.onCancelAlert.bind(this);
    this.viewTxn = this.viewTxn.bind(this);
  }

  componentDidMount() {
    const role = cookies.get('role');
    if (role !== 'super_admin') {
      this.props.setWalletBalance();
    }
    // this.getTransporterId();
    this.setState({ transactionData: [] });
    this.setState({
      verifyLoader: true,
    });
    setTimeout(() => {
      this.getTransactionList();
    }, 2000);
  }

  getTransporterId() {
    const body = {};
    apiCaller('premium-api/get-transporter', 'post', body, true, false)
      .then(response => {
        if (response.hasOwnProperty('transporter_id')) {
          this.setState({
            transporter_id: response.transporter_id,
          });
        }
      })
      .catch(err => {});
  }

  handleToggle(value, data) {
    const { checked, checkedData } = this.state;
    const dataValue = data;
    const currentDataIndex = checkedData.indexOf(dataValue);
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const selectedData = [...checkedData];
    if (currentDataIndex === -1) {
      selectedData.push(dataValue);
    } else {
      selectedData.splice(currentDataIndex, 1);
    }
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
      checkedData: selectedData,
    });
  }

  viewTxn(data) {
    this.setState({
      currentTransactionData: data,
      showDetailed: true,
    });
  }

  onConfirmAlert(payable) {
    const { classes } = this.props;
    this.setState({
      alert: (
        <SweetAlert
          error
          showCancel
          confirmBtnText="Yes, Reject it!"
          cancelBtnBsStyle="success"
          confirmBtnBsStyle="danger"
          title="Are you sure you want Reject this transaction?"
          onConfirm={() => this.rejectTransaction(payable)}
          onCancel={() => this.onCancelAlert()}
          confirmBtnCssClass={`${classes.button} ${classes.danger}`}
          cancelBtnCssClass={`${classes.button} ${classes.success}`}
        />
      ),
    });
  }

  onCancelAlert() {
    this.setState({
      alert: null,
      fromDate: '',
      toDate: '',
    });
  }

  changeFont(value, indexValue) {
    let color;
    let values = value;
    switch (value) {
      case 'queued':
        color = '#d480f2';
        values = 'Queued';
        break;
      case 'payout.initiated':
        color = '#7c78ff';
        values = 'Initiated';
        break;
      case 'otp_verified':
        color = '#87e0ed';
        values = 'Verified';
        break;
      case 'payout.processed':
        color = '#74f285';
        values = 'Processed';
        break;
      case 'payout.processing':
        color = '#0000FF';
        values = 'Processing';
        break;
      case 'user_created':
        color = '#7c78ff';
        values = 'Created';
        break;
      case 'payout.reversed':
        color = '#f44336';
        values = 'Reversed';
        break;
      case 'payout.failed':
        color = '#ba2d36';
        values = 'Failed';
        break;
      case 'admin_rejected':
        color = '#e6303c';
        values = 'Rejected';
        break;
      case 'wallet_failed':
        color = '#e6303c';
        values = 'LOW BALANCE';
        break;
      case 'paas_failed':
        color = '#e6303c';
        values = 'Paas Failed';
        break;
      default:
        color = '#000000';
    }
    return indexValue !== 'number' ? (
      indexValue !== 'date' ? (
        <span style={{ fontSize: '16px', color }}>
          {color !== '#000000' ? values.toUpperCase() : values}
        </span>
      ) : (
        <span style={{ fontSize: '16px', color }}>
          {indexValue === 'date'
            ? moment(value, 'DD-MM-YYYY hh:mm:ss').format('MMM Do')
            : '___'}
        </span>
      )
    ) : (
      <span
        style={{
          fontSize: '16px',
          color: '#0000FF',
          cursor: 'pointer',
          fontWeight: 400,
        }}
      >
        {values}
      </span>
    );
  }

  refreshTransactions() {
    const transactionData = [];
    this.setState({
      transactionData,
      checked: [],
      checkedData: [],
    });
    this.getTransactionList();
  }

  retryTransaction(item) {
    const body = {
      type: 'transaction',
      id: item.payout_id,
    };
    apiCaller(
      'premium-api/retry-id-transaction-account',
      'post',
      body,
      true,
      false
    )
      .then(response => {
        this.setState(
          {
            infoMessage: 'Retry Transaction',
            isLoading: false,
          },
          this.showNotification('infoSnack')
        );
      })
      .catch(err => {
        this.setState(
          {
            errMessage: err.message || 'Oops Something went wrong',
            isLoading: false,
          },
          this.showNotification('errSnack')
        );
      });
  }

  getTransactionList() {
    const role = cookies.get('role');
    this.setState({ verifyLoader: true });
    const transactions = [];
    const transactionData = JSON.parse(JSON.stringify(transactions));
    const body = {
      total_items: 30,
    };
    let nextCursorId = null;
    let path = '';
    if (role !== 'super_admin') {
      path = 'premium-api/read-payout-list';
    } else {
      path = 'premium-api/read-all-premium-payout-list';
      body.from_date = null;
      body.to_date = null;
    }
    apiCaller(path, 'post', body, true, false)
      .then(response => {
        this.setState({
          updateDate: moment
            .unix(response.current_time)
            .format('DD-MM-YYYY hh:mm:ss'),
        });
        if (response.items.length !== 0) {
          response.items.forEach(dataList => {
            if (
              response.hasOwnProperty('LastEvaluatedKey') &&
              response.LastEvaluatedKey !== null
            ) {
              nextCursorId = response.LastEvaluatedKey;
            }
            if (dataList.payin !== true) {
              transactionData.push({
                color: 'success',
                data: [
                  // DD-MM-YYYY hh:mm:ss MMM Do YY
                  moment
                    .unix(dataList.created_at)
                    .format('DD-MM-YYYY hh:mm:ss'),
                  dataList.serial_index || '___',
                  dataList.amount || '___',
                  dataList.account_number || '___',
                  dataList.status || '___',
                  dataList.truck_number || '___',
                  dataList.vendor_name || '___',
                  dataList.service_provider_utr || '___',
                  dataList.premium_transporter_id || '___',
                  dataList.user_id || '___',
                  dataList.reference_number || '___',
                  dataList.from_city || '___',
                  dataList.to_city || '___',
                  dataList.ifsc || '___',
                  dataList.date,
                  dataList.notes || '___',
                ],
                dataList,
              });
            }
          });
          this.props.setTransactions(transactionData, nextCursorId);
          if (
            response.hasOwnProperty('LastEvaluatedKey') &&
            response.LastEvaluatedKey !== null
          ) {
            this.getNextTransactions();
          }
          this.setState({ verifyLoader: false });
        } else {
          this.props.setTransactions([], nextCursorId);
          this.setState({ verifyLoader: false });
        }
      })
      .catch(err => {
        this.setState({ verifyLoader: false });
      });
  }

  getNextTransactions() {
    const role = cookies.get('role');
    const { lastEvaluatedKey, transactions } = this.props;
    const transactionData = JSON.parse(JSON.stringify(transactions));
    const body = {
      total_items: 30,
    };
    if (lastEvaluatedKey !== '' && lastEvaluatedKey !== null) {
      body.LastEvaluatedKey = lastEvaluatedKey;
    }
    let nextCursorId = null;
    let path = '';
    if (role !== 'super_admin') {
      path = 'premium-api/read-payout-list';
    } else {
      path = 'premium-api/read-all-premium-payout-list';
      body.from_date = null;
      body.to_date = null;
    }
    apiCaller(path, 'post', body, true, false)
      .then(response => {
        if (response.items.length !== 0) {
          response.items.forEach(dataList => {
            if (
              response.hasOwnProperty('LastEvaluatedKey') &&
              response.LastEvaluatedKey !== null
            ) {
              nextCursorId = response.LastEvaluatedKey;
            }
            if (dataList.payin !== true) {
              transactionData.push({
                color: 'success',
                data: [
                  moment
                    .unix(dataList.created_at)
                    .format('DD-MM-YYYY hh:mm:ss'),
                  dataList.serial_index || '___',
                  dataList.amount || '___',
                  dataList.account_number || '___',
                  dataList.status || '___',
                  dataList.truck_number || '___',
                  dataList.vendor_name || '___',
                  dataList.service_provider_utr || '___',
                  dataList.premium_transporter_id || '___',
                  dataList.user_id || '___',
                  dataList.reference_number || '___',
                  dataList.from_city || '___',
                  dataList.to_city || '___',
                  dataList.ifsc || '___',
                  dataList.date || '___',
                  dataList.notes || '___',
                ],
                dataList,
              });
            }
          });
          this.props.setTransactions(transactionData, nextCursorId);
        } else {
          this.props.setTransactions(transactionData, nextCursorId);
        }
      })
      .catch(err => {});
  }

  updateWallet(e) {
    const role = cookies.get('role');
    if (role !== 'super_admin') {
      this.props.setWalletBalance();
    }
    e.preventDefault();
  }

  verifyEntry(payableEntry, list) {
    let payable = '';
    if (list !== 'checkbox') {
      this.setState({ clickedTransaction: list });
      payable = [payableEntry];
    }
    this.setState({ verifyLoader: true });
    const user = cookies.get('username');
    const body = {
      user_id: user,
      payout_id: list !== 'checkbox' ? payable : payableEntry,
    };
    apiCaller('util-api/send-premium-payout-otp', 'post', body, true, false)
      .then(response => {
        this.handleClickOpen('classicModal');
        this.setState({
          payoutId: list !== 'checkbox' ? payable : payableEntry,
          verifyLoader: false,
        });
      })
      .catch(err => {
        console.log('Error', err);
        this.setState(
          {
            errMessage: err.message,
            isLoading: false,
            verifyLoader: false,
          },
          this.showNotification('errSnack')
        );
      });
  }

  resendOtpPayable() {
    const { payoutId } = this.state;
    const user = cookies.get('username');
    const userDetails = cookies.get('userDetails');
    const phone = userDetails.phone_number;
    const body = {
      user_id: user,
      payout_id: payoutId,
    };
    apiCaller('util-api/send-premium-payout-otp', 'post', body, true, false)
      .then(response => {
        this.setState(
          {
            infoMessage: `OTP sent to ${phone}`,
            isLoading: false,
          },
          this.showNotification('infoSnack')
        );
      })
      .catch(err => {
        this.setState(
          {
            errMessage: err.message,
            isLoading: false,
          },
          this.showNotification('errSnack')
        );
      });
  }

  rejectTransaction(payableEntry) {
    const user = cookies.get('username');
    const body = {
      user_id: user,
      payout_id: payableEntry,
      status: 'admin_rejected',
    };
    apiCaller('premium-api/verify-otp', 'post', body, true, false)
      .then(response => {
        this.setState({
          isRejectNotification: true,
          payout_id: [],
          status: '',
          alert: null,
        });
        this.showNotification('isRejectNotification');
        this.refreshTransactions();
      })
      .catch(err => {
        console.log('err', err);
        this.setState(
          {
            errMessage: err.message || 'Oops Something went wrong',
            isLoading: false,
            payout_id: [],
            status: '',
            isRejectNotification: false,
            alert: null,
          },
          this.showNotification('errSnack')
        );
      });
  }

  // eslint-disable-next-line react/sort-comp
  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  cancelPayableOtp(modal) {
    this.setState({
      classicModal: false,
      isLoadingCancel: false,
      isLoadingOtp: false,
      otp: '',
      otpState: '',
      clickedTransaction: '',
    });
  }

  submitOtp() {
    const { otp, payoutId, checkedData } = this.state;
    const user = cookies.get('username');
    const body = {
      user_id: user,
      otp,
      payout_id: payoutId,
    };
    let total_amount = 0;
    checkedData.forEach(data => {
      total_amount += data.amount;
    });
    apiCaller('premium-api/verify-otp', 'post', body, true, false)
      .then(response => {
        if (response) {
          if (response.hasOwnProperty('message')) {
            if (response.message === 'otp/payout id is wrong') {
              this.setState(
                {
                  errMessage: 'Invalid OTP',
                  isLoading: false,
                },
                this.showNotification('errSnack')
              );
            } else if (response.message.includes('success')) {
              ReactGA.event({
                category: 'payout',
                action: 'payout_approve',
                label: 'amount',
                value: total_amount,
              });
              this.showNotification('isAcceptNotification');
              this.handleClose('classicModal');
              this.refreshTransactions();
              this.setState({
                otp: '',
                otpState: '',
                checked: [],
                checkedData: [],
                payout_id: [],
                clickedTransaction: '',
              });
            } else {
              this.setState(
                {
                  errMessage:
                    response.message ||
                    'Some thing went wrong. Please try again',
                  isLoading: false,
                },
                this.showNotification('errSnack')
              );
            }
          }
        } else {
          this.setState(
            {
              errMessage:
                response.message || 'Some thing went wrong. Please try again',
              isLoading: false,
            },
            this.showNotification('errSnack')
          );
        }
      })
      .catch(err => {
        console.log('error', err);
        this.setState(
          {
            errMessage: err.message || 'Oops Something went wrong',
            isLoading: false,
          },
          this.showNotification('errSnack')
        );
      });
  }

  showDialog() {
    const { classes } = this.props;
    const {
      classicModal,
      name,
      otp,
      otpState,
      isLoadingOtp,
      isLoadingCancel,
      otpError,
      isOtpNotification,
      checkedData,
    } = this.state;
    return (
      <Dialog
        classes={{
          root: `${classes.center}`,
          // paper: classes.modal,
        }}
        open={classicModal}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <p>
            Transactions:{' '}
            {checkedData.length > 0 ? (
              checkedData.map(value => <b>#{value.serial_index}, </b>)
            ) : (
              <b>#{this.state.clickedTransaction}</b>
            )}
          </p>
          <h4 className={classes.modalTitle}>{name} Please Enter OTP</h4>
          <Snackbar
            place="tc"
            color="info"
            icon={AddAlert}
            message="OTP sent"
            open={isOtpNotification}
            closeNotification={() =>
              this.setState({ isOtpNotification: false })
            }
            close
          />
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
              <CustomInput
                labelText="Enter Otp"
                id="truck-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event => this.change(event, 'otp', 'otp', 5),
                  type: 'length',
                  value: otp,
                }}
                success={otpState === 'success'}
                error={otpState === 'error'}
              />
              <Button
                color="info"
                simple
                onClick={() => this.resendOtpPayable()}
              >
                Resend OTP
              </Button>
            </GridItem>
            <p style={{ color: 'red' }}>{otpError}</p>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {isLoadingOtp || isLoadingCancel ? (
            <div>
              <CircularProgress
                className={classes.progress}
                style={{ color: purple[500] }}
                thickness={7}
              />
            </div>
          ) : (
            <div>
              <Button onClick={() => this.cancelPayableOtp('classicModal')}>
                Cancel
              </Button>
              <Button onClick={() => this.submitOtp()} color="success">
                Submit OTP
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  renderButtons() {
    const { classes } = this.props;
    return (
      <>
        <Button
          simple
          size="sm"
          color="success"
          onClick={() => this.verifyEntry()}
          className={classes.actionButton}
        >
          Approve
        </Button>
        <Button
          simple
          size="sm"
          color="danger"
          className={classes.actionButton}
          onClick={() => this.rejectTransaction()}
        >
          Reject
        </Button>
      </>
    );
  }

  /**
   * will return the notification snackbar
   * @param {string} place
   */
  showNotification(place) {
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 5000);
    }
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  verifyOtp(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value) && value.toString().length === 5) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'otp':
        if (this.verifyOtp(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        this.setState({
          [`${stateName}State`]: 'success',
          [stateName]: event.target.value,
        });
        break;
    }
  }

  closeDetailedDialog() {
    this.setState({
      showDetailed: false,
      currentTransactionData: {},
    });
  }

  showDetailedTransaction() {
    const { classes } = this.props;
    const { showDetailed, currentTransactionData } = this.state;
    const {
      status,
      reference_number,
      to_city,
      from_city,
      account_no,
      truck_no,
      ifsc,
      date,
      ref_date,
      notes,
      amount,
      vendor_name,
      transaction_number,
    } = currentTransactionData;
    return (
      <Dialog
        classes={{
          root: `${classes.center}`,
          // paper: classes.modal,
        }}
        open={showDetailed}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        fullWidth
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4
            className={classes.modalTitle}
            style={{
              textAlign: 'center',
              fontWeight: 500,
              marginLeft: '2px',
            }}
          >
            # {transaction_number}
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <b>Amount: </b>{' '}
              <span
                style={{
                  textAlign: 'right',
                  fontWeight: 500,
                  marginLeft: '2px',
                }}
              >
                <i
                  className="fa fa-inr"
                  style={{ fontSize: '15px' }}
                  aria-hidden="true"
                />{' '}
                {amount || ' ---'}
              </span>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <b>Status: </b> {this.changeFont(status, 'status') || ' ---'}
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <b>Date: </b>{' '}
              {moment(date, 'DD-MM-YYYY hh:mm:ss').format('DD-MM-YYYY')}
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <b>From : </b> {from_city || ' ---'}
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <b>To: </b> {to_city || ' ---'}
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <b>Acc No: </b> {account_no || ' ---'}
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <b>Vendor: </b> {vendor_name || ' ---'}
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <b>Truck No: </b> {truck_no || ' ---'}
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <b>Ifsc: </b> {ifsc || ' ---'}
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <b>Reference: </b> {reference_number || ' ---'}
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <b>Ref Date: </b>{' '}
              {ref_date === undefined
                ? '---'
                : moment(ref_date).format('DD-MM-YYYY')}
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <b>Notes: </b> {notes || ' ---'}
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <div>
            <Button
              color="success"
              onClick={() => this.closeDetailedDialog('showDetailed')}
            >
              OK
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { classes } = this.props;
    const {
      isAcceptNotification,
      isRejectNotification,
      verifyLoader,
      alert,
    } = this.state;
    const {
      errSnack,
      errMessage,
      infoMessage,
      infoSnack,
      checkedData,
    } = this.state;
    const user = cookies.get('role');
    const walletBalance = Number(this.props.walletBalance);
    return (
      <GridContainer>
        {alert}
        {this.showDialog()}
        {this.showDetailedTransaction()}
        <GridItem xs={12}>
          <Snackbar
            place="tc"
            color="success"
            icon={AddAlert}
            message="Entry Accepted Successfully"
            open={isAcceptNotification}
            closeNotification={() =>
              this.setState({ isAcceptNotification: false })
            }
            close
          />
          <Snackbar
            place="tc"
            color="success"
            icon={AddAlert}
            message="Rejected Successfully"
            open={isRejectNotification}
            closeNotification={() =>
              this.setState({ isRejectNotification: false })
            }
            close
          />
          <Snackbar
            place="tc"
            color="success"
            icon={AddAlert}
            message={infoMessage || ''}
            open={infoSnack}
            closeNotification={() => this.setState({ infoSnack: false })}
            close
          />
          <Snackbar
            place="tc"
            color="danger"
            icon={AddAlert}
            message={errMessage || ''}
            open={errSnack}
            closeNotification={() => this.setState({ errSnack: false })}
            close
          />
          <Card>
            <CardHeader color="info">
              <CardIcon color="warning">
                <Assignment />
              </CardIcon>
              <h5
                className={classes.cardTitle}
                style={{ color: '#FFF', fontWeight: '400', fontSize: '28px' }}
              >
                Transactions
              </h5>
              <Refresh
                onClick={() => this.refreshTransactions()}
                style={{
                  float: 'right',
                  top: '0px',
                  cursor: 'pointer',
                  fontSize: 30,
                  marginTop: '20px',
                }}
              />
              <h4
                style={{
                  float: 'right',
                  top: '0px',
                  marginRight: '15px',
                  marginTop: '20px',
                }}
              >
                {this.state.updateDate}
              </h4>
            </CardHeader>
            <CardBody>
              <LoadingOverlay
                active={verifyLoader}
                spinner
                text="Loading Transactions..."
              >
                {user !== 'maker' && user !== 'super_admin' ? (
                  <GridContainer justify="center">
                    <Button
                      size="lg"
                      color="success"
                      onClick={() =>
                        this.verifyEntry(this.state.checked, 'checkbox')
                      }
                      className={classes.actionButton}
                      disabled={this.state.checked.length <= 0}
                    >
                      Approve
                    </Button>
                  </GridContainer>
                ) : (
                  ''
                )}
                {this.props.transactions.length <= 0 ? (
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <p
                      className={classes.textCenter}
                      style={{
                        textAlign: 'center',
                        fontWeight: 500,
                        fontSize: '16px',
                      }}
                    >
                      {!verifyLoader &&
                        'You don’t seem to have any transactions, time to make your first payment'}
                    </p>
                  </GridItem>
                ) : (
                  <ReactTable
                    getTheadThProps={() => ({
                      style: {
                        outline: 0,
                        color: 'black',
                        fontSize: '18px',
                        fontWeight: '400',
                        textAlign: 'left',
                      },
                    })}
                    PaginationComponent={Pagination}
                    data={this.props.transactions.map((prop, key) => ({
                      checkbox: (prop.data[4] === 'user_created' ||
                        prop.data[4] === 'wallet_failed') &&
                        Number(prop.data[2]) <= walletBalance &&
                        user !== 'maker' && (
                          <Checkbox
                            key={prop.dataList.payout_id}
                            className={classes.positionAbsolute}
                            tabIndex={-1}
                            id={prop.dataList.payout_id}
                            onClick={() =>
                              this.handleToggle(
                                prop.dataList.payout_id,
                                prop.dataList
                              )
                            }
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                            checked={this.state.checked.includes(
                              prop.dataList.payout_id
                            )}
                          />
                        ),
                      id: key,
                      transaction_number: prop.data[1],
                      amount: prop.data[2],
                      account_no: prop.data[3],
                      vendor_name: prop.data[6],
                      truck_no: prop.data[5],
                      date: prop.data[0],
                      utr: prop.data[7],
                      transporter: prop.data[8],
                      user_id: prop.data[9],
                      status: prop.data[4],
                      reference_number: prop.data[10],
                      from_city: prop.data[11],
                      to_city: prop.data[12],
                      ifsc: prop.data[13],
                      ref_date: prop.data[14],
                      notes: prop.data[15],
                      actions: (
                        <GridContainer>
                          {user !== 'maker' ? (
                            <>
                              {Number(prop.data[2]) <= walletBalance ? (
                                <GridItem xs={12} sm={4} md={4}>
                                  {prop.data[4] === 'user_created' ? (
                                    checkedData.length === 0 ? (
                                      <Tooltip title="Approve" arrow>
                                        <Button
                                          justIcon
                                          round
                                          simple
                                          color="success"
                                          className="approve"
                                          onClick={() =>
                                            this.verifyEntry(
                                              prop.dataList.payout_id,
                                              prop.dataList.serial_index
                                            )
                                          }
                                        >
                                          <ThumbUp />
                                        </Button>
                                      </Tooltip>
                                    ) : (
                                      ''
                                    )
                                  ) : (
                                    ' '
                                  )}
                                </GridItem>
                              ) : (
                                ''
                              )}
                              {prop.data[4] === 'user_created' ? (
                                <GridItem xs={12} sm={4} md={4}>
                                  <Tooltip title="Reject" arrow>
                                    <Button
                                      justIcon
                                      round
                                      simple
                                      color="danger"
                                      className="reject"
                                      onClick={() =>
                                        this.onConfirmAlert([
                                          `${prop.dataList.payout_id}`,
                                        ])
                                      }
                                    >
                                      <ThumbDown />
                                    </Button>
                                  </Tooltip>{' '}
                                </GridItem>
                              ) : (
                                ''
                              )}
                              {prop.data[4] === 'paas_failed' ? (
                                <GridItem xs={12} sm={4} md={4}>
                                  <Tooltip title="Retry" arrow>
                                    <Button
                                      justIcon
                                      round
                                      simple
                                      color="info"
                                      className="retry"
                                      onClick={() =>
                                        this.retryTransaction(prop.dataList)
                                      }
                                    >
                                      <Refresh />
                                    </Button>
                                  </Tooltip>{' '}
                                </GridItem>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </GridContainer>
                      ),
                    }))}
                    filterable
                    columns={
                      user !== 'super_admin'
                        ? [
                            {
                              Header: '',
                              accessor: 'checkbox',
                              Cell: row =>
                                this.changeFont(row.value, 'checkbox'),
                              sortable: false,
                              filterable: false,
                            },
                            {
                              Header: 'TXN',
                              accessor: 'transaction_number',
                              maxWidth: 85,
                              filterable: false,
                              Cell: row => (
                                <Link
                                  component="button"
                                  variant="body2"
                                  style={{
                                    fontSize: '18px',
                                    fontWeight: '500',
                                  }}
                                  href="#"
                                  onClick={() => this.viewTxn(row.original)}
                                >
                                  {row.value}
                                </Link>
                              ),
                              Filter: ({ filter, onChange }) => (
                                <input
                                  onChange={event =>
                                    onChange(event.target.value)
                                  }
                                  value={filter ? filter.value : ''}
                                  placeholder="Transaction"
                                  style={{
                                    width: '100%',
                                    backgroundColor: 'white',
                                    color: 'black',
                                  }}
                                />
                              ),
                            },
                            {
                              Header: () => (
                                <div
                                  style={{
                                    textAlign: 'right',
                                  }}
                                >
                                  Amount
                                </div>
                              ),
                              accessor: 'amount',
                              filterable: false,
                              Cell: row => (
                                <div
                                  style={{
                                    textAlign: 'right',
                                    fontWeight: 500,
                                  }}
                                >
                                  <i
                                    className="fa fa-inr"
                                    style={{ fontSize: '15px' }}
                                    aria-hidden="true"
                                  />{' '}
                                  {row.value}
                                </div>
                              ),
                              Filter: ({ filter, onChange }) => (
                                <input
                                  onChange={event =>
                                    onChange(event.target.value)
                                  }
                                  value={filter ? filter.value : ''}
                                  placeholder="Amount"
                                  style={{
                                    width: '100%',
                                    backgroundColor: 'white',
                                    color: 'black',
                                    textAlign: 'right',
                                  }}
                                />
                              ),
                              sortable: false,
                            },
                            {
                              Header: 'Acc No',
                              accessor: 'account_no',
                              filterable: false,
                              Cell: row =>
                                this.changeFont(row.value, 'acctn_no'),
                            },
                            {
                              Header: 'Vendor',
                              accessor: 'vendor_name',
                              Cell: row => this.changeFont(row.value, 'vendor'),
                              filterable: false,
                              Filter: ({ filter, onChange }) => (
                                <input
                                  onChange={event =>
                                    onChange(event.target.value)
                                  }
                                  value={filter ? filter.value : ''}
                                  placeholder="Vendor Name"
                                  style={{
                                    width: '100%',
                                    backgroundColor: 'white',
                                    color: 'black',
                                  }}
                                />
                              ),
                            },
                            {
                              Header: 'Truck No',
                              accessor: 'truck_no',
                              Cell: row =>
                                this.changeFont(row.value, 'truck_no'),
                              sortable: false,
                              filterable: false,
                            },
                            {
                              Header: 'Date',
                              accessor: 'date',
                              Cell: row => (
                                <Tooltip
                                  title={
                                    <span style={{ fontSize: '15px' }}>
                                      {row.value}
                                    </span>
                                  }
                                  arrow
                                >
                                  {this.changeFont(row.value, 'date')}
                                </Tooltip>
                              ),
                              sortable: false,
                              filterable: false,
                            },
                            {
                              Header: 'UTR',
                              accessor: 'utr',
                              Cell: row => this.changeFont(row.value, 'utr'),
                              sortable: false,
                              filterable: false,
                            },
                            {
                              Header: 'Status',
                              accessor: 'status',
                              Cell: row => (
                                <div style={{ fontWeight: 500 }}>
                                  {this.changeFont(row.value, 'status')}
                                </div>
                              ),
                              Filter: ({ filter, onChange }) => (
                                <input
                                  onChange={event =>
                                    onChange(event.target.value)
                                  }
                                  value={filter ? filter.value : ''}
                                  placeholder="Status"
                                  style={{
                                    width: '100%',
                                    backgroundColor: 'white',
                                    color: 'black',
                                  }}
                                />
                              ),
                              filterable: false,
                            },
                            {
                              Header: 'Created By',
                              accessor: 'user_id',
                              Cell: row =>
                                this.changeFont(row.value, 'user_id'),
                              sortable: false,
                              filterable: false,
                            },
                            {
                              Header: '',
                              accessor: 'actions',
                              Cell: row => (
                                <div
                                  style={{
                                    textAlign: 'left',
                                  }}
                                >
                                  {row.value}
                                </div>
                              ),
                              sortable: false,
                              filterable: false,
                              minWidth: 150,
                            },
                          ]
                        : [
                            {
                              filterable: false,
                              Header: 'TXN',
                              accessor: 'transaction_number',
                              Cell: row => (
                                <Link
                                  component="button"
                                  variant="body2"
                                  style={{
                                    fontSize: '18px',
                                    fontWeight: '500',
                                  }}
                                  href="#"
                                  onClick={() => this.viewTxn(row.original)}
                                >
                                  {row.value}
                                </Link>
                              ),
                              Filter: ({ filter, onChange }) => (
                                <input
                                  onChange={event =>
                                    onChange(event.target.value)
                                  }
                                  value={filter ? filter.value : ''}
                                  placeholder="Transaction"
                                  style={{
                                    width: '100%',
                                    backgroundColor: 'white',
                                    color: 'black',
                                  }}
                                />
                              ),
                            },
                            {
                              Header: () => (
                                <div
                                  style={{
                                    textAlign: 'right',
                                  }}
                                >
                                  Amount
                                </div>
                              ),
                              accessor: 'amount',
                              filterable: false,
                              Cell: row => (
                                <div
                                  style={{
                                    textAlign: 'right',
                                    fontWeight: 500,
                                  }}
                                >
                                  <i
                                    className="fa fa-inr"
                                    style={{ fontSize: '15px' }}
                                    aria-hidden="true"
                                  />{' '}
                                  {row.value}
                                </div>
                              ),
                              Filter: ({ filter, onChange }) => (
                                <input
                                  onChange={event =>
                                    onChange(event.target.value)
                                  }
                                  value={filter ? filter.value : ''}
                                  placeholder="Amount"
                                  style={{
                                    width: '100%',
                                    backgroundColor: 'white',
                                    color: 'black',
                                    textAlign: 'right',
                                  }}
                                />
                              ),
                              sortable: false,
                            },
                            {
                              Header: 'Acc No',
                              accessor: 'account_no',
                              filterable: false,
                              Cell: row =>
                                this.changeFont(row.value, 'accnt_no'),
                              Filter: ({ filter, onChange }) => (
                                <input
                                  onChange={event =>
                                    onChange(event.target.value)
                                  }
                                  value={filter ? filter.value : ''}
                                  placeholder="Accnt no"
                                  style={{
                                    width: '100%',
                                    backgroundColor: 'white',
                                    color: 'black',
                                  }}
                                />
                              ),
                            },
                            {
                              Header: 'Vendor',
                              accessor: 'vendor_name',
                              filterable: false,
                              Cell: row => this.changeFont(row.value, 'vendor'),
                              Filter: ({ filter, onChange }) => (
                                <input
                                  onChange={event =>
                                    onChange(event.target.value)
                                  }
                                  value={filter ? filter.value : ''}
                                  placeholder="Vendor Name"
                                  style={{
                                    width: '100%',
                                    backgroundColor: 'white',
                                    color: 'black',
                                  }}
                                />
                              ),
                            },
                            {
                              Header: 'Truck No',
                              accessor: 'truck_no',
                              Cell: row =>
                                this.changeFont(row.value, 'truck_no'),
                              sortable: false,
                              filterable: false,
                            },
                            {
                              Header: 'Date',
                              accessor: 'date',
                              Cell: row => (
                                <Tooltip
                                  title={
                                    <span style={{ fontSize: '15px' }}>
                                      {row.value}
                                    </span>
                                  }
                                  arrow
                                >
                                  {this.changeFont(row.value, 'date')}
                                </Tooltip>
                              ),
                              sortable: false,
                              filterable: false,
                            },
                            {
                              Header: 'UTR',
                              accessor: 'utr',
                              Cell: row => this.changeFont(row.value, 'utr'),
                              sortable: false,
                              filterable: false,
                            },
                            {
                              Header: 'Transporter',
                              accessor: 'transporter',
                              filterable: false,
                              Cell: row =>
                                this.changeFont(row.value, 'transporter'),
                              Filter: ({ filter, onChange }) => (
                                <input
                                  onChange={event =>
                                    onChange(event.target.value)
                                  }
                                  value={filter ? filter.value : ''}
                                  placeholder="Transporter"
                                  style={{
                                    width: '100%',
                                    backgroundColor: 'white',
                                    color: 'black',
                                  }}
                                />
                              ),
                            },
                            {
                              Header: 'Status',
                              accessor: 'status',
                              Cell: row => (
                                <div
                                  style={{ textAlign: 'left', fontWeight: 500 }}
                                >
                                  {this.changeFont(row.value, 'status')}
                                </div>
                              ),
                              sortable: false,
                              filterable: false,
                              Filter: ({ filter, onChange }) => (
                                <input
                                  onChange={event =>
                                    onChange(event.target.value)
                                  }
                                  value={filter ? filter.value : ''}
                                  placeholder="Status"
                                  style={{
                                    width: '100%',
                                    backgroundColor: 'white',
                                    color: 'black',
                                  }}
                                />
                              ),
                            },
                          ]
                    }
                    minRows={0}
                    defaultPageSize={30}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight tableFont"
                    customClassesForCells={[1, 2, 3, 4, 5, 6]}
                    // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
                    customHeadClassesForCells={[0, 4, 5]}
                  />
                )}
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

Transactions.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setWalletBalance,
      setTransactions,
    },
    dispatch
  );

const mapStateToProps = state => ({
  walletBalance: state.main.walletBalance,
  transactions: state.transactions.transactions,
  lastEvaluatedKey: state.transactions.lastEvaluatedKey,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Transactions));
