/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-prototype-builtins */
/**
 * Global API Caller function
 * @function
 * @param {RequestBody} => {formData for The APi}
 */

import Cookies from 'universal-cookie';
import { checkAuth, setAuthCookies, refreshToken } from '../services';

import history from '../history';

const cookies = new Cookies();

// export let API_URL = 'https://api.lobb.in';
export let API_URL =
  process.env.REACT_APP_API_PROD === 'true'
    ? 'https://api.lobb.in'
    : 'https://api-dev.lobb.in';

export default function apiCaller(
  endpoint,
  method = 'get',
  body,
  authorization = false,
  isForm = false,
  isFirebase = false
) {
  if (isFirebase) {
    API_URL = 'https://us-central1-lobb-office.cloudfunctions.net';
  }
  return new Promise((resolve, reject) => {
    let headers = {
      Accept: 'application/json, */*',
    };
    if (authorization) {
      headers = {
        ...headers,
        Authorization: `${cookies.get('idToken') || window.idToken}`,
      };
    }
    let options = {
      headers,
      method,
    };

    const data = { ...body };

    if (method !== 'get' && !isForm) {
      options = {
        ...options,
        body: JSON.stringify(data),
      };
    }
    if (isForm) {
      const form = new FormData();
      Object.keys(body).forEach(key => {
        form.append(key, body[key]);
      });
      options = {
        ...options,
        body: form,
      };
    }

    return fetch(`${API_URL}/${endpoint}`, options)
      .then(
        response => {
          if (response.status === 401) {
            refreshToken();
            checkAuths();
            reject(response.status);
          } else {
            resolve(response.json());
          }
        },
        err => {
          refreshToken();
          console.log('ER', err);
          reject(err);
        }
      )
      .catch(error => {
        // if(error.message === 'The')
        console.log('Error', error);
        refreshToken();
        reject(error);
      });
  });
}

function checkAuths() {
  checkAuth()
    .then(result => {
      setAuthCookies(result);
    })
    .catch(err => {
      const result = {
        isAuthenticated: false,
        role: '',
        activeRole: '',
        userDetails: {
          name: '',
          email: '',
          phoneNumber: '',
        },
        idToken: '',
        accessToken: '',
        refreshToken: '',
        username: '',
        userPoolId: '',
      };
      history.push('/pages/initial-page');
      setAuthCookies(result);
    });
}
