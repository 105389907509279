/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable func-names */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */
/* eslint-disable camelcase */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons
import Refresh from '@material-ui/icons/Refresh';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import classNames from 'classnames';
import CardHeader from 'components/Card/CardHeader.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import CardFooter from 'components/Card/CardFooter.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import Select from 'react-select';
import CallMade from '@material-ui/icons/CallMade';
import AddAlert from '@material-ui/icons/AddAlert';
import Assignment from '@material-ui/icons/Assignment';
import CallReceived from '@material-ui/icons/CallReceived';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import CardIcon from 'components/Card/CardIcon.jsx';
import DateRange from '@material-ui/icons/DateRange';

import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import moment from 'moment';
import Cookie from 'universal-cookie';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import { addAuthtoReduxStore } from '../../reducers/auth';
import { setUserDetails, setWalletBalance } from '../../reducers/main';
import { setWalletList } from '../../reducers/walletList';
import apiCaller from '../../utils/apiCaller';
import Pagination from './Pagination';

const cookies = new Cookie();

class WalletList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction: [],
      transactionArray: [],
      transactionData: [],
      transporters: [],
      transporterId: null,
      transporterName: null,
      errSnack: false,
      errMessage: '',
      account_number: '',
      role: '',
      updateDate: '',
      refreshDate: '',
      fromDate: '',
      toDate: '',
      isFilter: false,
      checksAlert: false,
      epocFromDate: '',
      epocToDate: '',

      excelFromDate: '',
      excelToDate: '',
      isDownload: false,
      excelChecksAlert: false,
      excelEocFromDate: '',
      excelEpocToDate: '',
      progressValue: 10,
      progressBuffer: 15,
      isChangeColor: false,
    };
    this.getTransactionList = this.getTransactionList.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.refreshWallet = this.refreshWallet.bind(this);
    this.getBankDetails = this.getBankDetails.bind(this);
    this.changeFont = this.changeFont.bind(this);
    this.retryClientWallet = this.retryClientWallet.bind(this);
    this.refreshClientWallet = this.refreshClientWallet.bind(this);
    this.showChecksAlert = this.showChecksAlert.bind(this);
    this.closeChecksAlert = this.closeChecksAlert.bind(this);
    this.confirmDates = this.confirmDates.bind(this);
    this.fromDateChange = this.fromDateChange.bind(this);
    this.toDateChange = this.toDateChange.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.getTransporterList = this.getTransporterList.bind(this);
    this.excelShowChecksAlert = this.excelShowChecksAlert.bind(this);
    this.excelCloseChecksAlert = this.excelCloseChecksAlert.bind(this);
    this.excelConfirmDates = this.excelConfirmDates.bind(this);
    this.excelFromDateChange = this.excelFromDateChange.bind(this);
    this.excelToDateChange = this.excelToDateChange.bind(this);
  }

  componentDidMount() {
    const role = cookies.get('role');
    this.setState({ role });
    // this.getTransactionList();
    if (role === 'super_admin') {
      this.getTransporterList();
    } else {
      this.getBankDetails();
    }
  }

  getTransporterList() {
    const body = {
      total_items: 200,
    };
    const { transporters } = this.state;
    apiCaller(
      'premium-api/read-premium-transporter-list',
      'post',
      body,
      true,
      false
    )
      .then(response => {
        response.data.items.forEach(transporterData => {
          transporters.push({
            name: transporterData.company_name,
            label: `${transporterData.company_user_id}`,
            transporterId: transporterData.user_id,
            value: transporterData.user_id,
            transporterName: transporterData.company_name,
          });
        });
        if (response.data.hasOwnProperty('LastEvaluatedKey')) {
          if (response.data.LastEvaluatedKey !== null) {
            this.setState(
              {
                transporters: transporters.sort((a, b) =>
                  a.transporterName.localeCompare(b.transporterName)
                ),
              },
              () =>
                this.getTransporterListWithLastKey(
                  response.data.LastEvaluatedKey
                )
            );
          } else {
            this.setState({
              transporters: transporters.sort((a, b) =>
                a.transporterName.localeCompare(b.transporterName)
              ),
            });
          }
        } else {
          this.setState({
            transporters: transporters.sort((a, b) =>
              a.transporterName.localeCompare(b.transporterName)
            ),
          });
        }
      })
      .catch(err => {
        this.setState(
          {
            errMessage: err.message,
            isLoading: false,
          },
          this.showNotification('errSnack')
        );
      });
  }

  getTransporterListWithLastKey(LastEvaluatedKey) {
    const body = {
      total_items: 200,
      LastEvaluatedKey,
    };
    const { transporters } = this.state;
    apiCaller(
      'premium-api/read-premium-transporter-list',
      'post',
      body,
      true,
      false
    )
      .then(response => {
        response.data.items.forEach(transporterData => {
          transporters.push({
            name: transporterData.company_name,
            label: `${transporterData.company_user_id}`,
            transporterId: transporterData.user_id,
            value: transporterData.user_id,
            transporterName: transporterData.company_name,
          });
        });
        if (response.data.hasOwnProperty('LastEvaluatedKey')) {
          if (response.data.LastEvaluatedKey !== null) {
            this.setState(
              {
                transporters: transporters.sort((a, b) =>
                  a.transporterName.localeCompare(b.transporterName)
                ),
              },
              () =>
                this.getTransporterListWithLastKey(
                  response.data.LastEvaluatedKey
                )
            );
          } else {
            this.setState({
              transporters: transporters.sort((a, b) =>
                a.transporterName.localeCompare(b.transporterName)
              ),
            });
          }
        } else {
          this.setState({
            transporters: transporters.sort((a, b) =>
              a.transporterName.localeCompare(b.transporterName)
            ),
          });
        }
      })
      .catch(err => {
        this.setState(
          {
            errMessage: err.message,
            isLoading: false,
          },
          this.showNotification('errSnack')
        );
      });
  }

  changeFont(value, indexValue) {
    let color;
    let values = value;
    switch (value) {
      case 'queued':
        color = '#d480f2';
        values = 'Queued';
        break;
      case 'otp_verified':
        color = '#87e0ed';
        values = 'Verified';
        break;
      case 'payout.processed':
        color = '#74f285';
        values = 'Processed';
        break;
      case 'payout.processing':
        color = '#0000FF';
        values = 'Processing';
        break;
      case 'user_created':
        color = '#7c78ff';
        values = 'Created';
        break;
      case 'payout.reversed':
        color = '#79f299';
        values = 'Reversed';
        break;
      case 'payout.failed':
        color = '#ba2d36';
        values = 'Failed';
        break;
      case 'admin_rejected':
        color = '#e6303c';
        values = 'Rejected';
        break;
      case 'wallet_failed':
        color = '#e6303c';
        values = 'LOW BALANCE';
        break;
      case 'Paas_failed':
        color = '#e6303c';
        values = 'Paas Failed';
        break;
      case 'Account Recharged':
        color = '#7c78ff';
        values = 'Account Recharged';
        break;
      default:
        color = '#000000';
    }
    return indexValue !== 'date' ? (
      <span style={{ fontSize: '16px', color }}>
        {color !== '#000000' ? values.toUpperCase() : values}
      </span>
    ) : (
      <span style={{ fontSize: '16px', color }}>
        {indexValue === 'date'
          ? moment(value, 'DD-MM-YYYY hh:mm:ss').format('MMM Do')
          : '___'}
      </span>
    );
  }

  refreshWallet() {
    this.setState({
      transactionData: [],
    });
    cookies.remove('epocFromDate');
    cookies.remove('epocToDate');
    cookies.remove('transporterId');
    this.resetFilter();
    // this.getTransactionList();
  }

  refreshClientWallet() {
    this.retryClientWallet();
  }

  retryClientWallet() {
    const body = {
      type: 'virtual_account',
    };
    apiCaller(
      'premium-api/retry-id-transaction-account',
      'post',
      body,
      true,
      false
    )
      .then(response => {
        if (response.message !== 'virtual account not found') {
          this.setState(
            {
              infoMessage: 'Account retrieve Successfully',
              isLoading: false,
            },
            this.showNotification('infoSnack')
          );
        }
      })
      .catch(err => {
        console.log('error', err);
        this.setState(
          {
            errMessage: err.message || 'Oops Something went wrong',
            isLoading: false,
          },
          this.showNotification('errSnack')
        );
      });
  }

  showChecksAlert() {
    this.setState({
      checksAlert: true,
      checksError: '',
    });
  }

  closeChecksAlert() {
    this.setState({
      checksAlert: false,
      checksError: '',
      selectedTransporter: '',
      transporterId: '',
      fromDate: '',
      toDate: '',
      // selectedTrucker: null,
    });
  }

  fromDateChange(fromDate) {
    this.setState({ fromDate });
  }

  toDateChange(toDate) {
    this.setState({ toDate });
  }

  excelShowChecksAlert() {
    this.setState({
      excelChecksAlert: true,
      excelchecksError: '',
    });
  }

  excelCloseChecksAlert() {
    this.setState({
      excelChecksAlert: false,
      excelchecksError: '',
      selectedTransporter: '',
      excelFromDate: '',
      excelToDate: '',
      transporterId: '',
      // selectedTrucker: null,
    });
  }

  excelFromDateChange(excelFromDate) {
    this.setState({ excelFromDate });
  }

  excelToDateChange(excelToDate) {
    this.setState({ excelToDate });
  }

  excelModalWindow() {
    const { classes } = this.props;
    const {
      excelFromDate,
      excelToDate,
      transporterId,
      isDownload,
      progressValue,
      progressBuffer,
      selectedTransporter,
      transporters,
      role,
    } = this.state;
    const yesterday = Datetime.moment().subtract(0, 'day');
    const valid = function(current) {
      return current.isBefore(yesterday);
    };
    const tomorrow = Datetime.moment(excelFromDate).subtract(1, 'day');
    const validat = function(current) {
      if (current.isAfter(tomorrow) && current.isBefore(yesterday)) {
        return true;
      }
    };
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <SweetAlert
            showCancel
            confirmBtnText="Download"
            title=""
            onConfirm={this.excelConfirmDates}
            onCancel={this.excelCloseChecksAlert}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            style={{ height: '80%' }}
          >
            <h3 style={{ marginBottom: '0px' }}>
              <span className={classes.rajdhaniFont}>
                Download Account History
              </span>
            </h3>
            {isDownload ? (
              <LinearProgress
                variant="buffer"
                value={progressValue}
                valueBuffer={progressBuffer}
              />
            ) : (
              ''
            )}
            <GridContainer>
              {role === 'super_admin' ? (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2} />
                  <GridItem
                    xs={12}
                    sm={12}
                    md={8}
                    style={{ marginTop: '20px' }}
                  >
                    <InputLabel className={classes.labelText}>
                      Select Transporter
                    </InputLabel>
                    <Select
                      value={selectedTransporter}
                      onChange={this.handleTransporter}
                      options={transporters}
                      placeholder="Select Transporter"
                      clearable
                    />
                  </GridItem>
                </GridContainer>
              ) : (
                ''
              )}
              {role === 'super_admin' &&
              transporterId !== null &&
              transporterId !== '' ? (
                <>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2} />
                    <GridItem
                      xs={12}
                      sm={12}
                      md={8}
                      style={{ marginTop: '20px' }}
                    >
                      <InputLabel className={classes.label}>
                        From Date *
                      </InputLabel>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          onChange={this.excelFromDateChange}
                          value={excelFromDate}
                          dateFormat="DD-MM-YYYY"
                          timeFormat={false}
                          inputProps={{ placeholder: 'Select Date' }}
                          isValidDate={valid}
                          required
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2} />
                    <GridItem
                      xs={12}
                      sm={12}
                      md={8}
                      style={{ marginTop: '20px' }}
                    >
                      <InputLabel className={classes.label}>
                        To Date *
                      </InputLabel>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          onChange={this.excelToDateChange}
                          value={excelToDate}
                          dateFormat="DD-MM-YYYY"
                          timeFormat={false}
                          inputProps={{ placeholder: 'Select Date' }}
                          isValidDate={validat}
                          required
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </>
              ) : (
                ''
              )}
              {role === 'admin' ? (
                <>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2} />
                    <GridItem
                      xs={12}
                      sm={12}
                      md={8}
                      style={{ marginTop: '20px' }}
                    >
                      <InputLabel className={classes.label}>
                        From Date *
                      </InputLabel>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          onChange={this.excelFromDateChange}
                          value={excelFromDate}
                          dateFormat="DD-MM-YYYY"
                          timeFormat={false}
                          inputProps={{ placeholder: 'Select Date' }}
                          isValidDate={valid}
                          required
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2} />
                    <GridItem
                      xs={12}
                      sm={12}
                      md={8}
                      style={{ marginTop: '20px' }}
                    >
                      <InputLabel className={classes.label}>
                        To Date *
                      </InputLabel>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          onChange={this.excelToDateChange}
                          value={excelToDate}
                          dateFormat="DD-MM-YYYY"
                          timeFormat={false}
                          inputProps={{ placeholder: 'Select Date' }}
                          isValidDate={validat}
                          required
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </>
              ) : (
                ''
              )}
            </GridContainer>
            <Snackbar
              place="tc"
              color="danger"
              icon={AddAlert}
              message="Select From and To date."
              open={this.state.tc}
              closeNotification={() => this.setState({ tc: false })}
              close
            />
          </SweetAlert>
        </GridItem>
      </GridContainer>
    );
  }

  excelConfirmDates() {
    const { excelFromDate, excelToDate } = this.state;
    if (excelFromDate !== '' && excelToDate === '') {
      this.setState(
        {
          errMessage: 'Please fill To date',
          isLoading: false,
          excelChecksAlert: true,
        },
        this.showNotification('errSnack')
      );
    } else if (excelFromDate === '' && excelToDate !== '') {
      this.setState(
        {
          errMessage: 'Please fill From date',
          isLoading: false,
          excelChecksAlert: true,
        },
        this.showNotification('errSnack')
      );
    } else if (excelFromDate === '' && excelToDate === '') {
      this.setState(
        {
          errMessage: 'Please fill From and To date',
          isLoading: false,
          excelChecksAlert: true,
        },
        this.showNotification('errSnack')
      );
    } else {
      this.setState(
        {
          // isFilter: true,
          isDownload: true,
          excelChecksAlert: true,
          excelchecksError: '',
        },
        () => this.downloadExcel('click')
      );
    }
  }

  downloadExcel(value) {
    const role = cookies.get('role');
    const adminTransporterId = cookies.get('username');
    const { excelFromDate, excelToDate, transporterId } = this.state;
    let startDate = '';
    let endDate = '';
    let excelEpocFromDate = '';
    let excelEpocToDate = '';
    let fromDates = '';
    let toDates = '';
    // To restrict the dates to be automatically taken
    if (excelFromDate === '') {
      startDate = null;
    } else {
      startDate = new Date(excelFromDate);
      fromDates = startDate.setHours(0, 0, 0, 0);
    }
    if (excelToDate === '') {
      endDate = null;
    } else {
      endDate = new Date(excelToDate);
      toDates = endDate.setHours(24, 0, 0, 0);
    }
    // const start = new Date(startDate);
    excelEpocFromDate = moment(fromDates).unix();
    excelEpocToDate = moment(toDates).unix();
    this.setState({
      excelEpocFromDate,
      excelEpocToDate,
    });
    let transporterValues = '';
    if (role === 'admin') {
      transporterValues = adminTransporterId;
    } else {
      transporterValues = transporterId;
    }
    const body = {
      transporter_id: transporterValues,
      from_date: excelEpocFromDate,
      to_date: excelEpocToDate,
    };
    const pollingValue = false;
    if (value === 'resend') {
      body.polling = pollingValue;
    } else {
      body.polling = true;
    }
    apiCaller(
      'export-api/premium-transporter-export',
      'post',
      body,
      true,
      false
    )
      .then(response => {
        if (response.found === false) {
          this.setState({
            excelChecksAlert: true,
            isDownload: true,
          });
          this.resendRequest('resend');
        } else {
          this.setState({
            progressValue: 100,
            progressBuffer: 100,
          });
          window.location.href = response.url;
          setTimeout(() => {
            this.setState({
              excelChecksAlert: false,
              isDownload: false,
              excelFromDate: '',
              exceltoDate: '',
              progressValue: 10,
              progressBuffer: 15,
            });
          }, 1000);
        }
      })
      .catch(err => {
        this.setState(
          {
            errMessage: err.message || 'Oops Something went wrong',
            isLoading: false,
            excelChecksAlert: true,
          },
          this.showNotification('errSnack')
        );
      });
  }

  resendRequest(value) {
    const { progressValue, progressBuffer } = this.state;
    const adding = 2;
    setTimeout(() => {
      this.setState({
        progressValue: Number(progressValue + adding),
        progressBuffer: Number(progressBuffer + adding),
      });
      this.downloadExcel(value);
    }, 2000);
  }

  getBankDetails() {
    const body = {};
    apiCaller(
      'premium-api/client-check-wallet-balance',
      'post',
      body,
      true,
      false
    )
      .then(response => {
        this.setState({
          updateDate: moment
            .unix(response.current_time)
            .format('DD-MM-YYYY hh:mm:ss'),
        });
        if (response.data.message !== 'virtual account not found') {
          response.data.receivers.forEach(data => {
            this.setState({
              account_number: data.account_number || '---',
              name: data.name || '---',
              ifsc: data.ifsc || '---',
              bank_name: data.bank_name === null ? '...' : data.bank_name,
            });
          });
        } else {
          this.setState(
            {
              infoMessage: 'Virtual account not Found',
              isLoading: false,
            },
            this.showNotification('infoSnack')
          );
        }
      })
      .catch(err => {
        console.log('err', err);
      });
  }

  handleTransporter = selectedTransporter => {
    this.setState({
      selectedTransporter,
      transporterName: selectedTransporter.transporterName,
      transporterId: selectedTransporter.transporterId,
    });
  };

  getTransactionList() {
    const role = cookies.get('role');
    const { fromDate, toDate, transporterId } = this.state;
    let startDate = '';
    let endDate = '';
    let epocFromDate = '';
    let epocToDate = '';
    let fromDates = '';
    let toDates = '';
    if (role === 'super_admin') {
      // To restrict the dates to be automatically taken
      if (fromDate === '') {
        startDate = null;
      } else {
        startDate = new Date(fromDate);
        fromDates = startDate.setHours(0, 0, 0, 0);
      }
      if (toDate === '') {
        endDate = null;
      } else {
        endDate = new Date(toDate);
        toDates = endDate.setHours(24, 0, 0, 0);
      }
      // const start = new Date(startDate);
      epocFromDate = moment(fromDates).unix();
      epocToDate = moment(toDates).unix();
      this.setState({
        epocFromDate,
        epocToDate,
      });
      cookies.set('fromDate', isNaN(epocFromDate) ? null : epocFromDate);
      cookies.set('toDate', isNaN(epocToDate) ? null : epocToDate);
      cookies.set('transporterId', transporterId === null ? '' : transporterId);
    }
    const body = {
      total_items: 30,
      filter_status: 'payout.processed',
    };
    // const transactionData = [];
    const transactions = [];
    const transactionData = JSON.parse(JSON.stringify(transactions));
    let nextCursorId = null;
    let path = '';
    if (role !== 'super_admin') {
      path = 'premium-api/read-payout-list';
    } else {
      path = 'premium-api/read-all-premium-payout-list';
      body.from_date = isNaN(epocFromDate) ? null : epocFromDate;
      body.to_date = isNaN(epocToDate) ? null : epocToDate;
      if (transporterId !== null && transporterId !== '') {
        body.transporter_id = transporterId === undefined ? '' : transporterId;
      }
    }
    apiCaller(path, 'post', body, true, false)
      .then(response => {
        this.setState({
          refreshDate: moment
            .unix(response.current_time)
            .format('DD-MM-YYYY hh:mm:ss'),
        });
        if (response.items.length !== 0) {
          response.items.forEach(dataList => {
            if (
              response.hasOwnProperty('LastEvaluatedKey') &&
              response.LastEvaluatedKey !== null
            ) {
              nextCursorId = response.LastEvaluatedKey;
            }
            if (
              dataList.payin === true ||
              dataList.status === 'payout.processed'
            ) {
              transactionData.push({
                color: 'success',
                data: [
                  dataList.serial_index === '' ? '....' : dataList.serial_index,
                  dataList.amount,
                  moment
                    .unix(dataList.created_at)
                    .format('DD-MM-YYYY hh:mm:ss'),
                  dataList.status === undefined
                    ? 'Account Recharged'
                    : dataList.status,
                  dataList.payin === undefined ? '' : dataList.payin,
                  dataList.premium_transporter_id,
                ],
                dataList,
              });
            }
          });
          this.props.setWalletList(transactionData, nextCursorId);
          if (
            response.hasOwnProperty('LastEvaluatedKey') &&
            response.LastEvaluatedKey !== null
          ) {
            this.getNextTransactions();
          }
          setTimeout(() => {
            this.setState({ verifyLoader: false });
          }, 1000);
        } else {
          this.props.setWalletList([], nextCursorId);
          this.setState(
            {
              errMessage: 'No Transactions found',
              isLoading: false,
            },
            this.showNotification('errSnack')
          );
        }
      })
      .catch(err => {
        this.setState(
          {
            errMessage: err.message || 'Oops Something went wrong',
            isLoading: false,
          },
          this.showNotification('errSnack')
        );
      });
    if (role !== 'super_admin') {
      this.props.setWalletBalance();
    }
  }

  getNextTransactions() {
    const role = cookies.get('role');
    const { lastEvaluatedKey, listWallet } = this.props;
    const transactionData = JSON.parse(JSON.stringify(listWallet));
    let fromDate = '';
    let toDate = '';
    let transporterId = '';
    if (role === 'super_admin') {
      fromDate = cookies.get('epocFromDate');
      toDate = cookies.get('epocToDate');
      transporterId = cookies.get('transporterId');
    }
    const body = {
      total_items: 30,
      filter_status: 'payout.processed',
    };
    if (lastEvaluatedKey !== '' && lastEvaluatedKey !== null) {
      body.LastEvaluatedKey = lastEvaluatedKey;
    }
    let nextCursorId = null;
    let path = '';
    if (role !== 'super_admin') {
      path = 'premium-api/read-payout-list';
    } else {
      path = 'premium-api/read-all-premium-payout-list';
      body.from_date = isNaN(fromDate) ? null : fromDate;
      body.to_date = isNaN(toDate) ? null : toDate;
      if (transporterId !== '') {
        body.transporter_id = transporterId === undefined ? '' : transporterId;
      }
    }
    apiCaller(path, 'post', body, true, false)
      .then(response => {
        if (response.items.length !== 0) {
          response.items.forEach(dataList => {
            if (
              response.hasOwnProperty('LastEvaluatedKey') &&
              response.LastEvaluatedKey !== null
            ) {
              nextCursorId = response.LastEvaluatedKey;
            }
            if (
              dataList.payin === true ||
              dataList.status === 'payout.processed'
            ) {
              transactionData.push({
                color: 'success',
                data: [
                  dataList.serial_index === '' ? '....' : dataList.serial_index,
                  dataList.amount,
                  moment
                    .unix(dataList.created_at)
                    .format('DD-MM-YYYY hh:mm:ss'),
                  dataList.status === undefined
                    ? 'Account Recharged'
                    : dataList.status,
                  dataList.payin === undefined ? '' : dataList.payin,
                  dataList.premium_transporter_id,
                ],
                dataList,
              });
            }
          });
          this.props.setWalletList(transactionData, nextCursorId);
        } else {
          this.props.setWalletList(transactionData, nextCursorId);
        }
      })
      .catch(err => {});
  }

  /**
   * will return the notification snackbar
   * @param {string} place
   */
  showNotification(place) {
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 5000);
    }
  }

  confirmDates() {
    const { fromDate, toDate } = this.state;
    if (fromDate !== '' && toDate === '') {
      this.setState(
        {
          errMessage: 'Please fill To date',
          isLoading: false,
        },
        this.showNotification('errSnack')
      );
    } else if (fromDate === '' && toDate !== '') {
      this.setState(
        {
          errMessage: 'Please fill From date',
          isLoading: false,
        },
        this.showNotification('errSnack')
      );
    } else {
      this.setState(
        {
          isFilter: true,
          checksAlert: false,
          checksError: '',
        },
        () => this.getTransactionList()
      );
    }
  }

  /**
   * Used to reset the filter in the transactions
   */
  resetFilter() {
    cookies.remove('epocFromDate');
    cookies.remove('epocToDate');
    cookies.remove('transporterId');
    this.setState(
      {
        checksAlert: false,
        checksError: '',
        isFilter: false,
        fromDate: '',
        toDate: '',
        selectedTransporter: '',
        transporterId: null,
      },
      () => this.getTransactionList()
    );
  }

  modalWindow() {
    const { classes } = this.props;
    const {
      fromDate,
      toDate,
      selectedTransporter,
      transporters,
      transporterId,
    } = this.state;
    const yesterday = Datetime.moment().subtract(0, 'day');
    const valid = function(current) {
      return current.isBefore(yesterday);
    };
    const tomorrow = Datetime.moment(fromDate).subtract(1, 'day');
    const validat = function(current) {
      if (current.isAfter(tomorrow) && current.isBefore(yesterday)) {
        return true;
      }
    };
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <SweetAlert
            showCancel
            confirmBtnText="Apply"
            title=""
            onConfirm={this.confirmDates}
            onCancel={this.closeChecksAlert}
            confirmBtnCssClass={`${classes.button} ${classes.success}`}
            cancelBtnCssClass={`${classes.button} ${classes.warning}`}
            style={{ height: '70%' }}
          >
            <h3 style={{ marginBottom: '0px' }}>
              <span className={classes.rajdhaniFont}>
                Filter Account History
              </span>
            </h3>
            <GridContainer>
              <GridItem xs={12} sm={12} md={1} />
              <GridItem xs={12} sm={12} md={8} style={{ marginTop: '20px' }}>
                <InputLabel className={classes.labelText}>
                  Select Transporter
                </InputLabel>
                <Select
                  value={selectedTransporter}
                  onChange={this.handleTransporter}
                  options={transporters}
                  placeholder="Select Transporter"
                  clearable
                />
              </GridItem>
              {transporterId !== null && transporterId !== '' ? (
                <>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={1} />
                    <GridItem
                      xs={12}
                      sm={12}
                      md={8}
                      style={{ marginTop: '20px' }}
                    >
                      <InputLabel className={classes.label}>
                        From Date *
                      </InputLabel>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          onChange={this.fromDateChange}
                          value={fromDate}
                          dateFormat="DD-MM-YYYY"
                          timeFormat={false}
                          inputProps={{ placeholder: 'Select Date' }}
                          isValidDate={valid}
                          required
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={1} />
                    <GridItem
                      xs={12}
                      sm={12}
                      md={8}
                      style={{ marginTop: '20px' }}
                    >
                      <InputLabel className={classes.label}>
                        To Date *
                      </InputLabel>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          onChange={this.toDateChange}
                          value={toDate}
                          dateFormat="DD-MM-YYYY"
                          timeFormat={false}
                          inputProps={{ placeholder: 'Select Date' }}
                          isValidDate={validat}
                          required
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </>
              ) : (
                ''
              )}
            </GridContainer>
            <Snackbar
              place="tc"
              color="danger"
              icon={AddAlert}
              message="Select From and To date."
              open={this.state.tc}
              closeNotification={() => this.setState({ tc: false })}
              close
            />
            {this.state.isFilter && (
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6} textCenter>
                  <Button
                    color="danger"
                    aria-label="edit"
                    size="sm"
                    style={{ float: 'center' }}
                    // className={}
                    onClick={() => this.resetFilter()}
                  >
                    Reset
                  </Button>
                </GridItem>
              </GridContainer>
            )}
          </SweetAlert>
        </GridItem>
      </GridContainer>
    );
  }

  updateWallet(e) {
    const { classes } = this.props;
    const { isLoading } = this.state;
    this.setState({ isLoading: true });
    if (isLoading) {
      return (
        <CircularProgress
          className={classes.progress}
          style={{ color: 'danger' }}
          thickness={7}
        />
      );
    }
    this.setState(
      {
        infoMessage: 'Updated Account successfully',
        isLoading: false,
      },
      this.showNotification('infoSnack')
    );
    const role = cookies.get('role');
    if (role !== 'super_admin') {
      this.props.setWalletBalance();
    }
    e.preventDefault();
  }

  render() {
    const { classes } = this.props;
    const {
      errMessage,
      errSnack,
      account_number,
      name,
      ifsc,
      infoMessage,
      infoSnack,
      updateDate,
      role,
      checksAlert,
      isFilter,
      excelChecksAlert,
      isChangeColor,
    } = this.state;
    const searchButton = `${classes.top} ${classes.searchButton} ${classNames({
      [classes.searchRTL]: '',
    })}`;
    // const replaced = account_number.replace(/.(?=.{4,}$)/g, 'X');
    return (
      <GridContainer>
        <Snackbar
          place="tc"
          color="success"
          icon={AddAlert}
          message={infoMessage || ''}
          open={infoSnack}
          closeNotification={() => this.setState({ infoSnack: false })}
          close
        />
        <Snackbar
          place="tc"
          color="danger"
          icon={AddAlert}
          message={errMessage || ''}
          open={errSnack}
          closeNotification={() => this.setState({ errSnack: false })}
          close
        />
        {role !== 'super_admin' ? (
          <>
            <GridItem xs={12} sm={6} md={6} lg={6}>
              <Card>
                <CardHeader color="warning" stats icon>
                  <CardIcon color="warning" style={{ height: '74px' }}>
                    <i
                      className="fa fa-inr"
                      style={{ fontSize: '28px', marginTop: '-5px' }}
                      aria-hidden="true"
                    />{' '}
                  </CardIcon>
                  <p className={classes.cardCategory}>
                    <span style={{ fontWeight: '300', fontSize: '20px' }}>
                      Account Balance
                    </span>
                  </p>
                  <h5
                    className={classes.cardTitle}
                    style={{ fontSize: '32px' }}
                  >
                    <i
                      className="fa fa-inr"
                      style={{ fontSize: '28px', paddingLeft: '30px' }}
                      aria-hidden="true"
                    />{' '}
                    {Intl.NumberFormat('en-IN').format(
                      this.props.walletBalance
                    )}
                  </h5>
                  <p
                    className={classes.cardTitle}
                    style={{ fontSize: '30px', float: 'left' }}
                  >
                    <b>
                      {`${account_number}`
                        .replace(/(\d{4})/g, '$1 ')
                        .replace(/(^\s+|\s+$)/, '')}
                    </b>
                  </p>
                  <br />
                  <p
                    className={classes.cardTitle}
                    style={{ marginTop: '37px' }}
                  />
                </CardHeader>
                <CardFooter>
                  <div className={classes.stats}>
                    <DateRange />
                    <p style={{ marginTop: '2px', fontSize: '14px' }}>
                      {updateDate}
                    </p>
                  </div>
                  <div>
                    <Refresh
                      fontSize="large"
                      onClick={e => this.updateWallet(e)}
                      style={{
                        float: 'right',
                        top: '0px',
                        cursor: 'pointer',
                        fontSize: 30,
                      }}
                    />
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card>
                <CardHeader color="warning">
                  <h4>
                    {' '}
                    Bank Details <small />{' '}
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={3} lg={3}>
                      <h4 style={{ fontWeight: 'bold' }}>Account No: </h4>
                      <h4 style={{ fontWeight: 'bold' }}>Name:</h4>
                      <h4 style={{ fontWeight: 'bold' }}>IFSC:</h4>
                      {/* <Button
                    size="sm"
                    color="warning"
                    className={classes.actionButton}
                    onClick={() => {
                      this.retryClientWallet();
                    }}
                  >
                    Request Wallet
                  </Button> */}
                    </GridItem>
                    <GridItem xs={8} sm={8} md={9} lg={9}>
                      <h4> {account_number}</h4>
                      <h4>{name}</h4>
                      <h4>{ifsc}</h4>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </>
        ) : (
          ''
        )}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          {checksAlert && this.modalWindow()}
          {excelChecksAlert && this.excelModalWindow()}
          <Card>
            <CardHeader color="warning">
              <CardIcon color="info">
                <Assignment />
              </CardIcon>
              <h4
                style={{ color: '#FFF', fontWeight: '400', fontSize: '28px' }}
              >
                {' '}
                Account History <small />{' '}
              </h4>
              {role === 'super_admin' ? (
                <Button
                  color={isFilter ? 'success' : 'white'}
                  aria-label="edit"
                  justIcon
                  round
                  className={searchButton}
                  onClick={() => this.showChecksAlert()}
                  style={{
                    marginTop: '15px',
                    float: 'right',
                    top: '0px',
                    marginRight: '15px',
                  }}
                >
                  <i className="fa fa-filter" aria-hidden="true" />
                </Button>
              ) : (
                ''
              )}
              {role === 'admin' || role === 'super_admin' ? (
                <Button
                  color={isChangeColor ? 'success' : 'white'}
                  aria-label="edit"
                  justIcon
                  round
                  className={searchButton}
                  onClick={() => this.excelShowChecksAlert()}
                  style={{
                    marginTop: '15px',
                    float: 'right',
                    top: '0px',
                    marginRight: '15px',
                  }}
                >
                  <i
                    className="fa fa-arrow-down"
                    aria-hidden="true"
                    style={{ color: 'green' }}
                  />
                </Button>
              ) : (
                ''
              )}
              <Refresh
                fontSize="large"
                onClick={() => this.refreshWallet()}
                style={{
                  float: 'right',
                  top: '0px',
                  cursor: 'pointer',
                  fontSize: 30,
                  marginTop: '25px',
                  marginRight: '10px',
                }}
              />
              <h4
                style={{
                  float: 'right',
                  top: '0px',
                  marginRight: '15px',
                  marginTop: '32px',
                }}
              >
                {this.state.refreshDate === 'Invalid date'
                  ? ''
                  : this.state.refreshDate}
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                getTheadThProps={() => ({
                  style: {
                    outline: 0,
                    color: 'black',
                    fontSize: '18px',
                    fontWeight: '400',
                    textAlign: 'left',
                  },
                })}
                PaginationComponent={Pagination}
                data={this.props.listWallet.map((prop, key) => ({
                  id: key,
                  reference_number: prop.data[0],
                  amount: prop.data[1],
                  date: prop.data[2],
                  transporter: prop.data[5],
                  status: prop.data[3],
                  actions: (
                    <GridContainer>
                      {prop.data[4] === true ? (
                        <GridItem xs={12} sm={4} md={4}>
                          <Tooltip title="Credit" arrow>
                            <Button
                              justIcon
                              round
                              simple
                              color="success"
                              className="credit"
                            >
                              <CallReceived />
                            </Button>
                          </Tooltip>{' '}
                        </GridItem>
                      ) : (
                        <GridItem xs={12} sm={4} md={4}>
                          <Tooltip title="Debit" arrow>
                            <Button
                              justIcon
                              round
                              simple
                              color="danger"
                              className="debit"
                            >
                              <CallMade />
                            </Button>
                          </Tooltip>{' '}
                        </GridItem>
                      )}
                    </GridContainer>
                  ),
                }))}
                filterable
                columns={
                  role !== 'super_admin'
                    ? [
                        {
                          filterable: false,
                          Header: 'TXN',
                          accessor: 'reference_number',
                          Cell: row => this.changeFont(row.value, 'number'),
                          Filter: ({ filter, onChange }) => (
                            <input
                              onChange={event => onChange(event.target.value)}
                              value={filter ? filter.value : ''}
                              placeholder="TXN"
                              style={{
                                width: '100%',
                                backgroundColor: 'white',
                                color: 'black',
                              }}
                            />
                          ),
                        },
                        {
                          Header: () => (
                            <div
                              style={{
                                textAlign: 'right',
                              }}
                            >
                              Amount
                            </div>
                          ),
                          filterable: false,
                          accessor: 'amount',
                          Cell: row => (
                            <div
                              style={{
                                textAlign: 'right',
                                fontWeight: 500,
                              }}
                            >
                              <i
                                className="fa fa-inr"
                                style={{ fontSize: '15px' }}
                                aria-hidden="true"
                              />{' '}
                              {row.value}
                            </div>
                          ),
                          Filter: ({ filter, onChange }) => (
                            <input
                              onChange={event => onChange(event.target.value)}
                              value={filter ? filter.value : ''}
                              placeholder="Amount"
                              style={{
                                width: '100%',
                                backgroundColor: 'white',
                                color: 'black',
                                textAlign: 'right',
                              }}
                            />
                          ),
                          sortable: false,
                        },
                        {
                          Header: 'Date',
                          accessor: 'date',
                          Cell: row => (
                            <Tooltip
                              title={
                                <span style={{ fontSize: '15px' }}>
                                  {row.value}
                                </span>
                              }
                              arrow
                            >
                              {this.changeFont(row.value, 'date')}
                            </Tooltip>
                          ),
                          sortable: false,
                          filterable: false,
                        },
                        {
                          filterable: false,
                          Header: 'Status',
                          accessor: 'status',
                          Cell: row => (
                            <div style={{ fontWeight: 500 }}>
                              {this.changeFont(row.value, 'status')}
                            </div>
                          ),
                          Filter: ({ filter, onChange }) => (
                            <input
                              onChange={event => onChange(event.target.value)}
                              value={filter ? filter.value : ''}
                              placeholder="Status"
                              style={{
                                width: '100%',
                                backgroundColor: 'white',
                                color: 'black',
                              }}
                            />
                          ),
                        },
                        {
                          Header: 'Actions',
                          accessor: 'actions',
                          sortable: false,
                          filterable: false,
                        },
                      ]
                    : [
                        {
                          filterable: false,
                          Header: 'TXN',
                          accessor: 'reference_number',
                          Cell: row => this.changeFont(row.value, 'number'),
                          Filter: ({ filter, onChange }) => (
                            <input
                              onChange={event => onChange(event.target.value)}
                              value={filter ? filter.value : ''}
                              placeholder="TXN"
                              style={{
                                width: '100%',
                                backgroundColor: 'white',
                                color: 'black',
                              }}
                            />
                          ),
                        },
                        {
                          filterable: false,
                          Header: () => (
                            <div
                              style={{
                                textAlign: 'right',
                              }}
                            >
                              Amount
                            </div>
                          ),
                          accessor: 'amount',
                          Cell: row => (
                            <div
                              style={{
                                textAlign: 'right',
                                fontWeight: 500,
                              }}
                            >
                              <i
                                className="fa fa-inr"
                                style={{ fontSize: '15px' }}
                                aria-hidden="true"
                              />{' '}
                              {row.value}
                            </div>
                          ),
                          Filter: ({ filter, onChange }) => (
                            <input
                              onChange={event => onChange(event.target.value)}
                              value={filter ? filter.value : ''}
                              placeholder="Amount"
                              style={{
                                width: '100%',
                                backgroundColor: 'white',
                                color: 'black',
                                textAlign: 'right',
                              }}
                            />
                          ),
                          sortable: false,
                        },
                        {
                          Header: 'Date',
                          accessor: 'date',
                          Cell: row => (
                            <Tooltip
                              title={
                                <span style={{ fontSize: '15px' }}>
                                  {row.value}
                                </span>
                              }
                              arrow
                            >
                              {this.changeFont(row.value, 'date')}
                            </Tooltip>
                          ),
                          sortable: false,
                          filterable: false,
                        },
                        {
                          filterable: false,
                          Header: 'Transporter',
                          accessor: 'transporter',
                          Cell: row =>
                            this.changeFont(row.value, 'transporter'),
                          Filter: ({ filter, onChange }) => (
                            <input
                              onChange={event => onChange(event.target.value)}
                              value={filter ? filter.value : ''}
                              placeholder="Transporter"
                              style={{
                                width: '100%',
                                backgroundColor: 'white',
                                color: 'black',
                              }}
                            />
                          ),
                        },
                        {
                          filterable: false,
                          Header: 'Status',
                          accessor: 'status',
                          Cell: row => (
                            <div style={{ fontWeight: 500 }}>
                              {this.changeFont(row.value, 'status')}
                            </div>
                          ),
                          Filter: ({ filter, onChange }) => (
                            <input
                              onChange={event => onChange(event.target.value)}
                              value={filter ? filter.value : ''}
                              placeholder="Status"
                              style={{
                                width: '100%',
                                backgroundColor: 'white',
                                color: 'black',
                              }}
                            />
                          ),
                        },
                        {
                          Header: '',
                          accessor: 'actions',
                          sortable: false,
                          filterable: false,
                        },
                      ]
                }
                minRows={0}
                defaultPageSize={30}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

WalletList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setUserDetails,
      addAuthtoReduxStore,
      setWalletBalance,
      setWalletList,
    },
    dispatch
  );

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
  auth: state.auth.auth,
  walletBalance: state.main.walletBalance,
  listWallet: state.wallet.listWallet,
  lastEvaluatedKey: state.wallet.lastEvaluatedKey,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle)(WalletList));
