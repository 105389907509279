/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import { Button, DialogContent, Grid } from '@material-ui/core';
import panaImage from '../../../assets/img/pana.svg';
import apiCaller from '../../../utils/apiCallerESP';

const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid lightgray 2px',
  // borderRadius: '7px',
  margin: '20px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',

  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};
const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: { marginTop: '40px' },
  dialogActions: { display: 'flex', alignContent: 'center' },
  info: { display: 'flex', flexDirection: 'column' },
  TextField: {
    border: '1px solid lightgray',
    borderRadius: '4px',
    padding: ' 2px',
    marginBottom: '12px',
    width: '220px',
  },
  footerAction: { display: 'flex', justifyContent: 'center', margin: '30px' },
  cta: { backgroundColor: '#3366FF', color: '#FFFFFF', marginLeft: '10px' },
  label: { fontWeight: 'bold', margin: '10px' },
  tripcontainer: {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'space-between',
  },
  select: { width: '200px' },
  value: { margin: '10px' },
}));

export default function MaxWidthDialog({ data }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [trip, setTrip] = React.useState();
  const { trip_id } = data;
  const getTripDetails = async () => {
    const endPoint = 'get-trip-details';
    const body = {
      trip_id,
    };

    try {
      const response = await apiCaller(endPoint, body);
      const { trip_details } = response.data.body;
      setTrip(trip_details);
    } catch (e) {
      console.log(e);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
    getTripDetails();
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        onClick={() => handleClickOpen()}
        style={{
          textTransform: 'capitalize',
          textDecoration: 'underline',
          color: 'blue',
        }}
      >
        Trip Details
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.Dialog}
      >
        <DialogContent>
          <fieldset style={shifter}>
            <legend style={{ maxWidth: '300px' }}>
              <div style={avatarContainer}>
                <div style={avatar}>
                  <AccountBalanceIcon style={{ color: 'white' }} />
                </div>
                <Typography variant="h6" gutterBottom>
                  Personal Details
                </Typography>
              </div>
            </legend>
            <Grid container>
              <Grid item xs={8}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.label}>
                      From City :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.value}>
                      {trip ? trip?.from_city : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.label}>
                      To City :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.value}>
                      {trip ? trip?.to_city : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.label}>
                      Truck Number:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {/* <Typography variant="body2" className={classes.label}>
                      To City :
                    </Typography> */}
                    <Typography variant="body2" className={classes.value}>
                      {trip ? trip?.truck_number : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.label}>
                      BTH :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {/* <Typography variant="body2" className={classes.label}>
                      To City :
                    </Typography> */}
                    <Typography variant="body2" className={classes.value}>
                      {trip ? trip?.balance : ''}
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={6}>
                    <Typography variant="body2" className={classes.label}>
                      UTR :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField onChange={event => setUTR(event.target.value)} />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.label}>
                      Remarks :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      onChange={event => setRemarks(event.target.value)}
                    />
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img src={panaImage} />
                </div>
              </Grid>
            </Grid>
            {/* <div className={classes.footerAction}>
              <Button
                // className={classes.cta2}
                variant="contained"
                size="small"
                onClick={handleEnableESP}
              >
                Submit
              </Button>
            </div> */}
          </fieldset>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
