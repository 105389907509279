/* eslint-disable camelcase */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-lonely-if */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from 'components/Table/Table.jsx';

// @material-ui/icons
import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
import Add from '@material-ui/icons/Add';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Close from '@material-ui/icons/Close';
import AddAlert from '@material-ui/icons/AddAlert';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';

import registerPageStyle from 'assets/jss/material-dashboard-pro-react/views/registerPageStyle';

import apiCaller from '../../utils/apiCaller';

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class Vendor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isButtonLoading: false,
      bankModal: false,
      name: '',
      nameState: '',
      contactName: '',
      email: '',
      emailState: '',
      phoneNumber: '',
      phoneNumberState: '',
      panNumber: '',
      panNumberState: '',
      gstNumber: '',
      gstNumberState: '',
      banks: [],
      banksData: [],
      accountNumber: '',
      accountNumberState: '',
      ifsc: '',
      ifscState: '',
      bankHolderName: '',
      bankHolderNameState: '',
      bankName: '',
      bankNameState: '',
      errMessage: '',
      errSnack: false,
      infoMessage: '',
      infoSnack: false,
      isLoadingBank: false,
      isLoadingVerifyBank: false,
      isVerified: false,
      validateError: '',
      name_at_bank: '',
    };
    this.change = this.change.bind(this);
    this.submitVendor = this.submitVendor.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.validateBankFields = this.validateBankFields.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.showNotification = this.showNotification.bind(this);
  }

  // Modal Window To Add The Bank Details
  getBankDetailsForm() {
    const { classes } = this.props;
    const {
      bankModal,
      bankHolderName,
      bankHolderNameState,
      accountNumber,
      accountNumberState,
      ifsc,
      ifscState,
      bankName,
      bankNameState,
      name_at_bank,
      isVerified,
      isLoadingVerifyBank,
    } = this.state;
    const { banksData } = this.state;
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        classes={{ root: classes.center, paper: classes.modal }}
        style={{ maxWidth: '400px !important', textAlign: 'center' }}
        open={bankModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('bankModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => this.handleClose('bankModal')}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>Add Bank Details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Bank Holder Name *"
                id="bank Holder Name"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'bankHolderName', 'alpha'),
                  value: bankHolderName,
                }}
                success={bankHolderNameState === 'success'}
                error={bankHolderNameState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Account Number *"
                id="account-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'accountNumber', 'length', 9),
                  type: 'length',
                  value: accountNumber,
                }}
                success={accountNumberState === 'success'}
                error={accountNumberState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="IFSC Code *"
                id="ifsc-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event => this.change(event, 'ifsc', 'ifsc'),
                  type: 'ifsc',
                  value: ifsc,
                }}
                success={ifscState === 'success'}
                error={ifscState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Bank name *"
                id="bank-name"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'bankName', 'length', 2),
                  type: 'length',
                  value: bankName,
                }}
                success={bankNameState === 'success'}
                error={bankNameState === 'error'}
              />
            </GridItem>
          </GridContainer>
          {isVerified && (
            <GridContainer justify="flex-start" style={{ marginTop: '20px' }}>
              <GridItem xs={12} sm={12} md={12}>
                <div>
                  <p
                    style={{
                      textAlign: 'left',
                      fontSize: '18px',
                      fontWeight: 500,
                      marginBottom: '10px',
                    }}
                  >
                    <u>Verified Details</u>
                  </p>
                  <p
                    style={{
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 500,
                      marginBottom: '5px',
                    }}
                  >
                    Bank Holder Name:{' '}
                    <span style={{ color: '#008000' }}>{name_at_bank}</span>
                  </p>
                </div>
              </GridItem>
            </GridContainer>
          )}
        </DialogContent>
        <p style={{ color: 'red' }}>{this.state.validateError}</p>
        {banksData.length < 1 ? (
          <DialogActions className={classes.modalFooter}>
            {this.state.isLoadingBank ? (
              <CircularProgress
                className={classes.progress}
                style={{ color: purple[500] }}
                thickness={4}
              />
            ) : (
              <div>
                {isLoadingVerifyBank ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    thickness={4}
                  />
                ) : (
                  <Button color="primary" onClick={() => this.verifyBank()}>
                    Verify
                  </Button>
                )}

                <Button
                  color="primary"
                  onClick={() => this.addBanks()}
                  disabled={!isVerified}
                >
                  Add
                </Button>
              </div>
            )}
          </DialogActions>
        ) : (
          ''
        )}
      </Dialog>
    );
  }

  /**
   * Will return the Bank Details Component
   * @function
   */
  getBanks() {
    const { classes } = this.props;
    const { banksData } = this.state;
    return (
      <Card>
        <CardHeader style={{ marginBottom: '2px', paddingBottom: '2px' }}>
          <h4>Add Bank *</h4>
        </CardHeader>
        {banksData.length > 0 ? (
          <React.Fragment>
            <CardBody className={classes.customCardContentClass}>
              <Table
                hover
                tableHead={['Name', 'Account No', 'Bank', 'Ifsc']}
                tableData={banksData}
              />
            </CardBody>
          </React.Fragment>
        ) : (
          ''
        )}
        {banksData.length < 1 ? (
          <CardFooter>
            <Button
              color="info"
              onClick={() => this.handleClickOpen('bankModal')}
              className={classes.updateProfileButton}
              justIcon
            >
              <Add />
            </Button>
          </CardFooter>
        ) : (
          ''
        )}
      </Card>
    );
  }

  /**
   * Shows the notification when user click submit for success message.
   */
  showNotification = place => {
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 6000);
    }
  };

  // Verify Bank Details.
  verifyBank() {
    const { accountNumber, bankHolderName, ifsc, phoneNumber } = this.state;
    this.validateBankFields()
      .then(success => {
        this.setState({
          isLoadingVerifyBank: true,
        });
        const body = {
          bank_list: [
            {
              account_number: accountNumber,
              ifsc_code: ifsc,
              account_name: bankHolderName,
              phone: phoneNumber,
            },
          ],
        };
        apiCaller('premium-api/verify-bank', 'post', body, true, false)
          .then(res => {
            if (res.hasOwnProperty('list')) {
              if (res.list.hasOwnProperty('all_banks')) {
                const bankData = res.list.all_banks;
                bankData.forEach(newBank => {
                  if (newBank.account_verified) {
                    this.setState({
                      isLoadingVerifyBank: false,
                      validateError: '',
                      isVerified: true,
                      name_at_bank: newBank.name_at_bank,
                    });
                  } else {
                    this.setState({
                      isLoadingVerifyBank: false,
                      isVerified: false,
                      validateError:
                        'We are unable to validate the bank details. Please check if the bank details are correct.',
                    });
                  }
                });
              } else {
                this.setState({
                  isLoadingVerifyBank: false,
                  validateError: 'Something went wrong please try again.',
                });
              }
            }
          })
          .catch(err => {
            console.log('err', err);
            this.setState({
              isLoadingBank: false,
              validateError: 'Something went wrong please try again.',
            });
          });
      })
      .catch(err => {
        console.log('err', err);
        this.setState({
          isLoadingBank: false,
          validateError: 'Please check if the bank details are correct.',
        });
      });
  }

  // Will Add Banks
  addBanks() {
    const {
      banks,
      banksData,
      accountNumber,
      bankName,
      bankHolderName,
      ifsc,
    } = this.state;
    this.validateBankFields()
      .then(success => {
        this.setState({
          isLoadingBank: true,
        });
        this.setState({ isLoadingBank: false, validateError: '' });
        banks.push({
          account_number: accountNumber,
          bank_name: bankName,
          ifsc_code: ifsc,
          account_name: bankHolderName,
          active: true,
        });
        banksData.push({
          color: 'success',
          data: [bankHolderName, accountNumber, bankName, ifsc],
        });
        this.handleClose('bankModal');
        this.setState({
          banksData,
          banks,
          accountNumber: '',
          ifsc: '',
          bankName: '',
          bankHolderName: '',
          accountNumberState: '',
          ifscState: '',
          bankNameState: '',
          isVerified: false,
          isLoadingVerifyBank: false,
          name_at_bank: '',
        });
      })
      .catch(err => {
        console.log('err', err);
        this.setState({
          isLoadingBank: false,
          validateError: 'Please check if the bank details are correct.',
        });
      });
  }

  verifyIfsc(value) {
    const regIfsc = /^[A-Za-z]{4}0[A-Z0-9]{6}$/;
    if (regIfsc.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPhone(value) {
    const regPhone = /^[6789]\d{9}$/;
    if (regPhone.test(value)) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyAlpha(value) {
    const emailRex = /^[a-zA-Z() ]+$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that returns true if value is Valid GST, false otherwise
  verifyGst(value) {
    const gstRex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (gstRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPan(value) {
    const regpan = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
    if (regpan.test(value)) {
      return true;
    }
    return false;
  }

  clearForm() {
    const self = this;
    self.setState({
      isLoading: false,
      isButtonLoading: false,
      bankModal: false,
      name: '',
      nameState: '',
      contactName: '',
      email: '',
      emailState: '',
      phoneNumber: '',
      phoneNumberState: '',
      panNumber: '',
      panNumberState: '',
      gstNumber: '',
      gstNumberState: '',
      banksData: [],
      banks: [],
      accountNumber: '',
      accountNumberState: '',
      ifsc: '',
      ifscState: '',
      bankHolderName: '',
      bankHolderNameState: '',
      bankName: '',
      bankNameState: '',
    });
  }

  submitVendor() {
    const {
      name,
      contactName,
      email,
      phoneNumber,
      gstNumber,
      panNumber,
      banks,
    } = this.state;
    this.setState({ isLoading: true, isButtonLoading: true });
    this.validateAllFields()
      .then(success => {
        if (success && banks.length !== 0) {
          const body = {
            company_name: name,
            contact_name: contactName === '' ? '' : contactName,
            email_address: email === '' ? '' : email,
            phone_number: phoneNumber,
            gst_number: gstNumber === '' ? '' : gstNumber,
            pan_number: panNumber === '' ? '' : panNumber,
            bank_info: banks,
          };
          apiCaller('premium-api/add-vendor', 'post', body, true, false)
            .then(response => {
              if (response.vendor_id !== '') {
                this.setState({ isLoading: false });
                this.setState(
                  {
                    infoMessage:
                      'Vendor is being verified and will be available in 5 seconds',
                    isButtonLoading: false,
                    isLoading: false,
                  },
                  this.showNotification('infoSnack')
                );
                this.clearForm();
              } else {
                this.setState(
                  {
                    errMessage: 'Oops something went wrong',
                    isLoading: false,
                    isButtonLoading: false,
                  },
                  this.showNotification('errSnack')
                );
              }
            })
            .catch(err => {
              this.setState(
                {
                  errMessage: err.message || 'Oops something went wrong',
                  isLoading: false,
                  isButtonLoading: false,
                },
                this.showNotification('errSnack')
              );
            });
        } else {
          this.setState(
            {
              errMessage: 'Please Fill Mandatory Fields',
              isLoading: false,
              isButtonLoading: false,
            },
            this.showNotification('errSnack')
          );
        }
      })
      .catch(err => {
        this.setState(
          {
            errMessage: err.message || 'Please Fill Mandatory Fields',
            isLoading: false,
            isButtonLoading: false,
          },
          this.showNotification('errSnack')
        );
      });
  }

  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  /**
   * closes the modal button
   * @param {*} modal
   */
  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'required':
        if (this.verifyRequired(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'alpha':
        if (this.verifyAlpha(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'pan':
        if (this.verifyPan(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'phone':
        if (this.verifyPhone(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'ifsc':
        if (this.verifyIfsc(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'gst':
        if (this.verifyGst(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  // Will Verify Bank Details
  validateBankFields() {
    const {
      accountNumber,
      bankHolderName,
      ifsc,
      bankName,
      phoneNumber,
    } = this.state;
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Account Number
      if (this.verifyLength(accountNumber, 9)) {
        this.setState({
          accountNumberState: 'success',
        });
      } else {
        this.setState({ accountNumberState: 'error' });
        error = true;
      }

      if (this.verifyPhone(phoneNumber)) {
        this.setState({
          phoneNumberState: 'success',
        });
      } else {
        this.setState({ phoneNumberState: 'error' });
        error = true;
      }

      // Verify Account Number
      if (this.verifyAlpha(bankHolderName)) {
        this.setState({
          bankHolderNameState: 'success',
        });
      } else {
        this.setState({ bankHolderNameState: 'error' });
        error = true;
      }
      // Verify IFSC
      if (this.verifyIfsc(ifsc)) {
        this.setState({
          ifscState: 'success',
        });
      } else {
        this.setState({ ifscState: 'error' });
        error = true;
      }

      // Verify Bank Name
      if (this.verifyLength(bankName, 2)) {
        this.setState({ bankNameState: 'success' });
      } else {
        this.setState({ bankNameState: 'error' });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        reject(error);
      }
    });
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    const { name, phoneNumber } = this.state;
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Name
      if (this.verifyLength(name, 1)) {
        this.setState({
          nameState: 'success',
        });
      } else {
        this.setState({
          nameState: 'error',
        });
        error = true;
      }

      // Verify Email
      // if (this.verifyEmail(email)) {
      //   this.setState({
      //     emailState: 'success',
      //   });
      // } else {
      //   this.setState({
      //     emailState: 'error',
      //   });
      //   error = true;
      // }

      // Verify pan
      // if (this.verifyPan(panNumber)) {
      //   this.setState({
      //     panNumberState: 'success',
      //   });
      // } else {
      //   this.setState({ panNumberState: 'error' });
      //   error = true;
      // }

      // gst
      // if (this.verifyGst(gstNumber)) {
      //   this.setState({
      //     gstNumberState: 'success',
      //   });
      // } else {
      //   this.setState({ gstNumberState: 'error' });
      //   error = true;
      // }

      // Verify Phone Number
      if (this.verifyPhone(phoneNumber)) {
        this.setState({
          phoneNumberState: 'success',
        });
      } else {
        this.setState({
          phoneNumberState: 'error',
        });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        reject(error);
      }
    });
  }

  render() {
    const { classes } = this.props;
    const {
      name,
      nameState,
      contactName,
      email,
      emailState,
      phoneNumber,
      phoneNumberState,
      panNumber,
      panNumberState,
      gstNumber,
      gstNumberState,
      isButtonLoading,
      errMessage,
      errSnack,
      infoMessage,
      infoSnack,
    } = this.state;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <Snackbar
              place="tc"
              color="danger"
              icon={AddAlert}
              message={errMessage || ''}
              open={errSnack}
              closeNotification={() => this.setState({ errSnack: false })}
              close
            />
            <Snackbar
              place="tc"
              color="primary"
              icon={AddAlert}
              message={<h5>{infoMessage}</h5> || ''}
              open={infoSnack}
              closeNotification={() => this.setState({ infoSnack: false })}
              close
            />
            <CardHeader color="warning">
              {/* <CardIcon color="primary"> {vendorList.length} </CardIcon> */}
              <h4>
                {' '}
                Add Vendor <small />{' '}
              </h4>
            </CardHeader>
            <CardBody>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  {/* <h2 className={classes.cardTitle}>Vendor</h2> */}
                  <CardBody>
                    <form>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={4}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              onChange: event =>
                                this.change(event, 'name', 'length', 2),
                              type: 'length',
                              value: name,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                >
                                  <Face
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              placeholder: 'Company Name *',
                            }}
                            success={nameState === 'success'}
                            error={nameState === 'error'}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              onChange: event =>
                                this.change(event, 'contactName', 'length', 2),
                              type: 'length',
                              value: contactName,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                />
                              ),
                              placeholder: 'ContactName',
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              onChange: event =>
                                this.change(event, 'email', 'email'),
                              type: 'email',
                              value: email,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                >
                                  <Email
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              placeholder: 'Email...',
                            }}
                            success={emailState === 'success'}
                            error={emailState === 'error'}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              onChange: event =>
                                this.change(event, 'phoneNumber', 'phone'),
                              type: 'length',
                              value: phoneNumber,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                />
                              ),
                              placeholder: 'Phone Number *',
                            }}
                            success={phoneNumberState === 'success'}
                            error={phoneNumberState === 'error'}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              onChange: event =>
                                this.change(event, 'panNumber', 'pan'),
                              type: 'length',
                              value: panNumber,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                />
                              ),
                              placeholder: 'PAN',
                            }}
                            success={panNumberState === 'success'}
                            error={panNumberState === 'error'}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              onChange: event =>
                                this.change(event, 'gstNumber', 'gst'),
                              type: 'length',
                              value: gstNumber,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                />
                              ),
                              placeholder: 'GST',
                            }}
                            success={gstNumberState === 'success'}
                            error={gstNumberState === 'error'}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={8}>
                          {this.getBanks()}
                        </GridItem>
                        <GridItem xs={12} sm={6} md={8}>
                          {this.getBankDetailsForm()}
                        </GridItem>
                      </GridContainer>
                      <div className={classes.center}>
                        {isButtonLoading ? (
                          <CircularProgress
                            className={classes.progress}
                            style={{ color: purple[500] }}
                            thickness={7}
                          />
                        ) : (
                          <Button
                            round
                            color="primary"
                            onClick={this.submitVendor}
                          >
                            Create
                          </Button>
                        )}
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

Vendor.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(registerPageStyle)(Vendor);
