/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Dialog from '@material-ui/core/Dialog';
import { Button, DialogContent, Grid } from '@material-ui/core';
import apiCaller from '../../../utils/apiCallerESP';

const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid lightgray 2px',
  // borderRadius: '7px',
  margin: '20px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',

  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};
const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: { marginTop: '40px' },
  dialogActions: { display: 'flex', alignContent: 'center' },
  info: { display: 'flex', alignContent: 'baseline', margin: '10px' },
  TextField: {
    border: '1px solid lightgray',
    borderRadius: '4px',
    padding: ' 2px',
    // marginLeft: '10px',
  },
  footerAction: { display: 'flex', justifyContent: 'center', margin: '30px' },
  cta: { backgroundColor: '#3366FF', color: '#FFFFFF' },
  label: { fontWeight: 'bold' },
  // esitButton: { fontWeight: 'bold' },
  submitButton: {
    marginLeft: '10px',
    backgroundColor: '#3366FF',
    color: 'white',
  },
  // value: { marginLeft: '10px' },
}));

export default function MaxWidthDialog({ transporterESPDetails }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [utr, setUTR] = React.useState('');
  const [amount, setAmount] = React.useState('');
  const [remarks, setRemarks] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  const {
    accountName,
    transporterName,
    transporterID,
    accountId,
  } = transporterESPDetails;
  // console.log('ESP  DETAILS FOR ASINGLE', transporterESPDetails);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handlePayinAmount = event => {
    const regex = /^[0-9][A-Za-z0-9 -]*$/;
    const test = regex.test(event.target.value);
    if (test) {
      // setValid({ limit: true });
      setAmount(event.target.value);
    } else {
      // setValid({ limit: false });
    }
  };
  const handleSubmitPayin = async () => {
    const body = {
      transporter_id: transporterID,
      account_id: accountId,
      utr,
      remark: remarks,
      amount,
    };
    const endPoint = 'payin-esp';
    // console.log('BODY', body);
    const response = await apiCaller(endPoint, body);
    const { status } = response;
    if (status === 200) {
      setSuccess(true);
    }
    // console.log('RESPONSE ESP ENABLE', response);
  };
  const handleSnackbarClose = () => {
    setSuccess(false);
  };

  // useEffect(() => {}, []);

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        className={classes.viewButton}
        startIcon={<AddCircleIcon />}
        size="small"
        onClick={handleClickOpen}
      >
        Add Payin
      </Button>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.Dialog}
      >
        <DialogContent>
          <fieldset style={shifter}>
            <legend style={{ maxWidth: '300px' }}>
              <div style={avatarContainer}>
                <div style={avatar}>
                  <AccountBalanceIcon style={{ color: 'white' }} />
                </div>
                <Typography variant="h6" gutterBottom>
                  ESP Details
                </Typography>
              </div>
            </legend>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body2" className={classes.label}>
                    Transporter Name :
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className={classes.value}>
                    {transporterName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className={classes.label}>
                    Account Number :
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className={classes.value}>
                    {accountName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className={classes.label}>
                    Amount :
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <input
                    type="text"
                    className={classes.TextField}
                    onChange={handlePayinAmount}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className={classes.label}>
                    UTR :
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <input
                    type="text"
                    className={classes.TextField}
                    onChange={event => setUTR(event.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className={classes.label}>
                    Remarks :
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <input
                    type="text"
                    className={classes.TextField}
                    onChange={event => setRemarks(event.target.value)}
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '20px',
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  className={classes.editButton}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  className={classes.submitButton}
                  onClick={handleSubmitPayin}
                >
                  Submit
                </Button>
              </div>
            </div>
          </fieldset>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={success}
        onClose={handleSnackbarClose}
        message="success"
        // key={vertical + horizontal}
      />
    </React.Fragment>
  );
}
