/* eslint-disable no-prototype-builtins */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import ReactTable from 'react-table';
import { bindActionCreators } from 'redux';

import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import AddAlert from '@material-ui/icons/AddAlert';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import Create from '@material-ui/icons/Create';
import Refresh from '@material-ui/icons/Refresh';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import VendorEdit from './VendorEdit';
import { setVendors } from '../../reducers/transactions';
import Pagination from './Pagination';

import apiCaller from '../../utils/apiCaller';

class VendorList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      view: true,
      tableData: [],
      vendorDataList: [],
      errMessage: '',
      errSnack: false,
    };
    this.goToView = this.goToView.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.refreshVendorList = this.refreshVendorList.bind(this);
    this.changeFont = this.changeFont.bind(this);
  }

  componentDidMount() {}

  changeFont(value) {
    return <span style={{ fontSize: '16px' }}>{value}</span>;
  }

  refreshVendorList() {
    this.setState({
      vendorDataList: [],
    });
    this.getVendorList();
  }

  getVendorList() {
    const vendorData = [];
    const body = {
      total_items: 10,
    };
    let nextCursorId = null;
    apiCaller('premium-api/read-vendor-list', 'post', body, true, false)
      .then(response => {
        if (response.items.length !== 0) {
          response.items.forEach(dataList => {
            if (
              response.hasOwnProperty('LastEvaluatedKey') &&
              response.LastEvaluatedKey !== null
            ) {
              nextCursorId = response.LastEvaluatedKey;
            }
            vendorData.push({
              color: 'success',
              data: [
                dataList.company_name,
                dataList.trucker_name || '___',
                dataList.phone_number || '___',
                dataList.email_address || '___',
              ],
              dataList,
            });
          });
          this.props.setVendors(vendorData, nextCursorId);
          setTimeout(() => {
            if (
              response.hasOwnProperty('LastEvaluatedKey') &&
              response.LastEvaluatedKey !== null
            ) {
              this.getNextVendors();
            }
          }, 1000);
        } else {
          this.props.setVendors([], null);
        }
      })
      .catch(err => {});
  }

  getNextVendors() {
    const { vendorLastEvaluatedKey, vendors } = this.props;
    const vendorData = JSON.parse(JSON.stringify(vendors));
    const body = {
      total_items: 10,
    };
    if (vendorLastEvaluatedKey !== '' || vendorLastEvaluatedKey !== null) {
      body.LastEvaluatedKey = vendorLastEvaluatedKey;
    }
    let nextCursorId = null;
    apiCaller('premium-api/read-vendor-list', 'post', body, true, false)
      .then(response => {
        if (response.items.length !== 0) {
          response.items.forEach(dataList => {
            if (
              response.hasOwnProperty('LastEvaluatedKey') &&
              response.LastEvaluatedKey !== null
            ) {
              nextCursorId = response.LastEvaluatedKey;
            }
            vendorData.push({
              color: 'success',
              data: [
                dataList.company_name,
                dataList.trucker_name || '___',
                dataList.phone_number || '___',
                dataList.email_address || '___',
              ],
              dataList,
            });
          });
          this.props.setVendors(vendorData, nextCursorId);
        }
      })
      .catch(err => {});
  }

  /**
   * Shows the notification when user click submit for success message.
   */
  showNotification = place => {
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 4000);
    }
  };

  openEditForm(data) {
    // let selectedVendor = data.bank_details.base;
    const deepClone = JSON.parse(JSON.stringify(data));
    const deepCloneBankDetails = JSON.parse(
      JSON.stringify(deepClone.bank_details)
    );
    const { base } = deepCloneBankDetails;
    const selectedVendor = {
      contact_id: deepClone.contact_id,
      trucker_name: deepClone.trucker_name,
      email_address: deepClone.email_address,
      gst_number: deepClone.gst_number,
      pan_number: deepClone.pan_number,
      phone_number: deepClone.phone_number,
      transporter_id: deepClone.transporter_id,
      trucker_id: deepClone.trucker_id,
      company_name: deepClone.company_name,
      bank_details: base,
    };
    this.setState({ selectedVendor, view: false });
  }

  goToView() {
    this.setState({ view: true });
  }

  compare(a, b) {
    const bandA = a.data[0].toUpperCase();
    const bandB = b.data[0].toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  render() {
    const {
      isLoading,
      view,
      selectedVendor,
      errMessage,
      errSnack,
    } = this.state;
    const { classes } = this.props;
    return (
      <>
        {view ? (
          <GridContainer justify="center">
            <Snackbar
              place="tc"
              color="danger"
              icon={AddAlert}
              message={errMessage || ''}
              open={errSnack}
              closeNotification={() => this.setState({ errSnack: false })}
              close
            />
            <GridItem xs={12}>
              <Card>
                <CardHeader color="warning">
                  <CardIcon color="primary">
                    {' '}
                    {this.props.vendors.length}{' '}
                  </CardIcon>
                  <h5
                    className={classes.cardTitle}
                    style={{
                      color: '#FFF',
                      fontWeight: '400',
                      fontSize: '28px',
                    }}
                  >
                    Vendor List
                  </h5>
                  <Refresh
                    fontSize="large"
                    onClick={() => this.refreshVendorList()}
                    style={{
                      float: 'right',
                      top: '0px',
                      cursor: 'pointer',
                      fontSize: 30,
                    }}
                  />
                </CardHeader>
                <CardBody>
                  {isLoading ? (
                    <CircularProgress
                      className={classes.progress}
                      style={{ color: purple[500] }}
                      thickness={7}
                    />
                  ) : (
                    <>
                      {/* <CardBody> */}
                      <ReactTable
                        getTheadThProps={() => ({
                          style: {
                            outline: 0,
                            color: 'black',
                            fontSize: '18px',
                            fontWeight: '400',
                          },
                        })}
                        PaginationComponent={Pagination}
                        // style={{
                        //   height: '00px',
                        // }}
                        data={this.props.vendors
                          .sort(this.compare)
                          .map((prop, key) => ({
                            id: key,
                            name: prop.data[0],
                            contactPerson: prop.data[1],
                            phone: prop.data[2],
                            email: prop.data[3],
                            actions: (
                              <div className="actions-right">
                                <Button
                                  justIcon
                                  round
                                  simple
                                  onClick={() =>
                                    this.openEditForm(prop.dataList)
                                  }
                                  color="info"
                                  className="like"
                                >
                                  <Create />
                                </Button>{' '}
                              </div>
                            ),
                          }))}
                        filterable
                        columns={[
                          {
                            filterable: false,
                            Header: 'Name',
                            accessor: 'name',
                            Cell: row => this.changeFont(row.value),
                            Filter: ({ filter, onChange }) => (
                              <input
                                onChange={event => onChange(event.target.value)}
                                value={filter ? filter.value : ''}
                                placeholder="Name"
                                style={{
                                  width: '100%',
                                  backgroundColor: 'white',
                                  color: 'black',
                                }}
                              />
                            ),
                          },
                          {
                            filterable: false,
                            Header: 'Contact Person',
                            accessor: 'contactPerson',
                            Cell: row => this.changeFont(row.value),
                            Filter: ({ filter, onChange }) => (
                              <input
                                onChange={event => onChange(event.target.value)}
                                value={filter ? filter.value : ''}
                                placeholder="Contact Person"
                                style={{
                                  width: '100%',
                                  backgroundColor: 'white',
                                  color: 'black',
                                }}
                              />
                            ),
                          },
                          {
                            filterable: false,
                            Header: 'Phone',
                            accessor: 'phone',
                            Cell: row => this.changeFont(row.value),
                            Filter: ({ filter, onChange }) => (
                              <input
                                onChange={event => onChange(event.target.value)}
                                value={filter ? filter.value : ''}
                                placeholder="Phone"
                                style={{
                                  width: '100%',
                                  backgroundColor: 'white',
                                  color: 'black',
                                }}
                              />
                            ),
                          },
                          {
                            filterable: false,
                            Header: 'Email',
                            accessor: 'email',
                            Cell: row => this.changeFont(row.value),
                            Filter: ({ filter, onChange }) => (
                              <input
                                onChange={event => onChange(event.target.value)}
                                value={filter ? filter.value : ''}
                                placeholder="Email"
                                style={{
                                  width: '100%',
                                  backgroundColor: 'white',
                                  color: 'black',
                                }}
                              />
                            ),
                          },
                          {
                            Header: 'Actions',
                            accessor: 'actions',
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        minRows={0}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom={false}
                        className="-striped -highlight"
                      />
                      {/* </CardBody> */}
                    </>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        ) : (
          <VendorEdit goBack={this.goToView} selectedVendor={selectedVendor} />
        )}
      </>
    );
  }
}

VendorList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setVendors,
    },
    dispatch
  );

const mapStateToProps = state => ({
  walletBalance: state.main.walletBalance,
  vendors: state.transactions.vendors,
  vendorLastEvaluatedKey: state.transactions.vendorLastEvaluatedKey,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(VendorList));
