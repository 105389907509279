/* eslint-disable no-prototype-builtins */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
import React from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// material-ui icons
import Assignment from '@material-ui/icons/Assignment';
import PropTypes from 'prop-types';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Slide from '@material-ui/core/Slide';

import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import AddAlert from '@material-ui/icons/AddAlert';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import ReactTable from 'react-table';
import Refresh from '@material-ui/icons/Refresh';
import Tooltip from '@material-ui/core/Tooltip';
import LoadingOverlay from 'react-loading-overlay';
import InputLabel from '@material-ui/core/InputLabel';
import ReactSelect from 'react-select';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';
import Cookie from 'universal-cookie';
import moment from 'moment';
import PaginationHistory from './PaginationHistory';
import { setVendorTransactions } from '../../reducers/vendorHistory';

import apiCaller from '../../utils/apiCaller';

const cookies = new Cookie();

const style = {
  customCardContentClass: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  tableFont: {
    fontSize: '25px',
    marginTop: '15px',
    color: 'green',
  },
};

function Transition(props) {
  return <Slide direction="down" {...props} />;
}
class VendorHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      transactionData: [],
      truckers: [],
      selectedTrucker: '',
      truckerId: '',
      truckerName: '',
      infoMessage: '',
      infoSnack: false,
      errSnack: false,
      errMessage: '',
      isLoading: false,
      updateDate: '',
      verifyLoader: false,
    };
    this.getTransactionList = this.getTransactionList.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.refreshTransactions = this.refreshTransactions.bind(this);
    this.changeFont = this.changeFont.bind(this);
    this.getvendorList = this.getvendorList.bind(this);
  }

  componentDidMount() {
    this.setState({ transactionData: [] });
    this.getvendorList();
  }

  changeFont(value, indexValue) {
    let color;
    let values = value;
    switch (value) {
      case 'queued':
        color = '#d480f2';
        values = 'Queued';
        break;
      case 'otp_verified':
        color = '#87e0ed';
        values = 'Verified';
        break;
      case 'payout.processed':
        color = '#74f285';
        values = 'Processed';
        break;
      case 'payout.processing':
        color = '#0000FF';
        values = 'Processing';
        break;
      case 'user_created':
        color = '#7c78ff';
        values = 'Created';
        break;
      case 'payout.reversed':
        color = '#79f299';
        values = 'Reversed';
        break;
      case 'payout.failed':
        color = '#ba2d36';
        values = 'Failed';
        break;
      case 'admin_rejected':
        color = '#e6303c';
        values = 'Rejected';
        break;
      case 'wallet_failed':
        color = '#e6303c';
        values = 'LOW BALANCE';
        break;
      case 'paas_failed':
        color = '#e6303c';
        values = 'Paas Failed';
        break;
      default:
        color = '#000000';
    }
    return indexValue !== 'date' ? (
      <span style={{ fontSize: '16px', color }}>
        {color !== '#000000' ? values.toUpperCase() : values}
      </span>
    ) : (
      <span style={{ fontSize: '16px', color }}>
        {indexValue === 'date'
          ? moment(value, 'DD-MM-YYYY hh:mm:ss').format('MMM Do')
          : '___'}
      </span>
    );
  }

  handleTrucker = selectedTrucker => {
    this.setState({
      selectedTrucker,
      truckerName: selectedTrucker.truckerName,
      truckerId: selectedTrucker.truckerId,
    });
    this.getTransactionList(selectedTrucker);
  };

  getvendorList() {
    const body = {
      total_items: 50,
    };
    const { truckers } = this.state;
    apiCaller('premium-api/read-vendor-list', 'post', body, true, false)
      .then(response => {
        response.items.forEach(vendorData => {
          truckers.push({
            name: vendorData.company_name,
            label: vendorData.company_name,
            truckerId: vendorData.trucker_id,
            truckerName: vendorData.company_name,
          });
        });
        this.setState({
          truckers: truckers.sort((a, b) =>
            a.truckerName.localeCompare(b.truckerName)
          ),
        });
      })
      .catch(err => {
        this.setState(
          {
            errMessage: err.message,
            isLoading: false,
          },
          this.showNotification('errSnack')
        );
      });
  }

  refreshTransactions() {
    const transactionData = [];
    const selectedTrucker = '';
    this.setState({
      transactionData,
      selectedTrucker,
    });
    this.getTransactionList(selectedTrucker);
  }

  getTransactionList(selectedTruck) {
    this.setState({ verifyLoader: true });
    const transactions = [];
    const transactionData = JSON.parse(JSON.stringify(transactions));
    let body = {};
    let nextCursorId = null;
    if (selectedTruck !== '') {
      body = {
        vendor_id: selectedTruck.truckerId,
        total_items: 25,
      };
    } else {
      body = {
        total_items: 25,
      };
    }
    apiCaller('premium-api/read-payout-list', 'post', body, true, false)
      .then(response => {
        if (response.items.length !== 0) {
          this.setState({
            updateDate: moment
              .unix(response.current_time)
              .format('DD-MM-YYYY hh:mm:ss'),
          });
          response.items.forEach(dataList => {
            if (
              response.hasOwnProperty('LastEvaluatedKey') &&
              response.LastEvaluatedKey !== null
            ) {
              nextCursorId = response.LastEvaluatedKey;
            }
            if (dataList.payin !== true) {
              transactionData.push({
                color: 'success',
                data: [
                  // DD-MM-YYYY hh:mm:ss MMM Do YY
                  moment
                    .unix(dataList.created_at)
                    .format('DD-MM-YYYY hh:mm:ss'),
                  dataList.serial_index || '___',
                  dataList.amount || '___',
                  dataList.account_number || '___',
                  dataList.status || '___',
                  dataList.truck_number || '___',
                  dataList.vendor_name || '___',
                  dataList.service_provider_utr || '___',
                  dataList.premium_transporter_id || '___',
                ],
                dataList,
              });
            }
          });
          this.props.setVendorTransactions(transactionData, nextCursorId);
          if (
            response.hasOwnProperty('LastEvaluatedKey') &&
            response.LastEvaluatedKey !== null
          ) {
            this.getNextTransactions();
          }
          setTimeout(() => {
            this.setState({ verifyLoader: false });
          }, 1000);
        } else {
          this.props.setVendorTransactions([], nextCursorId);
          this.setState({ verifyLoader: false });
        }
      })
      .catch(err => {
        this.setState({ verifyLoader: false });
      });
  }

  getNextTransactions() {
    const { lastEvaluatedKey, vendorTransactions } = this.props;
    const transactionData = JSON.parse(JSON.stringify(vendorTransactions));
    const body = {
      total_items: 25,
    };
    if (lastEvaluatedKey !== '' || lastEvaluatedKey !== null) {
      body.LastEvaluatedKey = lastEvaluatedKey;
      body.vendor_id = lastEvaluatedKey.vendor_id;
    }
    let nextCursorId = null;
    apiCaller('premium-api/read-payout-list', 'post', body, true, false)
      .then(response => {
        if (response.items.length !== 0) {
          response.items.forEach(dataList => {
            if (
              response.hasOwnProperty('LastEvaluatedKey') &&
              response.LastEvaluatedKey !== null
            ) {
              nextCursorId = response.LastEvaluatedKey;
            }
            if (dataList.payin !== true) {
              transactionData.push({
                color: 'success',
                data: [
                  moment
                    .unix(dataList.created_at)
                    .format('DD-MM-YYYY hh:mm:ss'),
                  dataList.serial_index || '___',
                  dataList.amount || '___',
                  dataList.account_number || '___',
                  dataList.status || '___',
                  dataList.truck_number || '___',
                  dataList.vendor_name || '___',
                  dataList.service_provider_utr || '___',
                ],
                dataList,
              });
            }
          });
          this.props.setVendorTransactions(transactionData, nextCursorId);
        }
      })
      .catch(err => {});
  }

  updateWallet(e) {
    const role = cookies.get('role');
    if (role !== 'super_admin') {
      this.props.setWalletBalance();
    }
    e.preventDefault();
  }

  // eslint-disable-next-line react/sort-comp
  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  cancelPayableOtp(modal) {
    this.setState({
      classicModal: false,
      isLoadingCancel: false,
      isLoadingOtp: false,
      otp: '',
      otpState: '',
    });
  }

  showDialog() {
    const { classes } = this.props;
    const {
      classicModal,
      name,
      otp,
      otpState,
      isLoadingOtp,
      isLoadingCancel,
      otpError,
      isOtpNotification,
      transactionSerial,
    } = this.state;
    return (
      <Dialog
        classes={{
          root: `${classes.center}`,
          // paper: classes.modal,
        }}
        open={classicModal}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}># {transactionSerial} </h4>
          <h4 className={classes.modalTitle}>{name} Please Enter OTP</h4>
          <Snackbar
            place="tc"
            color="info"
            icon={AddAlert}
            message="OTP sent"
            open={isOtpNotification}
            closeNotification={() =>
              this.setState({ isOtpNotification: false })
            }
            close
          />
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
              <CustomInput
                labelText="Enter Otp"
                id="truck-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event => this.change(event, 'otp', 'otp', 5),
                  type: 'length',
                  value: otp,
                }}
                success={otpState === 'success'}
                error={otpState === 'error'}
              />
              <Button
                color="info"
                simple
                onClick={() => this.resendOtpPayable()}
              >
                Resend OTP
              </Button>
            </GridItem>
            <p style={{ color: 'red' }}>{otpError}</p>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {isLoadingOtp || isLoadingCancel ? (
            <div>
              <CircularProgress
                className={classes.progress}
                style={{ color: purple[500] }}
                thickness={7}
              />
            </div>
          ) : (
            <div>
              <Button onClick={() => this.cancelPayableOtp('classicModal')}>
                Cancel
              </Button>
              <Button onClick={() => this.submitOtp()} color="success">
                Submit OTP
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  /**
   * will return the notification snackbar
   * @param {string} place
   */
  showNotification(place) {
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 5000);
    }
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  verifyOtp(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value) && value.toString().length === 5) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'otp':
        if (this.verifyOtp(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        this.setState({
          [`${stateName}State`]: 'success',
          [stateName]: event.target.value,
        });
        break;
    }
  }

  render() {
    const { classes } = this.props;
    const { verifyLoader, truckers, selectedTrucker } = this.state;
    const { errSnack, errMessage, infoMessage, infoSnack } = this.state;
    const colourStyles = {
      control: styles => ({ ...styles, backgroundColor: 'white' }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isDisabled ? 'red' : '#FFF',
        color: '#000',
        cursor: isDisabled ? 'not-allowed' : 'default',
      }),
    };
    return (
      <GridContainer>
        {this.showDialog()}
        <GridItem xs={12}>
          <Snackbar
            place="tc"
            color="success"
            icon={AddAlert}
            message={infoMessage || ''}
            open={infoSnack}
            closeNotification={() => this.setState({ infoSnack: false })}
            close
          />
          <Snackbar
            place="tc"
            color="danger"
            icon={AddAlert}
            message={errMessage || ''}
            open={errSnack}
            closeNotification={() => this.setState({ errSnack: false })}
            close
          />
          <Card>
            <CardHeader color="info">
              <CardIcon color="warning">
                <Assignment />
              </CardIcon>
              <h5
                className={classes.cardTitle}
                style={{ color: '#FFF', fontWeight: '400', fontSize: '28px' }}
              >
                Vendor Transactions
              </h5>
              <Refresh
                onClick={() => this.refreshTransactions()}
                style={{
                  float: 'right',
                  top: '0px',
                  cursor: 'pointer',
                  fontSize: 30,
                  marginTop: '25px',
                }}
              />
              <h4
                style={{
                  float: 'right',
                  top: '0px',
                  marginRight: '15px',
                  marginTop: '32px',
                }}
              >
                {this.state.updateDate}
              </h4>
              <div>
                <GridItem
                  xs={12}
                  sm={6}
                  md={4}
                  style={{ float: 'right', top: '0px', width: '240px' }}
                >
                  <InputLabel className={classes.labelText} />
                  <ReactSelect
                    value={selectedTrucker}
                    onChange={this.handleTrucker}
                    options={truckers}
                    placeholder="Select Vendor *"
                    styles={colourStyles}
                  />
                </GridItem>
              </div>
            </CardHeader>
            <CardBody>
              <LoadingOverlay
                active={verifyLoader}
                spinner
                text="Loading Transactions..."
              >
                <ReactTable
                  getTheadThProps={() => ({
                    style: {
                      outline: 0,
                      color: 'black',
                      fontSize: '18px',
                      fontWeight: '400',
                      textAlign: 'left',
                    },
                  })}
                  PaginationComponent={PaginationHistory}
                  data={this.props.vendorTransactions.map((prop, key) => ({
                    id: key,
                    transaction_number: prop.data[1],
                    amount: prop.data[2],
                    account_no: prop.data[3],
                    vendor_name: prop.data[6],
                    truck_no: prop.data[5],
                    date: prop.data[0],
                    utr: prop.data[7],
                    transporter: prop.data[8],
                    status: prop.data[4],
                  }))}
                  filterable
                  columns={[
                    {
                      filterable: false,
                      Header: 'TXN',
                      accessor: 'transaction_number',
                      Cell: row => this.changeFont(row.value, 'number'),
                      Filter: ({ filter, onChange }) => (
                        <input
                          onChange={event => onChange(event.target.value)}
                          value={filter ? filter.value : ''}
                          placeholder="Transaction"
                          style={{
                            width: '100%',
                            backgroundColor: 'white',
                            color: 'black',
                          }}
                        />
                      ),
                    },
                    {
                      filterable: false,
                      Header: () => (
                        <div
                          style={{
                            textAlign: 'right',
                          }}
                        >
                          Amount
                        </div>
                      ),
                      accessor: 'amount',
                      Cell: row => (
                        <div
                          style={{
                            textAlign: 'right',
                            fontWeight: 500,
                          }}
                        >
                          <i
                            className="fa fa-inr"
                            style={{ fontSize: '15px' }}
                            aria-hidden="true"
                          />{' '}
                          {row.value}
                        </div>
                      ),
                      Filter: ({ filter, onChange }) => (
                        <input
                          onChange={event => onChange(event.target.value)}
                          value={filter ? filter.value : ''}
                          placeholder="Amount"
                          style={{
                            width: '100%',
                            backgroundColor: 'white',
                            color: 'black',
                            textAlign: 'right',
                          }}
                        />
                      ),
                      sortable: false,
                    },
                    {
                      filterable: false,
                      Header: 'Acc No',
                      accessor: 'account_no',
                      Cell: row => this.changeFont(row.value, 'acctn_no'),
                      Filter: ({ filter, onChange }) => (
                        <input
                          onChange={event => onChange(event.target.value)}
                          value={filter ? filter.value : ''}
                          placeholder="Accnt no"
                          style={{
                            width: '100%',
                            backgroundColor: 'white',
                            color: 'black',
                          }}
                        />
                      ),
                    },
                    {
                      filterable: false,
                      Header: 'Vendor',
                      accessor: 'vendor_name',
                      Cell: row => this.changeFont(row.value, 'vendor'),
                      Filter: ({ filter, onChange }) => (
                        <input
                          onChange={event => onChange(event.target.value)}
                          value={filter ? filter.value : ''}
                          placeholder="Vendor Name"
                          style={{
                            width: '100%',
                            backgroundColor: 'white',
                            color: 'black',
                          }}
                        />
                      ),
                    },
                    {
                      Header: 'Truck No',
                      accessor: 'truck_no',
                      Cell: row => this.changeFont(row.value, 'truck_no'),
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: 'Date',
                      accessor: 'date',
                      Cell: row => (
                        <Tooltip
                          title={
                            <span style={{ fontSize: '15px' }}>
                              {row.value}
                            </span>
                          }
                          arrow
                        >
                          {this.changeFont(row.value, 'date')}
                        </Tooltip>
                      ),
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: 'UTR',
                      accessor: 'utr',
                      Cell: row => this.changeFont(row.value, 'utr'),
                      sortable: false,
                      filterable: false,
                    },
                    {
                      filterable: false,
                      Header: 'Status',
                      accessor: 'status',
                      Cell: row => (
                        <div style={{ fontWeight: 500 }}>
                          {this.changeFont(row.value, 'status')}
                        </div>
                      ),
                      Filter: ({ filter, onChange }) => (
                        <input
                          onChange={event => onChange(event.target.value)}
                          value={filter ? filter.value : ''}
                          placeholder="Status"
                          style={{
                            width: '100%',
                            backgroundColor: 'white',
                            color: 'black',
                          }}
                        />
                      ),
                    },
                  ]}
                  minRows={0}
                  defaultPageSize={25}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight tableFont"
                />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

VendorHistory.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setVendorTransactions,
    },
    dispatch
  );

const mapStateToProps = state => ({
  vendorTransactions: state.vendorTransactions.vendorTransactions,
  lastEvaluatedKey: state.vendorTransactions.lastEvaluatedKey,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(VendorHistory));
