/* eslint-disable no-var */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Button from '@material-ui/core/Button';
import TableHead from '@material-ui/core/TableHead';
import IconButton from '@material-ui/core/IconButton';
import LoopIcon from '@material-ui/icons/Loop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Popup from 'reactjs-popup';
import moment from 'moment';
import Popover from '@material-ui/core/Popover';
import 'reactjs-popup/dist/index.css';
import { Typography } from '@material-ui/core';
import BulkTransactionHistoryModal from './BulkTransactionHistoryModal';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 50,
  },
  tableHead: {
    backgroundColor: 'black',
  },
  tableCell: {
    color: 'white',
  },
  sampleButton: {
    background: '#3DAC16',
    color: 'white',
    // width: '124px',
    height: '30px',
    // padding: '1px 10px',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));
var next = false;
var activePage = 0;
function createData(date, time, UploadedBy, success, failure, status, action) {
  return { date, time, UploadedBy, success, failure, status, action };
}
export default function DenseTable({
  data,
  fetching,
  refreshCall,
  lastDataKey,
  lastDataTimestamp,
  previousData,
  previousDisabled,
  PageRecords,
  lastPage,
}) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [activePage, setActivePage] = React.useState(0);
  const [lastRefreshTime, setLastRefreshTime] = React.useState(
    moment().format('MMMM Do YYYY, h:mm:ss a')
  );

  const handleChangePage = () => {
    // setPage(page + 1);
    next = true;
    activePage += 1;
    refreshCall(lastDataKey, lastDataTimestamp, activePage, next);
  };
  const handlePreviousChangePage = () => {
    const previous = true;
    activePage -= 1;
    refreshCall(lastDataKey, lastDataTimestamp, activePage, next, previous);
  };
  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleRefreshCall = () => {
    setLastRefreshTime(moment().format('MMMM Do YYYY, h:mm:ss a'));
    refreshCall();
  };

  const open = Boolean(anchorEl);
  const rows = data?.map(item =>
    createData(
      item.date,
      item.time,
      item.user_id,
      item.success_count,
      item.failure_count,
      item.status,
      item.log_id
    )
  );
  const headers = [
    { title: 'Date' },
    { title: 'Time' },
    { title: 'Uploaded by' },
    { title: 'Transaction Success' },
    { title: 'Transaction Failure' },
    { title: 'Status' },
    { title: 'Summary' },
    {
      title: (
        <>
          <IconButton
            onClick={handleRefreshCall}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <LoopIcon style={{ color: 'white' }} />
          </IconButton>
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography>Last Refreshed at {lastRefreshTime}</Typography>
          </Popover>
        </>
      ),
    },
  ];

  // useEffect(() => {
  //   console.log('activePage: ---', activePage);
  // }, []);
  // console.log('PageRecords: ---', PageRecords);

  return (
    <Box className={classes.tableContainer}>
      <Typography variant="h6" gutterBottom>
        Bulk Transaction history
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '20px',
        }}
      >
        {/* <ButtonGroup disableElevation variant="contained" color="gray"> */}
        <Button
          onClick={handlePreviousChangePage}
          disabled={activePage == 0 || previousDisabled}
          variant="contained"
        >
          Previous
        </Button>
        <Button
          onClick={handleChangePage}
          disabled={lastPage}
          variant="contained"
        >
          next
        </Button>
        {/* </ButtonGroup> */}
      </div>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead className={classes.tableHead}>
            <TableRow>
              {headers.map(item => (
                <TableCell align="center" className={classes.tableCell}>
                  {item.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {fetching ? (
              <CircularProgress style={{ margin: 40 }} />
            ) : (
              // rows
              //   ?
              //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              rows?.map(row => (
                <TableRow key={row.date}>
                  <TableCell
                    align="center"
                    style={{
                      padding: '20px',
                      fontWeight: 'bold',
                    }}
                  >
                    {row.date}
                  </TableCell>
                  <TableCell align="center" style={{ padding: '20px' }}>
                    {row.time}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      padding: '20px',
                      fontWeight: 'bold',
                    }}
                  >
                    {row.UploadedBy}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: 'green', padding: '20px' }}
                  >
                    {row.success}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: 'red', padding: '20px' }}
                  >
                    {row.failure}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color:
                        row.status == 'Completed'
                          ? 'green'
                          : row.status == 'Processing'
                          ? '#FF7E03'
                          : row.status == 'Initiated'
                          ? '#163BAC'
                          : '',
                      padding: '20px',
                    }}
                  >
                    {row.status}
                  </TableCell>
                  <TableCell align="center" style={{ padding: '20px' }}>
                    {row.status == 'Completed' ? (
                      <Popup
                        trigger={
                          <Button
                            startIcon={<AssignmentIcon />}
                            className={classes.sampleButton}
                          >
                            View
                          </Button>
                        }
                        position="right center"
                        modal
                      >
                        <BulkTransactionHistoryModal
                          logID={row.action}
                          data={data}
                        />
                      </Popup>
                    ) : (
                      ''
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
