/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-empty */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import AddAlert from '@material-ui/icons/AddAlert';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';

import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import ReactGA from 'react-ga';

import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Lock from '@material-ui/icons/Lock';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx';
import { setActiveRole } from '../../reducers/main';
import { addAuthtoReduxStore } from '../../reducers/auth';
import history from '../../history';
import { checkAuth, setAuthCookies } from '../../services';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: 'cardHidden',
      name: '',
      nameState: '',
      password: '',
      passwordState: '',
      userName: '',
      userNameState: '',
      code: '',
      codeState: '',
      newPassword: '',
      newPasswordState: '',
      loginView: true,
      resetView: false,
      newPasswordView: false,
      confirmCodeView: false,
      forcePasswordView: false,
      limit: false,
      limitMessage: 'You exceed the limit of 5',
      errMessage: '',
      errSnack: false,
      infoMessage: '',
      infoSnack: false,
      user: {},
      confirmPassword: '',
    };
    this.validateAllFields = this.validateAllFields.bind(this);

    this.confirmSignIn = this.confirmSignIn.bind(this);

    this.resendVerificationCode = this.resendVerificationCode.bind(this);
    this.sendResetPasswordCode = this.sendResetPasswordCode.bind(this);
    this.goToNewPasswordScreen = this.goToNewPasswordScreen.bind(this);
    this.goToForcePasswordScreen = this.goToForcePasswordScreen.bind(this);
    this.goToResetPasswordScreen = this.goToResetPasswordScreen.bind(this);
    this.goToRegisterScreen = this.goToRegisterScreen.bind(this);
    this.goToSignIn = this.goToSignIn.bind(this);
    this.goToConfirmCodeView = this.goToConfirmCodeView.bind(this);
    this.signIn = this.signIn.bind(this);
    this.change = this.change.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.submitNewPassword = this.submitNewPassword.bind(this);
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(() => {
      this.setState({ cardAnimaton: '' });
    }, 700);
    this.checkAuth();
  }

  /**
   * Check weather the user is Authenticated
   * @function
   */
  checkAuth() {
    checkAuth()
      .then(result => {
        this.setState({
          isLoading: false,
        });
        if (result.isAuthenticated) {
          if (result.role === 'user') {
            this.setState({
              validateOtpForm: true,
            });
          } else if (result.role === 'super_admin') {
            history.push('/super_admin/dashboard');
          } else if (result.role === 'admin') {
            history.push('/admin/dashboard');
          } else if (result.role === 'checker') {
            history.push('/checker/dashboard');
          } else if (result.role === 'maker') {
            history.push('/maker/dashboard');
          }
        } else {
          this.setState(
            {
              errMessage: 'Please Register to login!',
            },
            this.showNotification('errSnack')
          );
          history.push('/pages/login-page');
        }
        this.props.addAuthtoReduxStore(result);
        setAuthCookies(result);
      })
      .catch(err => {
        const result = {
          isAuthenticated: false,
          role: '',
          activeRole: '',
          userDetails: {
            name: '',
            email: '',
            phoneNumber: '',
          },
          idToken: '',
          accessToken: '',
          refreshToken: '',
          username: '',
          userPoolId: '',
        };
        this.props.addAuthtoReduxStore(result);
        setAuthCookies(result);
        history.push('/pages/login-page');
      });
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  verifyOtp(value) {
    // const numberRex = new RegExp('^[0-9]+$');
    return true;
  }

  verifyConfirmPassword(value) {
    const { newPassword } = this.state;
    if (value === newPassword) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'required':
        if (this.verifyRequired(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'otp':
        if (this.verifyOtp(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'confirmPassword':
        if (this.verifyConfirmPassword(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  /**
   * Shows the notification when user click submit for success message.
   */
  showNotification = place => {
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 6000);
    }
  };

  // validates all required fields and returns a promise
  validateAllFields(screen) {
    const {
      password,
      userName,
      code,
      newPassword,
      confirmPassword,
    } = this.state;
    let error = false;
    return new Promise((resolve, reject) => {
      if (screen === 'signin') {
        // Verify  Name
        if (this.verifyLength(userName, 1)) {
          this.setState({
            userNameState: 'success',
          });
        } else {
          this.setState({ userNameState: 'error' });
          error = true;
        }
        // Verify  password
        if (this.verifyLength(password, 1)) {
          this.setState({
            passwordState: 'success',
          });
        } else {
          this.setState({ passwordState: 'error' });
          error = true;
        }
      } else if (screen === 'resetPassword') {
        if (this.verifyLength(userName, 1)) {
          this.setState({
            userNameState: 'success',
          });
        } else {
          this.setState({ userNameState: 'error' });
          error = true;
        }
      } else if (screen === 'newPassword') {
        if (this.verifyLength(userName, 1)) {
          this.setState({
            userNameState: 'success',
          });
        } else {
          this.setState({ userNameState: 'error' });
          error = true;
        }
        // Verify  password
        if (this.verifyLength(newPassword, 1)) {
          this.setState({
            newPasswordState: 'success',
          });
        } else {
          this.setState({ newPasswordState: 'error' });
          error = true;
        }
        if (this.verifyLength(code, 1)) {
          this.setState({
            codeState: 'success',
            errMessage: '',
          });
        } else {
          this.setState(
            {
              codeState: 'error',
              errMessage: 'Invalid Otp',
            },
            this.showNotification('errSnack')
          );
          error = true;
        }
      } else if (screen === 'confirmCode') {
        if (this.verifyOtp(code)) {
          this.setState({
            codeState: 'success',
            errMessage: '',
          });
        } else {
          this.setState(
            {
              codeState: 'error',
              errMessage: 'Invalid Otp',
            },
            this.showNotification('errSnack')
          );
          error = true;
        }
      } else if (screen === 'forceChangePassword') {
        if (this.verifyLength(newPassword, 1)) {
          this.setState({
            newPasswordState: 'success',
          });
        } else {
          this.setState({ newPasswordState: 'error' });
          error = true;
        }

        if (this.verifyLength(confirmPassword, 1)) {
          this.setState({
            confirmPasswordState: 'success',
          });
        } else {
          this.setState({ confirmPasswordState: 'error' });
          error = true;
        }

        if (this.verifyConfirmPassword(confirmPassword)) {
          this.setState({
            confirmPasswordState: 'success',
          });
        } else {
          this.setState({ confirmPasswordState: 'error' });
          error = true;
        }
      }

      if (!error) {
        resolve(true);
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('validation Failed');
      }
    });
  }

  goToRegisterScreen() {
    history.push('/pages/register-page');
  }

  goToSignIn() {
    this.setState({
      loginView: true,
      resetView: false,
      newPasswordView: false,
      confirmCodeView: false,
      forcePasswordView: false,
    });
  }

  goToResetPasswordScreen() {
    this.setState({
      loginView: false,
      resetView: true,
      newPasswordView: false,
      confirmCodeView: false,
      forcePasswordView: false,
    });
  }

  goToNewPasswordScreen() {
    this.setState({
      loginView: false,
      resetView: false,
      newPasswordView: true,
      confirmCodeView: false,
      forcePasswordView: false,
    });
  }

  goToConfirmCodeView() {
    this.setState({
      loginView: false,
      resetView: false,
      newPasswordView: false,
      confirmCodeView: true,
      forcePasswordView: false,
    });
  }

  goToForcePasswordScreen() {
    this.setState({
      loginView: false,
      resetView: false,
      newPasswordView: false,
      confirmCodeView: false,
      forcePasswordView: true,
    });
  }

  submitNewPassword(event) {
    const { userName, code, newPassword } = this.state;
    this.validateAllFields('newPassword')
      .then(success => {
        if (success) {
          Auth.forgotPasswordSubmit(userName, code, newPassword)
            .then(data => {
              this.setState(
                {
                  infoMessage: 'Password Reset successfully',
                },
                this.showNotification('infoSnack')
              );
              this.goToSignIn();
              this.clearForm();
            })
            .catch(err => {
              this.setState(
                {
                  errMessage: err.message,
                },
                this.showNotification('errSnack')
              );
            });
        }
      })
      .catch(err => {
        console.log('error', err);
      });
    event.preventDefault();
  }

  confirmSignIn(event) {
    const { userName, code } = this.state;
    this.validateAllFields('confirmCode')
      .then(success => {
        if (success) {
          Auth.confirmSignUp(userName, code, {
            // Optional. Force user confirmation irrespective of existing alias. By default set to True.
            forceAliasCreation: true,
          })
            .then(data => {
              this.setState(
                {
                  infoMessage: 'Successfully Verified',
                },
                this.showNotification('infoSnack')
              );
              this.goToSignIn();
            })
            .catch(err => {
              this.setState(
                {
                  errMessage: err.message,
                },
                this.showNotification('errSnack')
              );
              console.log('Error', err);
            });
        }
      })
      .catch(err => {
        console.log('error', err);
      });
    event.preventDefault();
  }

  signIn(event) {
    const { userName, password } = this.state;
    this.validateAllFields('signin')
      .then(success => {
        if (success) {
          // Auth.signIn('test', 'test1234')
          Auth.signIn(userName, password)
            .then(user => {
              ReactGA.event({
                category: 'OTP',
                action: 'Login',
                label: 'Logged In',
              });
              // this.props.addAuthtoReduxStore(auth);
              if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                this.setState(
                  {
                    user,
                  },
                  this.goToForcePasswordScreen()
                );
                this.setState(
                  {
                    infoMessage: 'Please change your password',
                  },
                  this.showNotification('infoSnack')
                );
              } else {
                this.clearForm();
                history.push('/pages/initial-page');
              }
            })
            .catch(err => {
              console.log('Error in SIgnin', err);
              if (err.hasOwnProperty('code')) {
                if (err.code === 'UserNotConfirmedException') {
                  this.setState(
                    {
                      infoMessage:
                        'Please Enter verification code sent to your mobile number',
                    },
                    this.showNotification('infoSnack')
                  );
                  this.goToConfirmCodeView();
                } else {
                  this.setState(
                    {
                      errMessage: err.message,
                    },
                    this.showNotification('errSnack')
                  );
                }
              }
            });
          // history.push(`/${role}/dashbaord`);
        }
      })
      .catch(err => {
        console.log('error', err);
        this.setState(
          {
            errMessage: err.message,
          },
          this.showNotification('errSnack')
        );
      });
    event.preventDefault();
  }

  sendResetPasswordCode(event) {
    const { userName } = this.state;
    Auth.forgotPassword(userName)
      .then(data => {
        this.setState(
          {
            infoMessage: `Code sent to ${data.CodeDeliveryDetails.Destination}`,
          },
          this.showNotification('infoSnack')
        );
        this.goToNewPasswordScreen();
      })
      .catch(err => {
        console.log('Error', err);
        this.setState(
          {
            errMessage: `${err.message}`,
          },
          this.showNotification('errSnack')
        );
      });
    event.preventDefault();
  }

  clearForm() {
    this.setState({
      name: '',
      nameState: '',
      password: '',
      passwordState: '',
      newPassword: '',
    });
  }

  resendVerificationCode() {
    const { userName } = this.state;
    this.validateAllFields('signin').then(success => {
      if (success) {
        Auth.resendSignUp(userName)
          .then(data => {
            this.setState(
              {
                infoMessage: `Code sent to ${data.CodeDeliveryDetails.Destination}`,
              },
              this.showNotification('infoSnack')
            );
          })
          .catch(e => {
            this.setState(
              {
                errMessage: `${e.message}`,
              },
              this.showNotification('errSnack')
            );
            console.log('Error in sending veification code', e);
          });
      }
    });
  }

  forceChangePassword(event) {
    const { newPassword, user } = this.state;
    this.validateAllFields('forceChangePassword').then(success => {
      if (success) {
        Auth.completeNewPassword(user, newPassword)
          .then(data => {
            this.setState(
              {
                infoMessage: `Password successfully changed`,
              },
              this.showNotification('infoSnack')
            );
            this.clearForm();
            history.push('/pages/initial-page');
          })
          .catch(err => {
            console.log('Error', err);
            this.setState(
              {
                errMessage: `${err.message}`,
              },
              this.showNotification('errSnack')
            );
          });
      }
    });

    event.preventDefault();
  }

  renderLoginView() {
    const { classes } = this.props;
    const { password, passwordState, userName, userNameState } = this.state;
    return (
      <form className={classes.form} onSubmit={event => this.signIn(event)}>
        <h4>Sign in to your account</h4>
        <GridContainer>
          <GridItem xs={12} sm={6} md={12}>
            <CustomInput
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                onChange: event => this.change(event, 'userName', 'length', 2),
                type: 'length',
                value: userName,
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                placeholder: 'User Name',
              }}
              success={userNameState === 'success'}
              error={userNameState === 'error'}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={12}>
            <CustomInput
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                onChange: event => this.change(event, 'password', 'length', 4),
                type: 'password',
                value: password,
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                placeholder: 'Password',
              }}
              success={passwordState === 'success'}
              error={passwordState === 'error'}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={9}>
            <h6>
              <a href="#" onClick={this.goToResetPasswordScreen}>
                Forgot Password? Reset Password
              </a>
            </h6>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={8} md={8}>
            <h6>
              <a href="#" onClick={this.goToRegisterScreen}>
                No Account? Create Account
              </a>
            </h6>
          </GridItem>
          <GridItem xs={12} sm={8} md={4}>
            <Button color="warning" type="submit">
              Sign In
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  }

  renderConfirmCodeView() {
    const { classes } = this.props;
    const { passwordState, userName, userNameState, code } = this.state;
    return (
      <form
        className={classes.form}
        onSubmit={event => this.confirmSignIn(event)}
      >
        <h4>Confirm Sign Up</h4>
        <GridContainer>
          <GridItem xs={12} sm={6} md={12}>
            <CustomInput
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                onChange: event => this.change(event, 'userName', 'length', 2),
                type: 'length',
                value: userName,
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                placeholder: 'User Name',
                disabled: true,
              }}
              success={userNameState === 'success'}
              error={userNameState === 'error'}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={12}>
            <CustomInput
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                onChange: event => this.change(event, 'code', 'length', 4),
                type: 'length',
                value: code,
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                placeholder: 'Confirmation Code',
              }}
              success={passwordState === 'success'}
              error={passwordState === 'error'}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={9}>
            {/* <a href="#" > */}
            <h6>
              <a href="#" onClick={this.resendVerificationCode}>
                Lost your code? Resend code
              </a>
            </h6>
            {/* </a> */}
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={8} md={7}>
            <h6>
              <a href="#" onClick={this.goToSignIn}>
                Back to Sign In
              </a>
            </h6>
          </GridItem>
          <GridItem xs={12} sm={8} md={5}>
            <Button color="warning" type="submit">
              Confirm
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  }

  renderResetView() {
    const { classes } = this.props;
    const { userName, userNameState } = this.state;
    return (
      <form
        className={classes.form}
        onSubmit={event => this.sendResetPasswordCode(event)}
      >
        <h4>Reset your Password</h4>
        <GridContainer>
          <GridItem xs={12} sm={6} md={12}>
            <CustomInput
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                onChange: event => this.change(event, 'userName', 'length', 2),
                type: 'length',
                value: userName,
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                placeholder: 'User Name',
              }}
              success={userNameState === 'success'}
              error={userNameState === 'error'}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={8} md={6}>
            <h6>
              <a href="#" onClick={this.goToSignIn}>
                Back to Sign In
              </a>
            </h6>
          </GridItem>
          <GridItem xs={12} sm={8} md={6}>
            <Button color="warning" type="submit">
              Send Code
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  }

  renderForcePasswordView() {
    const { classes } = this.props;
    const {
      newPassword,
      newPasswordState,
      confirmPassword,
      confirmPasswordState,
    } = this.state;
    return (
      <form
        className={classes.form}
        onSubmit={event => this.forceChangePassword(event)}
      >
        <h4>Change Password</h4>
        <GridContainer>
          <GridItem xs={12} sm={6} md={12}>
            <CustomInput
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                onChange: event =>
                  this.change(event, 'newPassword', 'length', 2),
                type: 'password',
                value: newPassword,
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                placeholder: 'New Password',
              }}
              success={newPasswordState === 'success'}
              error={newPasswordState === 'error'}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={12}>
            <CustomInput
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                onChange: event =>
                  this.change(event, 'confirmPassword', 'confirmPassword'),
                type: 'password',
                value: confirmPassword,
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                placeholder: 'Confirm Password',
              }}
              success={confirmPasswordState === 'success'}
              error={confirmPasswordState === 'error'}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={8} md={8} />
          <GridItem xs={12} sm={8} md={4}>
            <Button color="warning" type="submit">
              Submit
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  }

  renderNewPasswordView() {
    const { classes } = this.props;
    const { code, codeState, newPassword, newPasswordState } = this.state;
    return (
      <form
        className={classes.form}
        onSubmit={event => this.submitNewPassword(event)}
      >
        <h4>Reset your Password</h4>
        <GridContainer>
          <GridItem xs={12} sm={6} md={12}>
            <CustomInput
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                onChange: event => this.change(event, 'code', 'otp', 4),
                type: 'password',
                value: code,
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                placeholder: 'Code',
              }}
              success={codeState === 'success'}
              error={codeState === 'error'}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={12}>
            <CustomInput
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                onChange: event =>
                  this.change(event, 'newPassword', 'length', 2),
                type: 'password',
                value: newPassword,
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                placeholder: 'New Password',
              }}
              success={newPasswordState === 'success'}
              error={newPasswordState === 'error'}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={8} md={8}>
            {/* <Link onClick={this.handleClick}>No Account? Create Account</Link> */}
            <h6>
              <a href="#" onClick={this.sendResetPasswordCode}>
                Resend Code
              </a>
            </h6>
          </GridItem>
          <GridItem xs={12} sm={8} md={8}>
            <h6>
              <a href="#" onClick={this.goToSignIn}>
                Back to Sign In
              </a>
            </h6>
          </GridItem>
          <GridItem xs={12} sm={8} md={4}>
            <Button color="warning" type="submit">
              Submit
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  }

  render() {
    const { classes } = this.props;
    const {
      loginView,
      resetView,
      newPasswordView,
      confirmCodeView,
      forcePasswordView,
    } = this.state;
    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <Card login className={classes[this.state.cardAnimaton]}>
                <Snackbar
                  place="tc"
                  color="danger"
                  icon={AddAlert}
                  message={this.state.errMessage || ''}
                  open={this.state.errSnack}
                  closeNotification={() => this.setState({ errSnack: false })}
                  close
                />
                <Snackbar
                  place="tc"
                  color="info"
                  icon={AddAlert}
                  message={this.state.infoMessage || ''}
                  open={this.state.infoSnack}
                  closeNotification={() => this.setState({ infoSnack: false })}
                  close
                />
                <CardBody>
                  {confirmCodeView && this.renderConfirmCodeView()}
                  {loginView && this.renderLoginView()}
                  {resetView && this.renderResetView()}
                  {newPasswordView && this.renderNewPasswordView()}
                  {forcePasswordView && this.renderForcePasswordView()}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setActiveRole,
      addAuthtoReduxStore,
    },
    dispatch
  );

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
  auth: state.auth.auth,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(loginPageStyle)(LoginPage));
