/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
import React from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// @material-ui/icons
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Datetime from 'react-datetime';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Danger from 'components/Typography/Danger.jsx';
import ReactSelect from 'react-select';
import AddAlert from '@material-ui/icons/AddAlert';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import PlacesAutocomplete from 'react-places-autocomplete';
import ReactGA from 'react-ga';

import userProfileStyles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx';

import history from '../../history';
import './autoSelect.css';

import apiCaller from '../../utils/apiCaller';

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: 'success',
      transferAmount: '',
      truck_no: '',
      fromCityAddress: '',
      toCityAddress: '',
      toCity: '',
      truckNumber: '',
      referenceDate: new Date(),
      reference: '',
      notes: '',
      isSuccess: false,
      isError: false,
      success: 'success',
      error: 'danger',
      truckers: [],
      selectedTrucker: '',
      truckerId: '',
      truckerName: '',
      truckerNameState: '',
      fromCityAddressState: '',
      toCityAddressState: '',
      errMessage: '',
      errSnack: false,
      infoMessage: '',
      infoSnack: false,
    };
    this.change = this.change.bind(this);
    this.submitTransaction = this.submitTransaction.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.referenceDateChange = this.referenceDateChange.bind(this);
    this.handleTrucker = this.handleTrucker.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.clearFields = this.clearFields.bind(this);
    this.getvendorList = this.getvendorList.bind(this);
  }

  componentDidMount() {
    this.getvendorList();
  }

  getvendorList() {
    const body = {
      total_items: 1000,
    };
    const truckers = [];
    apiCaller('premium-api/read-vendor-list', 'post', body, true, false)
      .then(response => {
        response.items.forEach(vendorData => {
          const { bank_details } = vendorData;
          const { base } = bank_details;
          let registered = false;
          base.forEach(bank => {
            if (
              bank.active &&
              bank.cashfree_registered &&
              bank.razorpay_registered
            ) {
              registered = true;
            }
          });
          if (registered) {
            truckers.push({
              name: vendorData.company_name,
              label: vendorData.company_name,
              truckerId: vendorData.trucker_id,
              truckerName: vendorData.company_name,
            });
          }
        });
        this.setState({
          truckers,
        });
      })
      .catch(err => {
        this.setState(
          {
            errMessage: err.message,
            isLoading: false,
          },
          this.showNotification('errSnack')
        );
      });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  handleChange(date) {
    this.setState({
      startDate: date,
      date: moment(date).format('MMM Do YY'),
    });
  }

  handleTrucker = selectedTrucker => {
    this.setState({
      selectedTrucker,
      truckerName: selectedTrucker.truckerName,
      truckerId: selectedTrucker.truckerId,
    });
  };

  referenceDateChange(referenceDate) {
    this.setState({ referenceDate });
  }

  handleChangeFromCity = fromCityAddress => {
    this.setState({ fromCityAddress });
  };

  handleChangeToCity = toCityAddress => {
    this.setState({ toCityAddress });
  };

  handleSelectToCity = toCityAddress => {
    this.setState({ toCityAddress });
  };

  handleSelectFromCity = fromCityAddress => {
    this.setState({ fromCityAddress });
  };

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  /**
   * Shows the notification when user click submit for success message.
   */
  showNotification = place => {
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 4000);
    }
  };

  verifyNumberAndGreater(value, minValue) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value) && value >= minValue) {
      return true;
    }
    return false;
  }

  verifyMaxNumber(value, maxValue) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value) && value <= maxValue) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;

      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'greater':
        if (this.verifyNumberAndGreater(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked }, this.updateSettingsInDB);
  };

  resendOtpPayable() {}

  validateAllFields() {
    const { transferAmount, truckerName } = this.state;
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify  Amount
      if (this.verifyNumberAndGreater(transferAmount, 1)) {
        this.setState({
          transferAmountState: 'success',
        });
      } else {
        this.setState({
          transferAmountState: 'error',
        });
        error = true;
      }

      if (this.verifyLength(truckerName, 1)) {
        this.setState({
          tuckerNameState: 'success',
        });
      } else {
        this.setState({
          tuckerNameState: 'error',
        });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('validation Failed');
      }
    });
  }

  submitTransaction() {
    const {
      transferAmount,
      truckNumber,
      truckerId,
      reference,
      referenceDate,
      notes,
      fromCityAddress,
      toCityAddress,
    } = this.state;
    this.setState({
      isLoading: true,
    });
    this.validateAllFields()
      .then(success => {
        const changeDate = moment(referenceDate).format('YYYY-MM-DD hh:mm:ss');
        // const changeDate = referenceDate.local().format('YYYY-MMM-DD h:mm:ss');
        const passData = {
          vendor_id: truckerId,
          amount: Number(transferAmount),
          truck_number: truckNumber,
          from_city: fromCityAddress,
          to_city: toCityAddress,
          date: changeDate === 'Invalid date' ? '' : changeDate,
          notes,
          reference_number: reference,
        };
        apiCaller('premium-api/add-payout', 'post', passData, true, false)
          .then(response => {
            if (response.message === 'success') {
              ReactGA.event({
                category: 'payout',
                action: 'payout_create',
                label: 'Payout Created',
              });
              this.setState(
                {
                  infoMessage: 'Transaction Added Successfully',
                  isLoading: false,
                },
                this.showNotification('infoSnack')
              );
              this.clearFields();
            } else {
              this.setState(
                {
                  errMessage: response.message || 'Fill mandatory fields',
                  isLoading: false,
                },
                this.showNotification('errSnack')
              );
            }
          })
          .catch(err => {
            this.setState(
              {
                errMessage: err.message || 'Fill mandatory fields',
                isLoading: false,
              },
              this.showNotification('errSnack')
            );
          });
      })
      .catch(err => {
        this.setState(
          {
            errMessage: err.message || 'Oops Something went wrong',
            isLoading: false,
          },
          this.showNotification('errSnack')
        );
      });
  }

  clearFields() {
    this.setState({
      transferAmount: '',
      transferAmountState: '',
      truckNumberState: '',
      referenceState: '',
      notesState: '',
      truck_no: '',
      fromCityAddress: '',
      toCityAddress: '',
      toCity: '',
      truckNumber: '',
      reference: '',
      notes: '',
      selectedTrucker: '',
      truckerId: '',
      truckerName: '',
      truckerNameState: '',
      fromCityAddressState: '',
      toCityAddressState: '',
    });
  }

  getTransactionForm() {
    const { classes } = this.props;
    const {
      transferAmount,
      truckNumber,
      referenceDate,
      notes,
      reference,
      referenceState,
      transferAmountState,
      truckerNameState,
      truckNumberState,
      fromCityAddressState,
      toCityAddressState,
      selectedTrucker,
      truckers,
      notesState,
    } = this.state;
    const searchOptions = {
      componentRestrictions: { country: 'in' },
    };
    return (
      <div justify="center">
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              labelText="Transfer Amount *"
              id="amount"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: event =>
                  this.change(event, 'transferAmount', 'greater', 1),
                type: 'greater',
                value: transferAmount,
                rightAlign: true,
                autoFocus: true,
              }}
              autoFocus
              success={transferAmountState === 'success'}
              error={transferAmountState === 'error'}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4} style={{ marginTop: '6px' }}>
            <InputLabel className={classes.labelText} />
            <ReactSelect
              value={selectedTrucker}
              onChange={this.handleTrucker}
              options={truckers}
              placeholder="Select Vendor *"
            />
            {truckerNameState === 'error' && (
              <Danger>{truckerNameState}</Danger>
            )}
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              labelText="Truck Number"
              id="truck number"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: event =>
                  this.change(event, 'truckNumber', 'length', 1),
                value: truckNumber,
              }}
              success={truckNumberState === 'success'}
              error={truckNumberState === 'error'}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="simple-select"
              className={classes.selectLabelSearch}
            />
            <PlacesAutocomplete
              value={this.state.fromCityAddress}
              onChange={this.handleChangeFromCity}
              onSelect={this.handleSelectFromCity}
              searchOptions={searchOptions}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <input
                    {...getInputProps({
                      placeholder: 'Select from city',
                      className: 'location-search-input',
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? {
                            backgroundColor: '#fafafa',
                            cursor: 'pointer',
                          }
                        : {
                            backgroundColor: '#ffffff',
                            cursor: 'pointer',
                          };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            {fromCityAddressState === 'error' && (
              <Danger>{fromCityAddressState}</Danger>
            )}
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="simple-select"
              className={classes.selectLabelSearch}
            />
            <PlacesAutocomplete
              value={this.state.toCityAddress}
              onChange={this.handleChangeToCity}
              onSelect={this.handleSelectToCity}
              searchOptions={searchOptions}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <input
                    {...getInputProps({
                      placeholder: 'Select City',
                      className: 'location-search-input',
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? {
                            backgroundColor: '#fafafa',
                            cursor: 'pointer',
                          }
                        : {
                            backgroundColor: '#ffffff',
                            cursor: 'pointer',
                          };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            {fromCityAddressState === 'error' && (
              <Danger>{toCityAddressState}</Danger>
            )}
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              labelText="Reference"
              id="Refereence"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: event => this.change(event, 'reference', 'length', 1),
                type: 'length',
                value: reference,
              }}
              success={referenceState === 'success'}
              error={referenceState === 'error'}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <InputLabel className={classes.label} />
            <br />
            <FormControl fullWidth>
              <Datetime
                onChange={this.referenceDateChange}
                value={
                  referenceDate !== '' || referenceDate === 'Invalid date'
                    ? moment(referenceDate).format('DD-MM-YYYY')
                    : ''
                }
                timeFormat={false}
                inputProps={{ placeholder: 'Reference Date' }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="Add Notes"
              id="about-me"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: notes,
                onChange: event => this.change(event, 'notes', 'length', 1),
                type: 'length',
              }}
              success={notesState === 'success'}
              error={notesState === 'error'}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  goToListView() {
    history.push('/admin/lhc');
  }

  render() {
    const { classes } = this.props;
    const { errMessage, errSnack, infoMessage, infoSnack } = this.state;
    return (
      <div>
        <GridContainer>
          <Snackbar
            place="tc"
            color="danger"
            icon={AddAlert}
            message={errMessage || ''}
            open={errSnack}
            closeNotification={() => this.setState({ errSnack: false })}
            close
          />
          <Snackbar
            place="tc"
            color="info"
            icon={AddAlert}
            message={infoMessage || ''}
            open={infoSnack}
            closeNotification={() => this.setState({ infoSnack: false })}
            close
          />
          <GridItem xs={12} sm={12} md={12}>
            {this.state.alert}
            <Card>
              <CardHeader color="info">
                <h4 className={classes.cardIconTitle}>
                  <small>Add Transaction</small>
                </h4>
              </CardHeader>
              <CardBody>
                {this.getTransactionForm()}
                {this.state.isLoading ? (
                  <GridContainer justify="flex-end">
                    <GridItem>
                      <CircularProgress
                        className={classes.progress}
                        style={{ color: purple[500], textAlign: 'right' }}
                        thickness={7}
                      />
                    </GridItem>
                  </GridContainer>
                ) : (
                  <Button
                    color="rose"
                    className={classes.updateProfileButton}
                    onClick={this.submitTransaction}
                  >
                    Submit
                  </Button>
                )}
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Snackbar
          place="tc"
          color={this.state.color}
          icon={AddAlert}
          message={this.state.message}
          open={this.state.tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
      </div>
    );
  }
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
});

export default connect(mapStateToProps)(
  withStyles(userProfileStyles)(UserProfile)
);
