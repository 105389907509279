/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';

import footerStyle from 'assets/jss/material-dashboard-pro-react/components/footerStyle';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      versionNumber: '1.0.0',
    };
  }

  componentDidMount() {}

  render() {
    const { classes, fluid, white, black } = this.props;
    const { versionNumber } = this.state;
    const container = cx({
      [classes.container]: !fluid,
      [classes.containerFluid]: fluid,
      [classes.whiteColor]: white,
      [classes.blackColor]: black,
    });

    return (
      <footer className={classes.footer}>
        <div className={container}>
          <div className={classes.left}>
            <List className={classes.list}>
              {/* <ListItem className={classes.inlineBlock}>
              <a href="#home" className={block}>
                {rtlActive ? "الصفحة الرئيسية" : "Home"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="#company" className={block}>
                {rtlActive ? "شركة" : "Company"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="#portfolio" className={block}>
                {rtlActive ? "بعدسة" : "Portfolio"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="#blog" className={block}>
                {rtlActive ? "مدونة" : "Blog"}
              </a>
            </ListItem> */}
            </List>
          </div>
          <p className={classes.right} style={{ color: '#0A0B09' }}>
            {versionNumber ? `V  ${versionNumber}` : null}
          </p>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool,
  black: PropTypes.bool,
};

export default withStyles(footerStyle)(Footer);
