/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-alert */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Table from 'components/Table/Table.jsx';

// @material-ui/icons
import Add from '@material-ui/icons/Add';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import ReactDOM from 'react-dom';
import Switch from '@material-ui/core/Switch';
import Done from '@material-ui/icons/Done';

import ArrowBack from '@material-ui/icons/ArrowBack';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import registerPageStyle from 'assets/jss/material-dashboard-pro-react/views/registerPageStyle';

import Cookies from 'universal-cookie';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import AddAlert from '@material-ui/icons/AddAlert';
import SweetAlert from 'react-bootstrap-sweetalert';
import apiCaller from '../../utils/apiCaller';

const cookies = new Cookies();

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class VendorEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bankModal: false,
      name: '',
      nameState: '',
      contactPerson: '',
      email: '',
      emailState: '',
      phoneNumber: '',
      phoneNumberState: '',
      panNumber: '',
      panNumberState: '',
      gstNumber: '',
      gstNumberState: '',
      banks: [],
      banksData: [],
      accountNumber: '',
      accountNumberState: '',
      ifsc: '',
      ifscState: '',
      bankHolderName: '',
      bankHolderNameState: '',
      bankName: '',
      bankNameState: '',
      editBank: false,
      activeError: '',
      banksState: 'Please Add Bank Details',
      errMessage: '',
      errSnack: false,
      infoMessage: '',
      infoSnack: false,
      approveAlert: false,
      fundAccountLoading: false,
      fundAccountSuccess: false,
      isLoadingBank: false,
      validateError: '',
      isLoadingVerifyBank: false,
      isVerified: false,
      name_at_bank: '',
    };
    this.change = this.change.bind(this);
    this.submitVendor = this.submitVendor.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.validateBankFields = this.validateBankFields.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.verifyRequired = this.verifyRequired.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.retryContactAndFundAccount = this.retryContactAndFundAccount.bind(
      this
    );
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-find-dom-node
    ReactDOM.findDOMNode(this).scrollIntoView();
    const { classes, selectedVendor } = this.props;
    const {
      company_name,
      trucker_name,
      phone_number,
      email_address,
      gst_number,
      pan_number,
      bank_details,
      trucker_id,
    } = selectedVendor;
    const banks = [];
    const initialBanks = [];
    const banksData = [];
    bank_details.forEach((bank, i) => {
      banksData.push({
        color: 'success',
        data: [
          bank.account_name,
          bank.account_number,
          bank.bank_name,
          bank.ifsc_code,
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.block}>
              <FormControlLabel
                control={
                  <Switch
                    checked={bank.active}
                    onChange={this.handleChange(bank, i)}
                    value={bank.active}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      icon: classes.switchIcon,
                      iconChecked: classes.switchIconChecked,
                      bar: classes.switchBar,
                    }}
                  />
                }
              />
            </div>
          </GridItem>,
          bank.razorpay_registered && bank.cashfree_registered ? (
            <Done style={{ color: '#43a047 ' }} />
          ) : (
            <React.Fragment>
              {cookies.get('role') == 'super_admin' ||
              cookies.get('role') == 'admin' ? (
                <Button
                  size="sm"
                  color="warning"
                  className={classes.actionButton}
                  key={i}
                  onClick={() => {
                    this.retryContactAndFundAccount(bank);
                  }}
                >
                  Retry
                </Button>
              ) : (
                <Close style={{ color: '#FF0000 ' }} />
              )}
            </React.Fragment>
          ),
        ],
      });
    });

    this.setState({
      banksData,
      banks,
      // initialBanksLength: banks.length,
      initialBanks,
    });
    // });
    this.setState({
      name: company_name,
      contactPerson: trucker_name || '',
      phoneNumber: phone_number || '',
      email: email_address || '',
      gstNumber: gst_number || '',
      panNumber: pan_number || '',
      banks: bank_details,
      trucker_id,
    });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.goBack();
  }

  /**
   * Shows the notification when user click submit for success message.
   */
  showNotification = place => {
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 4000);
    }
  };

  // function that returns true if value is there, false otherwise
  verifyRequired(value) {
    if (value) {
      return true;
    }
    return false;
  }

  // function that returns true if value is Valid GST, false otherwise
  verifyGst(value) {
    const gstRex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (gstRex.test(value)) {
      return true;
    }
    return false;
  }

  verifyAlpha(value) {
    const emailRex = /^[a-zA-Z() ]+$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  verifyIfsc(value) {
    const regIfsc = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/;
    if (regIfsc.test(value)) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    const emailRex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPan(value) {
    const regpan = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
    if (regpan.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPhone(value) {
    const regPhone = /^[6789]\d{9}$/;
    if (regPhone.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value === undefined) {
      return false;
    }
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'required':
        if (this.verifyRequired(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'alpha':
        if (this.verifyAlpha(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'pan':
        if (this.verifyPan(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'phone':
        if (this.verifyPhone(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'gst':
        if (this.verifyGst(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'ifsc':
        if (this.verifyIfsc(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    const { name, banks, phoneNumber } = this.state;
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Name
      if (this.verifyLength(name, 1)) {
        this.setState({
          nameState: 'success',
        });
      } else {
        this.setState({
          nameState: 'error',
        });
        error = true;
      }

      if (banks.length > 0) {
        this.setState({ banksState: '' });
      } else {
        this.setState({ banksState: 'Please Add Bank Details' });
        error = true;
      }

      // Verify Phone Number
      if (this.verifyPhone(phoneNumber)) {
        this.setState({
          phoneNumberState: 'success',
        });
      } else {
        this.setState({
          phoneNumberState: 'error',
        });
        error = true;
      }

      let numActiveBanks = 0;
      banks.forEach(bank => {
        if (bank.active) {
          numActiveBanks += 1;
        }
      });
      if (numActiveBanks !== 1) {
        this.setState({
          activeError: 'Exactly 1 bank must be active.',
        });
        error = true;
      } else {
        this.setState({
          activeError: '',
        });
      }

      if (!error) {
        resolve(true);
      } else {
        reject(error);
      }
    });
  }

  // Modal Window To Add The Bank Details
  getBankDetailsForm() {
    const { classes } = this.props;
    const {
      bankModal,
      bankHolderName,
      bankHolderNameState,
      accountNumber,
      accountNumberState,
      ifsc,
      ifscState,
      bankName,
      bankNameState,
      name_at_bank,
      isVerified,
      isLoadingVerifyBank,
    } = this.state;
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        classes={{ root: classes.center, paper: classes.modal }}
        style={{ maxWidth: '400px !important', textAlign: 'center' }}
        open={bankModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('bankModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => this.handleClose('bankModal')}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>Add Bank Details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Bank Holder Name *"
                id="bank Holder Name"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'bankHolderName', 'alpha'),
                  value: bankHolderName,
                }}
                success={bankHolderNameState === 'success'}
                error={bankHolderNameState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Account Number *"
                id="account-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'accountNumber', 'length', 10),
                  type: 'length',
                  value: accountNumber,
                }}
                success={accountNumberState === 'success'}
                error={accountNumberState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="IFSC Code *"
                id="ifsc-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event => this.change(event, 'ifsc', 'ifsc'),
                  type: 'ifsc',
                  value: ifsc,
                }}
                success={ifscState === 'success'}
                error={ifscState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Bank name *"
                id="bank-name"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'bankName', 'length', 2),
                  type: 'length',
                  value: bankName,
                }}
                success={bankNameState === 'success'}
                error={bankNameState === 'error'}
              />
            </GridItem>
          </GridContainer>
          {isVerified && (
            <GridContainer justify="flex-start" style={{ marginTop: '20px' }}>
              <GridItem xs={12} sm={12} md={12}>
                <div>
                  <p
                    style={{
                      textAlign: 'left',
                      fontSize: '18px',
                      fontWeight: 500,
                      marginBottom: '10px',
                    }}
                  >
                    <u>Verified Details</u>
                  </p>
                  <p
                    style={{
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 500,
                      marginBottom: '5px',
                    }}
                  >
                    Bank Holder Name:{' '}
                    <span style={{ color: '#008000' }}>{name_at_bank}</span>
                  </p>
                </div>
              </GridItem>
            </GridContainer>
          )}
        </DialogContent>
        <p style={{ color: 'red' }}>{this.state.validateError}</p>
        <DialogActions className={classes.modalFooter}>
          {this.state.isLoadingBank ? (
            <CircularProgress
              className={classes.progress}
              style={{ color: purple[500] }}
              thickness={4}
            />
          ) : (
            <div>
              {isLoadingVerifyBank ? (
                <CircularProgress
                  className={classes.progress}
                  style={{ color: purple[500] }}
                  thickness={4}
                />
              ) : (
                <Button color="primary" onClick={() => this.verifyBanks()}>
                  Verify
                </Button>
              )}

              <Button
                color="primary"
                onClick={() => this.addBanks()}
                disabled={!isVerified}
              >
                Add
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  /**
   * Will return the Bank Details Component
   * @function
   */
  getBanks() {
    const { classes } = this.props;
    const { banksData, banksState, activeError } = this.state;
    return (
      <Card>
        <CardHeader style={{ marginBottom: '2px', paddingBottom: '2px' }}>
          <h4>Add Banks</h4>
        </CardHeader>
        {banksData.length > 0 ? (
          <React.Fragment>
            <CardBody className={classes.customCardContentClass}>
              <Table
                hover
                tableHead={[
                  'Name',
                  'Account No',
                  'Bank',
                  'Ifsc',
                  'Active',
                  'Fund Account Status',
                ]}
                tableData={banksData}
              />
            </CardBody>
            <div style={{ textAlign: 'center' }}>
              <p
                className={`${classes.textCenter} ${classes.title}`}
                style={{ textAlign: 'center', color: 'red' }}
              >
                {activeError}
              </p>
            </div>
          </React.Fragment>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <p
              className={`${classes.textCenter} ${classes.title}`}
              style={{ textAlign: 'center', color: 'red' }}
            >
              {banksState}
            </p>
          </div>
        )}
        <CardFooter>
          <Button
            color="info"
            onClick={() => this.handleClickOpen('bankModal')}
            justIcon
          >
            <Add />
          </Button>
        </CardFooter>
      </Card>
    );
  }

  // Will Verify Bank Details
  validateBankFields() {
    const { accountNumber, bankHolderName, ifsc, bankName } = this.state;
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Account Number
      if (this.verifyLength(accountNumber, 5)) {
        this.setState({
          accountNumberState: 'success',
        });
      } else {
        this.setState({ accountNumberState: 'error' });
        error = true;
      }

      // Verify Account Number
      if (this.verifyAlpha(bankHolderName)) {
        this.setState({
          bankHolderNameState: 'success',
        });
      } else {
        this.setState({ bankHolderNameState: 'error' });
        error = true;
      }

      // Verify IFSC
      if (this.verifyIfsc(ifsc)) {
        this.setState({
          ifscState: 'success',
        });
      } else {
        this.setState({ ifscState: 'error' });
        error = true;
      }

      // Verify Bank Name
      if (this.verifyLength(bankName, 2)) {
        this.setState({ bankNameState: 'success' });
      } else {
        this.setState({ bankNameState: 'error' });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        reject(error);
      }
    });
  }

  //  Handle BankAccountActive Toggle
  handleChange = (currentBank, i) => event => {
    const { classes } = this.props;
    const { banks, banksData } = this.state;
    // If Bank Edit is Success (Compared with the Previous values)
    this.setState({
      isBankEdit: true,
    });
    banks[i].active = event.target.checked;
    banksData[i].data = [
      currentBank.account_name,
      currentBank.account_number,
      currentBank.bank_name,
      currentBank.ifsc_code,
      <GridItem xs={12} sm={12} md={12}>
        <div className={classes.block}>
          <FormControlLabel
            control={
              <Switch
                checked={banks[i].active}
                onChange={this.handleChange(banks[i], i)}
                value={banks[i].active}
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  icon: classes.switchIcon,
                  iconChecked: classes.switchIconChecked,
                  bar: classes.switchBar,
                }}
              />
            }
          />
        </div>
      </GridItem>,
      currentBank.razorpay_registered && currentBank.cashfree_registered ? (
        <Done style={{ color: '#43a047 ' }} />
      ) : (
        <React.Fragment>
          {cookies.get('role') == 'super_admin' ||
          cookies.get('role') == 'admin' ? (
            <div>
              {currentBank.hasOwnProperty('fund_id') && (
                <Button
                  size="sm"
                  color="warning"
                  className={classes.actionButton}
                  key={i}
                  onClick={() => {
                    this.retryContactAndFundAccount(currentBank);
                  }}
                >
                  retry
                </Button>
              )}
            </div>
          ) : (
            <Close style={{ color: '#FF0000 ' }} />
          )}
        </React.Fragment>
      ),
    ];

    this.setState({
      banksData,
      banks,
    });
  };

  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  // Verify Bank Details.
  verifyBanks() {
    const { accountNumber, bankHolderName, ifsc, phoneNumber } = this.state;
    this.validateBankFields()
      .then(success => {
        this.setState({
          isLoadingVerifyBank: true,
        });
        const body = {
          bank_list: [
            {
              account_number: accountNumber,
              ifsc_code: ifsc,
              account_name: bankHolderName,
              phone: phoneNumber,
            },
          ],
        };
        apiCaller('premium-api/verify-bank', 'post', body, true, false)
          .then(res => {
            if (res.hasOwnProperty('list')) {
              if (res.list.hasOwnProperty('all_banks')) {
                const bankData = res.list.all_banks;
                bankData.forEach(newBank => {
                  if (newBank.account_verified) {
                    this.setState({
                      isLoadingVerifyBank: false,
                      validateError: '',
                      isVerified: true,
                      name_at_bank: newBank.name_at_bank,
                    });
                  } else {
                    this.setState({
                      isLoadingVerifyBank: false,
                      isVerified: false,
                      validateError:
                        'We are unable to validate the bank details. Please check if the bank details are correct.',
                    });
                  }
                });
              } else {
                this.setState({
                  isLoadingVerifyBank: false,
                  validateError: 'Something went wrong please try again.',
                });
              }
            }
          })
          .catch(err => {
            console.log('err', err);
            this.setState({
              isLoadingBank: false,
              validateError: 'Something went wrong please try again.',
            });
          });
      })
      .catch(err => {
        console.log('err', err);
        this.setState({
          isLoadingBank: false,
          validateError: 'Please check if the bank details are correct.',
        });
      });
  }

  // Will Add Banks
  addBanks() {
    const { classes } = this.props;
    const {
      banks,
      banksData,
      accountNumber,
      bankName,
      bankHolderName,
      initialBanks,
      ifsc,
    } = this.state;
    const bank = {
      account_number: accountNumber,
      bank_name: bankName,
      ifsc_code: ifsc,
      account_name: bankHolderName,
      active: false,
    };
    if (
      banks.some(
        bank => bank.account_number === accountNumber && bank.ifsc_code === ifsc
      )
    ) {
      alert('Account already exists!');
    } else {
      this.validateBankFields()
        .then(success => {
          this.setState({
            isLoadingBank: true,
          });
          this.setState({ isLoadingBank: false, validateError: '' });
          banks.push({
            account_number: accountNumber,
            bank_name: bankName,
            ifsc_code: ifsc,
            account_name: bankHolderName,
            active: true,
          });
          initialBanks.push({
            account_number: accountNumber,
            bank_name: bankName,
            ifsc_code: ifsc,
            account_name: bankHolderName,
            active: false,
          });
          banksData.push({
            color: 'success',
            data: [
              bankHolderName,
              accountNumber,
              bankName,
              ifsc,
              <GridItem xs={12} sm={12} md={12}>
                <div className={classes.block}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={false}
                        onChange={this.handleChange(bank, banksData.length)}
                        value={false}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          icon: classes.switchIcon,
                          iconChecked: classes.switchIconChecked,
                          bar: classes.switchBar,
                        }}
                      />
                    }
                  />
                </div>
              </GridItem>,
              '',
            ],
          });
          this.handleClose('bankModal');
          this.setState({
            banksData,
            banks,
            accountNumber: '',
            ifsc: '',
            bankName: '',
            bankHolderName: '',
            accountNumberState: '',
            ifscState: '',
            bankNameState: '',
            isVerified: false,
            isLoadingVerifyBank: false,
            name_at_bank: '',
          });
        })
        .catch(err => {
          console.log('err', err);
          this.setState({
            isLoadingBank: false,
            validateError: 'Please check if the bank details are correct.',
          });
        });
    }
  }

  retryContactAndFundAccount(bank) {
    this.setState({
      approveAlert: true,
      fundAccountLoading: true,
      razorpayError: '',
    });
    this.verifyBank(bank)
      .then(res => {
        const { selectedVendor } = this.props;
        const body = {
          type: 'contact_fund_id',
          beneficiary_id: selectedVendor.trucker_id,
          account_name: bank.account_name,
          account_number: bank.account_number,
          ifsc_code: bank.ifsc_code,
        };
        apiCaller(
          'premium-api/retry-id-transaction-account',
          'post',
          body,
          true,
          false
        )
          .then(
            response => {
              if (response.message === 'success') {
                this.setState({
                  fundAccountLoading: false,
                  fundAccountSuccess: true,
                });
                setTimeout(() => {
                  this.props.goBack();
                }, 1000);
              }
            },
            err => {
              this.setState({
                fundAccountLoading: false,
                fundAccountSuccess: false,
                razorpayError: err.message || 'Something Went Wrong !..',
              });
            }
          )
          .catch(err => {
            console.log('error', err);
            this.setState({
              fundAccountLoading: false,
              fundAccountSuccess: false,
              razorpayError: err.message || 'Something Went Wrong!!..',
            });
          });
      })
      .catch(err => {
        this.setState({
          fundAccountLoading: false,
          fundAccountSuccess: false,
          razorpayError:
            'We are unable to validate the bank details. Please check if the bank details are correct.',
        });
      });
  }

  /**
   * closes the modal button
   * @param {*} modal
   */
  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
    this.setState({ editBank: false });
    if (modal === 'bankModal') {
      this.setState({
        accountNumber: '',
        ifsc: '',
        bankName: '',
        bankHolderName: '',
        accountNumberState: '',
        ifscState: '',
        bankNameState: '',
        bankHolderNameState: '',
        isVerified: '',
        isLoadingVerifyBank: false,
        name_at_bank: '',
      });
    }
  }

  submitVendor() {
    const {
      name,
      contactPerson,
      email,
      phoneNumber,
      gstNumber,
      panNumber,
      banks,
      trucker_id,
    } = this.state;
    this.validateAllFields().then(success => {
      if (success) {
        const body = {
          vendor_id: trucker_id,
          company_name: name,
          contact_name: contactPerson,
          email_address: email,
          phone_number: phoneNumber,
          gst_number: gstNumber,
          pan_number: panNumber,
          bank_info: banks,
        };
        this.setState({
          isLoading: true,
        });
        apiCaller('premium-api/add-vendor', 'post', body, true, false)
          .then(response => {
            this.setState({ isLoading: false });
            this.setState(
              {
                infoMessage: 'Vendor Edited Successfully',
                isLoading: false,
              },
              this.showNotification('infoSnack')
            );
            this.props.goBack();
          })
          .catch(err => {
            this.setState({ isLoading: false });
            console.log('err', err);
          });
      } else {
        this.setState({ isLoading: false });
      }
    });
  }

  verifyBank(bank) {
    const { phoneNumber } = this.state;
    return new Promise((resolve, reject) => {
      const body = {
        bank_list: [
          {
            account_number: bank.account_number,
            ifsc_code: bank.ifsc_code,
            account_name: bank.account_name,
            phone: phoneNumber,
          },
        ],
      };
      apiCaller('premium-api/verify-bank', 'post', body, true, false).then(
        res => {
          if (res.hasOwnProperty('list')) {
            if (res.list.hasOwnProperty('all_banks')) {
              const bankData = res.list.all_banks;
              bankData.forEach(newBank => {
                if (newBank.account_verified) {
                  resolve('success');
                } else {
                  reject('error');
                }
              });
            } else {
              reject('error');
            }
          } else {
            reject('error');
          }
        }
      );
    });
  }

  render() {
    const { classes } = this.props;
    const {
      alert,
      nameState,
      name,
      contactPerson,
      phoneNumber,
      phoneNumberState,
      email,
      emailState,
      gstNumber,
      gstNumberState,
      panNumber,
      panNumberState,
      isLoading,
      errMessage,
      errSnack,
      infoMessage,
      infoSnack,

      approveAlert,
      fundAccountSuccess,
      fundAccountLoading,
      razorpayError,
    } = this.state;
    return (
      <>
        <GridContainer {...this.props}>
          <GridItem xs={12} sm={12} md={12}>
            {alert}
            <Snackbar
              place="tc"
              color="danger"
              icon={AddAlert}
              message={errMessage || ''}
              open={errSnack}
              closeNotification={() => this.setState({ errSnack: false })}
              close
            />
            <Snackbar
              place="tc"
              color="info"
              icon={AddAlert}
              message={infoMessage || ''}
              open={infoSnack}
              closeNotification={() => this.setState({ infoSnack: false })}
              close
            />
            <Card>
              <CardHeader color="warning">
                <CardIcon
                  color="primary"
                  // eslint-disable-next-line react/destructuring-assignment
                  onClick={this.props.goBack}
                >
                  <ArrowBack />
                </CardIcon>
                <h4>
                  Vendor <small />
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Company Name *"
                      id="username"
                      formControlProps={{ fullWidth: true }}
                      success={nameState === 'success'}
                      error={nameState === 'error'}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'name', 'length', 1),
                        type: 'length',
                        value: name,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Contact Person "
                      id="contact-person"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'contactPerson', 'length', 1),
                        type: 'length',
                        value: contactPerson,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Phone Number *"
                      id="phone-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'phoneNumber', 'phone'),
                        type: 'phone',
                        value: phoneNumber,
                      }}
                      success={phoneNumberState === 'success'}
                      error={phoneNumberState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      success={emailState === 'success'}
                      error={emailState === 'error'}
                      labelText="Email address "
                      id="email-address"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => this.change(event, 'email', 'email'),
                        type: 'email',
                        value: email,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="GST Number "
                      id="gst-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'gstNumber', 'gst'),
                        type: 'gst',
                        value: gstNumber,
                      }}
                      success={gstNumberState === 'success'}
                      error={gstNumberState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="PAN Number"
                      id="pan-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'panNumber', 'pan'),
                        type: 'pan',
                        value: panNumber,
                      }}
                      success={panNumberState === 'success'}
                      error={panNumberState === 'error'}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {this.getBanks()}
                    {this.getBankDetailsForm()}
                  </GridItem>
                </GridContainer>
                {isLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    thickness={7}
                  />
                ) : (
                  <Button
                    color="warning"
                    onClick={this.submitVendor}
                    className={classes.updateProfileButton}
                  >
                    Submit
                  </Button>
                )}
                {/* <Clearfix /> */}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {approveAlert && (
          <SweetAlert
            confirmBtnText="Done"
            title=""
            onConfirm={() => {
              this.setState({ approveAlert: false });
            }}
            onCancel={() => {
              this.setState({ approveAlert: false });
            }}
            confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
          >
            <h3>
              <span className={this.props.classes.rajdhaniFont}>
                Retry Fund Account
              </span>
            </h3>
            <GridContainer>
              <GridItem xs={8}>
                <span style={{ fontSize: 20 }}> Creating Fund Account</span>
              </GridItem>
              <GridItem xs={4}>
                {fundAccountLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    size={20}
                    thickness={3}
                  />
                ) : fundAccountSuccess ? (
                  <Done style={{ color: '#43a047 ' }} />
                ) : (
                  <Close style={{ color: '#FF0000 ' }} />
                )}
              </GridItem>
              <GridItem xs={12}>
                <span
                  style={{
                    fontSize: 15,
                    float: 'left',
                    color: 'red',
                  }}
                >
                  {razorpayError}
                </span>
              </GridItem>
            </GridContainer>
          </SweetAlert>
        )}
      </>
    );
  }
}

VendorEdit.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
  systemDetails: state.main.systemDetails,
});

export default connect(mapStateToProps)(
  withStyles(registerPageStyle)(VendorEdit)
);
