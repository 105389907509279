import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';

const userProfileStyles = {
  cardTitle,
  ...customSelectStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400',
    },
  },
  datePicker: {
    border: 'none',
    borderBottom: 'solid #b7b2b2 1.2px',
    marginLeft: '15px',
    marginTop: '40px',
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center',
  },
  description: {
    color: '#999999',
  },
  updateProfileButton: {
    float: 'right',
  },
  ...sweetAlertStyle,
};
export default userProfileStyles;
