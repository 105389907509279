/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { Avatar, CircularProgress, IconButton } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DocumentDialog from './DocumentDialog';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: { backgroundColor: 'black' },
  tableCell: { color: 'white' },
  tableBodyCell: { height: '60px' },
  viewButton: { backgroundColor: '#3366FF' },
});

export default function DenseTable({
  fetching,
  brokerDetails,
  triggerRefresh,
}) {
  const classes = useStyles();

  function createData(agent, agentName, broker, status) {
    return {
      agentName,
      broker,
      status,
    };
  }

  const rows = brokerDetails?.map(broker =>
    createData(broker, broker.agent_name, broker, broker.approval_status)
  );

  return (
    <div>
      {fetching ? (
        <CircularProgress />
      ) : (
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableCell} align="left" />
                <TableCell className={classes.tableCell} align="left">
                  Agent Name
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Documents
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Status
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  <IconButton aria-label="refresh" component="span">
                    <AutorenewIcon
                      style={{ color: 'white' }}
                      onClick={() => triggerRefresh()}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map(row => (
                <TableRow
                  key={row.transactionSerial}
                  className={classes.tableBodyCell}
                >
                  <TableCell component="th" scope="row" align="left">
                    <Avatar alt="Remy Sharp" />
                  </TableCell>
                  <TableCell component="th" scope="row" align="left">
                    {row.agentName}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <DocumentDialog
                      brokerDetails={row.broker}
                      handleRefreshList={() => triggerRefresh()}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color:
                        row.status == 'requested'
                          ? 'gray'
                          : row.status == 'approved'
                          ? 'green'
                          : row.status == 'rejected'
                          ? 'red'
                          : 'black',
                    }}
                  >
                    {row.status}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
