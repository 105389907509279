import axios from 'axios';

const { REACT_APP_API_ESP_SUPER_ADMIN } = process.env;
export default function apiCaller(endPoint, data) {
  const url = `${REACT_APP_API_ESP_SUPER_ADMIN}${endPoint}`;
  return axios({
    method: 'post',
    url,
    data,
  });
}
